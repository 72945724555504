// @flow
import React from 'react';
import {PressableProps} from 'react-native';
import {Pressable} from 'react-native';

const Touchable = ({
    style = null,
    children,
    disabled = false,
    ...rest
}: PressableProps) => {
    return (
        <Pressable
            accessibilityRole="button"
            style={
                style
                    ? style
                    : ({pressed}) => ({
                        opacity: (pressed && !disabled) ? 0.7 : 1,
                        cursor: disabled ? 'default' : 'pointer',
                    })
            }
            {...rest}
        >
            {children}
        </Pressable>
    );
};

export default Touchable;