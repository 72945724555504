// @flow
import React, {useContext} from 'react';
import {ScrollView, View} from 'react-native';
import locales from "../../../locales/locales";
import FootprintTopLeft from "../../UI/Ornaments/FootprintTopLeft";
import FootprintTopRight from "../../UI/Ornaments/FootprintTopRight";
import OnboardingBottom from "../../UI/Ornaments/OnboardingBottom";
import DecoratedLayout from "../../UI/Layouts/DecoratedLayout/DecoratedLayout";
import LightPageHeader from "../../UI/PageHeaders/LightPageHeader/LightPageHeader";
import Button from "../../UI/Buttons/Button/Button";
import BasicCard from "../../UI/Cards/BasicCard/BasicCard";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Heading from "../../UI/Typos/Heading/Heading";
import Body from "../../UI/Typos/Body/Body";
import Config from "../../../Config/Config";

type Props = {
    thematicTitle: string,
    thematicIcon: Node,
    thematicIndex: number,
    thematicsNumber: number,
    thematicText: string,
    onPressReturn: () => void,
    onPressNext: () => void,
};

const ThematicMainScreen = ({
    thematicText,
    thematicsNumber,
    thematicIndex,
    thematicTitle,
    thematicIcon,
    onPressNext,
    onPressReturn,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);

    return (
        <DecoratedLayout
            bottomRightOrnament={(<OnboardingBottom />)}
            topLeftOrnament={(<FootprintTopLeft />)}
            topRightOrnament={(<FootprintTopRight />)}
        >
            <LightPageHeader
                backButton
                onPress={onPressReturn}
            />
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <BasicCard
                    customStyle={{backgroundColor: colors.background.card}}
                >
                    <ScrollView
                        contentContainerStyle={{alignItems: 'center'}}
                        showsVerticalScrollIndicator={Config.showVScrollBar}
                    >
                        <Heading
                            centered
                            customStyle={{marginBottom: spacing[2]}}
                            variant='onboarding_title'
                        >
                            {thematicTitle}
                        </Heading>
                        <Heading customStyle={{marginBottom: spacing[2]}} variant='h3'>
                            {`Thématique ${thematicIndex}/${thematicsNumber}`}
                        </Heading>
                        {thematicIcon}
                        <Body centered customStyle={{marginTop: spacing[2]}} variant='onboarding_text'>
                            {thematicText}
                        </Body>
                    </ScrollView>
                </BasicCard>
            </View>
            <Button
                customStyle={{marginBottom: spacing[2]}}
                onPress={onPressNext}
                text={locales.t('global.actions.next')}
            />
        </DecoratedLayout>
    );
};

export default ThematicMainScreen;