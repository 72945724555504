// @flow
import React, {useContext} from 'react';
import {connect} from "react-redux";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import type {Store as TypeStore} from "../../../../../Types/Reducers/Store";
import IcCamera from "../../../../UI/Icons/CarbonFootprint/Consumption/IcCamera";
import IcDesktopComputer from "../../../../UI/Icons/CarbonFootprint/Consumption/IcDesktopComputer";
import IcHomeCinema from "../../../../UI/Icons/CarbonFootprint/Consumption/IcHomeCinema";
import IcLaptop from "../../../../UI/Icons/CarbonFootprint/Consumption/IcLaptop";
import IcMobilePhone from "../../../../UI/Icons/CarbonFootprint/Consumption/IcMobilePhone";
import IcPrinter from "../../../../UI/Icons/CarbonFootprint/Consumption/IcPrinter";
import IcScreen from "../../../../UI/Icons/CarbonFootprint/Consumption/IcScreen";
import IcSpeakers from "../../../../UI/Icons/CarbonFootprint/Consumption/IcSpeakers";
import IcTablet from "../../../../UI/Icons/CarbonFootprint/Consumption/IcTablet";
import IcTelevision from "../../../../UI/Icons/CarbonFootprint/Consumption/IcTelevision";
import IcVideoGamePad from "../../../../UI/Icons/CarbonFootprint/Consumption/IcVideoGamePad";
import Body from "../../../../UI/Typos/Body/Body";
import MultiAnswerCountContainer from "../../MultiAnswerCountContainer";
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import {getTechnologyPurchases} from "../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer";
import type {ElementMap} from "../../../../../Types/Reducers/ElementMap";
import type {
    DeviceValueCount,
    TechnologyDeviceType,
} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
    technologyPurchases: ElementMap<TechnologyDeviceType, DeviceValueCount>,
    addTechnologyPurchase: TechnologyDeviceType => void,
    removeTechnologyPurchase: TechnologyDeviceType => void,
};

const options: Array<{value: TechnologyDeviceType, icon: Node}> = [
    {value: 'tv', icon: IcTelevision},
    {value: 'laptop', icon: IcLaptop},
    {value: 'computer', icon: IcDesktopComputer},
    {value: 'screen', icon: IcScreen},
    {value: 'tablet', icon: IcTablet},
    {value: 'smartphone', icon: IcMobilePhone},
    {value: 'console', icon: IcVideoGamePad},
    {value: 'home_cinema', icon: IcHomeCinema},
    {value: 'speaker', icon: IcSpeakers},
    {value: 'printer', icon: IcPrinter},
    {value: 'camera', icon: IcCamera},
];

const QTechnologyPurchases = ({
    technologyPurchases,
    addTechnologyPurchase,
    removeTechnologyPurchase,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <MultiAnswerCountContainer
            addItem={addTechnologyPurchase}
            listHeaderComponent={(
                <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                    {"Plusieurs réponses possibles"}
                </Body>
            )}
            numColumns={2}
            options={options}
            removeItem={removeTechnologyPurchase}
            selectedValues={technologyPurchases}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    ...props,
    technologyPurchases: getTechnologyPurchases(store),
});

const mapDispatchToProps = {
    addTechnologyPurchase: CFActions.addTechnologyPurchase,
    removeTechnologyPurchase: CFActions.removeTechnologyPurchase,
};

export default connect(mapStateToProps, mapDispatchToProps)(QTechnologyPurchases);
