// @flow
import React, {Node, useMemo} from 'react';
import {FlatList, View} from 'react-native';
import RoundAnswerItem from './RoundAnswerItem';
import locales from '../../../locales/locales';

type Option<T> = { value: T, icon: Node };
type Item<T> = { item: Option<T> };

export type Props<T> = {
    options: Array<Option<T>>,
    numColumns: number,
    selectedValue: T,
    handleChange: (?T) => null,
};

function SoloAnswerContainer<T>({
    options,
    numColumns,
    selectedValue,
    handleChange,
}: Props<T>){
    function handlePress(selected: Option<T>){
        if (selectedValue === selected.value) handleChange(null);
        else handleChange(selected.value);
    }

    function renderAnswer({item}: Item<T>){
        return (
            <RoundAnswerItem
                Icon={item.icon}
                cardLegend={locales.t(`cf.${item.value}`)}
                horizontal={numColumns === 1}
                onPress={() => handlePress(item)}
                selected={item.value === selectedValue}
            />
        );
    }

    const additionalMultiColumnStyle = useMemo(
        () => numColumns > 1 ? {columnWrapperStyle: {justifyContent: 'space-evenly'}} : {},
        [numColumns]
    );

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: 10}} />)}
            data={options}
            extraData={selectedValue}
            keyExtractor={item => item.value}
            numColumns={numColumns}
            renderItem={renderAnswer}
            {...additionalMultiColumnStyle}
        />
    );
}

export default SoloAnswerContainer;