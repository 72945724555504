// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getDailyStreamingVideoHours} from '../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import locales from "../../../../../locales/locales";
import IcStreaming from "../../../../UI/Icons/CarbonFootprint/Consumption/IcStreaming";
import Heading from "../../../../UI/Typos/Heading/Heading";
import Body from "../../../../UI/Typos/Body/Body";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderLabelContainer from "../../../../Shared/ESlider/SliderLabelContainer";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";
import type {QuantityType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setDailyStreamingVideoHours: string => void,
  dailyStreamingVideoHours: number,
};

const frequencies = ['never', 'little', 'alot', 'enormously', 'madly'];

function getNumberFromQuantityType(value: QuantityType): string{
    switch (value){
        case 'never':
            return '';
        case 'little':
            return '1 à 2h / semaine';
        case 'alot':
            return '4 à 5h / semaine';
        case 'enormously':
            return '1 à 2h / jour';
        case 'madly':
            return '4 à 5h / jour';
        default:
            return '';
    }
}

const QDailyStreamingVideoHours = ({
    setDailyStreamingVideoHours,
    dailyStreamingVideoHours,
}: Props) => {
    function handleChange(value: number){
        const frequency = frequencies[value];
        setDailyStreamingVideoHours(frequency);
    }

    return (
        <>
            <SliderScaleMinMax
                max='À la folie'
                min='Jamais'
            />
            <ESlider
                defaultValue={frequencies?.indexOf?.(dailyStreamingVideoHours) ?? 0}
                hasTrackMarks
                max={frequencies.length - 1}
                onSlidingComplete={handleChange}
                renderBelowLabel={(value) => (
                    <SliderLabelContainer>
                        <Heading centered variant='h3'>
                            {locales.t(`cf.${frequencies[value]}`)}
                        </Heading>
                        <Body centered variant='paragraph'>
                            {getNumberFromQuantityType(frequencies[value])}
                        </Body>
                    </SliderLabelContainer>
                )}
                step={1}
                thumbIcon={(<SliderThumbBackground><IcStreaming /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    dailyStreamingVideoHours: getDailyStreamingVideoHours(store),
    ...props,
});

const mapDispatchToProps = {setDailyStreamingVideoHours: CFActions.setDailyStreamingVideoHours};

export default connect(mapStateToProps, mapDispatchToProps)(QDailyStreamingVideoHours);
