/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcMicrowave = ({size = 35}: Props) => (
    <Svg
        className="Microwave_svg__icon"
        height={size}
        viewBox="0 0 1024 1024"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M1024 800a64 64 0 0 1-64 64H64a64 64 0 0 1-64-64V223.968a64 64 0 0 1 64-64h896a64 64 0 0 1 64 64V800z"
            fill="#EAEAEA"
        />
        <Path
            d="M800 768a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V256a32 32 0 0 1 32-32h672a32 32 0 0 1 32 32v512z"
            fill="#469FCC"
        />
        <Path
            d="m699.328 392.576-67.872-67.872a16 16 0 0 0-22.624 22.624l67.872 67.872a16 16 0 0 0 22.624-22.624zm-134.944-46.08a16 16 0 0 0-22.624 22.624l113.12 113.12a16 16 0 0 0 22.624-22.624l-113.12-113.12z"
            fill="#FFF"
        />
        <Path d="M864 271.936a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#434854" />
        <Path d="M864 431.968a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#3AAD73" />
        <Path d="M64 864h128v32H64zm768 0h128v32H832z" fill="#434854" />
        <Path d="M912 351.968a48 48 0 0 0 48-48c0-5.632-1.152-10.976-2.944-16-6.624 18.592-24.192 32-45.056 32s-38.432-13.408-45.056-32a47.456 47.456 0 0 0-2.944 16 48 48 0 0 0 48 48zm0 128a47.872 47.872 0 0 1-45.056-32 47.456 47.456 0 0 0-2.944 16 48 48 0 0 0 96 0c0-5.632-1.152-10.976-2.944-16-6.624 18.592-24.192 32-45.056 32z" />
    </Svg>
);

export default IcMicrowave;
