// @flow
import * as React from 'react';
import type {Node} from 'react';
import {Modal} from "react-native";
import InfoMM from "../ManagedModals/InfoMM/InfoMM";
import locales from "../../../../locales/locales";
import HelpMM from "../ManagedModals/HelpMM/HelpMM";
import ConfirmMM, {ConfirmModalOptions} from "../ManagedModals/ConfirmMM/ConfirmMM";
import EModal from "../EModal";
import WebShareFootprintModal from "../../../Sections/Footprint/WebShareFootprintModal";
import ImageZoomMM from "../ManagedModals/ImageZoomMM/ImageZoomMM";

type Props = {};

type ManagedModalPriority = 1 | 2 | 3 | 4 | 5;

type ManagedModal = {
    key: string,
    priority: ManagedModalPriority,
    modalContent: Node,
    animationType?: string,
};

type State = {
    running: boolean,
    visible: boolean,
    currentModal: ?ManagedModal,
};

class ModalManager extends React.PureComponent<Props, State>{
    constructor(){
        super();

        this.queue = [];
        this.state = {
            running: true,
            visible: false,
            currentModal: null,
        };
    }

    queue: Array<ManagedModal>;

    pause(): void{
        this.setState({running: false});
    }

    resume(): void{
        this.setState({running: true});
        this.showNext();
    }

    showNext(): void{
        const {running} = this.state;
        if(running){
            const nextModal = this.queue?.shift();
            if(nextModal){
                this.setState({
                    visible: true,
                    currentModal: nextModal,
                });
            }
        }
    }

    show(modal: ManagedModal): void{
        const {currentModal, visible} = this.state;
        // already have a modal: compare priorities
        if(currentModal && visible){
            if(modal.priority < (currentModal?.priority ?? 4)){
            // new modal has priority
            // put current modal back at end of queue, then new modal, then reverse
                this.queue?.push(currentModal, modal);
                this.queue?.reverse();
                this.showNext();
            } else
            // new modal does not have more priority: put in queue
            // will be shown on showNext() after current is closed
                this.queue?.push(modal);
        } else {
            // no current modal: default behavior
            this.queue?.push(modal);
            this.showNext();
        }
    }

    closeCurrentModal(): void{
        this.setState({
            visible: false,
            currentModal: null,
        });
        this.showNext();
    }

    render(){
        const {visible, currentModal} = this.state;
        return (
            <Modal
                animationType={currentModal?.animationType ?? "fade"}
                ariaHideApp
                closeModal={this.closeCurrentModal.bind(this)}
                onDismiss={() => null}
                onRequestClose={this.closeCurrentModal.bind(this)}
                onShow={() => null}
                statusBarTranslucent
                transparent
                visible={visible}
            >
                {
                    currentModal?.modalContent
                }
            </Modal>
        );
    }
}

let compRef: ?ModalManager;

const instance = (
    <ModalManager
        ref={(r) => {
            compRef = r;
        }}
    />
);

const showModal = (modal: ManagedModal) => {
    if(compRef) compRef.show(modal);
};

const closeModal = () => {
    if(compRef) compRef.closeCurrentModal();
};

const pauseModals = (): void => compRef ? compRef.pause() : () => null;
const resumeModals = (): void => compRef ? compRef.resume() : () => null;

const showInfoModal = (modal: ManagedModal, title = '') => showModal({
    ...modal,
    modalContent: (
        <EModal onClose={closeModal}>
            <InfoMM
                content={modal.modalContent}
                title={title}
            />
        </EModal>
    ),
});

const showHelpModal = (content: string, onClose: () => any = () => null) => showModal({
    priority: 5,
    animationType: 'slide',
    modalContent: (
        <HelpMM
            content={content}
            onClose={() => {
                onClose?.();
                closeModal();
            }}
        />
    ),
});

const showConfirmModal = (options: ConfirmModalOptions) => showModal({
    priority: 1,
    modalContent: (
        <EModal
            onClose={closeModal}
        >
            <ConfirmMM
                options={{
                    cancelText: options.cancelText ?? locales.t('global.actions.cancel'),
                    validText: options.validText ?? locales.t('global.actions.validate'),
                    onCancel: options.onCancel ? () => {
                        options.onCancel();
                        closeModal();
                    } : undefined,
                    onValidate: options?.onValidate ? () => {
                        options.onValidate();
                        closeModal();
                    } : undefined,
                    content: options.content,
                }}
            />
        </EModal>
    ),
});

const showWebShareFootprintModal = (
    footprint: string,
) => showModal({
    priority: 4,
    modalContent: (
        <WebShareFootprintModal footprint={footprint} onClose={closeModal} />
    ),
});

const showImageZoomModal = (imageURI: string) => showModal({
    priority: 4,
    modalContent: (
        <ImageZoomMM
            imageURI={imageURI}
            onClose={closeModal}
        />
    ),
});

export default {
    instance,
    showModal,
    pauseModals,
    resumeModals,

    // general custom modals
    showInfoModal,
    showHelpModal,
    showConfirmModal,
    showImageZoomModal,

    // web
    showWebShareFootprintModal,
};