/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const PlaneTravel = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="m22.75 31.75-3.792-6.417h-4.375c-.413 0-.759-.14-1.038-.42-.28-.28-.42-.625-.42-1.039 0-.413.14-.76.42-1.04a1.41 1.41 0 0 1 1.038-.418h4.375l3.792-6.417h1.604l-1.896 6.417h4.157l1.093-1.458h1.459l-.875 2.916.875 2.917h-1.459l-1.093-1.458h-4.157l1.896 6.416H22.75ZM10.646 20.082l1.896-6.417H8.385l-1.093 1.458H5.833l.875-2.916-.875-2.917h1.459l1.093 1.458h4.157l-1.896-6.416h1.604l3.792 6.416h4.375c.413 0 .76.14 1.04.419.278.28.418.627.418 1.04 0 .413-.14.759-.419 1.038-.28.28-.626.42-1.04.42h-4.374l-3.792 6.417h-1.604Z"
            fill={color}
        />
    </Svg>
);

export default PlaneTravel;