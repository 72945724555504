/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcTrain({size = 40}: Props){
    return (
        <Svg
            className="icon"
            height={size}
            viewBox="0 0 1024 1024"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M784.256 148.608H592.32c0-34.304-33.792-62.208-75.584-62.208s-75.52 27.904-75.52 62.208h-192c-30.336 0-54.912 25.728-54.912 57.536v612.608c0 31.68 24.576 57.408 54.912 57.408h535.04c30.4 0 54.848-25.728 54.848-57.408V206.208c0-31.808-24.448-57.6-54.848-57.6z"
                fill="#7EB47F"
            />
            <Path
                d="M489.536 259.328h-176.32c-34.432 0-62.272 18.048-62.272 40.32v127.68c0 22.272 27.904 40.32 62.272 40.32h176.32v-208.32zm230.784 0H544v208.32h176.32c34.368 0 62.272-18.048 62.272-40.32v-127.68c0-22.272-27.904-40.32-62.272-40.32z"
                fill="#4A5A4E"
            />
            <Path
                d="M444.16 151.68a67.328 61.568 0 1 0 134.656 0 67.328 61.568 0 1 0-134.656 0Z"
                fill="#E1D250"
            />
            <Path
                d="M511.488 232.96c-47.936 0-86.976-36.352-86.976-81.216s39.04-81.28 86.976-81.28 86.976 36.416 86.976 81.28-39.04 81.216-86.976 81.216zm0-123.136c-26.24 0-47.616 18.816-47.616 41.92 0 23.04 21.376 41.856 47.616 41.856s47.616-18.816 47.616-41.856c0-23.104-21.376-41.92-47.616-41.92z"
                fill="#609A62"
            />
            <Path
                d="m298.496 966.72-28.032-27.52 75.52-76.736 27.968 27.584zM728.896 966.72l-75.52-76.608 28.16-27.648 75.392 76.608z"
                fill="#4A5A4E"
            />
            <Path
                d="M776.064 632.512c-4.096-29.696-30.656-52.608-62.976-52.608s-58.944 22.912-63.04 52.608H395.072c-4.096-29.696-30.72-52.608-63.04-52.608-32.256 0-58.816 22.912-63.04 52.608h-74.688v186.304c0 31.68 24.576 57.408 54.912 57.408h535.04c30.4 0 54.848-25.728 54.848-57.408V632.512h-63.04z"
                fill="#4A5A4E"
            />
            <Path
                d="M294.272 655.808a37.952 36.352 0 1 0 75.904 0 37.952 36.352 0 1 0-75.904 0ZM679.616 655.808a38.016 36.352 0 1 0 76.032 0 38.016 36.352 0 1 0-76.032 0Z"
                fill="#FFF"
            />
        </Svg>
    );
}

export default IcTrain;
