// @flow
import React, {useContext, useMemo} from "react";
import {Platform, Share} from "react-native";
import ShareIcon from "../../UI/Icons/UI/Share";
import ActionIcon from "../../UI/Icons/IconContainers/ActionIcon/ActionIcon";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import ModalManager from "../../UI/Modals/ModalManager/ModalManager";
import locales from "../../../locales/locales";
import {getFootprintShareText} from "../../../libs/Utils/ShareUtils";
import {useSelector} from "react-redux";
import {getTCO2Footprint} from "../../../Reducers/CarbonFootprintReducer";

const ShareFootprintIcon = () => {
    const [,, spacing] = useContext(ThemeContext);

    const footprint = useSelector(state => getTCO2Footprint(state));
    const roundedTCO2Footprint = useMemo(() => footprint.toFixed(2).replace('.', ','), [footprint]);

    const showWebShareModal = () => ModalManager.showWebShareFootprintModal(roundedTCO2Footprint);

    const onShare = async() => {
        try {
            await Share.share(
                {message: getFootprintShareText(roundedTCO2Footprint)},
                {subject: locales.t('global.common.share_mail_subject')}
            );
        } catch (e){
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    return (
        <ActionIcon
            customStyle={{marginRight: spacing[1]}}
            onPress={Platform.OS === 'web' ? showWebShareModal : onShare}
        >
            <ShareIcon />
        </ActionIcon>
    );
};

export default ShareFootprintIcon;