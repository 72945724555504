// @flow
import React from 'react';
import {FlatList, Platform, View} from 'react-native';
import {connect} from 'react-redux';
import locales from '../../../../../locales/locales';
import IcBus from '../../../../UI/Icons/CarbonFootprint/Transport/IcBus';
import IcDieselCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcDieselCar';
import IcElectricCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcElectricCar';
import IcHighSpeedTrain from '../../../../UI/Icons/CarbonFootprint/Transport/IcHighSpeedTrain';
import IcHybridCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcHybridCar';
import IcMotorcycle from '../../../../UI/Icons/CarbonFootprint/Transport/IcMotorcycle';
import IcSubway from '../../../../UI/Icons/CarbonFootprint/Transport/IcSubway';
import IcTrain from '../../../../UI/Icons/CarbonFootprint/Transport/IcTrain';
import IcWalk from '../../../../UI/Icons/CarbonFootprint/Transport/IcWalk';
import {
    getMeansOfTransport,
    getWeeklyMeansOfTransport,
} from '../../../../../Reducers/CarbonFootprintReducers/CFTransportReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import type {ItemInfos} from "../../../../../Types/Utils/ItemInfos";
import Heading from "../../../../UI/Typos/Heading/Heading";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import type {
    MeansOfTransportOptionsType,
    WeeklyMeansOfTransportType,
} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
    setWeeklyMeansOfTransport: Array<WeeklyMeansOfTransportType> => void,
    weeklyMeansOfTransport: Array<WeeklyMeansOfTransportType>,
    meansOfTransport: Array<MeansOfTransportOptionsType>,
};

const options = {
    walkorbike: <IcWalk />,
    subway: <IcSubway />,
    bus: <IcBus />,
    train: <IcTrain />,
    tgv: <IcHighSpeedTrain />,
    motorbike: <IcMotorcycle />,
    hybridcar: <IcHybridCar />,
    fuelcar: <IcDieselCar />,
    electriccar: <IcElectricCar />,
};

const WeeklyMeansOfTransportQuestion = ({
    setWeeklyMeansOfTransport,
    weeklyMeansOfTransport,
    meansOfTransport,
}: Props) => {
    const handleChange = (mean: MeansOfTransportOptionsType, value: number) => {
        const filteredWeeklyMeans = weeklyMeansOfTransport.filter(element => element.mean !== mean);
        const newWeeklyMeans = [...filteredWeeklyMeans, {value, mean}];
        setWeeklyMeansOfTransport(newWeeklyMeans);
    };

    const renderItem = ({item}: ItemInfos<MeansOfTransportOptionsType>) => {
        const meanOfTransport = weeklyMeansOfTransport.find(mean => mean.mean === item);
        const nbHours = meanOfTransport ? meanOfTransport.value : 0;
        return (
            <>
                <Heading customStyle={{marginTop: 10}} variant='h3'>
                    {locales.t(`cf.${item}`)}
                </Heading>
                <ESlider
                    defaultValue={nbHours}
                    hasSliderSubText
                    max={20}
                    onSlidingComplete={value => handleChange(item, value)}
                    step={1}
                    subText="h/semaine"
                    thumbIcon={(<SliderThumbBackground>{options[item]}</SliderThumbBackground>)}
                />
            </>
        );
    };

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: 10}} />)}
            contentContainerStyle={{paddingBottom: (Platform.OS !== 'web' && meansOfTransport.length > 2) ? 30 : 4}}
            data={meansOfTransport}
            keyExtractor={item => item.toString()}
            renderItem={renderItem}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    meansOfTransport: getMeansOfTransport(store),
    weeklyMeansOfTransport: getWeeklyMeansOfTransport(store),
    ...props,
});

const mapDispatchToProps = {setWeeklyMeansOfTransport: CFActions.setWeeklyMeansOfTransport};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeeklyMeansOfTransportQuestion);
