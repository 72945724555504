// @flow
import React, {useContext} from 'react';
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import ThemeIcon from "../../../UI/Icons/IconContainers/ThemeIcon/ThemeIcon";
import {getFootprintThematicColor} from "../../../../libs/Utils/ColorUtils";
import Energy from "../../../UI/Icons/CarbonFootprint/Energy";
import Waste from "../../../UI/Icons/CarbonFootprint/Details/Waste";
import HouseConstruction from "../../../UI/Icons/CarbonFootprint/Details/HouseConstruction";
import VideoStreaming from "../../../UI/Icons/CarbonFootprint/Details/VideoStreaming";
import NewPurchase from "../../../UI/Icons/CarbonFootprint/Details/NewPurchase";
import TechPurchase from "../../../UI/Icons/CarbonFootprint/Details/TechPurchase";
import MailSending from "../../../UI/Icons/CarbonFootprint/Details/MailSending";
import DietType from "../../../UI/Icons/CarbonFootprint/Details/DietType";
import Drinks from "../../../UI/Icons/CarbonFootprint/Details/Drinks";
import TransportMeans from "../../../UI/Icons/CarbonFootprint/Details/TransportMeans";
import PlaneTravel from "../../../UI/Icons/CarbonFootprint/Details/PlaneTravel";
import type {FootprintDetailString, FootprintSectionString} from "../../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    thematic: FootprintSectionString,
    detailName: FootprintDetailString,
};

const ThematicDetailIcon = ({
    thematic,
    detailName,
}: Props) => {
    const [colors] = useContext(ThemeContext);

    const getIcon = () => {
        let icon;
        const color = getFootprintThematicColor(thematic, colors);

        switch (detailName){
            case 'EnergyConsumption':
                icon = <Energy />;
                break;
            case 'Trash':
                icon = <Waste />;
                break;
            case 'HouseConstruction':
                icon = <HouseConstruction />;
                break;
            case 'VideoStreaming':
                icon = <VideoStreaming />;
                break;
            case 'NewPurchase':
                icon = <NewPurchase />;
                break;
            case 'TechPurchase':
                icon = <TechPurchase />;
                break;
            case 'MailSending':
                icon = <MailSending />;
                break;
            case 'DietType':
                icon = <DietType />;
                break;
            case 'Drinks':
                icon = <Drinks />;
                break;
            case 'PlasticBottles':
                icon = <Waste />;
                break;
            case 'TransportMeans':
                icon = <TransportMeans />;
                break;
            case 'PlaneTravel':
                icon = <PlaneTravel />;
                break;
            default:
                return;
        }

        return (
            <ThemeIcon color={color}>
                {icon}
            </ThemeIcon>
        );
    };

    return getIcon();
};

export default ThematicDetailIcon;
