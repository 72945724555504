// @flow
import React from 'react';
import {connect} from 'react-redux';
import IcPlane from '../../../../UI/Icons/CarbonFootprint/Transport/IcPlane';
import {getNbYearlyPlaneHours} from '../../../../../Reducers/CarbonFootprintReducers/CFTransportReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import Heading from "../../../../UI/Typos/Heading/Heading";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";

type Props = {
  setNbYearlyPlaneHours: number => void,
  nbYearlyPlaneHours: number,
};

const NbPlaneHoursQuestion = ({
    setNbYearlyPlaneHours,
    nbYearlyPlaneHours,
}: Props) => {
    return (
        <>
            <Heading customStyle={{marginTop: 10}} variant='h3'>
                {"Avion"}
            </Heading>
            <ESlider
                defaultValue={nbYearlyPlaneHours}
                hasSliderSubText
                max={50}
                onSlidingComplete={setNbYearlyPlaneHours}
                step={1}
                subText="h/an"
                thumbIcon={(<SliderThumbBackground><IcPlane /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    nbYearlyPlaneHours: getNbYearlyPlaneHours(store),
    ...props,
});
const mapDispatchToProps = {setNbYearlyPlaneHours: CFActions.setNbYearlyPlaneHours};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NbPlaneHoursQuestion);