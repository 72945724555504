// @flow
import React, {useContext} from "react";
import type {Node} from 'react';
import {Text} from "react-native";
import type {TextStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";

type BodyVariant = 'onboarding_text' | 'paragraph' | 'paragraph_light' | 'caption';

type Props = {
    variant: BodyVariant,
    children: Node,
    customStyle?: TextStyleProp,
    color?: string,
    selectable?: boolean,
    centered?: boolean,
};

const Body = ({
    variant,
    children,
    customStyle,
    color,
    selectable = true,
    centered = false,
    ...rest
}: Props) => {
    const [colors, fonts] = useContext(ThemeContext);
    const getLineHeight = (fontSize) => fontSize + (fontSize * 0.5);

    const getStyle = () => {
        let fontSize = 18, fontFamily: fonts.regular;
        switch (variant){
            case 'onboarding_text':
                fontFamily = fonts.regular;
                break;
            case 'paragraph':
                fontSize = 12;
                fontFamily = fonts.regular;
                break;
            case 'paragraph_light':
                fontSize = 12;
                fontFamily = fonts.light;
                break;
            case 'caption':
                fontSize = 10;
                fontFamily = fonts.bold;
                break;
            default:
                break;
        }
        const lineHeight = getLineHeight(fontSize);
        return {
            fontSize,
            lineHeight,
            fontFamily,
        };
    };

    return (
        <Text
            allowFontScaling={false}
            selectable={selectable}
            style={{
                ...getStyle(),
                color: color ?? colors.text.dark,
                textAlign: centered ? 'center' : 'left',
                ...customStyle,
            }}
            {...rest}
        >
            {children}
        </Text>
    );
};

export default Body;