// @flow
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import type {Store} from '../../../../../Types/Reducers/Store';
import ThematicMainScreen from '../../ThematicMainScreen';
import appRoutes from "../../../../../Navigation/AppRoutes";
import ThematicIcon from "../../Icons/ThematicIcon";

const thematicText = `L'énergie et le logement sont à l’origine de 18% de l'empreinte carbone moyenne d'un Français, soit 1,78 tonnes CO2 équivalent par an 🧐.\nC’est pas Versailles ici, vraiment ? Réponds à ces questions pour connaitre ta moyenne personnelle ⚡️`;

const mapStateToProps = (store: Store, props: Object) => ({
    thematicTitle: 'Énergie',
    thematicIcon: <ThematicIcon size={100} thematic='energy' />,
    thematicIndex: 3,
    thematicsNumber: 5,
    onPressReturn: () => props.navigation.navigate(appRoutes.cf.diet.questions, {back: true}),
    onPressNext: () => props.navigation.navigate(appRoutes.cf.energy.questions),
    thematicText,
    ...props,
});

export default connect(
    mapStateToProps,
)(ThematicMainScreen);
