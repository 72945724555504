/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const VideoStreaming = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="m13.854 23.145 10.209-6.562-10.209-6.563v13.125Zm-2.187 8.021v-2.917H5.833a2.807 2.807 0 0 1-2.059-.856 2.81 2.81 0 0 1-.857-2.06v-17.5c0-.802.286-1.49.857-2.06a2.807 2.807 0 0 1 2.06-.857h23.333c.802 0 1.489.285 2.06.856.571.572.856 1.259.856 2.06v17.5c0 .803-.285 1.49-.856 2.061a2.812 2.812 0 0 1-2.06.856h-5.834v2.917H11.667Zm-5.834-5.833h23.334v-17.5H5.833v17.5Z"
            fill={color}
        />
    </Svg>
);

export default VideoStreaming;