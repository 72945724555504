// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
};

const LeftChevron = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={14} width={8} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M7 1 1 7l6 6"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </Svg>
);

export default LeftChevron;