// @flow
import React from 'react';
import Body from "../Body/Body";
import {Platform, View} from "react-native";
import Hyperlink from "react-native-hyperlink";
import {hasParents} from '../../../../libs/Markdown';
import {openLink} from "../../../../libs/Utils/LinkUtils";
import SmartImage from "../../../Shared/SmartImage/SmartImage";
import ChipButton from "../../Buttons/ChipButton/ChipButton";

const markdownRules = {
    link: (node, children, parent, styles) => (
        <Body
            customStyle={{...styles.link}}
            key={node.key}
            onPress={() => openLink(node.attributes.href)}
            variant='paragraph'
        >
            {children}
        </Body>
    ),
    paragraph: (node, children, parent, styles) => (
        <View key={node.key} style={styles.paragraph}>
            <Hyperlink
                linkDefault
                linkStyle={styles.link}
                onPress={openLink}
            >
                <Body variant='paragraph'>
                    {children}
                </Body>
            </Hyperlink>
        </View>
    ),
    image: (node, children, parent, styles) => {
        if(Platform.OS === 'android'){
            return (
                <ChipButton
                    customStyle={{...styles.paragraph}}
                    key={node.key}
                    lighterRound
                    onPress={() => openLink(node.attributes.src)}
                    text={`Afficher l'image`}
                />
            );
        }


        return (
            <SmartImage
                imageStyle={styles.image}
                key={node?.key}
                resizeMode='contain'
                savable
                source={{uri: node.attributes.src, cache: 'force-cache'}}
                uri={node.attributes.src}
                zoomable
            />
        );
    },
    heading1: (node, children, parent, styles) => {
        return (
            <View key={node.key} style={styles.headingContainer}>
                {children}
            </View>
        );
    },
    heading2: (node, children, parent, styles) => {
        return (
            <View key={node.key} style={styles.headingContainer}>
                {children}
            </View>
        );
    },
    heading3: (node, children, parent, styles) => {
        return (
            <View key={node.key} style={styles.headingContainer}>
                {children}
            </View>
        );
    },

    list_item: (node, children, parent, styles) => {
        if (hasParents(parent, 'bullet_list')){
            return (
                <View key={node.key} style={styles.listUnorderedItem}>
                    <Body
                        customStyle={styles.listUnorderedItemIcon}
                        variant='paragraph'
                    >{'\u00B7'}
                    </Body>
                    <Body
                        customStyle={{...styles.listItem}}
                        variant='paragraph'
                    >{children}
                    </Body>
                </View>
            );
        }


        if (hasParents(parent, 'ordered_list')){
            return (
                <View key={node.key} style={styles.listOrderedItem}>
                    <Body
                        customStyle={styles.listOrderedItemIcon}
                        variant='paragraph'
                    >{node.index + 1}{node.markup}
                    </Body>
                    <Body
                        customStyle={{...styles.listItem}}
                        variant='paragraph'
                    >{children}
                    </Body>
                </View>
            );
        }


        return (
            <Body
                customStyle={{...styles.listItem}}
                key={node.key}
                variant='paragraph'
            >{children}
            </Body>
        );
    },
};

export default markdownRules;