// @flow
import {Platform, StyleSheet} from "react-native";
import Config from "../../../../Config/Config";
import type {ThemeColorsType, ThemeFontsType} from "../../../../Types/ThemeTypes";

const getMarkdownRendererStyles = (
    colors: ThemeColorsType,
    fonts: ThemeFontsType,
    style: Object = {},
) => StyleSheet.create({
    ...style,
    body: {
        color: colors.text.dark,
        fontFamily: fonts.regular,
        fontSize: 12,
        ...style.body,
    },
    bullet_list: {
        maxWidth: '100%',
        ...style.bullet_list,
    },
    heading1: {
        color: colors.text.dark,
        fontFamily: fonts.bold,
        ...style.heading1,
    },
    image: {
        alignSelf: 'center',
        height: 300,
        resizeMode: 'contain',
        width: Platform.OS === 'web' ? 300 : Config.DEVICE_WIDTH,
        ...style.image,
    },
    link: {
        color: colors.action,
        fontFamily: fonts.regular,
        marginBottom: -4,
        textDecorationLine: 'underline',
        ...style.link,
    },
    listItem: {
        flex: 1,
        ...style.listItem,
    },
    listOrderedItem: {
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: 16,
        ...style.listOrderedItem,
    },
    listOrderedItemIcon: {
        color: colors.text.dark,
        marginRight: 5,
        ...style.listOrderedItemIcon,
    },
    listUnorderedItem: {
        flexDirection: 'row',
        ...style.listUnorderedItem,
    },
    listUnorderedItemIcon: {
        color: colors.text.dark,
        marginRight: 5,
        ...style.listUnorderedItemIcon,
    },
    paragraph: {
        paddingVertical: 6,
        ...style.paragraph,
    },
    strong: {
        color: colors.text.dark,
        fontFamily: fonts.bold,
        ...style.strong,
    },
});

export {getMarkdownRendererStyles};
