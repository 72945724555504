/* eslint-disable max-len */
// @flow
import React from "react";
import {
    getDietType,
    getDrinksImpact,
    getLocalConsumptionSavedImpact,
    getMeatEatingImpactEstimation,
    getNoMeatDietImpactEstimation,
    isPlayerDrinkingShit,
    isPlayerEatingMeat,
} from "../../../../../Reducers/CarbonFootprintReducers/CFDietReducer";
import QDietType from "./QDietType";
import QDrinkTypes from "./QDrinkTypes";
import QDrinkTypesQuantities from "./QDrinkTypesQuantities";
import QLocalConsumption from "./QLocalConsumption";
import QMeatType from "./QMeatType";
import type {CFQuestionMapItem} from "../../../../../Types/CarbonFootprint/FootprintTypes";

const DietQuestionsMap: Map<string, CFQuestionMapItem> = new Map();

DietQuestionsMap.set('first', {
    question: 'Quel régime alimentaire te correspond le mieux ?',
    questionDescription: 'Alimentation : 1/3',
    infoModalContent: `Ton régime alimentaire a un rôle primordial à jouer si tu souhaites réduire ton impact sur l’environnement.\nL’estimation des différents types de repas se base sur les données de l’[ADEME](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/repas.htm).`,
    impact: 3,
    form: (<QDietType />),
    getEstimatedImpact: store => isPlayerEatingMeat(store) ? null : getNoMeatDietImpactEstimation(store),
    getDisableSubmit: store => getDietType(store) === null,
    getPreviousQuestion: () => null,
    getNextQuestion: store => isPlayerEatingMeat(store) ? 'first_bis' : 'second',
});

DietQuestionsMap.set('first_bis', {
    question: 'Quel type de viande manges-tu ?',
    questionDescription: 'Alimentation : 1 bis',
    infoModalContent: `En fonction du type de viande consommé, l’impact de ton repas peut varier du simple au quintuple. Le poisson et les volailles ont un impact proche. En revanche, la consommation de viande rouge comme le bœuf est nettement plus impactante. Diminuer la part de la viande rouge est déjà un bon geste à mettre en place.\nL’estimation des différents types de repas se base sur les données de l’[ADEME](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/repas.htm).`,
    impact: 3,
    form: (<QMeatType />),
    getEstimatedImpact: store => getMeatEatingImpactEstimation(store),
    getPreviousQuestion: () => 'first',
    getNextQuestion: () => 'second',
});

DietQuestionsMap.set('second', {
    question: 'Lesquelles de ces boissons consommes-tu ?',
    questionDescription: 'Alimentation : 2/3',
    infoModalContent: `L’alcool ou les boissons chaudes ont un impact non négligeable selon leur consommation. Pour plus de simplicité nous travaillons en ‘dose’. Les données proviennent de différentes sources pour : le café [en grain](https://www.balas.org/resources/Documents/2013%20Conference%20Files/2013%20Conference%20Proceedings/Carbon%20Footprint%20Across%20the%20Coffee%20Supply%20Chain%20-%20The%20Case%20of%20Costa%20Rican%20Coffee.pdf) ou en [capsule](https://www.nespresso.com/agit/article/cultures-climat/quel-impact-environnemental-pour-le-systeme-nespresso/), le [thé ou chocolat chaud](https://www.passeportsante.net/fr/Actualites/Nouvelles/Fiche.aspx?doc=2009092591_le-bilan-carbone-des-aliments-bientot-sur-une-etiquette-pres-de-chez-vous) ou encore l’[alcool](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/repas.htm).`,
    impact: 2,
    form: (<QDrinkTypes />),
    getEstimatedImpact: () => null,
    getPreviousQuestion: store => isPlayerEatingMeat(store) ? 'first_bis' : 'first',
    getNextQuestion: store => isPlayerDrinkingShit(store) ? 'second_bis' : 'third',
});

DietQuestionsMap.set('second_bis', {
    question: 'À quelle fréquence bois-tu chacune de ces boissons ?',
    questionDescription: 'Alimentation : 2 bis',
    infoModalContent: `La fréquence permet d’estimer l’impact sur l’année de ces boissons.`,
    impact: 2,
    form: (<QDrinkTypesQuantities />),
    getEstimatedImpact: store => getDrinksImpact(store) ?? 0,
    getPreviousQuestion: () => 'second',
    getNextQuestion: () => 'third',
});

DietQuestionsMap.set('third', {
    question: 'Quelle part de tes aliments estimes-tu issue d\'une production locale (moins de 200 km) et de saison ?',
    questionDescription: 'Alimentation : 3/3',
    infoModalContent: `Un produit alimentaire peut parcourir environ 2000 km en moyenne entre son lieu de production et notre assiette. Le choix des aliments a donc un impact sur le calcul de ton bilan carbone.\nIl est inutile de consommer des produits locaux s’ils ne sont pas de saison, car cela implique de les faire pousser sous serre.\nPour simplification, une alimentation locale permet d’économiser la moitié des transport dans l’alimentation (22,1 % selon [l’étude](https://www.iddri.org/sites/default/files/PDF/Publications/Hors%20catalogue%20Iddri/Empreinte-Carbone_Alimentation_France_VF_0.pdf), ramenés à 20 % pour les produits un peu plus éloignés).`,
    impact: 2,
    positiveImpact: true,
    form: (<QLocalConsumption />),
    getEstimatedImpact: store => getLocalConsumptionSavedImpact(store),
    getPreviousQuestion: store => isPlayerDrinkingShit(store) ? 'second_bis' : 'second',
    getNextQuestion: () => null,
});

export default DietQuestionsMap;