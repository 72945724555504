/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const TechPurchase = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M24.792 31.166c-.414 0-.76-.14-1.039-.42a1.407 1.407 0 0 1-.42-1.038V18.04c0-.413.14-.76.42-1.04a1.41 1.41 0 0 1 1.039-.418h5.833c.413 0 .76.14 1.038.418.28.28.42.627.42 1.04v11.667c0 .413-.14.759-.42 1.038-.279.28-.625.42-1.038.42h-5.833Zm0-2.917h5.833V19.5h-5.833v8.75Zm-13.125 2.917v-2.917h2.916v-2.916h-8.75a2.807 2.807 0 0 1-2.059-.856 2.81 2.81 0 0 1-.857-2.061V7.833c0-.802.286-1.49.857-2.06a2.807 2.807 0 0 1 2.06-.857H26.25c.802 0 1.489.285 2.06.856.571.572.857 1.259.857 2.06v5.834H26.25V7.833H5.833v14.583h14.584v2.917H17.5v2.916h2.917v2.917h-8.75Zm1.02-10.573 3.355-2.552 3.354 2.552-1.24-4.156 3.354-2.698h-4.156l-1.312-4.083-1.313 4.083h-4.156l3.354 2.698-1.24 4.156Z"
            fill={color}
        />
    </Svg>
);

export default TechPurchase;