// @flow
import {ComponentClass} from 'react';
import * as React from 'react';
import {Animated} from 'react-native';
import Touchable from '../../Buttons/Touchable/Touchable';
import ESwipeView from '../ESwipeView/ESwipeView';
import styles from './styles';

export type ToastOptions = {
    onPress?: () => any,
    hideOnPress?: boolean,
}

export type AllToastOptions = ToastOptions & {
    toastComponent: ComponentClass<any>,
    toastProps: any,
}

type Props = {
    onHide: () => void,
}
type State = {
    show: boolean,
    options: ?AllToastOptions,
    offset: number,
}

export default class EToast extends React.Component<Props, State>{
    constructor(){
        super();
        this.state = {
            show: false,
            options: null,
            offset: 0,
        };

        this.yPos = new Animated.Value(0);
    }

    static defaultProps = {};

    yPos: Animated.Value;

    swipeView: ?ESwipeView;

    timeoutId: ?TimeoutID;

    renderToast(){
        const {options} = this.state;
        if (!options) return null;
        return React.createElement(options.toastComponent, options.toastProps);
    }

    show(options: AllToastOptions, duration: number, offset: number = 0){
        this.setState({show: true, options, offset});
        Animated.spring(this.yPos, {
            toValue: 1,
            duration: 350,
            useNativeDriver: true,
        }).start(() => {
            if (duration !== 0){
                this.timeoutId = setTimeout(() => {
                    this.hide();
                }, duration);
            }
        });
    }

    onHide(){
        const {onHide} = this.props;
        this.setState({show: false});
        this.yPos.setValue(0);
        if (this.swipeView) this.swipeView.reset();
        onHide();
    }

    clearAutoDismiss(){
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = null;
    }

    hide(){
        this.clearAutoDismiss();
        Animated.spring(this.yPos, {
            toValue: 0,
            duration: 350,
            useNativeDriver: true,
        }).start(this.onHide.bind(this));
    }

    onSwiped = () => {
        this.clearAutoDismiss();
        this.onHide();
    };

    onPressToast = () => {
        const {options} = this.state;
        if (options && options.hideOnPress) this.hide();
        if (options && options.onPress) options.onPress();
    };

    render(){
        const {show} = this.state;

        const y = this.yPos.interpolate({
            inputRange: [0, 1],
            outputRange: [-200, 0],
        });

        const pos = {transform: [{translateY: y}]};

        return (
            <Animated.View pointerEvents="box-none" style={[styles.toastContainer, pos]}>
                <ESwipeView
                    onSwiped={this.onSwiped}
                    ref={(r: ?ESwipeView) => {
                        this.swipeView = r;
                    }}
                >
                    {
                        show
                            ? (
                                <Touchable
                                    onPress={this.onPressToast}
                                    style={{opacity: 1}}
                                >
                                    {this.renderToast()}
                                </Touchable>
                            )
                            : null
                    }
                </ESwipeView>
            </Animated.View>
        );
    }
}
