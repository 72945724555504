// @flow
import React, {useContext} from 'react';
import {ScrollView, View} from 'react-native';
import Button from '../../../Buttons/Button/Button';
import Touchable from '../../../Buttons/Touchable/Touchable';
import ActionIcon from '../../../Icons/IconContainers/ActionIcon/ActionIcon';
import Close from '../../../Icons/UI/Close';
import PageLayout from '../../../Layouts/PageLayout/PageLayout';
import Heading from '../../../Typos/Heading/Heading';
import {ThemeContext} from '../../../../../libs/Hoc/ThemeProvider';
import locales from '../../../../../locales/locales';
import styles from './styles';
import EMarkdown from "../../../Typos/EMarkdown/EMarkdown";
import Config from "../../../../../Config/Config";

type Props = {
    onClose: () => void,
    title?: string,
    content: string,
};

const HelpMM = ({
    onClose,
    title = 'Plus d\'infos',
    content,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <View
            style={styles.modalBackground}
        >
            <PageLayout>
                <ScrollView
                    contentContainerStyle={{flexGrow: 1}}
                    showsVerticalScrollIndicator={Config.showVScrollBar}
                >
                    <View
                        style={{
                            marginVertical: spacing[2],
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Heading
                            customStyle={{flex: 1}}
                            variant='h1'
                        >
                            {title}
                        </Heading>
                        {onClose ? (
                            <Touchable
                                onPress={onClose}
                            >
                                <ActionIcon onPress={onClose}>
                                    <Close />
                                </ActionIcon>
                            </Touchable>
                        ) : null}
                    </View>
                    <EMarkdown>
                        {content}
                    </EMarkdown>
                </ScrollView>
                <Button
                    onPress={onClose}
                    text={locales.t('global.actions.understood')}
                />
            </PageLayout>
        </View>
    );
};

export default HelpMM;