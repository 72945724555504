/* eslint-disable max-len */
// @flow
import React from "react";
import {
    getIsUsingCar,
    getKgCO2Plane,
    getKgCO2TransportOnlySolo,
    getKgCO2TransportSavedInCar,
    getMeansOfTransport,
    getWeeklyMeansOfTransport,
} from "../../../../../Reducers/CarbonFootprintReducers/CFTransportReducer";
import QMeansOfTransport from "./QMeansOfTransport";
import QWeeklyMeansOfTransport from "./QWeeklyMeansOfTransport";
import QNbPeopleInCar from "./QNbPeopleInCar";
import QNbPlaneHours from "./QNbPlaneHours";
import type {CFQuestionMapItem} from "../../../../../Types/CarbonFootprint/FootprintTypes";

const MobilityQuestionsMap: Map<string, CFQuestionMapItem> = new Map();

MobilityQuestionsMap.set('first', {
    question: 'Comment te déplaces-tu ?',
    questionDescription: 'Mobilité : 1/3',
    infoModalContent: `En fonction des moyens de transport que tu utilises et de la fréquence de cette utilisation, nous pouvons calculer l’empreinte carbone personnelle de tes déplacements.\nLes moyens de transports sont la **première source d’émission** pour les français.\nNos données sont basées sur l’outil [Impact Transport de l’Ademe](https://monimpacttransport.fr/).`,
    impact: 3,
    form: (<QMeansOfTransport />),
    getEstimatedImpact: () => null,
    getDisableSubmit: store => getMeansOfTransport(store).length === 0,
    getPreviousQuestion: () => null,
    getNextQuestion: () => 'second',
});

MobilityQuestionsMap.set('second', {
    question: 'Combien d’heures par semaine passes-tu dans ces modes de transport ?',
    questionDescription: 'Mobilité : 2/3',
    infoModalContent: `Grâce aux moyens de transport sélectionnés, nous pouvons désormais calculer l’impact de ces derniers en fonction de leur fréquence d’utilisation.\nAfin de t’aider à estimer ton impact, nous raisonnons en temps de trajet.\nPour cela, nous prenons la vitesse moyenne du moyen de transport en km/h et la multiplions par l’impact carbone au kilomètre.`,
    impact: 3,
    form: (<QWeeklyMeansOfTransport />),
    getEstimatedImpact: store => getKgCO2TransportOnlySolo(store),
    getDisableSubmit: store => getWeeklyMeansOfTransport(store).length === 0,
    getPreviousQuestion: () => 'first',
    getNextQuestion: store => getIsUsingCar(store) ? 'second_bis' : 'third',
});

MobilityQuestionsMap.set('second_bis', {
    question: 'La majeure partie du temps, à combien êtes-vous dans la voiture ?',
    questionDescription: 'Mobilité : 2 bis',
    infoModalContent: `Le fait de transporter ou non des personnes dans ta voiture a une influence sur l’empreinte carbone.\nPour ce calcul, l’empreinte carbone de la voiture est divisée par le nombre de personnes utilisant le véhicule.`,
    impact: 3,
    positiveImpact: true,
    form: (<QNbPeopleInCar />),
    getEstimatedImpact: store => getKgCO2TransportSavedInCar(store),
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'second',
    getNextQuestion: () => 'third',
});

MobilityQuestionsMap.set('third', {
    question: 'Combien de temps as-tu passé à voyager en avion au cours des 12 derniers mois ?',
    questionDescription: 'Mobilité : 3/3',
    infoModalContent: `Le temps que tu passes chaque année dans l’avion nous permet de calculer l’empreinte carbone de ce moyen de transport particulièrement coûteux pour l’environnement.`,
    impact: 3,
    form: (<QNbPlaneHours />),
    getEstimatedImpact: store => getKgCO2Plane(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: store => getIsUsingCar(store) ? 'second_bis' : 'second',
    getNextQuestion: () => null,
});

export default MobilityQuestionsMap;