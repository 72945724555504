/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcVegetarian({size = 35}: Props){
    return (
        <Svg
            className="icon"
            height={size}
            viewBox="0 0 1024 1024"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M810.667 448c0-46.293 36.906-83.755 82.88-85.077-2.71-90.155-6.72-169.046-6.72-169.046-342.955 28.267-688.555 98.368-688.555 98.368-45.888 9.088-55.083 18.176-64.256 54.464 0 0-18.368 72.598 9.173 226.902s128.79 217.856 174.422 217.856c0 0 373.653 35.178 569.216 10.282 0 0 7.722-146.133 9.002-268.437A85.333 85.333 0 0 1 810.667 448z"
                fill="#FFE57F"
            />
            <Path
                d="m321.6 749.93-2.005-.191h-2.006c-21.205 0-108.352-45.824-132.906-183.403-25.174-141.077-9.856-209.216-9.771-209.557 3.2-12.694 5.056-15.872 5.12-16.086.981-.725 5.845-3.498 26.688-7.594 3.264-.662 317.973-64.086 639.979-93.846l42.09-3.37c-1.152-25.792-1.984-42.006-1.984-42.006-342.954 28.267-688.554 98.368-688.554 98.368-45.888 9.088-55.083 18.176-64.256 54.464 0 0-18.368 72.598 9.173 226.902s128.81 217.856 174.443 217.856c0 0 203.946 19.2 389.333 19.2 65.536 0 128.79-2.411 179.883-8.918 0 0 .874-16.768 2.112-43.669-51.2 6.592-136.619 10.88-181.974 10.88-181.184 0-383.338-18.837-385.365-19.03z"
                fill="#FFB300"
            />
            <Path
                d="M130.773 366.827c1.558-13.419 3.222-20.118 3.222-20.118 9.173-36.288 18.346-45.376 64.256-54.464 0 0 345.6-70.101 688.554-98.368 0 0-287.061-14.677-349.44-20.778s-116.885 0-147.605 9.557c-30.741 9.536-130.944 51.243-130.944 51.243-137.707 53.184-128.064 132.928-128.043 132.928z"
                fill="#FFA000"
            />
            <Path
                d="M384 469.333a85.333 85.333 0 1 0 170.667 0 85.333 85.333 0 1 0-170.667 0ZM661.333 341.333a42.667 42.667 0 1 0 85.334 0 42.667 42.667 0 1 0-85.334 0ZM298.66700000000003 629.333a53.333 53.333 0 1 0 106.666 0 53.333 53.333 0 1 0-106.666 0ZM234.66699999999997 458.667a32 32 0 1 0 64 0 32 32 0 1 0-64 0ZM640 597.333a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z"
                fill="#FFC400"
            />
        </Svg>
    );
}

export default IcVegetarian;
