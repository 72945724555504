/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
  size?: number,
};

function IcPerson({size = 35}: Props){
    return (
        <Svg
            aria-hidden="true"
            className="iconify iconify--twemoji"
            height={size}
            viewBox="0 0 36 36"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M23.359 11.288c-4 0-12.652-1.04-12.652-1.04s-1.73 1.903-2.73 2.903c-1.863 1.863-1.701 4.221-1.948 7.162C6.336 28.487 11.569 35 18 35c6.394 0 11.606-6.44 11.965-14.551-.352-3.63-2.796-9.161-6.606-9.161z"
                fill="#AF7E57"
            />
            <Path
                d="M18 31c-2.347 0-3.575-1.16-3.707-1.293a.999.999 0 0 1 1.404-1.424c.051.047.806.717 2.303.717 1.519 0 2.273-.69 2.305-.719a1.008 1.008 0 0 1 1.408.029.994.994 0 0 1-.006 1.397C21.575 29.84 20.347 31 18 31zm1-5h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z"
                fill="#915A34"
            />
            <Path
                d="M13 22a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zm10 0a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1z"
                fill="#662113"
            />
            <Path
                d="M17.998 3.083c8.205 0 13.331 6.054 13.331 11.099 0 5.044-1.026 7.064-2.051 5.044-1.026-2.017-2.051-5.035-2.051-5.035s-3.478 2.253-6.203-1.019c0 0 1.077 6.054-5.077 0 0 0 1.026 4.037-5.127-1.008 0 0-3.077 2.018-4.102 7.062-.284 1.401-2.051 0-2.051-5.044C4.666 9.137 8.768 3.083 17.998 3.083"
                fill="#292F33"
            />
        </Svg>
    );
}

export default IcPerson;
