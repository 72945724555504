/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcBus({size = 45}: Props){
    return (
        <Svg
            className="icon"
            height={size}
            viewBox="0 0 1024 1024"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M863.68 390.848h-22.272a12.032 12.032 0 0 0-12.096 12.032V190.4a12.032 12.032 0 0 0-12.032-12.032H280.256a12.032 12.032 0 0 0-12.032 12.032v212.544a12.032 12.032 0 0 0-12.032-12.032H233.92a12.032 12.032 0 0 0-12.032 12.032V510.72a12.018 12.018 0 0 0 12.032 12.032h22.272a12.032 12.032 0 0 0 12.032-12.032v261.056a12.018 12.018 0 0 0 12.032 12.032H817.28a12.032 12.032 0 0 0 12.032-12.032V510.72c0 6.656 5.376 12.032 12.096 12.032h22.272a12.032 12.032 0 0 0 12.032-12.032V402.944a12.032 12.032 0 0 0-12.032-12.096z"
                fill="#E1B030"
            />
            <Path
                d="M342.208 659.584a44.224 42.304 0 1 0 88.448 0 44.224 42.304 0 1 0-88.448 0ZM666.944 659.584a44.224 42.304 0 1 0 88.448 0 44.224 42.304 0 1 0-88.448 0Z"
                fill="#FFF"
            />
            <Path
                d="M318.144 783.872h100.288V848H318.144zm373.888 0h100.224V848H692.032z"
                fill="#425760"
            />
            <Path
                d="M792.32 546.048a12.032 12.032 0 0 1-12.032 12.032H314.176a12.032 12.032 0 0 1-12.032-12.032v-321.6a12.018 12.018 0 0 1 12.032-12.032h466.112a12.018 12.018 0 0 1 12.032 12.032v321.6z"
                fill="#959CC5"
            />
            <Path
                d="M792.32 301.056c0 1.728-5.376 3.136-12.032 3.136H314.176c-6.656 0-12.032-1.408-12.032-3.136v-83.968c0-1.728 5.376-3.2 12.032-3.2h466.112c6.656 0 12.032 1.408 12.032 3.2v83.968z"
                fill="#586C77"
            />
            <Path
                d="M493.056 275.84c0 .704-1.664 1.28-3.712 1.28H347.328c-1.984 0-3.648-.576-3.648-1.28v-33.536c0-.704 1.664-1.28 3.648-1.28h142.016c2.048 0 3.712.576 3.712 1.28v33.536zm116.416 0c0 .704-.896 1.28-1.856 1.28h-74.56c-1.088 0-1.92-.576-1.92-1.28v-33.536c0-.704.896-1.28 1.92-1.28h74.56c1.024 0 1.856.576 1.856 1.28v33.536z"
                fill="#6CAA3D"
            />
            <Path
                d="M755.2 558.784h-32.128a56.128 56.128 0 0 0-112.192 0h-32.128c0-48.64 39.616-88.192 88.192-88.192 48.64.064 88.256 39.616 88.256 88.192z"
                fill="#2F428A"
            />
        </Svg>
    );
}

export default IcBus;
