/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {G, Path} from 'react-native-svg';

type Props = {
    size?: number,
};


function IcMail({size = 35}: Props){
    return (
        <Svg height={size} viewBox="0 0 32 19" width={size}>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M6.078 3.421a.868.868 0 01-.868.868H.868a.868.868 0 110-1.736H5.21c.48 0 .868.389.868.868M4.34 7.764a.866.866 0 01-.868.868H.868a.868.868 0 110-1.736h2.603c.483 0 .868.388.868.868m-1.736 4.343a.864.864 0 01-.868.867H.868a.868.868 0 010-1.736h.867c.48 0 .868.389.868.869"
                    fill="#55ACEE"
                />
                <Path
                    d="M28.053 14.713c-.543 1.92-2.54 3.476-4.46 3.476H6.224c-1.92 0-3.03-1.556-2.49-3.476L6.685 4.289C7.229 2.373 9.225.814 11.14.814h17.37c1.923 0 3.038 1.559 2.49 3.475l-2.948 10.424z"
                    fill="#CCD6DD"
                />
                <Path
                    d="M15.057 8.318l-11.25 8.089c-.028.024-.05.053-.074.078.148.483.45.905.86 1.203.032-.021.064-.028.092-.053l11.25-8.09c.434-.338.593-.889.35-1.227-.244-.34-.794-.34-1.228 0m11.99 8.167c-.01-.024-.013-.053-.028-.078l-6.664-8.09c-.243-.338-.793-.338-1.231 0-.434.34-.59.89-.345 1.229l6.663 8.088c.018.026.047.032.068.053a5.257 5.257 0 001.538-1.203"
                    fill="#99AAB5"
                />
                <Path
                    d="M31.006 4.29c.543-1.917-.572-3.477-2.49-3.477H11.145c-1.92 0-3.916 1.56-4.46 3.476l-.017.066 7.647 7.342c1.42 1.372 3.289 1.277 4.975.318l11.486-6.914.227-.812h.003z"
                    fill="#99AAB5"
                />
                <Path
                    d="M28.515.814h-17.37c-1.373 0-2.77.8-3.663 1.955l8.005 7.638c.65.624 2.113.63 3.175 0L31.076 2.79c-.229-1.164-1.182-1.976-2.56-1.976"
                    fill="#E1E8ED"
                />
            </G>
        </Svg>
    );
}

export default IcMail;
