// @flow
import {combineReducers} from 'redux';
import carbonFootprint from './CarbonFootprintReducer';

export const STATES_NAMES = {
    CARBONFOOTPRINTREDUCER: 'carbonFootprint',
};

const appReducer = combineReducers({
    carbonFootprint,
});

export default appReducer;
