// @flow
import React, {useCallback, useContext} from 'react';
import {View} from 'react-native';
import locales from "../../../locales/locales";
import {Entypo} from "@expo/vector-icons";
import Body from "../../UI/Typos/Body/Body";
import Touchable from "../../UI/Buttons/Touchable/Touchable";
import Heading from "../../UI/Typos/Heading/Heading";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import ThematicIcon from "./Icons/ThematicIcon";
import {getFootprintThematicColor} from "../../../libs/Utils/ColorUtils";
import type {CarbonFootprintSectionType, FootprintSectionString} from "../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    sectionName: FootprintSectionString,
    section: CarbonFootprintSectionType,
    onPress?: () => void,
}

const FootprintSectionCard = ({
    section,
    sectionName,
    onPress = null,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    const percentage = parseFloat(section.percentageShare.split(' ')[0]).toFixed(0) + '%';
    const sectionColor = getFootprintThematicColor(sectionName, colors);

    const renderContent = useCallback(() => (
        <>
            <ThematicIcon thematic={sectionName} />
            <View
                style={{
                    flexGrow: 1,
                    marginHorizontal: spacing[1],
                }}
            >
                <Heading
                    adjustsFontSizeToFit
                    minimumFontScale={0.6}
                    numberOfLines={1}
                    variant='h3'
                >
                    {locales.t(`global.domains.${sectionName}`)}
                </Heading>
            </View>
            <View
                style={{
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    flexShrink: 0,
                }}
            >
                <Heading variant='h3'>
                    {section.carbonMass?.replace('.', ',')}
                </Heading>
                <Body variant='paragraph'>
                    {percentage}
                </Body>
            </View>
            {
                onPress
                    ? (
                        <Entypo
                            color={sectionColor}
                            name="chevron-small-right"
                            size={30}
                        />
                    )
                    : null
            }
        </>
    ), [section]);

    const rowStyle = {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    };

    return onPress
        ? (
            <Touchable
                onPress={onPress}
                style={({pressed}) => ({
                    ...rowStyle,
                    opacity: pressed ? 0.7 : 1,
                })}
            >
                {renderContent()}
            </Touchable>
        ) : (
            <View
                style={rowStyle}
            >
                {renderContent()}
            </View>
        );
};

export default FootprintSectionCard;
