// @flow
import React, {Node, useEffect} from 'react';
import Animated, {useAnimatedStyle, useSharedValue, withDelay, withSpring} from "react-native-reanimated";

type Props = {
    children: Node,
    initialX?: number,
    initialY?: number,
    finalX?: number,
    finalY?: number,
    delay?: number,
    callback?: () => void,
};

const springConfig = {
    stiffness: 80,
    damping: 13,
};

const AnimatePositionOnEnter = ({
    children,
    initialX = 0,
    initialY = 0,
    finalX = 0,
    finalY = 0,
    delay = 0,
    callback,
}: Props) => {
    // if(!allowAnimations) return children;

    const xPos = useSharedValue(initialX);
    const yPos = useSharedValue(initialY);

    const animateXY = () => {
        xPos.value = withDelay(delay, withSpring(finalX, springConfig));
        // use only 1 callback to prevent duplicate behavior
        yPos.value = withDelay(delay, withSpring(finalY, springConfig, callback));
    };

    useEffect(() => {
        animateXY();
    }, []);

    const animatedStyles = useAnimatedStyle(() => ({
        transform: [
            {translateX: xPos.value},
            {translateY: yPos.value},
        ],
    }));

    return (
        <Animated.View
            style={animatedStyles}
        >
            {children}
        </Animated.View>
    );
};

export default AnimatePositionOnEnter;