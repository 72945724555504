// @flow
import React from 'react';
import {ScrollView, View} from 'react-native';
import locales from "../../../../../locales/locales";
import {useContext} from "react";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import Button from "../../../Buttons/Button/Button";
import EMarkdown from "../../../Typos/EMarkdown/EMarkdown";

export type ConfirmModalOptions = {
    content: string,
    cancelText?: string,
    validText?: string,
    onValidate?: () => any,
    onCancel?: () => any,
}

type Props = {
    options: ConfirmModalOptions,
}

const ConfirmMM = ({
    options = {
        content: '',
        cancelText: locales.t('global.actions.cancel'),
        validText: locales.t('global.actions.validate'),
        onValidate: undefined,
        onCancel: undefined,
    },
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <ScrollView
            contentContainerStyle={{
                flexGrow: 1,
                alignItems: 'stretch',
                justifyContent: 'center',
            }}
            style={{flexGrow: 1}}
        >
            <View
                style={{marginVertical: spacing[2]}}
            >
                <EMarkdown>
                    {options.content}
                </EMarkdown>
            </View>
            {
                options?.onCancel || options?.onValidate
                    ? (
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            {
                                options?.onCancel ?
                                    (
                                        <Button
                                            customStyle={{}}
                                            onPress={options.onCancel}
                                            text={options.cancelText}
                                            variant='outlined'
                                        />
                                    ) : null
                            }
                            {
                                options?.onValidate ?
                                    (
                                        <Button
                                            customStyle={{}}
                                            onPress={options?.onValidate}
                                            text={options.validText}
                                        />
                                    ) : null
                            }
                        </View>
                    )
                    : null
            }
        </ScrollView>
    );
};

export default ConfirmMM;
