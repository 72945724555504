/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcMeatType({size = 45}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .519h41.34v22.237H0z" id="daily_meat_prefix__a" />
                <Path d="M0 27.174h41.34V0H0z" id="daily_meat_prefix__c" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M18.561 36.122c-.126.089-.269.15-.42.18a5.362 5.362 0 0 1-1.047.102c-2.597 0-5.174-1.833-6.4-4.679-1.39-3.229-.637-6.828 1.792-8.555.125-.09.268-.151.419-.181 2.925-.577 6.056 1.348 7.447 4.577 1.391 3.23.638 6.828-1.791 8.556Z"
                    style={{
                        fill: "#939b48",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M20.131 40.346a1.087 1.087 0 0 1-1.428-.568L14.48 29.97a1.087 1.087 0 1 1 1.996-.859l4.224 9.807a1.087 1.087 0 0 1-.569 1.428Z"
                    style={{
                        fill: "#60682e",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M58.913 46.265a6.563 6.563 0 0 1-2.064 4.768 1.434 1.434 0 0 1-.98.388H38.375a1.431 1.431 0 0 1-1.256-.746 6.557 6.557 0 0 1-.797-3.131 6.506 6.506 0 0 1 2.895-5.434 6.546 6.546 0 0 1 6.371-8.018c3.301 0 6.039 2.459 6.478 5.641a6.546 6.546 0 0 1 6.847 6.532Z"
                    style={{
                        fill: "#d19b3f",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M58.501 15.104a4.06 4.06 0 0 1-2.71 3.45l-4.5 28.84a14.938 14.938 0 0 1-1.172 3.91H18.816l3.941-11.447c2.043-5.938 7.567-9.9 13.751-9.857.737.005 1.483.066 2.215.18l4.182.652 4.933-13.611a4.109 4.109 0 0 1-1.434-3.772 4.049 4.049 0 0 1 1.625-2.664 4.053 4.053 0 0 1 3.032-.736 4.062 4.062 0 0 1 1.922.847 4.081 4.081 0 0 1 5.518 4.208Z"
                    style={{
                        fill: "#98642e",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M46.404 13.449a4.049 4.049 0 0 1 1.625-2.664 4.048 4.048 0 0 1 3.032-.735c.712.11 1.372.404 1.922.847a4.081 4.081 0 0 1 5.518 4.207 4.06 4.06 0 0 1-2.71 3.45l-.824 5.281-8.983-1.497 1.854-5.117a4.109 4.109 0 0 1-1.434-3.772Z"
                    style={{
                        fill: "#c6a871",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="m42.144 38.303-7.265 7.265a1.082 1.082 0 0 1-1.537 0 1.088 1.088 0 0 1 0-1.537l7.265-7.265a1.088 1.088 0 0 1 1.537 1.537Zm-7.358-2.882a1.088 1.088 0 0 0-1.537 0l-4.538 4.538a1.089 1.089 0 0 0 .769 1.856c.278 0 .556-.106.768-.318l4.538-4.538a1.087 1.087 0 0 0 0-1.538Zm10.765 6.447a1.088 1.088 0 0 0-1.537 0l-4.538 4.538a1.088 1.088 0 0 0 1.537 1.537l4.538-4.538a1.086 1.086 0 0 0 0-1.537Z"
                    style={{
                        fill: "#4f3324",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M29.565 47.505a4.982 4.982 0 0 1-1.568 3.621 1.086 1.086 0 0 1-.744.295H13.968c-.397 0-.763-.218-.954-.567a4.973 4.973 0 0 1-.605-2.378c0-1.688.837-3.218 2.199-4.126a4.972 4.972 0 0 1 4.838-6.089 4.973 4.973 0 0 1 4.919 4.284 4.971 4.971 0 0 1 5.2 4.96Z"
                    style={{
                        fill: "#e9b84c",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M60 51.304c0 .601-.472 1.087-1.055 1.087h-8.832l-4.285 7.085c-.197.325-.55.524-.93.524H25.102c-.381 0-.733-.199-.93-.524l-4.285-7.085h-8.832c-.582 0-1.055-.486-1.055-1.087 0-.6.472-1.087 1.055-1.087h47.89c.583 0 1.055.487 1.055 1.087Z"
                    style={{
                        fill: "#5a482f",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M19.887 52.391h30.226l-4.285 7.085c-.197.325-.55.524-.93.524H25.102c-.381 0-.733-.199-.93-.524l-4.285-7.085Z"
                    style={{
                        fill: "#7d684d",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M41.042 22.141c0 1.764-1.435 3.2-3.199 3.2H29.43c-.565 0-1.025.459-1.025 1.025v2.112a1.086 1.086 0 1 1-2.174 0v-2.112a3.203 3.203 0 0 1 3.2-3.199h8.412a1.026 1.026 0 1 0 0-2.051H22.982a3.203 3.203 0 0 1-3.199-3.2 3.203 3.203 0 0 1 3.199-3.199h9.227c.565 0 1.025-.46 1.025-1.026a1.087 1.087 0 0 1 2.174 0c0 1.765-1.436 3.2-3.2 3.2h-9.226c-.565 0-1.026.46-1.026 1.025 0 .566.461 1.026 1.026 1.026h14.861a3.203 3.203 0 0 1 3.199 3.199Z"
                    style={{
                        fill: "#ceb896",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
            </G>
        </Svg>
    );
}

export default IcMeatType;
