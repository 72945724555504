// @flow
import {createNavigationContainerRef} from '@react-navigation/native';
export const navigationRef = createNavigationContainerRef();

/*
    CAUTION
    These function are not recommended for use.
    Use the navigation prop on screen components or the useNavigation hook instead.
    Only use these functions in non-component files (i.e. sagas, toasts).
 */

export function navigate(name, params){
    if(navigationRef.isReady()) navigationRef.navigate(name, params);
}

export function goBack(){
    if(navigationRef.isReady()) navigationRef.goBack();
}

export function reset(routes: Array<{name: string, screen?: Object, params?: Object}>){
    if(navigationRef.isReady()){
        navigationRef.reset({
            index: 0,
            routes,
        }); 
    }
}
