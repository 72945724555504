// @flow
import React, {useCallback, useContext, useState} from "react";
import type {Store as TypeStore} from "../../../../Types/Reducers/Store";
import useEaseAnim from "../../../../libs/Animations/useEaseAnim";
import appRoutes from "../../../../Navigation/AppRoutes";
import locales from "../../../../locales/locales";
import ModalManager from "../../../UI/Modals/ModalManager/ModalManager";
import {Animated, ScrollView, View} from "react-native";
import CFQuestionCO2Tag from "./CFQuestionCO2Tag";
import {NavigationProp, useFocusEffect} from "@react-navigation/native";
import FootprintTopLeft from "../../../UI/Ornaments/FootprintTopLeft";
import FootprintTopRight from "../../../UI/Ornaments/FootprintTopRight";
import OnboardingBottom from "../../../UI/Ornaments/OnboardingBottom";
import DecoratedLayout from "../../../UI/Layouts/DecoratedLayout/DecoratedLayout";
import ActionIcon from "../../../UI/Icons/IconContainers/ActionIcon/ActionIcon";
import LightPageHeader from "../../../UI/PageHeaders/LightPageHeader/LightPageHeader";
import Button from "../../../UI/Buttons/Button/Button";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import Close from "../../../UI/Icons/UI/Close";
import BasicCard from "../../../UI/Cards/BasicCard/BasicCard";
import SeeMoreButton from "../../../Shared/SeeMoreButton/SeeMoreButton";
import Heading from "../../../UI/Typos/Heading/Heading";
import Body from "../../../UI/Typos/Body/Body";
import type {CFQuestionMapItem} from "../../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    questions: Map<string, CFQuestionMapItem>,
    store: TypeStore,
    onPressBefore: () => void,
    onCompleteThematic: TypeStore => void,
    comesFromGoBack: boolean,
    navigation: NavigationProp,
};

const CFQuestions = ({
    questions,
    store,
    onPressBefore,
    onCompleteThematic,
    comesFromGoBack,
    navigation,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);

    const [questionLeftPos, arriveFromRightQ, leaveToRightQ, arriveFromLeftQ, leaveToLeftQ] = useEaseAnim();
    const [currentQuestion, setCurrentQuestion] = useState<CFQuestionMapItem>(questions.get('first'));

    useFocusEffect(
        useCallback(() => {
            if(comesFromGoBack) arriveFromLeftQ();
            else arriveFromRightQ();
        }, [comesFromGoBack])
    );

    const handleGoBack = () => {
        leaveToRightQ(() => {
            currentQuestion?.onPressReturn?.();
            const previousQ = currentQuestion?.getPreviousQuestion?.(store);
            if(previousQ){
                setCurrentQuestion(questions.get(previousQ));
                arriveFromLeftQ();
            } else
                // otherwise: start of thematic
                onPressBefore?.();
        });
    };

    const handleSubmit = () => {
        leaveToLeftQ(() => {
            // play side effect relative to question
            currentQuestion?.onPressNext?.(store, navigation);

            // if next question = go there
            const nextQ = currentQuestion?.getNextQuestion?.(store);
            if(nextQ){
                setCurrentQuestion(questions.get(nextQ));
                arriveFromRightQ();
            } else
                // otherwise: end of thematic
                onCompleteThematic?.(store);
        });
    };

    const showQuestionDetailsModal = () => ModalManager.showConfirmModal({
        content: currentQuestion?.infoModalContent ?? 'Erreur dans les sources.',
        validText: 'Ok',
        onValidate: () => null,
    });

    return (
        <DecoratedLayout
            bottomRightOrnament={(<OnboardingBottom />)}
            topLeftOrnament={(<FootprintTopLeft />)}
            topRightOrnament={(<FootprintTopRight />)}
        >
            <LightPageHeader
                backButton
                onPress={handleGoBack}
                title={currentQuestion?.questionDescription ?? 'Erreur question'}
            />
            <Animated.View
                style={{
                    justifyContent: 'center',
                    left: questionLeftPos,
                    marginVertical: spacing[1],
                    flex: 1,
                }}
            >
                <BasicCard
                    customStyle={{
                        backgroundColor: colors.background.card,
                        flexShrink: 1,
                    }}
                >
                    <Heading
                        centered
                        customStyle={{marginBottom: spacing[2]}}
                        variant='h2'
                    >
                        {currentQuestion?.question}
                    </Heading>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: spacing[2],
                        }}
                    >
                        <Body variant='paragraph_light'>
                            {locales.t(`ecogestures.impact.${currentQuestion?.impact ?? 0}`)}
                        </Body>
                        {
                            currentQuestion?.infoModalContent ? (
                                <SeeMoreButton onPress={showQuestionDetailsModal} title="Plus d'info" />
                            ) : null
                        }
                    </View>
                    {
                        typeof currentQuestion?.getEstimatedImpact(store) === 'number'
                            ? (
                                <CFQuestionCO2Tag
                                    impact={currentQuestion?.getEstimatedImpact(store) ?? 0}
                                    positiveImpact={currentQuestion?.positiveImpact ?? false}
                                />
                            )
                            : null
                    }
                    <ScrollView
                        showsVerticalScrollIndicator
                    >
                        {currentQuestion?.form ?? null}
                    </ScrollView>
                </BasicCard>

            </Animated.View>
            <Button
                customStyle={{marginBottom: spacing[2]}}
                disabled={currentQuestion?.getDisableSubmit?.(store)}
                onPress={handleSubmit}
                text={locales.t('global.actions.next')}
            />
        </DecoratedLayout>
    );
};


export default CFQuestions;

