/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcMotorcycle({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .854h42.015v15.22H0z" id="moto_prefix__a" />
                <Path d="M1.097.844h32.856v17.564H1.097z" id="moto_prefix__c" />
                <Path d="M.553 1.06h11.9v7.623H.553z" id="moto_prefix__e" />
                <Path d="M.077.538H11.11v5.449H.077z" id="moto_prefix__g" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M22.584 46.498c-2.252 0-4.08-1.836-4.08-4.099a4.087 4.087 0 014.08-4.094 4.09 4.09 0 014.087 4.094 4.093 4.093 0 01-4.087 4.1m0-10.538c-3.546 0-6.417 2.881-6.417 6.438s2.871 6.443 6.417 6.443c3.547 0 6.42-2.886 6.42-6.443 0-3.557-2.873-6.438-6.42-6.438M49.43 46.498a4.093 4.093 0 01-4.087-4.099 4.09 4.09 0 014.087-4.094 4.086 4.086 0 014.082 4.094 4.09 4.09 0 01-4.082 4.1m0-10.538c-3.546 0-6.423 2.881-6.423 6.438.004 3.561 2.877 6.443 6.423 6.443 3.546 0 6.418-2.886 6.418-6.443 0-3.557-2.872-6.438-6.418-6.438"
                    fill="#99AAB5"
                />
                <G transform="translate(15 33.838)">
                    <Mask fill="#fff" id="moto_prefix__b">
                        <Use xlinkHref="#moto_prefix__a" />
                    </Mask>
                    <Path
                        d="M7.584 13.73a5.26 5.26 0 01-5.252-5.268c0-2.905 2.351-5.269 5.248-5.269h.004c2.902 0 5.253 2.36 5.253 5.27 0 2.904-2.351 5.266-5.253 5.266m0-12.875C3.394.854 0 4.258 0 8.462c0 4.203 3.393 7.611 7.584 7.611 4.192 0 7.586-3.408 7.586-7.61 0-4.205-3.394-7.61-7.586-7.61M34.43 13.73a5.26 5.26 0 01-5.253-5.267 5.256 5.256 0 015.249-5.269h.004c2.896 0 5.253 2.36 5.253 5.27 0 2.904-2.357 5.266-5.253 5.266m0-12.875c-4.191 0-7.586 3.404-7.586 7.608 0 4.203 3.395 7.611 7.586 7.611 4.191 0 7.585-3.408 7.585-7.61 0-4.205-3.394-7.61-7.585-7.61"
                        fill="#292F33"
                        mask="url(#moto_prefix__b)"
                    />
                </G>
                <Path
                    d="M23.172 39.531c-2.336 4.685 1.167 3.03 2.333.69 1.056-2.121 4.02-2.849 5.437-.442a8.761 8.761 0 00-8.357-6.158c-1.545 0-3.058.41-4.39 1.194 3.304.19 6.258 2.145 4.973 4.716"
                    fill="#77B255"
                />
                <Path
                    d="M50.012 41.814c0 .65-.52 1.17-1.166 1.17H38.342a1.165 1.165 0 01-1.166-1.17c0-.646.521-1.169 1.166-1.169h10.504c.645 0 1.166.523 1.166 1.169"
                    fill="#99AAB5"
                />
                <Path
                    d="M49.923 43.346a1.167 1.167 0 01-1.641-.192l-6.536-8.248a1.166 1.166 0 01.18-1.64 1.161 1.161 0 011.649.184l6.533 8.25a1.172 1.172 0 01-.185 1.646"
                    fill="#99AAB5"
                />
                <Path
                    d="M29.544 31.71c.544.348.705 1.07.355 1.612l-5.66 8.877a1.163 1.163 0 01-1.602.395 1.176 1.176 0 01-.365-1.654l5.66-8.873a1.166 1.166 0 011.612-.357"
                    fill="#CCD6DD"
                />
                <G transform="translate(23.063 25.75)">
                    <Mask fill="#fff" id="moto_prefix__d">
                        <Use xlinkHref="#moto_prefix__c" />
                    </Mask>
                    <Path
                        d="M4.225 2.445c-.949.686-5.286 1.913-1.783 4.257 3.499 2.334 5.945 4.825 5.945 7.022 0 3.12-.769 4.684 1.057 4.684h8.167s-1.166-4.684 2.333-8.198c3.503-3.508 5.84-2.34 9.338-3.508 2.214-.743 4.67-2.344 4.67-3.514s0-2.344-2.332-1.17c-2.338 1.17-1.17 1.811-3.503.642-2.333-1.174-3.689.528-4.855.528-1.166 0-8.888 1.968-10.063-.062-.64-1.108-2.082-2.282-4.205-2.282-1.536 0-3.636.784-4.769 1.601"
                        fill="#77B255"
                        mask="url(#moto_prefix__d)"
                    />
                </G>
                <Path
                    d="M39.508 41.814a2.338 2.338 0 01-2.333 2.344 2.337 2.337 0 01-2.331-2.344 2.334 2.334 0 012.331-2.339 2.334 2.334 0 012.333 2.339"
                    fill="#66757F"
                />
                <G transform="translate(44.563 35.185)">
                    <Mask fill="#fff" id="moto_prefix__f">
                        <Use xlinkHref="#moto_prefix__e" />
                    </Mask>
                    <Path
                        d="M12.684 4.318L1.857 8.683.553 5.425 11.376 1.06z"
                        fill="#CCD6DD"
                        mask="url(#moto_prefix__f)"
                    />
                </G>
                <Path
                    d="M56.81 38.42l-10.827 4.363-.436-1.082 10.827-4.37z"
                    fill="#FFF"
                />
                <G transform="translate(45.906 37.881)">
                    <Mask fill="#fff" id="moto_prefix__h">
                        <Use xlinkHref="#moto_prefix__g" />
                    </Mask>
                    <Path
                        d="M11.34 1.623L.514 5.987.077 4.903 10.904.538z"
                        fill="#66757F"
                        mask="url(#moto_prefix__h)"
                    />
                </G>
                <Path
                    d="M37.175 29.1c1.594.452 6.585-.01 7.94-.114 1.347-.11 1.859.523 0 1.46-2.11 1.055-6.556 3.698-7.94 4.245-1.166.46-5.252-1.417-5.74-1.987-.488-.571 4.371-3.99 5.74-3.604m17.507-1.332c-1.806.044-3.503 0-4.67 0-1.165 0-1.35 1.289 1.806 1.289 3.162 0 2.864-1.289 2.864-1.289"
                    fill="#292F33"
                />
                <Path
                    d="M19.36 27.717c0-1.717 5.201-6.011 4.637-5.24-1.341 1.84-.574 4.07 1.736 4.07 2.307 0 3.162 7.631-.229 5.9-3.394-1.726-6.143-3.89-6.143-4.73"
                    fill="#77B255"
                />
                <Path
                    d="M25.04 20.242c.825.713.407 1.979-1.219 4.074-1.62 2.107-4.18 2.954-2.749.638 1.427-2.317 3.968-4.717 3.968-4.717"
                    fill="#CCD6DD"
                />
                <Path
                    d="M27.288 28.196c-.224.557-.85.827-1.403.61h-.006l-.157-.068a1.086 1.086 0 01-.6-1.413l.928-2.329a1.083 1.083 0 011.408-.604l.161.062c.554.224.82.856.602 1.411l-.933 2.33z"
                    fill="#66757F"
                />
                <Path
                    d="M30.07 24.24a.602.602 0 01-.35.757l-2.2.788a.576.576 0 01-.745-.35.586.586 0 01.351-.748l2.194-.794a.581.581 0 01.745.348h.005"
                    fill="#292F33"
                />
            </G>
        </Svg>
    );
}

export default IcMotorcycle;
