/* eslint-disable max-len */
// @flow
import type {Action, ActionP, ActionPM} from '../Types/Reducers/Action';
import type {CarbonFootprintPayloadType} from '../Types/CarbonFootprint/FootprintApiTypes';
import type {FootprintId} from '../Types/Types';
import type {
    DeviceType,
    DietType,
    DrinkType,
    DrinkTypeQuantity,
    EnergyType,
    EnergyTypeSpendings,
    NewProductQuantity,
    QuantityType,
    WeeklyMeansOfTransportType,
} from "../Types/CarbonFootprint/FootprintQuestionsTypes";

const CarbonFootprintTypes = {
    // mobility
    SET_MEANS_OF_TRANSPORT: 'SET_MEANS_OF_TRANSPORT',
    SET_WEEKLY_MEANS_OF_TRANSPORT: 'SET_WEEKLY_MEANS_OF_TRANSPORT',
    SET_NB_PEOPLE_IN_CAR: 'SET_NB_PEOPLE_IN_CAR',
    SET_NB_YEARLY_PLANE_HOURS: 'SET_NB_YEARLY_PLANE_HOURS',
    // diet
    SET_DIET_TYPE: 'SET_DIET_TYPE',
    SET_PART_OF_RED_MEAT: 'SET_PART_OF_RED_MEAT',
    SET_DRINK_TYPES: 'SET_DRINK_TYPES',
    SET_DRINK_TYPES_QUANTITIES: 'SET_DRINK_TYPES_QUANTITIES',
    SET_PART_OF_LOCAL_CONSUMPTION: 'SET_PART_OF_LOCAL_CONSUMPTION',
    // energy
    SET_NB_PEOPLE_IN_ACCOMODATION: 'SET_NB_PEOPLE_IN_ACCOMODATION',
    SET_ENERGY_TYPES: 'SET_ENERGY_TYPES',
    SET_MONTHLY_ENERGY_SPENDINGS: 'SET_MONTHLY_ENERGY_SPENDINGS',
    // consumption
    SET_MONTHLY_TRASHBAGS: 'SET_MONTHLY_TRASHBAGS',
    SET_MONTHLY_RECYCLABLE_TRASHBAGS: 'SET_MONTHLY_RECYCLABLE_TRASHBAGS',
    SET_DAILY_NB_PLASTIC_BOTTLES: 'SET_DAILY_NB_PLASTIC_BOTTLES',
    ADD_TECHNOLOGY_PURCHASE_ITEM: 'ADD_TECHNOLOGY_PURCHASE_ITEM',
    REMOVE_TECHNOLOGY_PURCHASE_ITEM: 'REMOVE_TECHNOLOGY_PURCHASE_ITEM',
    ADD_APPLIANCES_PURCHASE_ITEM: 'ADD_APPLIANCES_PURCHASE_ITEM',
    REMOVE_APPLIANCES_PURCHASE_ITEM: 'REMOVE_APPLIANCES_PURCHASE_ITEM',
    SET_MONTHLY_NEW_PRODUCTS_SPENDINGS: 'SET_MONTHLY_NEW_PRODUCTS_SPENDINGS',
    SET_DAILY_MAIL_SENDING: 'SET_DAILY_MAIL_SENDING',
    SET_DAILY_STREAMING_VIDEO_HOURS: 'SET_DAILY_STREAMING_VIDEO_HOURS',

    // data fetching
    FETCH_USER_CARBON_FOOTPRINT_HISTORY: 'FETCH_USER_CARBON_FOOTPRINT_HISTORY',
    FETCH_USER_CARBON_FOOTPRINT_HISTORY_SUCCESS: 'FETCH_USER_CARBON_FOOTPRINT_HISTORY_SUCCESS',
    FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY: 'FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY',
    FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY_SUCCESS: 'FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY_SUCCESS',
    FETCH_TRIBE_AVERAGE_FOOTPRINT: 'FETCH_TRIBE_AVERAGE_FOOTPRINT',
    FETCH_TRIBE_AVERAGE_FOOTPRINT_SUCCESS: 'FETCH_TRIBE_AVERAGE_FOOTPRINT_SUCCESS',
    FETCH_USER_SAVED_CO2: 'FETCH_USER_SAVED_CO2',
    FETCH_USER_SAVED_CO2_SUCCESS: 'FETCH_USER_SAVED_CO2_SUCCESS',
    FETCH_USER_SAVED_CO2_ERROR: 'FETCH_USER_SAVED_CO2_ERROR',
    FETCH_USER_LAST_CF_ANSWERS: 'FETCH_USER_LAST_CF_ANSWERS',
    FETCH_USER_LAST_CF_ANSWERS_SUCCESS: 'FETCH_USER_LAST_CF_ANSWERS_SUCCESS',
    FETCH_USER_LAST_CF_ANSWERS_ERROR: 'FETCH_USER_LAST_CF_ANSWERS_ERROR',

    // post
    POST_USER_CARBON_FOOTPRINT: 'POST_USER_CARBON_FOOTPRINT',
    POST_USER_CARBON_FOOTPRINT_SUCCESS: 'POST_USER_CARBON_FOOTPRINT_SUCCESS',
    POST_USER_CARBON_FOOTPRINT_ERROR: 'POST_USER_CARBON_FOOTPRINT_ERROR',
    RESET_FOOTPRINT_REDUCERS: 'RESET_FOOTPRINT_REDUCERS',

    // delete
    DELETE_FOOTPRINT: 'DELETE_FOOTPRINT',
    DELETE_FOOTPRINT_SUCCESS: 'DELETE_FOOTPRINT_SUCCESS',
    DELETE_FOOTPRINT_ERROR: 'DELETE_FOOTPRINT_ERROR',

    // utils
    SET_SIMULATING: 'SET_SIMULATING',
    SET_UPDATING: 'SET_UPDATING',
};

const setMeansOfTransport = (meansOfTransport: Array<WeeklyMeansOfTransportType>): ActionP<Array<WeeklyMeansOfTransportType>> => ({
    type: CarbonFootprintTypes.SET_MEANS_OF_TRANSPORT,
    payload: meansOfTransport,
});

const setWeeklyMeansOfTransport = (weeklyMeansOfTransport: Array<WeeklyMeansOfTransportType>): ActionP<Array<WeeklyMeansOfTransportType>> => ({
    type: CarbonFootprintTypes.SET_WEEKLY_MEANS_OF_TRANSPORT,
    payload: weeklyMeansOfTransport,
});

const setNbPeopleInCar = (nbPeopleInCar: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_NB_PEOPLE_IN_CAR,
    payload: nbPeopleInCar,
});

const setNbYearlyPlaneHours = (nbYearlyPlaneHours: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_NB_YEARLY_PLANE_HOURS,
    payload: nbYearlyPlaneHours,
});

const setDietType = (dietType: DietType): ActionP<DietType> => ({
    type: CarbonFootprintTypes.SET_DIET_TYPE,
    payload: dietType,
});

const setPartOfRedMeatType = (partOfRedMeat: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_PART_OF_RED_MEAT,
    payload: partOfRedMeat,
});

const setDrinkTypes = (drinkTypes: Array<DrinkType>): ActionP<Array<DrinkType>> => ({
    type: CarbonFootprintTypes.SET_DRINK_TYPES,
    payload: drinkTypes,
});

const setDrinkTypesQuantities = (drinkTypesQuantities: Array<DrinkTypeQuantity>): ActionP<Array<DrinkTypeQuantity>> => ({
    type: CarbonFootprintTypes.SET_DRINK_TYPES_QUANTITIES,
    payload: drinkTypesQuantities,
});

const setPartOfLocalConsumption = (localConsumption: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_PART_OF_LOCAL_CONSUMPTION,
    payload: localConsumption,
});

const setDailyNbPlasticBottles = (dailyNbPlasticBottles: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_DAILY_NB_PLASTIC_BOTTLES,
    payload: dailyNbPlasticBottles,
});

const setMonthlyNewProductsSpendings = (monthlyNewProductsSpendings: NewProductQuantity[]): ActionP<NewProductQuantity[]> => ({
    type: CarbonFootprintTypes.SET_MONTHLY_NEW_PRODUCTS_SPENDINGS,
    payload: monthlyNewProductsSpendings,
});

const setDailyMailSending = (dailyMailSending: QuantityType): ActionP<QuantityType> => ({
    type: CarbonFootprintTypes.SET_DAILY_MAIL_SENDING,
    payload: dailyMailSending,
});

const setDailyStreamingVideoHours = (dailyStreamingVideoHours: QuantityType): ActionP<QuantityType> => ({
    type: CarbonFootprintTypes.SET_DAILY_STREAMING_VIDEO_HOURS,
    payload: dailyStreamingVideoHours,
});

const setNbPeopleInAccomodation = (nbPeopleInAccomodation: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_NB_PEOPLE_IN_ACCOMODATION,
    payload: nbPeopleInAccomodation,
});

const setEnergyTypes = (energyTypes: Array<EnergyType>): ActionP<Array<EnergyType>> => ({
    type: CarbonFootprintTypes.SET_ENERGY_TYPES,
    payload: energyTypes,
});

const setMonthlyEnergySpendings = (monthlyEnergySpendings: Array<EnergyTypeSpendings>): ActionP<Array<EnergyTypeSpendings>> => ({
    type: CarbonFootprintTypes.SET_MONTHLY_ENERGY_SPENDINGS,
    payload: monthlyEnergySpendings,
});

const setMonthlyRecyclableTrashBags = (monthlyRecyclableTrashBags: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_MONTHLY_RECYCLABLE_TRASHBAGS,
    payload: monthlyRecyclableTrashBags,
});

const setMonthlyTrashBags = (monthlyTrashBags: number): ActionP<number> => ({
    type: CarbonFootprintTypes.SET_MONTHLY_TRASHBAGS,
    payload: monthlyTrashBags,
});

const addTechnologyPurchase = (device: DeviceType): ActionP<DeviceType> => ({
    type: CarbonFootprintTypes.ADD_TECHNOLOGY_PURCHASE_ITEM,
    payload: device,
});
const removeTechnologyPurchase = (device: DeviceType): ActionP<DeviceType> => ({
    type: CarbonFootprintTypes.REMOVE_TECHNOLOGY_PURCHASE_ITEM,
    payload: device,
});

const addAppliancesPurchase = (device: DeviceType): ActionP<DeviceType> => ({
    type: CarbonFootprintTypes.ADD_APPLIANCES_PURCHASE_ITEM,
    payload: device,
});
const removeAppliancesPurchase = (device: DeviceType): ActionP<DeviceType> => ({
    type: CarbonFootprintTypes.REMOVE_APPLIANCES_PURCHASE_ITEM,
    payload: device,
});

const fetchUserCarbonFootprintHistory = (): Action => ({type: CarbonFootprintTypes.FETCH_USER_CARBON_FOOTPRINT_HISTORY});

const fetchClientAverageCarbonFootprintHistory = (): Action => ({type: CarbonFootprintTypes.FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY});

const fetchTribeAverageFootprint = (): Action => ({type: CarbonFootprintTypes.FETCH_TRIBE_AVERAGE_FOOTPRINT});

const postUserCarbonFootprint = (payload: CarbonFootprintPayloadType, callback: () => void): ActionPM<CarbonFootprintPayloadType, {callback: () => void}> => ({
    type: CarbonFootprintTypes.POST_USER_CARBON_FOOTPRINT,
    payload,
    meta: {callback},
});

const resetFootprintReducers = (): Action => ({type: CarbonFootprintTypes.RESET_FOOTPRINT_REDUCERS});

const fetchUserSavedCo2 = (): Action => ({type: CarbonFootprintTypes.FETCH_USER_SAVED_CO2});

const deleteFootprint = (footprintId: FootprintId, callback: () => void): ActionPM<FootprintId, {callback: () => void}> => ({
    type: CarbonFootprintTypes.DELETE_FOOTPRINT,
    payload: footprintId,
    meta: {callback},
});

const setSimulatingCF = (simulating: boolean): ActionP<boolean> => ({
    type: CarbonFootprintTypes.SET_SIMULATING,
    payload: simulating,
});

const setUpdatingCF = (updating: boolean): ActionP<boolean> => ({
    type: CarbonFootprintTypes.SET_UPDATING,
    payload: updating,
});

const fetchUserLastCFAnswers = (): Action => ({type: CarbonFootprintTypes.FETCH_USER_LAST_CF_ANSWERS});

export default {
    Types: CarbonFootprintTypes,
    // Setters
    setMeansOfTransport,
    setWeeklyMeansOfTransport,
    setNbPeopleInCar,
    setNbYearlyPlaneHours,
    setDietType,
    setPartOfRedMeatType,
    setDrinkTypes,
    setDrinkTypesQuantities,
    setPartOfLocalConsumption,
    setDailyNbPlasticBottles,
    setMonthlyNewProductsSpendings,
    setDailyMailSending,
    setDailyStreamingVideoHours,
    setNbPeopleInAccomodation,
    setEnergyTypes,
    setMonthlyEnergySpendings,
    setMonthlyTrashBags,
    setMonthlyRecyclableTrashBags,
    addTechnologyPurchase,
    removeTechnologyPurchase,
    addAppliancesPurchase,
    removeAppliancesPurchase,

    // Fetchers
    fetchClientAverageCarbonFootprintHistory,
    fetchTribeAverageFootprint,
    fetchUserCarbonFootprintHistory,
    fetchUserSavedCo2,
    fetchUserLastCFAnswers,

    // Utils
    postUserCarbonFootprint,
    resetFootprintReducers,
    deleteFootprint,
    setSimulatingCF,
    setUpdatingCF,
};
