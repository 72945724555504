/* eslint-disable max-len */
// @flow
import {Platform} from 'react-native';

function removeLibpxlParameters(url: string): string{
    const paramsStartIndex = url.indexOf('?');
    const urlEndIndex = paramsStartIndex <= 0 ? url.length : paramsStartIndex;
    return url.substring(0, urlEndIndex);
}

function getLighterImageLibpxl(url: string, width: number = 500, height: number = 500, quality?: number): string{
    // Libpxl allows only 4 image formats : jpeg, png, gif and webp
    if (!(
        url.includes(".jpeg") ||
        url.includes(".png") ||
        url.includes(".gif") ||
        url.includes(".webp") ||
        url.includes(".jpg")
    )) return url;

    // We strip url parameters that might already be set
    const cleanURL = removeLibpxlParameters(url);

    if (url.includes(".gif") || url.includes(".webp")){
        // If animated, we can't resize the image or change quality. We only take care of format because
        // we don't handle webp on mobile
        const formatParam = Platform.OS === "web" ? '' : '?format=gif';
        return `${cleanURL}${formatParam}`;
    }
    const qualityParam = `quality=${quality ? quality : 80}`;
    const formatParam = '&format=jpeg';
    const widthParam = `width=${width}`;
    const heightParam = `&height=${height}`;

    return `${cleanURL}?${qualityParam}&${widthParam}${formatParam}${heightParam}`;
}

// Use of Cloudinary API to reduce weight of downloaded images
function getLighterImageCloudinary(url: string, width?: number, height?: number, quality?: number): string{
    if (url && url.includes('energic.libpx.com')) return getLighterImageLibpxl(url, width, height, quality);

    // noinspection DuplicatedCode
    if (!url || !url.includes('res.cloudinary.com/energic/image/upload/') || url.includes('f_auto')) return url;

    // start of actual image name, = after last / in url
    const imageNameIndex = url.lastIndexOf('image/upload/') + 'image/upload/'.length;
    if (imageNameIndex < 0) return url;
    const imageName = url.slice(imageNameIndex);
    const urlStart = url.slice(0, imageNameIndex);
    // you should start write parameters as after / and before image name !
    let cloudinaryParameters = 'c_limit,f_auto/';
    if (!!height && !!width) cloudinaryParameters = `w_${width},h_${height},${cloudinaryParameters}`;
    else cloudinaryParameters = `w_auto,${cloudinaryParameters}`;

    if (quality) cloudinaryParameters = `q_${quality},${cloudinaryParameters}`;
    else cloudinaryParameters = `q_auto,${cloudinaryParameters}`;

    return urlStart.concat(cloudinaryParameters, imageName);
}

const shortenText = (text: string, maxChar: number): string => {
    if(!text) return '';
    return text.length > maxChar ? text.substr(0, maxChar) + '...' : text;
};

export const isSameDay = (a: Date, b: Date): boolean => {
    return a.getFullYear() === b.getFullYear()
        && a.getMonth() === b.getMonth()
        && a.getDay() === b.getDay();
};

/**
* @param a - The reference date
* @param b - The comparing date
* @returns {boolean} - True is b is the day before a, false if not
*/
export const isYesterday = (a: Date, b: Date): boolean => {
    return a.getFullYear() === b.getFullYear()
        && a.getMonth() === b.getMonth()
        && a.getDay() - 1 === b.getDay();
};

export default {
    getLighterImageCloudinary,
    shortenText,
    isSameDay,
    isYesterday,
};
