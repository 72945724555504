// @flow
import React, {Node, useContext} from 'react';
import {FlatList, View} from 'react-native';
import type {ItemInfos} from "../../../Types/Utils/ItemInfos";
import locales from '../../../locales/locales';
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Touchable from "../../UI/Buttons/Touchable/Touchable";
import Body from "../../UI/Typos/Body/Body";
import type {ElementMap} from "../../../Types/Reducers/ElementMap";
import Close from "../../UI/Icons/UI/Close";

type Option<T> = { value: T, icon: Node };

export type ItemProps = {
    Icon: Element<any>,
    onPressAdd?: () => any,
    onPressRemove?: () => any,
    count: boolean,
    cardLegend: string,
};

const AnswerItem = ({
    Icon,
    onPressAdd = () => null,
    onPressRemove = () => null,
    count,
    cardLegend = '',
}: ItemProps) => {
    const [colors,, spacing] = useContext(ThemeContext);
    const hasCount = count !== 0;
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <View>
                <Touchable
                    onPress={onPressAdd}
                    style={({pressed}) => [{opacity: pressed ? 0.85 : 1}]}
                >
                    <View
                        style={{
                            backgroundColor: colors.text.white,
                            borderRadius: 60,
                            width: 60,
                            height: 60,
                            borderWidth: 2,
                            borderColor: hasCount ? colors.action : 'transparent',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Icon />
                    </View>
                </Touchable>

                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: colors.action,
                        display: hasCount ? 'flex' : 'none',
                    }}
                >
                    <Body
                        color={colors.text.white}
                        selectable={false}
                        variant='caption'
                    >
                        {count}
                    </Body>
                </View>

                <Touchable
                    onPress={onPressRemove}
                    style={({pressed}) => ({
                        opacity: pressed ? 0.7 : 1,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: colors.background.card,
                        display: hasCount ? 'flex' : 'none',
                    })}
                >
                    <Close color={colors.text.dark} size={14} />
                </Touchable>


            </View>
            <Body
                customStyle={{marginLeft: spacing[1]}}
                variant='paragraph'
            >
                {cardLegend}
            </Body>
        </View>
    );
};

export type Props<T> = {
    options: Array<Option<T>>,
    selectedValues: ElementMap<any, T>,
    listHeaderComponent?: Node,
    addItem: T => void,
    removeItem: T => void,
};

function MultiAnswerCountContainer<T>({
    options,
    selectedValues,
    listHeaderComponent = null,
    addItem,
    removeItem,
}: Props<T>){
    const [,, spacing] = useContext(ThemeContext);

    function renderAnswer({item}: ItemInfos<T>){
        const currentNb = selectedValues?.byId?.[item.value]?.count ?? 0;
        return (
            <AnswerItem
                Icon={item.icon}
                cardLegend={locales.t(`cf.${item.value}`)}
                count={currentNb}
                onPressAdd={() => addItem(item.value)}
                onPressRemove={() => removeItem(item.value)}
            />
        );
    }

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: spacing[2]}} />)}
            ListHeaderComponent={listHeaderComponent}
            data={options}
            keyExtractor={(item) => item?.value?.toString()}
            renderItem={renderAnswer}
        />
    );
}


export default MultiAnswerCountContainer;