/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
  size?: number,
};


function IcStreaming({size = 35}: Props){
    return (
        <Svg
            height={size}
            style={{enableBackground: "new 0 0 432.59 432.59"}}
            viewBox="0 0 432.59 432.59"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M432.59 51.735v221.36H0V51.735c0-6.67 5.41-12.08 12.08-12.08h408.43c6.67 0 12.08 5.41 12.08 12.08zM305.8 205.455v-93.83c0-11.37-9.22-20.58-20.59-20.58H147.38c-11.37 0-20.58 9.21-20.58 20.58v93.83c0 11.37 9.21 20.59 20.58 20.59h137.83c11.37 0 20.59-9.22 20.59-20.59z"
                style={{fill: "#e2e0d0"}}
            />
            <Path
                d="M432.59 273.095V43.729c-2.213-2.493-5.432-4.074-9.027-4.074H176.69L35.46 273.095h397.13z"
                style={{fill: "#eae8dc"}}
            />
            <Path
                d="m248.81 158.545-45.55 26.29v-52.59z"
                style={{fill: "#b6cce0"}}
            />
            <Path
                d="M305.8 111.625v93.83c0 11.37-9.22 20.59-20.59 20.59H147.38c-11.37 0-20.58-9.22-20.58-20.59v-93.83c0-11.37 9.21-20.58 20.58-20.58h137.83c11.37 0 20.59 9.21 20.59 20.58zm-102.54 73.21 45.55-26.29-45.55-26.3v52.59z"
                style={{fill: "#e59683"}}
            />
            <Path
                d="m254.79 314.045 9.06 68.89h-95.1l9.06-68.89z"
                style={{fill: "gray"}}
            />
            <Path
                d="M432.59 273.095v28.86c0 6.68-5.41 12.09-12.08 12.09H12.08c-6.67 0-12.08-5.41-12.08-12.09v-28.86h432.59zM132.47 372.935h167.65v20H132.47z"
                style={{fill: "#999"}}
            />
        </Svg>
    );
}

export default IcStreaming;
