/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcCoffee({size = 45}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .519h41.34v22.237H0z" id="daily_meat_prefix__a" />
                <Path d="M0 27.174h41.34V0H0z" id="daily_meat_prefix__c" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M37.116 29.913c-1.653-7.918-7.157-14.746-15.167-17.614-4.264-1.672-8.937.488-10.438 4.824l-.387 1.119c-3.85 12.335 2.509 26.215 14.246 30.999a8.198 8.198 0 0 0 1.568.448c-.136-8.082 3.881-15.343 10.178-19.776"
                    style={{
                        fill: "#573728",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="m36.426 58.205-1.184.053c-4.584.209-8.279-3.557-8.301-8.136-.458-13.115 10.035-24.18 23.536-24.699l1.183-.053c4.606-.245 8.336 3.302 8.34 7.916-.106 12.674-10.69 23.936-23.574 24.919"
                    style={{
                        fill: "#86573e",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M58.447 28.525a7.785 7.785 0 0 0-1.445-1.495.81.81 0 0 0-.23.537c-.128 8.181-4.828 12.684-13.242 12.684h-.063c-8.917.006-14.185 5.24-14.927 14.741.395.543.85 1.037 1.363 1.463a.85.85 0 0 0 .293-.598c.46-9.076 5.05-13.877 13.269-13.882h.068c9.041 0 14.42-4.891 14.914-13.45"
                    style={{
                        fill: "#573728",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
                <Path
                    d="M27.248 45.512c-3.739-3.438-4.544-8.455-2.237-14.207 3.282-8.264.392-15.078-8.101-19.283a7.848 7.848 0 0 0-1.889.788.841.841 0 0 0 .396.394c8.263 3.782 11.026 9.821 7.993 17.462-2.687 6.702-1.575 12.572 3.132 16.532a.842.842 0 0 0 .482.189 23.23 23.23 0 0 1 .224-1.875"
                    style={{
                        fill: "#86573e",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.84 0 0 .84 5.6 5.6)"
                />
            </G>
        </Svg>
    );
}

export default IcCoffee;
