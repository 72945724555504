// @flow
import {documentDirectory, downloadAsync} from 'expo-file-system';
import {createAlbumAsync, createAssetAsync, requestPermissionsAsync} from 'expo-media-library';
import React, {useMemo} from 'react';
import {PressableProps} from 'react-native';
import {Image} from 'react-native';
import type {ImageStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import ModalManager from '../../UI/Modals/ModalManager/ModalManager';
import LongTouchMenu from '../../UI/Buttons/LongTouchMenu';
import Utils from '../../../Config/Utils';

type Props = PressableProps & {
    uri: string,
    savable?: boolean,
    zoomable?: boolean,
    imageStyle?: ImageStyleProp,
};

const SmartImage = ({
    uri,
    onPress,
    savable = false,
    zoomable = false,
    disabled = false,
    imageStyle = {},
}: Props) => {
    const lighterURI = useMemo(() => Utils.getLighterImageCloudinary(uri, 500, 500), [uri]);

    const saveImage = async(uri: string) => {
        const {granted} = await requestPermissionsAsync();
        if (granted){
            const uriSplitted = uri.split('/');
            const fileName = uriSplitted[uriSplitted.length - 1];
            const res = await downloadAsync(uri, documentDirectory + fileName);
            const asset = await createAssetAsync(res.uri);
            await createAlbumAsync('Energic', asset, false);
        }
    };

    return (
        <LongTouchMenu
            actions={[
                {
                    name: "Enregistrer",
                    action: () => saveImage(uri),
                },
            ]}
            customStyles={{width: '100%'}}
            disabled={(!savable && !zoomable) || disabled}
            onPress={disabled ? () => null : onPress
                ? onPress
                : () => ModalManager.showImageZoomModal(uri)}
        >
            <Image
                source={{
                    uri: lighterURI,
                    cache: 'force-cache',
                }}
                style={{...imageStyle}}
            />
        </LongTouchMenu>
    );
};

export default SmartImage;