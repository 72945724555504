// @flow
import i18n from 'i18n-js';
import frFR from './fr/frFR';

const fr = frFR;

i18n.fallbacks = true;
i18n.translations = {fr};
i18n.locale = 'fr';

export default i18n;
