/* eslint-disable max-len, quotes */

export default {
    co2eq: 'tCO2eq/an',
    realise_cf: 'Réaliser son bilan',
    carbon_footprint_goto: 'Réaliser mon bilan carbone',
    simulate_cf: 'Simulation',
    my_impact: '🪴 Mon impact',
    my_impact_category: '{{category}}',
    history: {
        title: 'Historique des bilans',
        short: 'Historique',
    },
    ecogestures_to_progress: {
        zero: 'Aucun éco-geste à découvrir',
        one: 'Un éco-geste à découvrir',
        other: '{{count}} éco-gestes à découvrir',
    },
    data_header: {
        community: 'Bilan communauté',
        cop: 'Objectif COP21',
    },
    loading_category_ecogestures: 'Chargement des éco-gestes {{category}}',
    delete: {
        button_title: 'Supprimer ce bilan',
        confirm_delete: '**Supprimer ce bilan ?**\nIl ne sera plus comptabilisé dans les statistiques. Cette action est irréversible.',
    },
    modals: {
        quit: 'Es-tu sûr·e de vouloir **quitter\ndéfinitivement** ton bilan carbone ?',
        pass: 'Es-tu sûr·e de vouloir\n**passer cette question** ?',
    },
    start: {
        text: 'Bienvenue 😊\n\nTu t’apprêtes à réaliser ton bilan carbone 💪\n\nQuelques questions simples pour savoir où tu te situes par rapport à la moyenne des Français et découvrir tes leviers d’action !\n\nÀ toi de jouer !\n\nLa tribu Energic',
        simulation_mention: '\nCe bilan sera simulé, les résultats ne seront donc pas sauvegardés. Si tu as une mission Bilan carbone en cours, elle ne sera pas validée.',
        duration: 'Durée du questionnaire: ~5 min.',
    },
    end: {
        text: 'Ton bilan carbone est de...',
        legend: 'tonnes CO2eq./an',
        see_detailed_result: 'Mes résultats détaillés',
        sending_result: 'Envoi du résultat...',
        try_resend_results: 'Renvoyer mes résultats',
        share_result: 'Partager mon résultat',
        share_text_copied: 'Texte copié dans le presse-papiers',
        share_result_content_text: 'Je viens de réaliser mon bilan carbone et ce dernier s\'élève à... {{tCO2Footprint}} tonnes équivalent CO2 par an !\n' +
            ' Envie de faire (peut-être 😉) mieux que moi et de connaître ton niveau d\'engament ?\n' +
            ' Le test est disponible sut le site d\'Energic : https://bilancarbone.energic.io/',
        details: 'À titre de comparaison, le bilan carbone moyen d’un Français est de 9,9 tonnes de CO2eq/an et l’objectif COP21 est de… 2 tCO2eq/an soit 5 fois moins qu’actuellement !\n\nÀ toi de défier tes amis, ta famille et tes collègues en leur proposant de réaliser leur bilan carbone et accélérer la prise de conscience collective !',
        discover_energic: 'Découvrir Energic',
    },
    diet: {
        drink_quantity_label: {
            verylittle: '1/2x par mois',
            little: '1/2x par semaine',
            alot: '4/5x par semaine',
            enormously: '1/2x par jour',
            madly: '4/5x par jour',
        },
        drink_type_label: {
            coffee: 'Café en grain',
            capsule_coffee: 'Café en capsule',
            tea_or_chocolate: 'Thé / Chocolat chaud',
            alcool: 'Alcool',
        },
        drink_type_unit: {
            coffee: 'par tasse',
            capsule_coffee: 'par tasse',
            tea_or_chocolate: 'par tasse',
            alcool: 'par 25cl',
        },
    },
    heatingoil: 'Fioul',
    greenelectricity: 'Electricité verte',
    gas: 'Gaz',
    biogas: 'Biogaz',
    electricity: 'Electricité',
    heatnetwork: 'Réseau de chaleur',
    wood: 'Bois',
    alternativesearch: 'Moteur alternatif (don à des associations : Lilo)',
    classicsearch: 'Moteur classique (Google, Firefox, Bing)',
    greensearch: 'Moteur “verts” (Ecosia, Ecogine)',
    vegan: 'Végétalien (aucun produit d’origine animale)',
    vegetarian: 'Végétarien (oeufs, produits laitiers, mais pas de viande)',
    flexitarian: 'Flexitarien  (viande 1 à 3 fois par mois environ)',
    weeklymeateater: 'Un peu viandard (viande 1 à 3 fois par semaine environ)',
    dailymeateater: 'Viandard (une fois par jour environ)',
    everymealmeateater: 'Vraiment viandard (midi et soir, sinon ce n’est pas un repas)',
    coffee: 'Café en grain',
    capsule_coffee: 'Café en capsule',
    tea_or_chocolate: 'Thé / Chocolat chaud',
    alcool: 'Alcool',
    bus: 'Bus',
    fuelcar: 'Voiture essence ou diesel',
    electriccar: 'Voiture électrique',
    tgv: 'TGV',
    hybridcar: 'Voiture hybride',
    motorbike: 'Moto',
    subway: 'Métro/Tram',
    train: 'Train',
    walkorbike: 'Marche/Vélo',
    never: 'Jamais',
    sometimes: 'Parfois',
    often: 'Souvent',
    mostly: 'Pratiquement tout le temps',
    always: 'Tout le temps',
    verylittle: 'À peine',
    little: 'Un peu',
    alot: 'Beaucoup',
    enormously: 'Passionnément',
    madly: 'À la folie',
    classic: 'Moteur classique (Google, Bing)',
    alternativ: 'Moteur alternatif (dons à des associations: Lilo)',
    virtuous: 'Moteur "vert" (Ecosia, Ecogine)',

    // technology purchases
    tv: 'Télévision',
    laptop: 'Ordinateur portable',
    computer: 'Ordinateur fixe',
    screen: 'Écran',
    tablet: 'Tablette',
    smartphone: 'Smartphone',
    console: 'Console de jeux-vidéos',
    home_cinema: 'Home cinéma',
    speaker: 'Enceinte connectée',
    printer: 'Imprimante',
    camera: 'Appareil photo',

    // appliance purchases
    oven: 'Four',
    stove: 'Gazinière',
    microwave: 'Micro-ondes',
    dishwasher: 'Lave-vaisselle',
    washing_machine: 'Lave-linge',
    clothes_dryer: 'Sèche-linge',
    fridge: 'Réfrigérateur',
    freezer: 'Congélateur',
    wine_cellar: 'Cave à vin',
    vacuum_cleaner: 'Aspirateur',
    mixer: 'Robot cuisinier/pâtissier',

    // new products purchases
    clothing: 'Vêtements',
    furniture: 'Meubles & fournitures',
    decorations: 'Décorations & nettoyage',
    hobbies: 'Loisirs & autres',
};