// @flow
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ActivityIndicator, View} from "react-native";
import {footprintToPieData} from '../../../../libs/Utils/StringUtils';
import {VictoryPie} from "victory-native";
import {SortedFootprintSections} from "../../../../Types/CarbonFootprint/FootprintHistoryTypes";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import type {FootprintSectionString} from "../../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    footprint: SortedFootprintSections,
    size: number,
    centerComponent?: Node,
    sectionFocus?: FootprintSectionString,
    noAnimation?: boolean,
}

const PIE_END_ANGLE = 360;

const FootprintPie = ({
    footprint,
    size,
    centerComponent = null,
    sectionFocus = null,
    noAnimation = false,
}: Props) => {
    const [colors] = useContext(ThemeContext);
    const pieFormattedFootprint = useMemo(() => footprintToPieData(footprint, colors), [footprint]);
    const startedAnimRef = useRef(false);
    const [endAngle, setEndAngle] = useState(0);

    const startPieAnim = useCallback(() => {
        // set pie props afterwards for the animation to work
        setEndAngle(PIE_END_ANGLE);
    }, []);

    const handlePieAnim = useCallback(() => {
        if(pieFormattedFootprint.length > 0 && !startedAnimRef.current){
            startedAnimRef.current = true; // prevent doing the animation multiple times
            setTimeout(startPieAnim, 700);
        }
    }, [pieFormattedFootprint, startedAnimRef.current]);

    useEffect(() => {
        if(noAnimation) return;
        handlePieAnim();
    }, [pieFormattedFootprint]);


    const getInnerRadius = (pieData) => sectionFocus
        ? pieData.datum.key === sectionFocus
            ? size / 2.6
            : size / 2.3
        : size / 2.3;

    const getRadius = (pieData) => sectionFocus
        ? pieData.datum.key === sectionFocus
            ? size / 2
            : size / 2
        : size / 2;

    return (
        <View style={{alignItems: 'center', alignSelf: 'center', height: size, width: size}}>
            <VictoryPie
                animate={{duration: 300}}
                data={pieFormattedFootprint}
                endAngle={noAnimation ? PIE_END_ANGLE : endAngle}
                height={size}
                innerRadius={getInnerRadius}
                name="pie"
                padAngle={0.3}
                padding={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                radius={getRadius}
                sortKey="y"
                sortOrder="descending"
                startAngle={0}
                style={{
                    labels: {display: 'none'},
                    parent: {
                        padding: 0,
                        alignItems: 'center',
                    },
                    data: {fill: ({datum}) => datum?.fill ?? 'lightgrey'},
                }}
                width={size}
            />
            <View
                style={{
                    position: 'absolute',
                    top: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    height: size,
                    width: size,
                }}
            >
                {footprint ? centerComponent : (
                    <ActivityIndicator color={colors.action} />
                )}
            </View>
        </View>
    );
};

export default FootprintPie;
