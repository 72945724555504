/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const Energy = ({
    color = theme.colors.light.text.white,
    size = 36,
}: Props) => (
    <Svg fill="none" height={size} viewBox="0 0 36 36" width={size} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="m13.836 32.916 1.701-11.91H9.584c-.426 0-.688-.113-.788-.34-.099-.227-.035-.539.192-.936L18.94 2.291h1.702l-1.702 11.91h5.955c.425 0 .688.113.786.34.1.227.037.539-.19.936l-9.954 17.439h-1.7Z"
            fill={color}
        />
    </Svg>
);

export default Energy;