/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {G, Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcAlcohol({size = 35}: Props){
    return (
        <Svg
            height={size}
            viewBox="0 0 511.986 511.986"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M213.326 127.997V0h-85.341l.016 127.997L74.658 234.65v255.993c0 11.781 9.546 21.343 21.328 21.343h149.34c11.781 0 21.328-9.562 21.328-21.343V234.65l-53.328-106.653z"
                style={{fill: "#a0d468"}}
            />
            <Path
                d="M74.663 277.323h192v170.67h-192z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="m225.248 151.855-9.891-19.796c-1.062 1.109-4.859 4.859-11.218 8.562-9.953 5.78-21.218 8.702-33.483 8.702-12.266 0-23.531-2.922-33.468-8.702-6.359-3.688-10.156-7.438-11.218-8.562l-9.906 19.796c9.093 7.453 27.421 18.797 54.592 18.797 27.17 0 45.498-11.344 54.592-18.797zM191.983 373.318h-42.655c-5.891 0-10.672 4.781-10.672 10.672s4.781 10.656 10.672 10.656h42.655c5.906 0 10.671-4.766 10.671-10.656s-4.765-10.672-10.671-10.672zM213.326 330.648H127.984c-5.875 0-10.656 4.781-10.656 10.671 0 5.891 4.781 10.672 10.656 10.672h85.341c5.891 0 10.656-4.781 10.656-10.672.001-5.889-4.764-10.671-10.655-10.671z"
                style={{fill: "#434a54"}}
            />
            <Path
                d="M127.983 0h85.34v85.33h-85.34zM437.328 319.992s-2.875 63.998-53.342 63.998-53.343-63.998-53.343-63.998v-149.34h106.685v149.34z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="M373.333 369.713h21.312v134.94h-21.312z"
                style={{fill: "#ccd1d9"}}
            />
            <Path
                d="M415.984 490.643h-63.998c-5.906 0-10.656 4.781-10.656 10.672s4.75 10.671 10.656 10.671h63.998c5.875 0 10.656-4.78 10.656-10.671s-4.781-10.672-10.656-10.672z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="M330.643 255.993v63.999s2.876 63.998 53.343 63.998 53.342-63.998 53.342-63.998v-63.999H330.643z"
                style={{fill: "#ffce54"}}
            />
            <Path
                d="M426.64 309.32c0 5.891-4.781 10.672-10.656 10.672-5.904 0-10.654-4.781-10.654-10.672s4.75-10.672 10.654-10.672c5.875 0 10.656 4.782 10.656 10.672zM405.33 330.648c0 5.891-4.781 10.671-10.688 10.671-5.875 0-10.656-4.78-10.656-10.671s4.781-10.656 10.656-10.656c5.907 0 10.688 4.766 10.688 10.656z"
                style={{fill: "#f6bb42"}}
            />
            <G
                style={{opacity: 0.2}}
            >
                <Path
                    d="M415.984 170.652v149.34s-2.438 54.654-42.654 62.936c3.281.688 6.844 1.062 10.656 1.062 50.467 0 53.342-63.998 53.342-63.998v-149.34h-21.344z"
                    style={{fill: "#fff"}}
                />
            </G>
            <G
                style={{opacity: 0.2}}
            >
                <Path
                    d="m266.653 234.65-53.327-106.653V0h-21.328v127.997l53.327 106.653v255.993c0 11.781-9.547 21.343-21.328 21.343h21.328c11.781 0 21.328-9.562 21.328-21.343V234.65z"
                    style={{fill: "#fff"}}
                />
            </G>
        </Svg>
    );
}

export default IcAlcohol;
