/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {G, Path} from 'react-native-svg';

type Props = {
  size?: number,
};


function IcPlane({size = 30}: Props){
    return (
        <Svg height={size} viewBox="0 0 31 31" width={size}>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M26.056 20.694a.868.868 0 01-1.225.003l-.003-.003-1.227-1.228a.864.864 0 01-.004-1.224l.004-.003 2.455-2.459a.874.874 0 011.228 0l1.227 1.227a.865.865 0 01.004 1.228l-.004.004-2.455 2.455zM13.028 7.666a.865.865 0 01-1.224.004c0-.004-.004-.004-.004-.004l-1.227-1.228a.864.864 0 01-.004-1.227l.004-.004 2.455-2.45a.864.864 0 011.231 0l1.224 1.226a.859.859 0 01.004 1.22c0 .004-.004.004-.004.004l-2.455 2.46z"
                    fill="#66757F"
                />
                <Path
                    d="M1.739 19.106c1.736 0 9.554.864 9.554.864s.868 7.818.868 9.557c0 1.74-1.739 1.74-2.605.872-.871-.872-3.474-5.215-3.474-5.215S1.739 22.581.868 21.71C0 20.841 0 19.106 1.739 19.106M3.475 5.242c2.607-.035 21.713.833 21.713.833s.805 19.11.836 21.713c.032 2.611-2.398 3.482-3.354.004-.953-3.475-4.431-14.768-4.431-14.768S6.117 9.19 3.468 8.594C0 7.813.868 5.276 3.475 5.241"
                    fill="#55ACEE"
                />
                <Path
                    d="M23.453 2.603C25.188.868 29.53 0 30.399.868c.867.864 0 5.21-1.736 6.946C26.923 9.55 12.16 23.45 12.16 23.45s-5.648 3.91-6.946 2.603c-1.306-1.302 2.602-6.946 2.602-6.946s13.9-14.764 15.636-16.503"
                    fill="#CCD6DD"
                />
                <Path
                    d="M12.16 19.106s.435.435-3.475 4.343c-3.908 3.91-4.342 3.474-4.342 3.474s-.434-.433 3.474-4.342c3.909-3.909 4.344-3.475 4.344-3.475zM25.189 3.471a2.608 2.608 0 012.608 2.607h.698c.099-.275.169-.56.169-.87a2.602 2.602 0 00-2.606-2.605c-.307 0-.593.07-.87.17v.698z"
                    fill="#66757F"
                />
            </G>
        </Svg>
    );
}

export default IcPlane;
