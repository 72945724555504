/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcHeatNetwork({size = 55}: Props){
    return (
        <Svg
            height={size}
            viewBox="0 0 70 70"
            width={size}
        >
            <Defs>
                <Path d="M.213 0h33.686v34.02H.213z" id="prefix__a" />
                <Path d="M0 .061h33.687v34.02H0z" id="prefix__c" />
                <Path d="M0 .061h33.687v34.02H0z" id="prefix__e" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M24.07 52.263h1.601v-34.02H24.07zm10.807 0h1.6v-34.02h-1.6zm10.806 0h1.601v-34.02h-1.601z"
                    fill="#E6E6E6"
                />
                <G transform="translate(18.243 18.911)">
                    <Mask fill="#fff" id="prefix__b">
                        <Use xlinkHref="#prefix__a" />
                    </Mask>
                    <Path
                        d="M33.098 34.02H6.416a6.21 6.21 0 01-6.203-6.204 6.21 6.21 0 016.203-6.203h21.28c2.538 0 4.602-2.065 4.602-4.603s-2.064-4.603-4.603-4.603H6.416A6.21 6.21 0 01.213 6.204 6.211 6.211 0 016.416 0h26.682v1.6H6.416a4.61 4.61 0 00-4.603 4.604 4.607 4.607 0 004.603 4.602h21.28a6.21 6.21 0 016.203 6.204 6.212 6.212 0 01-6.204 6.204H6.416a4.607 4.607 0 00-4.603 4.602 4.608 4.608 0 004.603 4.603h26.682v1.601z"
                        fill="#E6E6E6"
                        mask="url(#prefix__b)"
                    />
                </G>
                <G transform="translate(18 18.668)">
                    <Mask fill="#fff" id="prefix__d">
                        <Use xlinkHref="#prefix__c" />
                    </Mask>
                    <Path
                        d="M32.886 34.08H6.204A6.21 6.21 0 010 27.878a6.212 6.212 0 016.204-6.204h21.279a4.607 4.607 0 004.602-4.602 4.607 4.607 0 00-4.602-4.603H6.203A6.213 6.213 0 010 6.264 6.21 6.21 0 016.204.061h26.682v1.6H6.204C3.666 1.662 1.6 3.727 1.6 6.265s2.065 4.603 4.603 4.603h21.279a6.212 6.212 0 016.204 6.204 6.211 6.211 0 01-6.204 6.203H6.203c-2.537 0-4.602 2.065-4.602 4.603s2.065 4.603 4.603 4.603h26.682v1.6z"
                        fill="#EB3624"
                        mask="url(#prefix__d)"
                    />
                </G>
                <G transform="translate(18 18.182)">
                    <Mask fill="#fff" id="prefix__f">
                        <Use xlinkHref="#prefix__e" />
                    </Mask>
                    <Path
                        d="M32.886 34.08H6.204A6.21 6.21 0 010 27.878a6.212 6.212 0 016.204-6.204h21.279a4.607 4.607 0 004.602-4.602 4.607 4.607 0 00-4.602-4.603H6.203A6.213 6.213 0 010 6.264 6.21 6.21 0 016.204.061h26.682v1.6H6.204C3.666 1.662 1.6 3.727 1.6 6.265s2.065 4.603 4.603 4.603h21.279a6.212 6.212 0 016.204 6.204 6.211 6.211 0 01-6.204 6.203H6.203c-2.537 0-4.602 2.065-4.602 4.603s2.065 4.603 4.603 4.603h26.682v1.6z"
                        fill="#FF4A38"
                        mask="url(#prefix__f)"
                    />
                </G>
                <Path
                    d="M39.518 20.015L36.674 34.13l7.883-.661-11.579 19.003.391-1.94 2.453-12.175-7.882.66z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M36.917 33.158l7.154-.661h.729L32.492 51.5l3.573-14.115-7.882.66h-.729l11.578-19.002z"
                    fill="#EEBF00"
                />
                <Path
                    d="M39.032 19.043l-2.844 14.115 7.883-.661L32.492 51.5l2.844-14.115-7.882.66z"
                    fill="#FFD900"
                />
            </G>
        </Svg>
    );
}

export default IcHeatNetwork;
