/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, G, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcVideoGamePad = ({size = 35}: Props) => (
    <Svg
        aria-hidden="true"
        className="VideoGamePad_svg__iconify VideoGamePad_svg__iconify--twemoji"
        height={size}
        viewBox="0 0 36 36"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="m2.13 14.856-.004-.002S.075 27.271.075 29.061c0 1.824 1.343 3.302 3 3.302.68 0 1.3-.258 1.803-.678l10.166-8.938L2.13 14.856zm31.69 0 .004-.002s2.051 12.417 2.051 14.207c0 1.824-1.343 3.302-3 3.302-.68 0-1.3-.258-1.803-.678l-10.166-8.938 12.914-7.891z"
            fill="#31373D"
        />
        <G fill="#14171A">
            <Circle cx={25.975} cy={15.551} r={8.5} />
            <Circle cx={9.975} cy={15.551} r={8.5} />
            <Path d="M9.975 7.051h16v16.87h-16z" />
        </G>
        <Circle cx={13.075} cy={23.301} fill="#14171A" r={5} />
        <Circle cx={22.875} cy={23.301} fill="#14171A" r={5} />
        <Circle cx={22.875} cy={23.301} fill="#67757F" r={3} />
        <Circle cx={13.075} cy={23.301} fill="#67757F" r={3} />
        <Circle cx={25.735} cy={11.133} fill="#FFCC4D" r={1.603} />
        <Circle cx={25.735} cy={17.607} fill="#77B255" r={1.603} />
        <Circle cx={22.498} cy={14.37} fill="#50A5E6" r={1.603} />
        <Circle cx={28.972} cy={14.37} fill="#DD2E44" r={1.603} />
        <Path
            d="M11.148 12.514v-2.168a.505.505 0 0 0-.505-.505H9.085a.505.505 0 0 0-.505.505v2.168l1.284 1.285 1.284-1.285zm-2.569 3.63v2.168c0 .279.226.505.505.505h1.558a.505.505 0 0 0 .505-.505v-2.168l-1.284-1.285-1.284 1.285zm5.269-3.1H11.68l-1.285 1.285 1.285 1.285h2.168a.506.506 0 0 0 .505-.505V13.55a.506.506 0 0 0-.505-.506zm-5.799 0H5.88a.506.506 0 0 0-.505.505v1.558c0 .279.226.505.505.505h2.168l1.285-1.285-1.284-1.283z"
            fill="#8899A6"
        />
    </Svg>
);

export default IcVideoGamePad;
