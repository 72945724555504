// @flow
import {Platform, StyleSheet} from 'react-native';
import Config from '../../../../../Config/Config';

const {DEVICE_HEIGHT, DEVICE_WIDTH} = Config;

const variableBgStyling = Platform.OS === 'web'
    ? {
        width: DEVICE_WIDTH,
        height: DEVICE_HEIGHT,
        overflow: 'hidden',
        borderRadius: 28,
        alignSelf: 'center',
        marginVertical: 'auto',
    } : {...StyleSheet.absoluteFill};

export default StyleSheet.create({
    modalBackground: {
        ...variableBgStyling,
        flexDirection: 'column',
    },
    subtitle: {
        backgroundColor: '#FFF',
        borderRadius: 1,
        fontFamily: 'Montserrat-Bold',
        fontSize: 22,
        marginBottom: '10%',
        paddingHorizontal: 16,
        paddingVertical: 3,
        zIndex: 1,
    },
    title: {
        borderRadius: 1,
        color: '#FFF',
        fontFamily: 'Montserrat-Bold',
        fontSize: 22,
        marginBottom: -2,
        paddingHorizontal: 16,
        paddingVertical: 3,
        zIndex: 2,
    },
});
