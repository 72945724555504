/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
  size?: number,
};

function IcRecyclableTrash({size = 35}: Props){
    return (
        <Svg
            height={size}
            style={{enableBackground: "new 0 0 511.981 511.981"}}
            viewBox="0 0 511.981 511.981"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M437.175 136.909 415.849 8.914a10.67 10.67 0 0 0-10.53-8.905H106.673c-5.219 0-9.672 3.765-10.516 8.905L74.814 136.909a10.633 10.633 0 0 0 2.391 8.641 10.61 10.61 0 0 0 8.14 3.781h341.316a10.6 10.6 0 0 0 8.123-3.781 10.64 10.64 0 0 0 2.391-8.641z"
                style={{fill: "#37bc9b"}}
            />
            <Path
                d="M434.69 174.298a10.711 10.711 0 0 0-8.029-3.64H85.345c-3.078 0-5.999 1.328-8.03 3.64s-2.953 5.391-2.547 8.438l42.67 319.988c.703 5.297 5.219 9.266 10.562 9.266h255.99c5.344 0 9.859-3.969 10.578-9.266l42.653-319.988a10.692 10.692 0 0 0-2.531-8.438z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="M458.659 128.003H53.347c-5.891 0-10.672 4.766-10.672 10.656v42.67c0 5.891 4.781 10.671 10.672 10.671h405.312c5.875 0 10.656-4.781 10.656-10.671v-42.67c0-5.89-4.781-10.656-10.656-10.656z"
                style={{fill: "#48cfad"}}
            />
            <Path
                d="M327.508 312.09a10.747 10.747 0 0 0-2.188-3.171c-4.156-4.156-10.906-4.156-15.077 0-3.203 3.202-3.922 7.905-2.234 11.812 9.921 19.952 6.562 44.873-10.031 61.467-13.077 13.077-31.311 17.921-48.217 14.53 2.719-3.031 3.562-7.515 1.781-11.468-2.406-5.375-8.719-7.781-14.094-5.359l-19.468 8.734a10.67 10.67 0 0 0-6.03 12.062c.156.688.375 1.375.672 2.047l8.733 19.452c2.438 5.375 8.75 7.78 14.124 5.359 4.281-1.922 6.656-6.327 6.219-10.749 24.719 6.312 52.03-.188 71.373-19.516 23.046-23.062 27.827-57.404 14.437-85.2zM214.012 300.45c13.078-13.093 31.312-17.921 48.218-14.546-2.719 3.047-3.562 7.516-1.781 11.484 2.406 5.374 8.734 7.78 14.094 5.358l19.468-8.749c5.374-2.406 7.78-8.719 5.358-14.094l-8.733-19.469c-2.438-5.358-8.75-7.765-14.124-5.343-4.281 1.922-6.656 6.312-6.219 10.749-24.719-6.328-52.03.172-71.373 19.516-23.046 23.046-27.827 57.403-14.438 85.199a10.99 10.99 0 0 0 2.188 3.156c4.156 4.172 10.906 4.172 15.078 0 3.203-3.188 3.922-7.906 2.234-11.797-9.923-19.966-6.563-44.871 10.03-61.464z"
                style={{fill: "#434a54"}}
            />
            <Path
                d="M159.995 0h191.99v63.997h-191.99z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="M149.343 85.333h213.319c5.875 0 10.656-4.781 10.656-10.672V.009h-21.327v63.997H159.999V.009h-21.328v74.653c0 5.89 4.781 10.671 10.672 10.671z"
                style={{
                    opacity: 0.1,
                    fill: "#080808",
                    enableBackground: "new",
                }}
            />
        </Svg>
    );
}

export default IcRecyclableTrash;
