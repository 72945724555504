// @flow
import React, {Node, useContext, useMemo} from 'react';
import {Platform, Pressable, StyleSheet, View} from 'react-native';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import Config from '../../../../Config/Config';
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import {parseColorToRgb, rgbToRgba} from "../../../../libs/Utils/ColorUtils";

type Props = {
    onClose: () => void,
    children: Node,
    backgroundColor?: string,
    visible: boolean,
    containerStyle?: ViewStyleProp,
};

const {DEVICE_HEIGHT, DEVICE_WIDTH} = Config;

const variableBgStyling = Platform.OS === 'web'
    ? {
        width: DEVICE_WIDTH,
        height: DEVICE_HEIGHT,
        maxHeight: DEVICE_HEIGHT,
        overflow: 'hidden',
        borderRadius: 28,
        alignSelf: 'center',
        marginVertical: 'auto',
    } : {...StyleSheet.absoluteFill};

const ClickableBackdrop = ({
    onClose,
    children,
    backgroundColor = null,
    visible = false,
    containerStyle = {},
}: Props) => {
    const [,,, colorScheme] = useContext(ThemeContext);
    const bgColor = useMemo(
        () => colorScheme === 'dark'
            ? rgbToRgba(parseColorToRgb('#FFFFFF'), 0.6)
            : rgbToRgba(parseColorToRgb('#000000'), 0.6),
        [colorScheme]
    );
    return (
        <View
            style={{
                display: visible ? 'flex' : 'none',
                ...variableBgStyling,
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                backgroundColor: backgroundColor ?? bgColor,
                ...containerStyle,
            }}
        >
            <Pressable
                onPress={onClose}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                }}
            />
            {children}
        </View>
    );
};

export default ClickableBackdrop;