// @flow
import React, {useContext} from 'react';
import {connect} from 'react-redux';
import MultiAnswerContainer from '../../MultiAnswerContainer';
import IcBus from '../../../../UI/Icons/CarbonFootprint/Transport/IcBus';
import IcDieselCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcDieselCar';
import IcElectricCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcElectricCar';
import IcHighSpeedTrain from '../../../../UI/Icons/CarbonFootprint/Transport/IcHighSpeedTrain';
import IcHybridCar from '../../../../UI/Icons/CarbonFootprint/Transport/IcHybridCar';
import IcMotorcycle from '../../../../UI/Icons/CarbonFootprint/Transport/IcMotorcycle';
import IcSubway from '../../../../UI/Icons/CarbonFootprint/Transport/IcSubway';
import IcTrain from '../../../../UI/Icons/CarbonFootprint/Transport/IcTrain';
import IcWalk from '../../../../UI/Icons/CarbonFootprint/Transport/IcWalk';
import {getMeansOfTransport} from '../../../../../Reducers/CarbonFootprintReducers/CFTransportReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import Body from "../../../../UI/Typos/Body/Body";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import type {MeansOfTransportOptionsType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setMeansOfTransport: Array<MeansOfTransportOptionsType> => void,
  meansOfTransport: Array<MeansOfTransportOptionsType>,
};

const options = [
    {value: 'walkorbike', icon: IcWalk},
    {value: 'subway', icon: IcSubway},
    {value: 'bus', icon: IcBus},
    {value: 'train', icon: IcTrain},
    {value: 'tgv', icon: IcHighSpeedTrain},
    {value: 'motorbike', icon: IcMotorcycle},
    {value: 'hybridcar', icon: IcHybridCar},
    {value: 'fuelcar', icon: IcDieselCar},
    {value: 'electriccar', icon: IcElectricCar},
];

const MeansOfTransportQuestion = ({
    setMeansOfTransport,
    meansOfTransport,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <MultiAnswerContainer
            handleChange={setMeansOfTransport}
            listHeaderComponent={(
                <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                    {"Plusieurs réponses possibles"}
                </Body>
            )}
            numColumns={3}
            options={options}
            selectedValues={meansOfTransport}
        />
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    meansOfTransport: getMeansOfTransport(store),
    ...props,
});

const mapDispatchToProps = {setMeansOfTransport: CFActions.setMeansOfTransport};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MeansOfTransportQuestion);
