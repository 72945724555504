// @flow
import React, {useContext} from 'react';
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import Energy from "../../../UI/Icons/CarbonFootprint/Energy";
import ThemeIcon from "../../../UI/Icons/IconContainers/ThemeIcon/ThemeIcon";
import Diet from "../../../UI/Icons/CarbonFootprint/Diet";
import Mobility from "../../../UI/Icons/CarbonFootprint/Mobility";
import ConsumptionAndDigital from "../../../UI/Icons/CarbonFootprint/ConsumptionAndDigital";
import PublicServices from "../../../UI/Icons/CarbonFootprint/PublicServices";
import {getFootprintThematicColor} from "../../../../libs/Utils/ColorUtils";
import type {FootprintSectionString} from "../../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    thematic: FootprintSectionString,
    size?: number,
};

const ThematicIcon = ({
    thematic,
    size = 48,
}: Props) => {
    const [colors] = useContext(ThemeContext);

    const getIcon = () => {
        let icon;
        const color = getFootprintThematicColor(thematic, colors);

        switch (thematic.toLowerCase()){
            case 'diet':
                icon = <Diet size={size * 0.75} />;
                break;
            case 'mobility':
                icon = <Mobility size={size * 0.75} />;
                break;
            case 'energy':
                icon = <Energy size={size * 0.75} />;
                break;
            case 'consumptionsanddigital':
                icon = <ConsumptionAndDigital size={size * 0.75} />;
                break;
            case 'publicservices':
                icon = <PublicServices size={size * 0.75} />;
                break;
            default:
                icon = <Diet size={size * 0.75} />;
                break;
        }

        return (
            <ThemeIcon color={color} size={size}>
                {icon}
            </ThemeIcon>
        );
    };

    return getIcon();
};

export default ThematicIcon;
