/* eslint-disable max-len */
// @flow
import React from "react";
import {
    getEnergyTypes,
    getKgCO2EnergiesImpact,
    getMonthlyEnergySpendings,
} from "../../../../../Reducers/CarbonFootprintReducers/CFEnergyReducer";
import QEnergyTypes from "./QEnergyTypes";
import QMonthlyEnergySpendings from "./QMonthlyEnergySpendings";
import Store from "../../../../../Config/Store";
import CFActions from "../../../../../Actions/CarbonFootprintActions";
import QNbPeopleInAccommodation from "./QNbPeopleInAccommodation";
import type {CFQuestionMapItem} from "../../../../../Types/CarbonFootprint/FootprintTypes";

const EnergyQuestionsMap: Map<string, CFQuestionMapItem> = new Map();

EnergyQuestionsMap.set('first', {
    question: 'Avec combien de personnes partages-tu ton logement ?',
    questionDescription: 'Énergie : 1/3',
    infoModalContent: `Comme pour la voiture, le nombre de personnes partageant ton logement a un impact sur ton empreinte carbone. L’impact de ton logement est divisé d’autant.`,
    impact: 3,
    positiveImpact: true,
    form: (<QNbPeopleInAccommodation />),
    onPressReturn: () => Store.dispatch(CFActions.setNbPeopleInAccomodation(1)),
    getEstimatedImpact: () => null,
    getPreviousQuestion: () => null,
    getNextQuestion: () => 'second',
});

EnergyQuestionsMap.set('second', {
    question: 'Quelles sources d’énergie utilises-tu à la maison ?',
    questionDescription: 'Énergie : 2/3',
    infoModalContent: `En France nous avons la spécificité de consommer une énergie majoritairement issue du nucléaire et donc globalement moins carbonée, pour autant l’énergie la moins carbonée est celle qu’on ne consomme pas.`,
    impact: 3,
    form: (<QEnergyTypes />),
    getEstimatedImpact: () => null,
    getDisableSubmit: store => getEnergyTypes(store).length === 0,
    getPreviousQuestion: () => 'first',
    getNextQuestion: () => 'third',
});

EnergyQuestionsMap.set('third', {
    question: 'Combien dépenses-tu  environ par mois pour chacune de ces énergies ?',
    questionDescription: 'Énergie : 3/3',
    infoModalContent: `Malheureusement, peu de personnes connaissent leur consommation en kWh, comme c’est le cas pour les consommations des voitures au L/100 par exemple. Mais nous sommes par contre attentifs au coût que cela représente chaque mois environ. Grâce à cela nous pouvons déduire le volume consommé mensuellement.\nNos sources [1](https://selectra.info/energie/guides/conso/energie-la-moins-chere) et [2](https://www.picbleu.fr/page/prix-officiel-des-energies-electricite-bois-fioul-gaz) sont basées sur les prix estimés de mai 2022.`,
    impact: 3,
    form: (<QMonthlyEnergySpendings />),
    getEstimatedImpact: store => getKgCO2EnergiesImpact(store),
    getDisableSubmit: store => getMonthlyEnergySpendings(store).length === 0,
    getPreviousQuestion: () => 'second',
    getNextQuestion: () => null,
});

export default EnergyQuestionsMap;