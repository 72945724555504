/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcDishwasher = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 57 57"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M1.5 0h54v57h-54z"
            style={{fill: "#d6d9df"}}
        />
        <Path
            d="M1.5 0h54v10h-54z"
            style={{fill: "#e7eced"}}
        />
        <Path
            d="M5.5 3h4v4h-4zM12.5 3h4v4h-4z"
            style={{fill: "#556080"}}
        />
        <Path
            d="M28.5 3h24v4h-24z"
            style={{fill: "#a4ca83"}}
        />
        <Path
            d="M6.5 17h44v35h-44z"
            style={{fill: "#a1c8ec"}}
        />
        <Path
            d="M6.5 32h44"
            style={{
                fill: "none",
                stroke: "#7fabda",
                strokeWidth: 2,
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <Path
            d="M16.5 32h-6v-5a3 3 0 1 1 6 0v5z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M11.5 20h4M13.5 20v4"
            style={{
                fill: "none",
                stroke: "#7fabda",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <Path
            d="M26.5 32h-6v-5a3 3 0 1 1 6 0v5z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M21.5 20h4M23.5 20v4"
            style={{
                fill: "none",
                stroke: "#7fabda",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <Path
            d="M36.5 32h-6v-5a3 3 0 1 1 6 0v5z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M31.5 20h4M33.5 20v4"
            style={{
                fill: "none",
                stroke: "#7fabda",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <Path
            d="M46.5 32h-6v-5a3 3 0 1 1 6 0v5z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M41.5 20h4M43.5 20v4"
            style={{
                fill: "none",
                stroke: "#7fabda",
                strokeWidth: 2,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 10,
            }}
        />
        <Path
            d="M23.5 48c0-6.627-5.373-12-12-12-1.787 0-3.476.401-5 1.102V52h16.283a11.84 11.84 0 0 0 .717-4z"
            style={{fill: "#6f98bc"}}
        />
        <Path
            d="M19.5 48a8 8 0 0 0-8-8c-1.893 0-3.63.661-5 1.76V52h11.918a7.921 7.921 0 0 0 1.082-4z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M20.5 36c-1.586 0-3.087.33-4.471.891C20.41 38.679 23.5 42.976 23.5 48a11.84 11.84 0 0 1-.717 4h9a11.84 11.84 0 0 0 .717-4c0-6.627-5.373-12-12-12z"
            style={{fill: "#568bb2"}}
        />
        <Path
            d="M20.5 40c-.024 0-.047.003-.071.003A11.939 11.939 0 0 1 23.5 48a11.84 11.84 0 0 1-.717 4h4.636a7.921 7.921 0 0 0 1.082-4 8.002 8.002 0 0 0-8.001-8z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M29.5 36c-1.586 0-3.087.33-4.471.891C29.41 38.679 32.5 42.976 32.5 48a11.84 11.84 0 0 1-.717 4h9a11.84 11.84 0 0 0 .717-4c0-6.627-5.373-12-12-12z"
            style={{fill: "#6f98bc"}}
        />
        <Path
            d="M29.5 40c-.024 0-.047.003-.071.003A11.939 11.939 0 0 1 32.5 48a11.84 11.84 0 0 1-.717 4h4.636a7.921 7.921 0 0 0 1.082-4 8.002 8.002 0 0 0-8.001-8z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M38.5 36c-1.586 0-3.087.33-4.471.891C38.41 38.679 41.5 42.976 41.5 48a11.84 11.84 0 0 1-.717 4h9a11.84 11.84 0 0 0 .717-4c0-6.627-5.373-12-12-12z"
            style={{fill: "#568bb2"}}
        />
        <Path
            d="M38.5 40c-.024 0-.047.003-.071.003A11.939 11.939 0 0 1 41.5 48a11.84 11.84 0 0 1-.717 4h4.636a7.921 7.921 0 0 0 1.082-4 8.002 8.002 0 0 0-8.001-8z"
            style={{fill: "#7fabda"}}
        />
        <Path
            d="M51.5 53h-46V16h46v37zm-44-2h42V18h-42v33z"
            style={{fill: "#fff"}}
        />
    </Svg>
);

export default IcDishwasher;
