// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {Text} from "react-native";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";

export type HeadingVariant = 'onboarding_title' | 'h1' | 'h2' | 'h3' | 'h4';

type Props = {
    variant: HeadingVariant,
    children: Node,
    customStyle?: Object,
    color?: string,
    selectable?: boolean,
    centered?: boolean,
};

const Heading = ({
    variant,
    children,
    customStyle = {},
    color,
    selectable = true,
    centered = false,
    ...rest
}: Props) => {
    const [colors, fonts] = useContext(ThemeContext);
    const getLineHeight = (fontSize) => fontSize + (fontSize * 0.3);

    const getStyle = () => {
        let fontSize = 18;
        switch (variant){
            case 'h1':
                break;
            case 'h2':
                fontSize = 16;
                break;
            case 'h3':
                fontSize = 14;
                break;
            case 'h4':
                fontSize = 12;
                break;
            case 'onboarding_title':
                fontSize = 34;
                break;
            default:
                break;
        }
        const lineHeight = getLineHeight(fontSize);
        return {
            fontSize,
            lineHeight,
        };
    };

    return (
        <Text
            allowFontScaling={false}
            selectable={selectable}
            style={{
                ...getStyle(),
                fontFamily: fonts.bold,
                color: color ?? colors.text.dark,
                textAlign: centered ? 'center' : 'left',
                ...customStyle,
            }}
            {...rest}
        >
            {children}
        </Text>
    );
};

export default Heading;