/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcSubway({size = 45}: Props){
    return (
        <Svg
            aria-hidden="true"
            height={size}
            viewBox="0 0 36 36"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M8.896 33.509c.7.412 1.421.79 2.178 1.106L17.65 20h-1.419L8.896 33.509zM19.77 20h-1.42l6.577 14.615a17.947 17.947 0 0 0 2.178-1.106L19.77 20z"
                fill="#808285"
            />
            <Path d="M12 26h2v2h-2zm10 0h2v2h-2z" fill="#58595B" />
            <Path
                d="M26 25a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2z"
                fill="#A7A9AC"
            />
            <Path
                d="M24.363 6H11.636A3.636 3.636 0 0 0 8 9.636V23a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9.636A3.636 3.636 0 0 0 24.363 6z"
                fill="#D1D3D4"
            />
            <Path d="M8 19v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8H8v4z" fill="#DD2E44" />
            <Path
                d="M26 17a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7z"
                fill="#58595B"
            />
            <Path
                d="M24 16a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v5z"
                fill="#55ACEE"
            />
            <Path d="M10 21h4v2h-4zm12 0h4v2h-4z" fill="#FFD983" />
            <Path
                d="M20 9a1 1 0 0 1-1 1h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1z"
                fill="#808285"
            />
        </Svg>
    );
}

export default IcSubway;
