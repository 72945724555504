// @flow
import React, {useContext} from "react";
import type {Node} from "react";
import BaseLayout from "../BaseLayout/BaseLayout";
import {View} from "react-native";
import styles from './styles';
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";

type Props = {
    children: Node,
    topLeftOrnament?: Node,
    topRightOrnament?: Node,
    bottomLeftOrnament?: Node,
    bottomRightOrnament?: Node,
    childrenStyle?: ViewStyleProp,
};

const DecoratedLayout = ({
    children,
    topLeftOrnament = null,
    topRightOrnament = null,
    bottomLeftOrnament = null,
    bottomRightOrnament = null,
    childrenStyle = {},
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <BaseLayout>
            <View
                style={styles.container}
            >
                {
                    topLeftOrnament
                        ? (
                            <View style={styles.topLeftOrnament}>
                                {topLeftOrnament}
                            </View>
                        ) : null
                }
                {
                    topRightOrnament
                        ? (
                            <View style={styles.topRightOrnament}>
                                {topRightOrnament}
                            </View>
                        ) : null
                }
                {
                    bottomLeftOrnament
                        ? (
                            <View style={styles.bottomLeftOrnament}>
                                {bottomLeftOrnament}
                            </View>
                        ) : null
                }
                {
                    bottomRightOrnament
                        ? (
                            <View style={styles.bottomRightOrnament}>
                                {bottomRightOrnament}
                            </View>
                        ) : null
                }
            </View>
            <View
                style={{
                    zIndex: 1,
                    paddingHorizontal: spacing[2],
                    paddingTop: spacing[4],
                    flex: 1,
                    ...childrenStyle,
                }}
            >
                {children}
            </View>
        </BaseLayout>
    );
};

export default DecoratedLayout;