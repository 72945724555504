// @flow
import React, {useContext} from "react";
import {View} from "react-native";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import Body from "../../../UI/Typos/Body/Body";
import type {CFQuestionImpactType} from "../../../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    impact: number,
    positiveImpact: boolean, // true when impact > 0 is better than 0
};

const CFQuestionCO2Tag = ({
    impact,
    positiveImpact = false,
}: Props) => {
    const [colors] = useContext(ThemeContext);

    const getLabel = (impact: CFQuestionImpactType): string => {
        return `Estimation : ${positiveImpact ? '' : '+'} ${impact?.toFixed(2).replace('.', ',')} kg CO2eq./an`;
    };

    const getColor = () => {
        if(
            (positiveImpact && impact !== 0) // positive impact && impact not null
            || (!positiveImpact && impact === 0) // negative impact but impact null
        ) return colors.feedback.success1;
        
        // negative impact & impact not null
        return colors.feedback.failure1;
    };

    return (
        <View
            style={{
                backgroundColor: getColor(),
                paddingVertical: 6,
                paddingHorizontal: 16,
                borderRadius: 5,
                alignItems: 'center',
                marginHorizontal: 10,
                marginBottom: 8,
            }}
        >
            <Body customStyle={{color: colors.text.white, flex: 0}} variant='caption'>
                {getLabel(impact)}
            </Body>
        </View>
    );
};

export default CFQuestionCO2Tag;