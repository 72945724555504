/* eslint-disable max-len */
// @flow
import React from "react";
import {TransitionPresets, createStackNavigator} from '@react-navigation/stack';
import PublicServicesThematicContainer from '../../Components/Sections/Footprint/CFQuestionsContainers/Thematics/PublicServicesThematicContainer';
import appRoutes from "../AppRoutes";
import locales from "../../locales/locales";
import MobilityThematicContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Thematics/MobilityThematicContainer";
import MobilityQuestionsContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Mobility/MobilityQuestionsContainer";
import DietThematicContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Thematics/DietThematicContainer";
import DietQuestionsContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Diet/DietQuestionsContainer";
import EnergyThematicContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Thematics/EnergyThematicContainer";
import EnergyQuestionsContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Energy/EnergyQuestionsContainer";
import ConsumptionThematicContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Thematics/ConsumptionThematicContainer";
import ConsumptionQuestionsContainer from "../../Components/Sections/Footprint/CFQuestionsContainers/Consumption/ConsumptionQuestionsContainer";
import CFEndScreen from "../../Views/Footprint/CFEndScreen";
import CFStartScreen from "../../Views/Footprint/CFStartScreen";
import FootprintCategoryDetails from "../../Views/Footprint/FootprintCategoryDetails";
import FootprintDetails from "../../Views/Footprint/FootprintDetails";

const CFStack = createStackNavigator();
export default function CarbonFootprintNavigator(){
    return (
        <CFStack.Navigator
            initialRouteName={appRoutes.cf.start}
            screenOptions={{
                gestureEnabled: false,
                title: locales.t('global.pages.cf'),
                headerShown: false,
                animationEnabled: true,
                ...TransitionPresets.SlideFromRightIOS,
            }}
        >
            <CFStack.Screen
                component={FootprintDetails}
                name={appRoutes.cf.detail}
            />
            <CFStack.Screen
                component={FootprintCategoryDetails}
                name={appRoutes.cf.category_detail}
            />
            <CFStack.Screen
                component={CFStartScreen}
                name={appRoutes.cf.start}
            />
            <CFStack.Screen
                component={MobilityThematicContainer}
                name={appRoutes.cf.mobility.thematic}
            />
            <CFStack.Screen
                component={MobilityQuestionsContainer}
                name={appRoutes.cf.mobility.questions}
            />
            <CFStack.Screen
                component={DietThematicContainer}
                name={appRoutes.cf.diet.thematic}
            />
            <CFStack.Screen
                component={DietQuestionsContainer}
                name={appRoutes.cf.diet.questions}
            />
            <CFStack.Screen
                component={EnergyThematicContainer}
                name={appRoutes.cf.energy.thematic}
            />
            <CFStack.Screen
                component={EnergyQuestionsContainer}
                name={appRoutes.cf.energy.questions}
            />
            <CFStack.Screen
                component={ConsumptionThematicContainer}
                name={appRoutes.cf.consumption.thematic}
            />
            <CFStack.Screen
                component={ConsumptionQuestionsContainer}
                name={appRoutes.cf.consumption.questions}
            />
            <CFStack.Screen
                component={PublicServicesThematicContainer}
                name={appRoutes.cf.public_services.thematic}
            />
            <CFStack.Screen
                component={CFEndScreen}
                name={appRoutes.cf.end}
            />
        </CFStack.Navigator>
    );
}