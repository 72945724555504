// @flow
import React from 'react';
import locales from '../../../locales/locales';
import ChipButton from '../../UI/Buttons/ChipButton/ChipButton';

type Props = {
    onPress: () => any,
    title?: string,
    disabled?: boolean,
};

const SeeMoreButton = ({
    onPress,
    title = locales.t('global.actions.see_all'),
    disabled,
}: Props) => (
    <ChipButton disabled={disabled} onPress={onPress} text={title} />
);

export default SeeMoreButton;