// @flow
import React, {useContext} from "react";
import type {Node} from "react";
import BaseLayout from "../BaseLayout/BaseLayout";
import {View} from "react-native";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";

type Props = {
    children: Node,
    childrenStyle?: ViewStyleProp,
};

const PageLayout = ({
    children,
    childrenStyle = {},
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <BaseLayout>
            <View
                style={{
                    zIndex: 1,
                    paddingHorizontal: spacing[2],
                    paddingTop: spacing[6],
                    paddingBottom: spacing[3],
                    flex: 1,
                    ...childrenStyle,
                }}
            >
                {children}
            </View>
        </BaseLayout>
    );
};

export default PageLayout;