/* eslint-disable max-len */
// @flow
import React from 'react';
import {connect} from 'react-redux';
import type {Store} from '../../../../../Types/Reducers/Store';
import ThematicMainScreen from '../../ThematicMainScreen';
import appRoutes from "../../../../../Navigation/AppRoutes";
import ThematicIcon from "../../Icons/ThematicIcon";

export const publicServicesThematicText = `L’empreinte carbone des services publics (écoles, hôpitaux, etc) ramenée par individu représente 14% de l'empreinte carbone moyenne d'un Français 🧐.\nTu ne peux pas agir directement dessus mais c'est bon à savoir et important de le prendre en compte 🤓`;

const mapStateToProps = (store: Store, props: Object) => ({
    thematicTitle: 'Données publiques',
    thematicIcon: <ThematicIcon size={100} thematic='publicServices' />,
    thematicIndex: 5,
    thematicsNumber: 5,
    onPressReturn: () => props.navigation.navigate(appRoutes.cf.consumption.questions),
    onPressNext: () => props.navigation.navigate(appRoutes.cf.end),
    thematicText: publicServicesThematicText,
    ...props,
});

export default connect(
    mapStateToProps,
)(ThematicMainScreen);
