const appRoutes = {
    app: 'App',
    auth_loading: 'AuthLoading',
    onboarding: {
        nav: 'Onboarding',
        intro: 'IntroductionScreen',
        welcome: 'WelcomeScreen',
        email: 'EmailScreen',
        password: 'PasswordScreen',
        names: 'NamesStep',
        login: 'LoginScreen',
        team: 'TeamStep',
        update_password: 'UpdatePasswordStepScreen',
        sponsor_code: 'SponsorCodeStep',
        cgu: 'CGUStep',
        barcode: 'Barcode',
        contact: 'ContactStep',
        ghost: 'GhostLoginStep',
        transition: 'TransitionScreen',
    },
    ftue: {
        root: 'FirstTimeUser',
        welcome: 'FTUEWelcome',
        rewards: 'FTUERewards',
        objectives: 'FTUEObjectives',
        objectives_result: 'FTUEObjectivesResult',
        coaching: 'FTUECoaching',
        coaching_time: 'FTUECoachingTime',
        displayMode: 'FTUEDisplayMode',
        footprint: 'FTUEFootprint',
    },
    menu: {
        root: 'BottomMenu',
        home: 'HomeScreen',
        newsFeed: 'NewsFeedScreen',
        messages: 'MessagesScreen',

        clientAgora: 'ClientAgoraScreen',
        teamAgora: 'TeamAgoraScreen',
        ideas: 'IdeasScreen',
        ecogestures: 'EcogesturesScreen',
        kiosk: 'KiosqueScreen',
        drawer: 'DrawerNavigator',
    },
    home: {
        root: 'Home',
        podium: 'PodiumScreen',
        podium_tabs: {
            solo: 'SoloPodium',
            team: 'TeamPodium',
        },
        statistics: 'StatisticsScreen',
        objectives: {
            nav: 'ObjectivesNav',
            list: 'ObjectivesScreen',
            detail: 'ObjectiveDetailScreen',
            totem_detail: 'TotemObjectiveDetailScreen',
            totem_rankings: 'TotemRankingsScreen',
            category_list: 'CategoryObjectivesScreen',
            contributions: 'ObjectiveContributionsScreen',
            ranking_objective: 'RankingObjectiveScreen',
        },
        chests: 'ChestsScreen',
        sponsorship: 'SponsorshipScreen',
        badges: 'BadgesScreen',
        unlocked_badges: 'UnlockedBadgesScreen',
        profile: {
            nav: 'ProfileScreen',
            root: 'ProfileMainScreen',
        },
        quiz: {
            root: 'QuizScreen',
            quiz: 'SimpleQuizScreen',
            survival: 'SurvivalQuizScreen',
            survival_rankings: 'SurvivalRankingsScreen',
        },
        settings: {
            nav: 'SettingsNav',
            root: 'SettingsScreen',
            settings_notifications: 'NotifsSettingsScreen',
            settings_data: 'DataSettingsScreen',
            settings_help: 'HelpSettingsScreen',
            settings_colors: 'ColorSettingsScreen',
        },
        impact: 'ImpactScreen',
        footprint_detail: 'FootprintDetailScreen',
        community: 'CommunityScreen',
        ecosystem: 'EcosystemScreen',
        notification_center: 'NotificationCenterScreen',
        player_profile: 'PlayerProfileScreen',
        team_profile: 'TeamProfileScreen',
        discover_trail: 'DiscoverTrailScreen',
        energies_forecast: 'EnergiesForecastScreen',
        energies_tabs: {
            ecowatt: 'Ecowatt',
            ecogaz: 'Ecogaz',
        },
    },
    agora: {
        root: 'Agora',
        message_detail: 'MessageScreen',
        edit_comment: 'EditCommentScreen',
        edit: 'EditMessageScreen',
        root_tabs: {
            client: 'ClientAgoraScreen',
            team: 'TeamAgoraScreen',
        },
    },
    ideas: {
        root: 'Ideas',
        client: 'ClientIdeasScreen',
        user: 'UserIdeasScreen',
        create: 'CreateIdeaScreen',
        root_tabs: {
            client: 'ClientIdeas',
            user: 'UserIdeas',
        },
    },
    ecogestures: {
        root: 'Ecogestures',
        detail: 'EcogestureDetails',
        feedbacks: 'EcogestureFeedbacks',
        my_space: 'EcogesturesMySpace',
        filter_result: 'EcogestureFilteredScreen',
    },
    kiosk: {
        root: 'Kiosque',
        detail: 'KiosqueDetails',
        my_space: 'KiosqueMySpace',
        feedbacks: 'KiosqueFeedbacks',
        filter_result: 'KiosqueFilteredScreen',
        kind_domain: 'KiosqueKindDomainScreen',
    },
    cf: {
        root: 'CarbonFootprintScreen',
        footprint_history: 'FootprintHistoryScreen',
        home: 'CarbonFootprintHome',
        detail: 'FootprintDetailScreen',
        category_detail: 'FootprintCategoryDetailScreen',
        start: 'StartScreen',
        end: 'EndScreen',
        mobility: {
            thematic: 'MobilityThematic',
            questions: 'MobilityQuestions',
        },
        diet: {
            thematic: 'DietThematic',
            questions: 'DietQuestions',
        },
        energy: {
            thematic: 'AccommodationThematic',
            questions: 'AccommodationQuestions',
        },
        consumption: {
            thematic: 'ConsumptionThematic',
            questions: 'ConsumptionQuestions',
        },
        public_services: {thematic: 'PublicServicesThematic'},
    },
    experienceLevels: {root: 'ExperienceScreen'},
};

export default appRoutes;