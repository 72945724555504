import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    toastContainer: {
        alignItems: 'center',
        elevation: 999,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 45,
        zIndex: 9999,
    },
});
