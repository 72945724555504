/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcPrinter = ({size = 35}: Props) => (
    <Svg
        aria-hidden="true"
        className="Printer_svg__iconify Printer_svg__iconify--twemoji"
        height={size}
        viewBox="0 0 36 36"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M30 12H6V5c0-1.105.826-2 1.846-2h20.309C29.173 3 30 3.895 30 5v7zm0 19a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-3h24v3z"
            fill="#67757F"
        />
        <Path d="M27 12H9V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10z" fill="#E1E8ED" />
        <Path
            d="M34 25c0 1-1 3-3 3H5c-2 0-3-2-3-3v-9a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v9z"
            fill="#5DADEC"
        />
        <Path
            d="M30 25c0-1.104-.978-2-2.182-2H8.182C6.977 23 6 23.896 6 25v4h24v-4z"
            fill="#292F33"
        />
        <Path d="M30 15a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-4h24v4z" fill="#4289C1" />
        <Path d="M27 34a2 2 0 0 1-2 2H11a2 2 0 0 1-2-2v-8h18v8z" fill="#E1E8ED" />
        <Path
            d="M25 29a1 1 0 0 1-1 1H12a1 1 0 1 1 0-2h12a1 1 0 0 1 1 1z"
            fill="#9AAAB4"
        />
        <Circle cx={30.5} cy={19.5} fill="#F5F8FA" r={1.5} />
        <Path
            d="M25 32a1 1 0 0 1-1 1H12a1 1 0 1 1 0-2h12a1 1 0 0 1 1 1zM9 25h18v2H9z"
            fill="#9AAAB4"
        />
    </Svg>
);

export default IcPrinter;
