/* eslint-disable max-len, quotes */

export default {
    ecogesture: {
        zero: 'écogeste',
        one: 'écogeste',
        other: 'écogestes',
    },
    todoBefore: {
        zero: 'À réaliser',
        other: 'À réaliser avant le {{count}}{{suffix}}',
    },
    days_left: {
        late: 'En retard de {{count}} jours',
        early: '{{count}} jours restants',
    },
    title: 'Mon coach personnel',
    impact: {
        1: 'Peu impactant',
        2: 'Impactant',
        3: 'Très impactant',
    },
    tier: {
        1: 'Facile',
        2: 'Moyen',
        3: 'Difficile',
        4: 'Difficile',
        5: 'Difficile',
    },
    statistics: {
        nb_done: {
            zero: 'déjà adopté',
            one: 'déjà adopté',
            other: 'déjà adoptés',
        },
        nb_impossible: {
            zero: 'impossible',
            one: 'impossible',
            other: 'impossibles',
        },
    },
    doneOnThe: 'Adopté le {{date}}',
    refused: 'Impossible pour moi',
    nb_user_has_done: {
        zero: 'l\'a adopté',
        one: 'l\'a adopté',
        other: 'l\'ont adopté',
    },
    already_done: 'Je le fais déjà',
    stop_asking: 'Autres options',
    will_do: 'Je me lance le défi',
    cant_do: 'Je ne peux pas réaliser cet écogeste',
    will_do_later: 'Remettre à plus tard',
    delay: 'Annuler mon action',
    help_modal: 'Les Éco-gestes regroupent **l’ensemble des bonnes pratiques à adopter**. Ces derniers sont triés par **niveau d’engagement**.\n\nL’option _"je vais le faire"_ permet de se fixer ses propres objectifs en plus des missions proposées par Nakoum. Attention, seulement 3 objectifs peuvent être prévus en simultané.',
    detail: {
        action_to_do: '📌 **Action à réaliser :**',
        how_to_do: '📚 **Comment la réaliser ?**',
        why_to_do: '🧐 **Pourquoi cet éco-geste ?**',
        go_further: '🤓 **Pour aller plus loin :**',
    },
    accepted: {
        congrats: 'Quand penses-tu pouvoir réaliser cet éco-geste ?',
        nb_adopted_players: 'Les %{nbPlayers} personnes qui l\'ont déjà adopté ont mis %{nbDays} jours en moyenne.',
    },
    todo: {date_saved: 'Cet éco-geste a été ajouté à tes missions pour le {{date}} !'},
    doing: {
        zero: 'Aucun objectif personnel en cours',
        one: 'Objectifs personnels',
        other: 'Objectifs personnels',
    },
    forbidden: 'Tu as déjà 3 éco-gestes à réaliser !\nAvant de réaliser ce nouvel éco-geste, termine déjà ceux que tu t\'es engagé·e à adopter.',
    validated: {
        modal_title: {
            zero: 'Tu es le premier joueur de ta communauté à réaliser cet éco-geste.',
            one: 'Tu es le second joueur à réaliser cet éco-geste.',
            other: 'Tu fais partie des {{nb}} joueurs qui réalisent cet éco-geste.',
        },
        modal_subtitle: 'Peux-tu partager ton expérience avec l\'éco-système Energic ?',
        feedback_placeholder: 'Écris ton retour ici 🙂\nPar exemple : Après plusieurs tentatives, j\'ai enfin réussi à adopter cet éco-geste en étant aidé par les membres de ma famille. C\'est devenu un rituel désormais !',
        skip_button: 'Pas maintenant',
    },
    impossible: {
        modal_title: 'Je comprends tout à fait !\n\nAvant de te proposer un nouvel éco-geste et dans le but d\'aider la communauté Energic, peux-tu partager **pourquoi il t\'est impossible de réaliser cet éco-geste ?** 💚',
        feedback_placeholder: 'Explique-moi tout 🧐',
    },
    feedback: {
        placeholder: 'Raconte-moi tout…',
        done: {
            text1: 'Dis-moi, quelles',
            text2: 'ont été les clés de ta réussite ?',
        },
        impossible: {
            text1: 'Dis-moi, pour',
            text2: 'quelles raisons',
            text3: 'ne peux-tu pas réaliser cet éco-geste ?',
        },
    },
    status: {
        todo: '',
        doing: 'En cours',
        done: {
            zero: 'Déjà adopté',
            one: 'Déjà adopté',
            other: 'Déjà adoptés',
        },
        impossible: {
            zero: 'Impossible',
            one: 'Impossible',
            other: 'Impossibles',
        },
    },
    nb_ecogestures: {
        zero: '0 écogeste',
        one: '1 écogeste',
        other: '{{count}} écogestes',
    },
    feedbacks: {
        nb_feedbacks_count: {
            zero: 'Retour d\'expérience ({{count}})',
            one: 'Retour d\'expérience ({{count}})',
            other: 'Retours d\'expérience ({{count}})',
        },
        no_feedbacks_yet: 'Aucun retour d\'expérience n\'a encore été posté.',
        input_bar_placeholder: 'Ajouter un retour d\'expérience',
        mine: 'Mon retour d\'expérience',
        post_completed: 'Merci pour ton retour d\'expérience !',
        update_completed: 'Le retour d\'expérience a bien été modifié.',
        confirm_report: 'Es-tu sûr de vouloir signaler ce retour d\'expérience ?',
        confirm_delete: 'Es-tu sûr de vouloir supprimer ton retour d\'expérience ?',
    },
    recommendations: {
        nakoum_advice: 'Contenu à la une',
        all_completed_placeholder: 'Tu as traité **l’ensemble des éco-gestes** 👏. Je t’invite à encourager les autres membres de ton équipe via la messagerie.',
        domain_completed_placeholder: 'Tu as traité l’ensemble des éco-gestes «&nbsp;**{{domain}}&nbsp;**» 👏. Je t’encourage à finaliser les autres thématiques.',
        nb_to_discover: {
            zero: '**Aucun éco-geste** à découvrir',
            one: '**Un éco-geste** à découvrir',
            other: '**{{count}} éco-gestes** à découvrir',
        },
    },
    my_space: {
        title: 'Mon espace',
        nb_to_adopt: {
            zero: 'Aucun éco-geste à adopter',
            one: 'Un éco-geste à adopter',
            other: '{{count}} éco-gestes à adopter',
        },
        nb_done: {
            zero: 'Aucun éco-geste adopté',
            one: 'Un éco-geste déjà adopté',
            other: '{{count}} éco-gestes déjà adoptés',
        },
        nb_impossible: {
            zero: 'Aucun éco-geste impossible',
            one: 'Un éco-geste impossible',
            other: '{{count}} éco-gestes impossibles',
        },
        none_to_adopt: 'Je t’invite à découvir de nouveaux éco-gestes à adopter pour agir au quotidien.',
        no_impossible: 'Les éco-gestes que tu n\'estimes pas pouvoir mettre en place apparaîtront ici.',
        done_or_impossible: 'Adoptés ou impossibles',
        go_to_mine: 'Voir mes éco-gestes',
        help_modal: 'Dans cet espace sont rassemblés les éco-gestes qui font déjà partie de tes habitudes, ceux que tu as pour objectif d\'adopter, et ceux qui sont impossibles pour le moment.\n\nPetite astuce : il n\'est jamais trop tard pour changer d\'avis ! Un éco-geste que tu estimais impossible à réaliser peut être modifié en sélectionnant "Mettre à jour".\nPour un éco-geste à adopter, tu peux le remettre à plus tard de la même manière !',
    },
    filters: {
        title: {
            zero: 'Aucun résultat',
            one: '1 Éco-geste',
            other: 'Éco-gestes ({{count}})',
        },
        no_result: 'Tu as traité tous les éco-gestes correspondants à tes filtres 👏. Je t’invite à faire une nouvelle recherche.',
        which_domains_filter: 'Quelle(s) thématiques souhaites-tu découvrir ?',
        which_tier_filter: 'Quel niveau de contenu souhaites-tu ?',
    },
};