// @flow
import {StyleSheet} from "react-native";

export default StyleSheet.create({
    bottomLeftOrnament: {
        bottom: 0,
        left: 0,
        position: 'absolute',
    },
    bottomRightOrnament: {
        bottom: 0,
        position: 'absolute',
        right: 0,
    },
    container: {
        ...StyleSheet.absoluteFill,
        alignItems: 'stretch',
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'absolute',
        width: '100%',
        zIndex: 0,
    },
    topLeftOrnament: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
    topRightOrnament: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
});