/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcOven = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M494.345 467.862H17.655C7.904 467.862 0 459.958 0 450.207V61.793c0-9.751 7.904-17.655 17.655-17.655h476.69c9.751 0 17.655 7.904 17.655 17.655v388.414c0 9.751-7.904 17.655-17.655 17.655z"
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M88.276 44.138h335.448v88.276H88.276z"
            style={{fill: "#959cb3"}}
        />
        <Path
            d="M476.69 432.552H35.31c-9.751 0-17.655-7.904-17.655-17.655V150.069c0-9.751 7.904-17.655 17.655-17.655h441.38c9.751 0 17.655 7.904 17.655 17.655v264.828c0 9.751-7.904 17.655-17.655 17.655z"
            style={{fill: "#707487"}}
        />
        <Circle
            cx={150.069}
            cy={88.276}
            r={26.483}
            style={{fill: "#707487"}}
        />
        <Path
            d="M450.207 397.241H61.793a8.829 8.829 0 0 1-8.828-8.828V211.862a8.829 8.829 0 0 1 8.828-8.828h388.414a8.829 8.829 0 0 1 8.828 8.828v176.552a8.829 8.829 0 0 1-8.828 8.827z"
            style={{fill: "#ffd764"}}
        />
        <Path
            d="M450.207 185.379H61.793a8.829 8.829 0 0 1 0-17.656h388.414a8.829 8.829 0 0 1 0 17.656z"
            style={{fill: "#e4eaf6"}}
        />
        <Path
            d="M308.966 105.931H203.034a8.829 8.829 0 0 1-8.828-8.828V79.448a8.829 8.829 0 0 1 8.828-8.828h105.931a8.829 8.829 0 0 1 8.828 8.828v17.655a8.828 8.828 0 0 1-8.827 8.828z"
            style={{fill: "#ffd764"}}
        />
        <Path
            d="M246.06 336.561a8.796 8.796 0 0 1-6.241-2.586l-86.966-87c-3.448-3.448-3.448-9.038 0-12.483a8.825 8.825 0 0 1 12.483 0l86.966 87c3.448 3.448 3.448 9.038 0 12.483a8.8 8.8 0 0 1-6.242 2.586zM344.276 383.996a8.798 8.798 0 0 1-6.241-2.586l-48.518-48.53c-3.448-3.448-3.448-9.038 0-12.483a8.825 8.825 0 0 1 12.483 0l48.517 48.53c3.448 3.448 3.448 9.038 0 12.483a8.798 8.798 0 0 1-6.241 2.586z"
            style={{fill: "#ffe678"}}
        />
        <Path
            d="M269.284 308.983a8.79 8.79 0 0 1-6.241-2.586L243.156 286.5c-3.448-3.448-3.448-9.038 0-12.483 3.448-3.448 9.043-3.448 12.483 0l19.887 19.896c3.448 3.448 3.448 9.038 0 12.483a8.8 8.8 0 0 1-6.242 2.587z"
            style={{fill: "#fff08c"}}
        />
        <Path
            d="M450.207 203.034H61.793a8.829 8.829 0 0 0-8.828 8.828v17.655a8.829 8.829 0 0 1 8.828-8.828h388.414a8.829 8.829 0 0 1 8.828 8.828v-17.655a8.83 8.83 0 0 0-8.828-8.828z"
            style={{fill: "#ffbe5a"}}
        />
        <Path
            d="M167.724 114.759a8.796 8.796 0 0 1-6.241-2.586l-35.31-35.31a8.825 8.825 0 0 1 0-12.483 8.825 8.825 0 0 1 12.483 0l35.31 35.31a8.825 8.825 0 0 1 0 12.483 8.8 8.8 0 0 1-6.242 2.586z"
            style={{fill: "#afb9d2"}}
        />
        <Circle
            cx={361.931}
            cy={88.276}
            r={26.483}
            style={{fill: "#707487"}}
        />
        <Path
            d="M379.586 114.759a8.796 8.796 0 0 1-6.241-2.586l-35.31-35.31a8.825 8.825 0 0 1 0-12.483 8.825 8.825 0 0 1 12.483 0l35.31 35.31a8.825 8.825 0 0 1 0 12.483 8.8 8.8 0 0 1-6.242 2.586z"
            style={{fill: "#afb9d2"}}
        />
    </Svg>
);

export default IcOven;
