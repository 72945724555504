/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcMixer = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M440.207 450.207H316.621l8.828-35.31H431.38z"
            style={{fill: "#c7cfe2"}}
        />
        <Path
            d="M263.656 220.69v123.586c0 48.754 39.522 88.276 88.276 88.276h52.966c48.754 0 88.276-39.522 88.276-88.276V220.69H263.656z"
            style={{fill: "#e4eaf6"}}
        />
        <Path
            d="M316.621 344.276V220.69h-52.966v123.586c0 48.754 39.522 88.276 88.276 88.276h39.384c-41.252 0-74.694-39.522-74.694-88.276z"
            style={{fill: "#d5dcec"}}
        />
        <Path
            d="M440.207 450.207H332.72c-84.759 0-154.465-66.311-158.698-150.962l-7.482-149.617a8.827 8.827 0 0 0-8.814-8.388H86.001a8.825 8.825 0 0 0-8.814 8.388L61.996 453.37l-21.759 10.548c-9.069 4.401-14.926 13.754-14.926 23.832v15.422A8.829 8.829 0 0 0 34.139 512H466.69a8.829 8.829 0 0 0 8.828-8.828v-17.655c0-19.47-15.84-35.31-35.311-35.31z"
            style={{fill: "#d2555a"}}
        />
        <Path
            d="m62.155 450.207-23.449 13.332a26.483 26.483 0 0 0-13.394 23.022v16.611A8.829 8.829 0 0 0 34.14 512h432.552a8.829 8.829 0 0 0 8.828-8.828v-17.655c0-19.501-15.809-35.31-35.31-35.31H62.155zM122.513 211.858c1.956 0 3.919-.092 5.882-.277 15.946-1.523 30.104-9.476 40.183-21.171l-2.039-40.781c-.004-.092-.056-.166-.063-.256-.413 22.991-17.687 42.522-39.752 44.631-1.405.134-2.81.199-4.211.199-10.978 0-21.646-4.07-29.789-11.474a44.262 44.262 0 0 1-13.554-23.832h-2.45l-1.545 30.883c1.769 2.114 3.627 4.152 5.666 6.008 11.397 10.364 26.196 16.07 41.672 16.07z"
            style={{fill: "#c34b50"}}
        />
        <Path
            d="M378.414 406.069a8.829 8.829 0 0 1-8.828-8.828v-220.69a8.829 8.829 0 0 1 17.656 0v220.69a8.829 8.829 0 0 1-8.828 8.828z"
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M396.069 264.828h-35.31a8.829 8.829 0 0 1 0-17.656h35.31a8.829 8.829 0 0 1 0 17.656z"
            style={{fill: "#c7cfe2"}}
        />
        <Path
            d="M378.414 406.069a8.803 8.803 0 0 1-5.142-1.655c-41.863-30.008-47.577-100.629-47.797-103.62a8.824 8.824 0 0 1 4.258-8.224l44.138-26.483a8.816 8.816 0 0 1 9.086 0l44.138 26.483a8.82 8.82 0 0 1 4.258 8.224c-.22 2.991-5.935 73.612-47.797 103.62a8.803 8.803 0 0 1-5.142 1.655zm-34.741-101.276c1.97 15.1 9.664 58.548 34.741 81.129 25.151-22.625 32.798-66.039 34.75-81.126l-34.75-20.85-34.741 20.847z"
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M334.276 148.966v18.759a8.829 8.829 0 0 0 8.828 8.828h70.621a8.829 8.829 0 0 0 8.828-8.828v-18.759h-88.277z"
            style={{fill: "#8f96ac"}}
        />
        <Path
            d="M369.587 167.724v-18.759h-35.31v18.759a8.829 8.829 0 0 0 8.828 8.828h35.31a8.828 8.828 0 0 1-8.828-8.828z"
            style={{fill: "#7c8296"}}
        />
        <Path
            d="m464.142 129.129-12.276-98.198C449.657 13.26 434.636 0 416.829 0H57.517C39.71 0 24.688 13.26 22.479 30.931l-12.276 98.198a26.502 26.502 0 0 0 6.426 20.814 26.5 26.5 0 0 0 19.853 8.953h42.689a44.27 44.27 0 0 0 13.551 23.832c8.142 7.405 18.81 11.474 29.789 11.474 1.401 0 2.806-.065 4.211-.199 22.332-2.134 39.828-22.099 39.828-45.452v-16.138a8.829 8.829 0 0 1 8.828-8.828h114.759a8.829 8.829 0 0 1 8.828 8.828v8.828c0 9.751 7.904 17.655 17.655 17.655h121.241a26.502 26.502 0 0 0 19.853-8.952 26.496 26.496 0 0 0 6.428-20.815z"
            style={{fill: "#ff6464"}}
        />
        <Circle
            cx={122.416}
            cy={150.069}
            r={13.241}
            style={{fill: "#707487"}}
        />
        <Path
            d="M493.173 229.517H263.656a8.829 8.829 0 0 1 0-17.656h229.517a8.829 8.829 0 0 1 0 17.656z"
            style={{fill: "#d7deed"}}
        />
        <Circle
            cx={95.934}
            cy={79.448}
            r={44.138}
            style={{fill: "#707487"}}
        />
        <Path
            d="M78.216 105.87a8.789 8.789 0 0 1-6.259-2.603 8.825 8.825 0 0 1 .034-12.483l35.43-35.188c3.448-3.439 9.043-3.431 12.483.034a8.825 8.825 0 0 1-.034 12.483l-35.43 35.188a8.802 8.802 0 0 1-6.224 2.569z"
            style={{fill: "#8f96ac"}}
        />
        <Path
            d="M307.793 220.69a8.829 8.829 0 0 1 8.828-8.828h-52.966a8.829 8.829 0 0 0 0 17.656h52.966a8.83 8.83 0 0 1-8.828-8.828z"
            style={{fill: "#c7cfe2"}}
        />
        <Path
            d="M466.69 273.655a8.829 8.829 0 0 0 8.828-8.828V256a8.829 8.829 0 0 0-17.656 0v8.828a8.828 8.828 0 0 0 8.828 8.827z"
            style={{fill: "#fff"}}
        />
        <Path
            d="M449.035 396.306a8.796 8.796 0 0 1-6.173-2.517 8.838 8.838 0 0 1-.138-12.487c9.759-9.97 15.138-23.121 15.138-37.026v-44.138a8.826 8.826 0 0 1 8.828-8.828 8.826 8.826 0 0 1 8.828 8.828v44.138c0 18.548-7.164 36.086-20.172 49.379a8.822 8.822 0 0 1-6.311 2.651z"
            style={{fill: "#f2f5fb"}}
        />
    </Svg>
);

export default IcMixer;
