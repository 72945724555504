/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcTelevision = ({size = 35}: Props) => (
    <Svg
        aria-hidden="true"
        className="Television_svg__iconify Television_svg__iconify--twemoji"
        height={size}
        viewBox="0 0 36 36"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M35 31s0 4-4 4H5c-4 0-4-4-4-4V12c0-4 4-4 4-4h26s4 0 4 4v19z"
            fill="#31373D"
        />
        <Path
            d="M21.303 10.389a.999.999 0 1 1-1.414 1.414l-9.192-9.192a.999.999 0 1 1 1.414-1.414l9.192 9.192z"
            fill="#31373D"
        />
        <Path
            d="M14.697 10.389a.999.999 0 1 0 1.414 1.414l9.192-9.192a.999.999 0 1 0-1.414-1.414l-9.192 9.192z"
            fill="#31373D"
        />
        <Path
            d="M18 11c8 0 10 1 11 2s2 3 2 8-1 7-2 8-3 2-11 2-10-1-11-2-2-3-2-8 1-7 2-8 3-2 11-2z"
            fill="#55ACEE"
        />
        <Circle cx={31.5} cy={31.5} fill="#66757F" r={1.5} />
        <Circle cx={4.5} cy={31.5} fill="#66757F" r={1.5} />
    </Svg>
);

export default IcTelevision;
