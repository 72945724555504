/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
};

const Share = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M13.053 16c-.59 0-1.088-.204-1.495-.61a2.033 2.033 0 0 1-.61-1.495 1.983 1.983 0 0 1 .126-.695L4.547 9.368c-.21.225-.452.404-.725.537-.274.134-.566.2-.875.2-.589 0-1.087-.203-1.494-.61A2.033 2.033 0 0 1 .843 8c0-.59.203-1.088.61-1.495.407-.407.905-.61 1.494-.61.31 0 .6.067.875.2.273.133.515.312.725.537L11.074 2.8a1.93 1.93 0 0 1-.095-.348 1.968 1.968 0 0 1-.032-.347c0-.59.204-1.087.611-1.494.407-.407.905-.611 1.495-.611.59 0 1.087.204 1.494.61.407.408.611.906.611 1.495 0 .59-.204 1.088-.61 1.495-.408.407-.906.61-1.495.61-.31 0-.6-.066-.874-.2a2.468 2.468 0 0 1-.726-.536L4.926 7.305a1.981 1.981 0 0 1 0 1.39l6.527 3.831c.21-.224.452-.403.726-.536.274-.134.565-.2.874-.2.59 0 1.087.203 1.494.61.407.407.611.905.611 1.495 0 .59-.204 1.088-.61 1.495-.408.406-.906.61-1.495.61Zm0-12.632c.35 0 .649-.123.894-.368.246-.246.369-.544.369-.895 0-.35-.123-.649-.369-.894a1.216 1.216 0 0 0-.894-.369c-.351 0-.65.123-.895.369a1.216 1.216 0 0 0-.368.894c0 .351.123.65.368.895.246.245.544.368.895.368ZM2.947 9.263c.351 0 .65-.123.895-.369.246-.245.369-.543.369-.894 0-.35-.123-.649-.37-.894a1.216 1.216 0 0 0-.894-.37c-.35 0-.649.124-.894.37A1.216 1.216 0 0 0 1.684 8c0 .35.123.649.37.894.244.246.543.37.893.37Zm10.106 5.895c.35 0 .649-.123.894-.369.246-.245.369-.543.369-.894s-.123-.65-.369-.895a1.216 1.216 0 0 0-.894-.368c-.351 0-.65.123-.895.368a1.216 1.216 0 0 0-.368.895c0 .35.123.649.368.894.246.246.544.369.895.369Z"
            fill={color}
        />
    </Svg>
);

export default Share;