// @flow
import type {ClientId} from '../../Types/Types';
import {eventActions, eventCategories, eventNames} from "./MatomoEvents";
import {Alert, Platform} from "react-native";
import {requestTrackingPermissionsAsync} from 'expo-tracking-transparency';
import Config from "../../Config/Config";

class MatomoTracker{
    constructor(userOptions){
        if (!userOptions.urlBase) throw new Error('urlBase is required for Matomo tracking.');

        if (!userOptions.siteId) throw new Error('siteId is required for Matomo tracking.');

        this.initialize(userOptions);
    }

    async getPermission(){
        const {granted} = await requestTrackingPermissionsAsync();
        return granted;
    }

    initialize({urlBase, trackerUrl, siteId, disabled = false, log = false}){
        const trackingAllowed = this.getPermission();
        const normalizedUrlBase = urlBase[urlBase.length - 1] !== '/' ? `${urlBase}/` : urlBase;

        const realDisabled = disabled || !trackingAllowed || Platform.OS === 'web' || Config.isLocalOrDev;
        this.disabled = realDisabled;
        this.log = log;

        if (realDisabled){
            log && Alert.alert('Matomo tracking is disabled.');
            return;
        }

        this.trackerUrl = trackerUrl ? trackerUrl : `${normalizedUrlBase}matomo.php`;
        this.siteId = siteId;

        log && Alert.alert('Matomo tracking enabled', `URL ${this.trackerUrl} / SITE ID ${this.siteId}`);
    }

    /**
     * Sets the client id of user later on/after init
     */
    setClientId(clientId: ClientId){
        if(clientId)
            this.clientId = clientId;
    }

    /**
     * Tracks app start as event
     */
    trackAppStart(){
        return this.trackEvent({
            category: encodeURIComponent(eventCategories.SYSTEM),
            action: encodeURIComponent(eventActions.OPEN),
            name: encodeURIComponent('APP'),
        });
    }

    /**
     * Tracks invite user as action
     */
    trackInviteUserAction(){
        return this.trackClickAction(eventNames.INVITE_USER);
    }

    /**
     * Tracks screen view as action
     *
     * @param name - The title of the action being tracked.
     * It is possible to use slashes / to set one or several categories for this action.
     * For example, Help / Feedback will create the Action Feedback in the category Help.
     */
    trackScreenView(name: string){
        if (!name) return this.trackError('name is required');
        return this.trackAction([eventCategories.NAVIGATION, eventActions.VISIT, name].join(' / '));
    }

    /**
     * Track click on specific element
     * @param name description of the targeted element
     * @param id optional uuid of the element
     */
    trackClickAction(name: string, id?: number){
        if (!name) return this.trackError('name is required');
        return this.trackEvent({
            category: encodeURIComponent(eventCategories.INTERACTION),
            action: encodeURIComponent(eventActions.CLICK),
            name: encodeURIComponent(name),
            value: id ? encodeURIComponent(id) : null,
        });
    }

    /**
     * Track the acceptance of a condition or proposition
     * @param name description of the accepted element
     */
    trackAcceptAction(name: string){
        if (!name) return this.trackError('name is required');
        return this.trackEvent({
            category: encodeURIComponent(eventCategories.INTERACTION),
            action: encodeURIComponent(eventActions.ACCEPT),
            name: encodeURIComponent(name),
        });
    }

    /**
     * Track clicking a link leading outside the app
     * @param action_name name of the action
     * @param outlinkUrl targeted external url
     */
    trackOuterLinkAction(action_name: string, outlinkUrl: string){
        if(!action_name || !outlinkUrl) return this.trackError('action_name & outlinkUrl required');
        return this.track({
            category: encodeURIComponent(eventCategories.INTERACTION),
            action: encodeURIComponent(eventActions.SHARE),
            action_name: encodeURIComponent(action_name),
            url: encodeURIComponent(outlinkUrl),
            link: encodeURIComponent(outlinkUrl),
        });
    }

    /**
     * Tracks actions
     * Doc: https://developer.matomo.org/api-reference/tracking-api#recommended-parameters
     * @param action_name - The title of the action being tracked.
     * It is possible to use slashes / to set one or several categories for this action.
     * For example, Help / Feedback will create the Action Feedback in the category Help.
     */
    trackAction(action_name: string){
        if (!action_name) return this.trackError('action_name is required');
        return this.track({
            action_name: encodeURIComponent(action_name),
            url: encodeURIComponent(action_name),
        });
    }

    /**
     * Tracks errors
     * @param error description of the error
     */
    trackError(error: string){
        return this.trackEvent({
            category: encodeURIComponent(eventCategories.SYSTEM),
            action: encodeURIComponent(eventActions.ERROR),
            name: encodeURIComponent(error),
        });
    }

    /**
     * Tracks custom events
     *
     * Doc: https://developer.matomo.org/api-reference/tracking-api#optional-event-tracking
     *
     * @param {Object} data -
     * {String} `category` - The event category. Must not be empty. (eg. Videos, Music, Games...)
     *
     * {String} `action` - The event action. Must not be empty. (eg. Play, Pause, Duration, Downloaded, Clicked...)
     *
     * {String} `name` - The event name. (eg. a Movie name, or Song name, or File name...)
     *
     * {String} `value` - The event value. Must be a float or integer value (numeric), not a string.
     */
    trackEvent({category, action, name, value}){
        if (!category) throw new Error('Error: category is required.');
        if (!action) throw new Error('Error: action is required.');

        return this.track({
            e_c: encodeURIComponent(category),
            e_a: encodeURIComponent(action),
            e_n: encodeURIComponent(name),
            e_v: value ? encodeURIComponent(value) : null,
        });
    }

    /**
     * Sends the tracking to Matomo
     */
    track(data){
        if (this.disabled) return;
        if (!data) return;
        const fetchObj = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods': 'HEAD, POST, GET, PATCH, PUT, OPTIONS, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            },
            body: new URLSearchParams({
                idsite: encodeURIComponent(this?.siteId),
                rec: 1,
                apiv: 1,
                dimension1: encodeURIComponent(this?.clientId), // custom dimension 1 = clientId
                ...data,
            }).toString(),
        };

        this.log && Alert.alert('Tracking action', JSON.stringify(fetchObj));

        return fetch(this.trackerUrl, fetchObj)
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);

                this.log && Alert.alert('Tracking sent', `${this.trackerUrl}, ${JSON.stringify(fetchObj)}`);
                return response;
            })
            .catch((error) => {
                this.log && Alert.alert(`Tracking error`, JSON.stringify(error) + JSON.stringify(fetchObj));
                return error;
            });
    }
}

export const createMatomoInstance = () => {
    return new MatomoTracker({
        urlBase: 'https://khlwgspvazanfewldthw-matomo.services.clever-cloud.com/',
        trackerUrl: 'https://khlwgspvazanfewldthw-matomo.services.clever-cloud.com/matomo.php',
        siteId: 2,
        log: false,
        disabled: false,
    });
};

export default MatomoTracker;
