// @flow
import React, {useContext} from "react";
import {ActivityIndicator} from "react-native";
import type {TextStyleProp, ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import Heading from "../../Typos/Heading/Heading";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import Touchable from '../Touchable/Touchable';

type Props = {
    text: string,
    onPress?: any => any,
    disabled?: boolean,
    customStyle?: ViewStyleProp,
    customTextStyle?: TextStyleProp,
    lighterRound?: boolean,
    loading?: boolean,
};

const ChipButton = ({
    text,
    onPress = () => null,
    disabled = false,
    customStyle = {},
    customTextStyle = {},
    lighterRound = false,
    loading = false,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    return (
        <Touchable
            onPress={disabled ? () => null : onPress}
            style={({pressed}) => ({
                backgroundColor: disabled ? colors.background.disabled : colors.action,
                paddingTop: 3,
                paddingBottom: spacing[1] * 0.5,
                paddingHorizontal: spacing[1] * 0.75,
                borderRadius: spacing[1] * (lighterRound ? 0.5 : 1.5),
                alignItems: 'center',
                justifyContent: 'center',
                opacity: disabled
                    ? 1
                    : pressed ? 0.7 : 1,
                cursor: disabled ? 'default' : 'pointer',
                ...customStyle,
            })}
        >
            {
                loading
                    ? (
                        <ActivityIndicator
                            color={colors.text.white}
                        />
                    )
                    : (
                        <Heading
                            customStyle={{
                                color: disabled ? colors.text.dark : colors.text.white,
                                ...customTextStyle,
                            }}
                            selectable={false}
                            variant='h4'
                        >
                            {text}
                        </Heading>
                    )
            }
        </Touchable>
    );
};

export default ChipButton;