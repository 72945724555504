/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcTea({size = 35}: Props){
    return (
        <Svg
            height={size}
            viewBox="0 0 511.984 511.984"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M448.033 213.329h-85.139c-11.78 0-21.326 9.546-21.326 21.328v39.866c1.375-10.461 10.312-18.531 21.139-18.531h42.656c11.78 0 21.342 9.547 21.342 21.343v106.653c0 11.781-9.562 21.344-21.342 21.344h-42.656c-10.827 0-19.764-8.078-21.139-18.531v39.843c0 11.781 9.546 21.343 21.326 21.343h85.139c11.781 0 21.328-9.562 21.328-21.343V234.657c0-11.781-9.547-21.328-21.328-21.328z"
                style={{fill: "#da4453"}}
            />
            <Path
                d="M362.613 469.33c0 23.562-19.107 42.654-42.67 42.654H85.278c-23.562 0-42.655-19.093-42.655-42.654V149.331h319.99V469.33z"
                style={{fill: "#ed5564"}}
            />
            <Path
                d="m285.632 110.638.047.07c-.016-.031-2.531-3.297-3.609-7.484-1.125-4.336-.281-7.508 2.812-10.609 8.656-8.694 11.53-19.991 8.062-31.812-2.312-7.937-6.797-13.546-7.391-14.249v-.008c0-.008-.016-.016-.016-.023v.008a10.64 10.64 0 0 0-8.218-3.867c-5.891 0-10.656 4.781-10.656 10.671 0 2.586.922 4.953 2.453 6.804.016.023 2.266 2.938 3.359 6.656 1.281 4.359.469 7.578-2.703 10.766-8.641 8.679-11.516 20.038-8.094 31.999 2.328 8.093 6.828 13.835 7.344 14.468v-.008a10.651 10.651 0 0 0 8.297 3.977c5.89 0 10.671-4.773 10.671-10.664a10.656 10.656 0 0 0-2.358-6.695zM221.102 25.343c-3.172-11.945-9.312-20.226-10-21.132l-.016.008A10.606 10.606 0 0 0 202.617 0c-5.89 0-10.671 4.773-10.671 10.664 0 2.414.812 4.625 2.156 6.406l-.016.008c.047.062 4.5 6.101 6.546 14.319 2.453 9.836.484 18.188-6.015 25.539-11.391 12.882-15.016 28.694-10.5 45.717 3.188 11.945 9.312 20.226 10 21.132l.016-.008c1.953 2.562 5.016 4.218 8.483 4.218 5.891 0 10.656-4.773 10.656-10.664a10.58 10.58 0 0 0-2.141-6.406v-.008c-.047-.062-4.484-6.102-6.531-14.32-2.469-9.835-.5-18.187 6-25.538 11.393-12.881 15.034-28.693 10.502-45.716zM143.573 60.803c-2.328-7.937-6.812-13.546-7.391-14.249v-.008c-.016-.008-.016-.016-.016-.023v.008c-1.968-2.359-4.921-3.867-8.218-3.867-5.891 0-10.672 4.781-10.672 10.671 0 2.586.922 4.953 2.453 6.804.031.023 2.281 2.938 3.375 6.656 1.266 4.359.453 7.578-2.719 10.766-8.641 8.679-11.516 20.038-8.078 31.999 2.328 8.093 6.828 13.835 7.328 14.468l.016-.008a10.628 10.628 0 0 0 8.297 3.977c5.891 0 10.671-4.773 10.671-10.664 0-2.539-.891-4.867-2.375-6.695l.062.07c-.031-.031-2.53-3.297-3.608-7.484-1.125-4.336-.281-7.508 2.812-10.609 8.657-8.695 11.516-19.992 8.063-31.812z"
                style={{fill: "#e6e9ed"}}
            />
            <Path
                d="M170.635 341.333H85.263v-85.341l21.342-21.335h42.702l21.328 21.671z"
                style={{fill: "#ffce54"}}
            />
            <Path
                d="M117.277 149.331v106.661c0 2.727 1.047 5.461 3.125 7.539 4.172 4.164 10.922 4.164 15.094 0a10.646 10.646 0 0 0 3.124-7.539v10.664-117.325h-21.343z"
                style={{fill: "#434a54"}}
            />
            <Path
                d="M103.481 504.453a10.668 10.668 0 0 0-15.094 0 10.611 10.611 0 0 0-3.125 7.531h21.327c.001-2.719-1.031-5.469-3.108-7.531zM146.151 504.453c-4.156-4.171-10.905-4.171-15.077 0a10.611 10.611 0 0 0-3.125 7.531h21.327c0-2.719-1.031-5.469-3.125-7.531zM188.838 504.453c-4.156-4.171-10.922-4.171-15.078 0-2.094 2.062-3.125 4.812-3.125 7.531h21.328c0-2.719-1.047-5.469-3.125-7.531zM231.524 504.453c-4.172-4.171-10.922-4.171-15.078 0-2.094 2.062-3.125 4.812-3.125 7.531h21.327c0-2.719-1.047-5.469-3.124-7.531zM274.211 504.453a10.668 10.668 0 0 0-15.094 0 10.611 10.611 0 0 0-3.125 7.531h21.344c0-2.719-1.047-5.469-3.125-7.531zM316.896 504.453a10.668 10.668 0 0 0-15.094 0 10.611 10.611 0 0 0-3.125 7.531h21.344c0-2.719-1.047-5.469-3.125-7.531z"
                style={{fill: "#da4453"}}
            />
        </Svg>
    );
}

export default IcTea;
