// @flow
import React, {Node, useContext} from 'react';
import {connectActionSheet} from '@expo/react-native-action-sheet';
import {Entypo} from '@expo/vector-icons';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import Touchable from '../Buttons/Touchable/Touchable';
import {ThemeContext} from '../../../libs/Hoc/ThemeProvider';

export type DropMenuActionType = {
    name: string,
    action: () => any,
}

type Props = {
    style?: ViewStyleProp,
    title: string,
    actions: Array<DropMenuActionType>,
    cancelButtonIndex: number,
    destructiveButtonIndex: ?number,
    showActionSheetWithOptions: (Object, (number) => any) => void,
    renderButton?: (() => any) => Node,
    onPress: () => any,
    color?: string,
}

const EDropMenu = ({
    style = {},
    title = 'Que souhaites-tu faire ?',
    renderButton = null,
    onPress = () => null,
    actions,
    cancelButtonIndex,
    destructiveButtonIndex,
    showActionSheetWithOptions,
    color,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);

    const toggleMenu = () => {
        onPress();
        showActionSheetWithOptions(
            {
                options: actions.map(a => a.name),
                cancelButtonIndex,
                destructiveButtonIndex,
                title,
            },
            (buttonIndex) => {
                if(actions?.[buttonIndex]) actions[buttonIndex].action();
            },
        );
    };

    return renderButton ? renderButton(toggleMenu) : (
        <Touchable
            onPress={toggleMenu}
            style={({pressed}) => ({
                ...style,
                opacity: pressed ? 0.7 : 1,
                paddingHorizontal: spacing[1] * 0.5,
                paddingVertical: spacing[1] * 0.2,
                alignItems: 'center',
            })}
        >
            <Entypo
                color={color ?? colors.text.highlight}
                name="dots-three-vertical"
                size={16}
            />
        </Touchable>
    );
};

export default connectActionSheet(EDropMenu);
