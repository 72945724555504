/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcFreezer = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M167.724 512h-17.655a8.829 8.829 0 0 1-8.828-8.828v-17.655h35.31v17.655a8.827 8.827 0 0 1-8.827 8.828zM361.931 512h-17.655a8.829 8.829 0 0 1-8.828-8.828v-17.655h35.31v17.655a8.827 8.827 0 0 1-8.827 8.828z"
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M361.931 494.345H150.069c-9.751 0-17.655-7.904-17.655-17.655V17.655C132.414 7.904 140.318 0 150.069 0h211.862c9.751 0 17.655 7.904 17.655 17.655V476.69c0 9.751-7.904 17.655-17.655 17.655z"
            style={{fill: "#00d7d7"}}
        />
        <Path
            d="M132.414 158.897h247.172v17.655H132.414z"
            style={{fill: "#00bebe"}}
        />
        <Path
            d="M353.103 141.241h-70.621a8.829 8.829 0 0 1 0-17.656h70.621a8.828 8.828 0 0 1 0 17.656zM282.483 194.207h70.621a8.829 8.829 0 0 1 0 17.656h-70.621a8.828 8.828 0 0 1 0-17.656z"
            style={{fill: "#e4eaf6"}}
        />
        <Path
            d="M132.414 476.69c0 9.75 7.905 17.655 17.655 17.655h211.862c9.75 0 17.655-7.905 17.655-17.655v-17.655H132.414v17.655z"
            style={{fill: "#00bebe"}}
        />
    </Svg>
);

export default IcFreezer;
