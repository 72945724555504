// @flow
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import type {Store} from '../../../../../Types/Reducers/Store';
import ThematicMainScreen from '../../ThematicMainScreen';
import appRoutes from "../../../../../Navigation/AppRoutes";
import ThematicIcon from "../../Icons/ThematicIcon";

const thematicText = `Les achats de produits neufs et nos habitudes sur Internet sont à l’origine de 17% de l'empreinte carbone moyenne d'un Français, et tout ça de manière pratiquement invisible 🧐 !\nGeek ou non, nous analysons tout cela pour calculer ta moyenne personnelle 💻`;

const mapStateToProps = (store: Store, props: Object) => ({
    thematicTitle: 'Consommation et Numérique',
    thematicIcon: <ThematicIcon size={100} thematic='consumptionsAndDigital' />,
    thematicIndex: 4,
    thematicsNumber: 5,
    onPressReturn: () => props.navigation.navigate(appRoutes.cf.energy.questions, {back: true}),
    onPressNext: () => props.navigation.navigate(appRoutes.cf.consumption.questions),
    thematicText,
    ...props,
});

export default connect(
    mapStateToProps,
)(ThematicMainScreen);
