/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const PublicServices = ({
    color = theme.colors.light.text.white,
    size = 36,
}: Props) => (
    <Svg fill="none" height={size} viewBox="0 0 36 36" width={size} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M7.292 31.166a2.812 2.812 0 0 1-2.061-.856 2.812 2.812 0 0 1-.856-2.06V13.665c0-.802.285-1.49.856-2.06a2.812 2.812 0 0 1 2.06-.857h5.834V9.036a2.83 2.83 0 0 1 .219-1.094c.073-.17.158-.335.255-.493.097-.158.219-.31.364-.455l2.516-2.516a1.399 1.399 0 0 1 1.021-.437c.389 0 .73.146 1.02.437l2.517 2.516a2.886 2.886 0 0 1 .838 2.042v7.546h5.833c.802 0 1.49.286 2.061.857.57.571.856 1.258.856 2.06v8.75c0 .802-.285 1.49-.856 2.06a2.812 2.812 0 0 1-2.06.857H7.291Zm0-2.917h2.916v-2.917H7.292v2.917Zm0-5.833h2.916v-2.917H7.292v2.917Zm0-5.834h2.916v-2.916H7.292v2.916Zm8.75 11.667h2.916v-2.917h-2.916v2.917Zm0-5.833h2.916v-2.917h-2.916v2.917Zm0-5.834h2.916v-2.916h-2.916v2.916Zm0-5.833h2.916V7.832h-2.916v2.917Zm8.75 17.5h2.916v-2.917h-2.916v2.917Zm0-5.833h2.916v-2.917h-2.916v2.917Z"
            fill={color}
        />
    </Svg>
);

export default PublicServices;