// @flow
import React from 'react';
import {connect} from 'react-redux';
import IcPerson from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcPerson';
import {getNbPeopleInAccomodation} from '../../../../../Reducers/CarbonFootprintReducers/CFEnergyReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
  setNbPeopleInAccomodation: number => void,
  nbPeopleInAccomodation: number,
};

const QNbPeopleInAccommodation = ({
    setNbPeopleInAccomodation,
    nbPeopleInAccomodation,
}: Props) => {
    // This takes the user in account as nbPeopleInAccommodation = nbPeopleLivingWithUser + user.
    // We can find this difference as well in onSlidingComplete of the slider
    const nbPeopleLivingWithUser = nbPeopleInAccomodation - 1;
    return (
        <>
            <SliderScaleMinMax
                max='10+'
                min='0'
            />
            <ESlider
                defaultValue={nbPeopleLivingWithUser}
                hasSliderSubText
                max={10}
                onSlidingComplete={v => setNbPeopleInAccomodation(v + 1)}
                step={1}
                subText="personne(s)"
                thumbIcon={(<SliderThumbBackground><IcPerson /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    nbPeopleInAccomodation: getNbPeopleInAccomodation(store),
    ...props,
});

const mapDispatchToProps = {setNbPeopleInAccomodation: CFActions.setNbPeopleInAccomodation};

export default connect(mapStateToProps, mapDispatchToProps)(QNbPeopleInAccommodation);
