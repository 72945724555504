// @flow
import React from 'react';
import {ScrollView} from 'react-native';
import type {DetailedCarbonFootprint} from '../../Types/CarbonFootprint/FootprintHistoryTypes';
import moment from 'moment';
import FootprintDetailSheet from "../../Components/Sections/Footprint/FootprintDetailSheet";
import locales from "../../locales/locales";
import {useNavigation} from "@react-navigation/native";
import SecondaryBTop from "../../Components/UI/Ornaments/SecondaryBTop";
import DecoratedLayout from "../../Components/UI/Layouts/DecoratedLayout/DecoratedLayout";
import LightPageHeader from "../../Components/UI/PageHeaders/LightPageHeader/LightPageHeader";
import type {Store} from "../../Types/Reducers/Store";
import {
    getStandaloneDetailedFootprint,
    getUserFootprintById,
    getUserLastFootprint,
} from "../../Reducers/CarbonFootprintReducer";
import {connect} from "react-redux";
import Config from "../../Config/Config";

type Props = {
    footprint: DetailedCarbonFootprint,
}

const FootprintDetails = ({
    footprint,
}: Props) => {
    const navigation = useNavigation();
    const renderContent = () => (
        <ScrollView
            showsVerticalScrollIndicator={Config.showVScrollBar}
        >
            <FootprintDetailSheet
                allowAccessHistory={false}
                footprintId={footprint?.footprintId}
                title={locales.t('cf.my_impact')}
            />
        </ScrollView>
    );

    const date = moment(footprint?.date).format('DD/MM/YYYY');

    return (
        <DecoratedLayout
            topLeftOrnament={(<SecondaryBTop />)}
        >
            <LightPageHeader
                backButton
                onPress={navigation.goBack}
                title={`Bilan du ${date}`}
            />
            {renderContent()}
        </DecoratedLayout>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    footprint: getStandaloneDetailedFootprint(store),
    ...props,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FootprintDetails);
