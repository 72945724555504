/* eslint-disable max-len, quotes */

export default {
    actions: {
        continue: 'Continuer',
        try_again: 'Réessayer',
        pass: 'Passer',
        finalize: 'Finaliser',
        begin: 'Commencer',
        send: 'Envoyer',
        close: 'Fermer',
        cancel: 'Annuler',
        edit: 'Modifier',
        quit: 'Quitter',
        go_back: 'Retour',
        i_propose: 'Je propose',
        i_vote: 'Je vote',
        see_all: 'Voir tout',
        see_less: 'Voir moins',
        read_more: 'Afficher la suite',
        read_less: 'Afficher moins',
        validate: 'Valider',
        update: 'Mettre à jour',
        refresh: 'Rafraichir',
        share: 'Partager',
        share_feedback: 'Partager mon expérience',
        invite: 'Inviter',
        confirm: 'Confirmer',
        delete: 'Supprimer',
        report: 'Signaler',
        follow_us_on: 'Suivez-nous sur',
        discover: 'Découvrir',
        i_go: 'J\'y vais',
        save: 'Enregistrer',
        start: 'Commencer',
        go_there: 'Y aller',
        understood: 'C\'est compris !',
        search: 'Rechercher',
        filter: 'Filtrer',
        go_read: 'Consulter',
        resume: 'Reprendre',
        next: 'Suivant',
        give_up: 'Abandonner',
    },
    pages: {
        home: 'Accueil',
        newsFeed: 'Fil d\'actu',
        messages: 'Messagerie',
        agora: {
            client: 'Fil d\'actu',
            team: 'Messages',
        },
        objectives: {
            all: 'Missions en cours',
            solo: 'Missions individuelles',
            team: 'Missions collectives',
            quiz: 'Missions quiz',
        },
        ideas: 'Boîte à idées',
        ecogestures: 'Éco-gestes',
        kiosk: 'Kiosque',
        quiz: 'Quiz',
        profile: 'Profil',
        change_avatar: 'Modifier mon personnage',
        settings: 'Paramètres',
        rankings: 'Classement',
        mission: {
            one: 'Mission',
            other: 'Missions',
        },
        coach: 'Coach',
        cf: 'Bilan carbone Energic',
        impact: 'Impact',
        chests: 'Récompenses',
        community: 'Communauté',
        ecosystem: 'Écosystème Energic',
        notification_center: 'Notifications',
        amulets: 'Badges',
        player_profile: 'Profil du joueur',
        team_profile: 'Profil de l\'équipe',
        energies_forecast: 'Météo des énergies',
    },
    drawerMenu: {
        my_profile: 'Profil',
        ideas: 'Boîte à idées',
        statistics: 'Statistiques',
        my_tribe: 'Mon équipe',
        my_community: 'Ma communauté',
        invite_players: 'Parrainer des joueurs',
        bonus: 'Aider Energic',
        settings: 'Paramètres',
    },
    common: {
        energic: 'Energic',
        welcome: 'Bienvenue',
        thanks: 'Merci',
        on: 'le',
        category: 'Catégorie',
        all: 'Tous',
        team: 'Équipe',
        client: 'Communauté',
        tier: 'Niveau d\'engagement',
        theme: {
            one: 'Thème',
            other: 'Thèmes',
        },
        ecogesture: 'Éco-geste',
        n_ieme: {
            one: '{{count}}er',
            other: '{{count}}ème',
        },
        me: 'Moi',
        loading: 'Chargement',
        lets_go: 'C\'est parti !',
        likes: {
            do_like: "**J'aime**",
            dont_like: "Je n'aime plus",
            liked_by_x: {
                one: 'Aimé par une autre pers.',
                other: 'Aimé par {{count}} autres pers.',
            },
        },
        share_mail_subject: 'Un contenu qui devrait vous intéresser',
        device: {
            gallery: 'Galerie photo',
            camera: 'Appareil photo',
        },
        date: {
            today: 'Aujourd\'hui',
            yesterday: 'Hier',
        },
        userInfo: {
            email: 'Adresse email',
            firstname: 'Prénom',
            lastname: 'Nom',
            password: 'Mot de passe',
            community_code: 'Code communauté',
        },
    },
    domains: {
        diet: 'Alimentation',
        mobility: 'Mobilité',
        finances: 'Finance',
        energy: 'Énergie',
        consumptionsAndDigital: 'Consommation et Numérique',
        consumptionsanddigital: 'Consommation et Numérique',
        publicServices: 'Services publics',
        publicservices: 'Services publics',
        commitment: 'Engagement',
        natureAndClimate: 'Nature et climat',
        natureandclimate: 'Nature et climat',
        alternatives: 'Alternatives',
        youth: 'Jeunesse',
        any: 'Tous les thèmes',
    },
    tiers: {any: 'Tous'},
    game_period_types: {
        teamsclash: 'Le Choc des Équipes',
        teamsrest: 'Le Repos des Guerriers',
        teamsrevenge: 'La Revanche des Héros',
    },
    errors: {
        defined_error: 'Erreur : {{e}}',
        permissions: {
            media_library: 'Energic n\'a pas la permission d\'accéder à la médiathèque de l\'appareil. Tu peux changer cette autorisation dans les paramètres système. ',
            camera: 'Energic n\'a pas la permission d\'accéder à l\'appareil photo. Tu peux changer cette autorisation dans les paramètres système. ',
        },
        upload_image_error: "Ton image n'a pas pu être téléchargée par nos services. Elle est peut être trop grande ou d'un format non supporté. Si le problème persiste, contacte le support à l'adresse support@energic.io",
        post_user_carbonfootprint_error: 'Tes résultats n\'ont pas pu être transmis. Vérifie ta connexion, réessaye plus tard ou contacte le support à l\'adresse support@energic.io',
        answer_question_error: 'Ta réponse n\'a pas pu être transmise. Vérifie ta connexion et appuie sur « Réessayer ».',
        query_error: 'Ta requête n\'a pas pu être transmise au serveur. Vérifie ta connexion, réessaye plus tard ou contacte le support à l\'adresse support@energic.io',
        global_error: 'Une **erreur est survenue…**\nNotre équipe technique est sur le coup pour rétablir le service.\nRéessaye dans la journée 😉\n\nPour patienter, je te propose de lire un de nos **[articles du Blog Energic](https://energic.io/blog#Portraits)** 🤩',
        forbidden_access: 'Il semblerait que tu ne soit pas autorisé à effectuer cette requête. Il se peut que ton compte ait été supprimé. Si ce n\'est pas le cas, n\'hésites pas à contacter Nakoum (nakoum@energic.io)',
        register_error: 'Erreur lors de la création du compte. Veuillez vérifier votre connexion, réessayez plus tard ou contactez le support à l\'adresse support@energic.io',
        not_existing_content: 'Ce contenu n\'est plus disponible.',
    },
};
