// @flow
import Immutable from 'seamless-immutable';
import type {ActionPM, OneOfAction} from '../Types/Reducers/Action';
import type {AnyStatus} from '../Types/Reducers/AnyStatus';
import type {DeleteStatus} from '../Types/Reducers/DeleteStatus';
import type {ElementMap} from '../Types/Reducers/ElementMap';
import type {FetchStatus} from '../Types/Reducers/FetchStatus';
import type {PaginationStatus} from '../Types/Reducers/PaginationStatus';
import type {PostStatus} from '../Types/Reducers/PostStatus';
import type {Reducers} from '../Types/Reducers/Reducers';
import type {UpdateFunction} from '../Types/Reducers/UpdateFunction';
import type {UpdateStatus} from '../Types/Reducers/UpdateStatus';
import type {ResponseErrors} from '../Types/Types';

const createStatus = (ing: string, ed: string, error: string): Object => ({
    [ing]: false,
    [ed]: false,
    [error]: [],
});

const updatePending = (status: Object, ing: string, error: string): Object => ({
    ...status,
    [ing]: true,
    [error]: [],
});

const updateReceived = (status: Object, ing: string, ed: string, error: string): Object => ({
    ...status,
    [ing]: false,
    [ed]: true,
    [error]: [],
});

const updateRejected = (status: Object, errorData: ResponseErrors, ing: string, error: string): Object => ({
    ...status,
    [ing]: false,
    [error]: errorData,
});

// FETCHING
const FETCHING = 'fetching';
const FETCHED = 'fetched';
const FETCH_ERROR = 'fetchError';
const createFetchStatus = (): FetchStatus => createStatus(FETCHING, FETCHED, FETCH_ERROR);
const updateFetching = (status: FetchStatus): FetchStatus => updatePending(status, FETCHING, FETCH_ERROR);
const updateFetched = (status: FetchStatus): FetchStatus => updateReceived(status, FETCHING, FETCHED, FETCH_ERROR);
const updateFetchError = (status: FetchStatus, error: ResponseErrors): FetchStatus => updateRejected(
    status, error, FETCHING, FETCH_ERROR,
);

// POSTING
const POSTING = 'posting';
const POSTED = 'posted';
const POST_ERROR = 'postError';
const createPostStatus = (): PostStatus => createStatus(POSTING, POSTED, POST_ERROR);
const updatePosting = (status: PostStatus): PostStatus => updatePending(status, POSTING, POST_ERROR);
const updatePosted = (status: PostStatus): PostStatus => updateReceived(status, POSTING, POSTED, POST_ERROR);
const updatePostError = (status: PostStatus, error: ResponseErrors): PostStatus => updateRejected(
    status, error, POSTING, POST_ERROR,
);

// DELETING
const DELETING = 'deleting';
const DELETED = 'deleted';
const DELETE_ERROR = 'deleteError';
const createDeleteStatus = (): DeleteStatus => createStatus(DELETING, DELETED, DELETE_ERROR);
const updateDeleting = (status: DeleteStatus): DeleteStatus => updatePending(status, DELETING, DELETE_ERROR);
const updateDeleted = (status: DeleteStatus): DeleteStatus => updateReceived(status, DELETING, DELETED, DELETE_ERROR);
const updateDeleteError = (status: DeleteStatus, error: ResponseErrors): DeleteStatus => updateRejected(
    status, error, DELETING, DELETE_ERROR,
);

// UPDATING
const UPDATING = 'updating';
const UPDATED = 'updated';
const UPDATE_ERROR = 'updateError';
const createUpdateStatus = (): UpdateStatus => createStatus(UPDATING, UPDATED, UPDATE_ERROR);
const updateUpdating = (status: UpdateStatus): UpdateStatus => updatePending(status, UPDATING, UPDATE_ERROR);
const updateUpdated = (status: UpdateStatus): UpdateStatus => updateReceived(status, UPDATING, UPDATED, UPDATE_ERROR);
const updateUpdateError = (status: UpdateStatus, error: ResponseErrors): UpdateStatus => updateRejected(
    status, error, UPDATING, UPDATE_ERROR,
);

const arrayToMap = (array: Array<any>, getId: (any) => any) => {
    const items = {};
    array.forEach((item) => {
        items[getId(item)] = item;
    });

    return items;
};

const mapToArray = (map: ElementMap<any, any>) => map.allIds.map(id => map.byId[id]);

function mergeReducers(reducers: Array<Reducers>): Reducers{
    const finalReducerMap = {};
    reducers.forEach((reducerMap: Reducers) => {
        Object.entries(reducerMap).forEach(([key, value]) => {
            if (!finalReducerMap[key]) finalReducerMap[key] = value;
            else {
                const last = finalReducerMap[key];
                finalReducerMap[key] = (s, a) => {
                    const newS = last(s, a);
                    // $FlowFixMe
                    return value(newS, a);
                };
            }
        });
    });
    return finalReducerMap;
}

const createReducer = (initialState: Object, ...mapReducers: Array<Reducers>) => {
    const reducers = mergeReducers(mapReducers);
    return (state: Object = initialState, action: OneOfAction<any, any>) => {
        const reducer = reducers[action.type];
        let newState = state;
        if (reducer) newState = reducer(state, action);
        return newState;
    };
};

const createElementMap = (): ElementMap<any, any> => Immutable({
    byId: {},
    allIds: [],
});

const addElementsToMap = (map: ElementMap<any, any>, elements: Array<any>, getId: (any) => any) => {
    const allIds = [];
    const byId = {};
    elements.forEach((e) => {
        const id = getId(e);
        if (map.byId[id] === undefined) allIds.push(id);

        byId[id] = e;
    });

    return map.merge({
        byId,
        allIds: map.allIds.concat(allIds),
    }, {deep: true});
};

const updateMapElement = (map: ElementMap<any, any>, element: any, getId: (any) => any): ElementMap<any, any> => {
    const allIds = [];
    const byId = {...map?.byId};
    const id = getId(element);
    if(map?.byId?.[id] === undefined) allIds.push(id);
    byId[id] = element;
    return map.merge({
        byId,
        allIds: map.allIds.concat(allIds),
    });
};

const setElementsToMap = (elements: Array<any>, getId: (any) => any) => addElementsToMap(
    createElementMap(),
    elements,
    getId,
);

const getElementsFromMap = (map: ElementMap<any, any>): Array<any> => map.allIds.map((id: any) => map.byId[id]);

const addElementToMap = (map: ElementMap<any, any>, element: any, getId: (any) => any) => (
    addElementsToMap(map, [element], getId)
);

const removeElementFromMap = (elementId: number | string, map: ElementMap<any, any>) => ({
    ...map,
    byId: {
        ...map.byId,
        [elementId]: undefined,
    },
    allIds: map.allIds.filter(id => id !== elementId),
});

const getMergeObj = (path: Array<any>, newData: any) => {
    if (path.length === 0) return {};
    if (path.length === 1) return {[path[0]]: newData};
    return {[path[0]]: getMergeObj(path.slice(1), newData)};
};

const getDataFromPath = (obj: Object, path: Array<any>) => {
    let current = obj;
    path.forEach((key) => {
        current = current?.[key];
    });
    return current;
};

const mergeData = (
    path: Array<any>,
    updateFn: (AnyStatus, ?any) => AnyStatus,
    state: Object,
    action: ActionPM<any, any>,
) => {
    const data = getDataFromPath(state, path);
    const updatedData = updateFn(data, action.payload);
    const mergeObj = getMergeObj(path, updatedData);
    return state.merge(mergeObj, {deep: true});
};

const createUpdateFunction = (
    updateFn: (AnyStatus) => AnyStatus,
    path: Array<any>,
    state: Object,
    action: ActionPM<any, any>,
) => mergeData(path, updateFn, state, action);

const handleStatus = (
    updateStart: UpdateFunction,
    updateSuccess: UpdateFunction,
    updateError: UpdateFunction,
    requestType: string,
    getStatus: (Object, ActionPM<any, any>) => Array<any>,
) => ({
    [`${requestType}`]: (state, action) => updateStart(getStatus(state, action), state, action),
    [`${requestType}_SUCCESS`]: (state, action) => updateSuccess(getStatus(state, action), state, action),
    [`${requestType}_ERROR`]: (state, action) => updateError(getStatus(state, action), state, action),
});

// $FlowFixMe
const fetchStart = createUpdateFunction.bind(null, updateFetching);
// $FlowFixMe
const fetchSuccess = createUpdateFunction.bind(null, updateFetched);
// $FlowFixMe
const fetchError = createUpdateFunction.bind(null, updateFetchError);

// $FlowFixMe
const postStart = createUpdateFunction.bind(null, updatePosting);
// $FlowFixMe
const postSuccess = createUpdateFunction.bind(null, updatePosted);
// $FlowFixMe
const postError = createUpdateFunction.bind(null, updatePostError);

// $FlowFixMe
const updateStart = createUpdateFunction.bind(null, updateUpdating);
// $FlowFixMe
const updateSuccess = createUpdateFunction.bind(null, updateUpdated);
// $FlowFixMe
const updateError = createUpdateFunction.bind(null, updateUpdateError);

// $FlowFixMe
const deleteStart = createUpdateFunction.bind(null, updateDeleting);
// $FlowFixMe
const deleteSuccess = createUpdateFunction.bind(null, updateDeleted);
// $FlowFixMe
const deleteError = createUpdateFunction.bind(null, updateDeleteError);

const handleFetchStatus = handleStatus.bind(null, fetchStart, fetchSuccess, fetchError);
const handlePostStatus = handleStatus.bind(null, postStart, postSuccess, postError);
const handleUpdateStatus = handleStatus.bind(null, updateStart, updateSuccess, updateError);
const handleDeleteStatus = handleStatus.bind(null, deleteStart, deleteSuccess, deleteError);

const createPaginationStatus = (limit: number = 10): PaginationStatus => ({
    offset: 0,
    hasMore: true,
    limit,
});

const shouldFetch = (fs: FetchStatus) => !fs.fetched && !fs.fetching;
const fetchIfNeeded = (fs: FetchStatus, fn: (...args: any) => any, ...fnParams: any) =>
    shouldFetch(fs) ? fn(...fnParams) : () => null;
const refresh = (fs: FetchStatus, fn: (...args: any) => any, ...fnParams: any) =>
    !fs.fetching ? fn(...fnParams) : () => null;
const someIsFetching = (fetchStatus: Array<FetchStatus>): boolean =>
    fetchStatus.reduce((i, fs) => i && fs.fetching, true);
const isElementInElementMap = (em: ElementMap<any, any>, elt: any) => em.allIds.includes(elt);

export default {
    // Status handlers
    handleUpdateStatus,
    handlePostStatus,
    handleFetchStatus,
    handleDeleteStatus,
    handleStatus,

    // Map utils
    removeElementFromMap,
    updateMapElement,
    getElementsFromMap,
    addElementToMap,
    addElementsToMap,
    setElementsToMap,
    createElementMap,
    createReducer,
    arrayToMap,
    mapToArray,
    isElementInElementMap,

    // Update status
    createUpdateStatus,
    updateUpdateError,
    updateUpdating,
    updateUpdated,

    // Delete status
    createDeleteStatus,
    updateDeleteError,
    updateDeleting,
    updateDeleted,

    // Fetch status
    createFetchStatus,
    updateFetchError,
    updateFetching,
    updateFetched,

    // Post status
    createPostStatus,
    updatePostError,
    updatePosting,
    updatePosted,


    createPaginationStatus,
    shouldFetch,
    fetchIfNeeded,
    refresh,
    someIsFetching,
};
