// @flow
import React from 'react';
import {connect} from 'react-redux';
import IcLocalConsumption from '../../../../UI/Icons/CarbonFootprint/Diet/IcLocalConsumption';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import {getPartOfLocalConsumption} from '../../../../../Reducers/CarbonFootprintReducers/CFDietReducer';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
  setPartOfLocalConsumption: number => void,
  partOfLocalConsumption: number,
}

const QLocalConsumption = ({
    setPartOfLocalConsumption,
    partOfLocalConsumption,
}: Props) => {
    function handleChange(value: number){
        setPartOfLocalConsumption(value);
    }
    return (
        <>
            <SliderScaleMinMax
                max='100'
                min='0'
            />
            <ESlider
                defaultValue={partOfLocalConsumption}
                hasSliderSubText
                max={100}
                onSlidingComplete={handleChange}
                step={10}
                subText="% de local (<200 km) et de saison"
                thumbIcon={(
                    <SliderThumbBackground>
                        <IcLocalConsumption height={32} width={32} />
                    </SliderThumbBackground>
                )}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    partOfLocalConsumption: getPartOfLocalConsumption(store),
    ...props,
});
const mapDispatchToProps = {setPartOfLocalConsumption: CFActions.setPartOfLocalConsumption};

export default connect(mapStateToProps, mapDispatchToProps)(QLocalConsumption);
