/* eslint-disable max-len */
// @flow
import React, {useContext} from 'react';
import {connect} from 'react-redux';
import MultiAnswerContainer from '../../MultiAnswerContainer';
import IcGreenElectricity from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcGreenElectricity';
import IcElectricity from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcElectricity';
import IcGas from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcGas';
import IcHeatingOil from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcHeatingOil';
import {getEnergyTypes} from '../../../../../Reducers/CarbonFootprintReducers/CFEnergyReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import IcWood from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcWood";
import IcHeatNetwork from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcHeatNetwork";
import IcBiogas from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcBiogas";
import Body from "../../../../UI/Typos/Body/Body";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import type {EnergyType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setEnergyTypes: (Array<EnergyType>) => void,
  energyTypes: Array<EnergyType>,
};

const options = [
    {value: 'greenelectricity', icon: IcGreenElectricity},
    {value: 'electricity', icon: IcElectricity},
    {value: 'gas', icon: IcGas},
    {value: 'biogas', icon: IcBiogas},
    {value: 'heatingoil', icon: IcHeatingOil},
    {value: 'wood', icon: IcWood},
    {value: 'heatnetwork', icon: IcHeatNetwork},
];

const QEnergyTypes = ({
    setEnergyTypes,
    energyTypes,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <>
            <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                {"Plusieurs réponses possibles"}
            </Body>
            <MultiAnswerContainer
                handleChange={setEnergyTypes}
                numColumns={3}
                options={options}
                selectedValues={energyTypes}
            />
        </>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    energyTypes: getEnergyTypes(store),
    ...props,
});

const mapDispatchToProps = {setEnergyTypes: CFActions.setEnergyTypes};

export default connect(mapStateToProps, mapDispatchToProps)(QEnergyTypes);
