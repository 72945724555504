// @flow
import React, {Node} from 'react';
import {connect} from 'react-redux';
import locales from "../../../../../locales/locales";
import Heading from "../../../../UI/Typos/Heading/Heading";
import IcTeeShirt from '../../../../UI/Icons/CarbonFootprint/Consumption/IcTeeShirt';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import {getMonthlyNewProductsSpendings} from '../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";
import IcChair from "../../../../UI/Icons/CarbonFootprint/Consumption/IcChair";
import IcFrame from "../../../../UI/Icons/CarbonFootprint/Consumption/IcFrame";
import IcRockingHorse from "../../../../UI/Icons/CarbonFootprint/Consumption/IcRockingHorse";
import {View} from "react-native";
import type {NewProductQuantity, NewProductType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setMonthlyNewProductsSpendings: NewProductQuantity[] => void,
  monthlyNewProductsSpendings: NewProductQuantity[],
}

const newProductsOptions: {product: NewProductType, icon: Node}[] = [
    {
        product: 'clothing',
        Icon: IcTeeShirt,
    },
    {
        product: 'furniture',
        Icon: IcChair,
    },
    {
        product: 'decorations',
        Icon: IcFrame,
    },
    {
        product: 'hobbies',
        Icon: IcRockingHorse,
    },
];

const QMonthlyNewProductSpendings = ({
    setMonthlyNewProductsSpendings,
    monthlyNewProductsSpendings,
}: Props) => {
    function handleChange(type: string, value: number){
        const filteredCurrent = monthlyNewProductsSpendings?.filter(el => el?.answerType !== type);
        const updated = [...filteredCurrent, {answerType: type, value}];
        setMonthlyNewProductsSpendings(updated);
    }

    return newProductsOptions?.map(({product, Icon}) => (
        <View key={product}>
            <Heading customStyle={{marginTop: 10}} variant='h3'>
                {locales.t(`cf.${product}`)}
            </Heading>
            <SliderScaleMinMax
                max='500'
                min='0'
            />
            <ESlider
                defaultValue={monthlyNewProductsSpendings?.find(npo => npo?.answerType === product)?.value ?? 0}
                hasSliderSubText
                max={500}
                onSlidingComplete={val => handleChange(product, val)}
                step={10}
                subText="€/mois"
                thumbIcon={(<SliderThumbBackground><Icon /></SliderThumbBackground>)}
            />
        </View>
    ));
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    monthlyNewProductsSpendings: getMonthlyNewProductsSpendings(store),
    ...props,
});

const mapDispatchToProps = {setMonthlyNewProductsSpendings: CFActions.setMonthlyNewProductsSpendings};

export default connect(mapStateToProps, mapDispatchToProps)(QMonthlyNewProductSpendings);