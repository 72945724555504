/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcWineCellar = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 385.033 385.033"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M215.334 9.127c0-5.021-4.107-9.127-9.128-9.127h-27.379c-5.021 0-9.128 4.106-9.128 9.127v13.69c0 5.02 4.107 9.127 9.128 9.127h27.379c5.02 0 9.128-4.107 9.128-9.127V9.127z"
            style={{fill: "#b3874f"}}
        />
        <Path
            d="M169.699 11.3h45.635v10.099h-45.635z"
            style={{fill: "#997443"}}
        />
        <Path
            d="M241.591 385.033c8.301 0 15.031-6.729 15.031-15.031V210.071c0-98.179-38.762-75.667-38.762-120.215l.001-27.164h-50.689v27.164c0 44.548-38.761 22.036-38.761 120.215v159.931c0 8.303 6.73 15.031 15.031 15.031h98.149z"
            style={{fill: "#00783e"}}
        />
        <Path
            d="M167.172 17.776h50.689v39.483h-50.689z"
            style={{fill: "#d9a460"}}
        />
        <Path
            d="M167.172 57.26h50.689v21.726h-50.689z"
            style={{fill: "#fedd3d"}}
        />
        <Path
            d="M238.154 175.686h-91.275c-1.362 9.075-2.17 20.274-2.17 34.385v158.664h95.615V210.071c0-14.111-.807-25.309-2.17-34.385z"
            style={{fill: "#712d3d"}}
        />
        <Path
            d="M161.747 200.499h61.537v143.424h-61.537z"
            style={{fill: "#fff"}}
        />
    </Svg>
);

export default IcWineCellar;
