export const eventCategories = {
    NAVIGATION: 'NAVIGATION',
    INTERACTION: 'INTERACTION',
    SYSTEM: 'SYSTEM',
};


export const eventActions = {
    VISIT: 'Visit',
    CLICK: 'Click',
    OPEN: 'Open',
    COMPLETE: 'Complete',
    CANCEL: 'Cancel',
    TRACK: 'Track',
    ERROR: 'Error',
    SHARE: 'Share',
    ACCEPT: 'Accept',
    DENY: 'Deny',
};

export const eventNames = {
    SKIP_FTUE: 'Skip_FTUE',
    ACCEPT_NOTIFICATIONS_ONBOARDING: 'Accept_Notifications_ONBOARDING',
    ACCEPT_ECO_NEWS_ONBOARDING: 'Accept_Eco-News_ONBOARDING',
    INVITE_USER: 'Invite_User',
    KIOSK_CONTENT: 'Kiosk_Content',
    NAKOUM_ADVICE_HOME: 'Nakoum_Advice_HOME',
};