// @flow
import React, {Node, useContext} from 'react';
import {View} from 'react-native';
import {ThemeContext} from '../../../../libs/Hoc/ThemeProvider';
import Button from '../../Buttons/Button/Button';
import BasicCard from '../../Cards/BasicCard/BasicCard';
import ActionIcon from '../../Icons/IconContainers/ActionIcon/ActionIcon';
import Close from '../../Icons/UI/Close';
import Heading from '../../Typos/Heading/Heading';
import ClickableBackdrop from "../ClickableBackdrop/ClickableBackdrop";

type Props = {
    onClose: () => void,
    content: Node,

    title?: string,

    mainButtonText?: string,
    mainButtonDisabled?: boolean,
    mainButtonLoading?: boolean,
    mainButtonOnPress?: any => any,

    secondButtonText?: string,
    secondButtonDisabled?: boolean,
    secondButtonOnPress?: any => any,

    thirdButtonText?: string,
    thirdButtonDisabled?: boolean,
    thirdButtonOnPress?: any => any,
};

const ActionModal = ({
    onClose,
    content,

    title = null,
    mainButtonOnPress = null,
    mainButtonDisabled = false,
    mainButtonLoading = false,
    mainButtonText,
    secondButtonOnPress = null,
    secondButtonDisabled = false,
    secondButtonText,

    thirdButtonOnPress = null,
    thirdButtonDisabled = false,
    thirdButtonText,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    return (
        <ClickableBackdrop
            containerStyle={{
                justifyContent: 'flex-end',
                flex: 1,
            }}
            onClose={onClose}
            visible
        >
            <BasicCard
                customStyle={{
                    backgroundColor: colors.background.gradient_start,
                    width: '100%',
                    alignItems: 'stretch',
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginBottom: spacing[1],
                    }}
                >
                    {
                        title ? (
                            <Heading
                                customStyle={{
                                    flex: 1,
                                    marginRight: spacing[1],
                                }}
                                variant='h3'
                            >
                                {title}
                            </Heading>
                        ) : null
                    }
                    <ActionIcon onPress={onClose}>
                        <Close />
                    </ActionIcon>
                </View>
                {content}
                <>
                    {
                        secondButtonOnPress
                            ? (
                                <Button
                                    customStyle={{marginBottom: spacing[1]}}
                                    disabled={secondButtonDisabled}
                                    onPress={secondButtonOnPress}
                                    text={secondButtonText}
                                    variant='outlined'
                                />
                            )
                            : null
                    }
                    {
                        mainButtonOnPress
                            ? (
                                <Button
                                    customStyle={{marginBottom: spacing[1]}}
                                    disabled={mainButtonDisabled}
                                    loading={mainButtonLoading}
                                    onPress={mainButtonOnPress}
                                    text={mainButtonText}
                                    variant='contained'
                                />
                            ) : null
                    }
                    {
                        // second, then main, then third makes no sense
                        // but that's what you get
                        thirdButtonOnPress
                            ? (
                                <Button
                                    customStyle={{marginBottom: spacing[1]}}
                                    disabled={thirdButtonDisabled}
                                    onPress={thirdButtonOnPress}
                                    text={thirdButtonText}
                                    variant='outlined'
                                />
                            ) : null
                    }
                </>
            </BasicCard>
        </ClickableBackdrop>
    );
};

export default ActionModal;