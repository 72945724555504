// @flow
import React, {useCallback, useContext, useMemo} from 'react';
import {FlatList, View} from 'react-native';
import locales from "../../locales/locales";
import type {DetailedCarbonFootprint} from "../../Types/CarbonFootprint/FootprintHistoryTypes";
import CFUtils from "../../Reducers/CarbonFootprintReducers/CFUtils";
import TotalFootprintPieCenter from "../../Components/Sections/Footprint/FootprintPie/TotalFootprintPieCenter";
import FootprintPie from "../../Components/Sections/Footprint/FootprintPie/FootprintPie";
import {useNavigation} from "@react-navigation/native";
import SecondaryBTop from "../../Components/UI/Ornaments/SecondaryBTop";
import LightPageHeader from "../../Components/UI/PageHeaders/LightPageHeader/LightPageHeader";
import DecoratedLayout from "../../Components/UI/Layouts/DecoratedLayout/DecoratedLayout";
import {ThemeContext} from "../../libs/Hoc/ThemeProvider";
import BasicCard from "../../Components/UI/Cards/BasicCard/BasicCard";
import Heading from "../../Components/UI/Typos/Heading/Heading";
import Body from "../../Components/UI/Typos/Body/Body";
import ThematicDetailIcon from "../../Components/Sections/Footprint/Icons/ThematicDetailIcon";
import type {Store} from "../../Types/Reducers/Store";
import {
    getCategoryDetailedParts, getStandaloneDetailedFootprint,
    getUserFootprintById,
    getUserLastFootprint,
} from "../../Reducers/CarbonFootprintReducer";
import {connect} from "react-redux";
import Config from "../../Config/Config";
import type {FootprintDetailPartType, FootprintSectionString} from "../../Types/CarbonFootprint/FootprintTypes";

type Props = {
    footprint: DetailedCarbonFootprint,
    category: FootprintSectionString,
    detailedParts: Array<{title: string, value: string}>,
}

const FootprintCategoryDetails = ({
    footprint,
    category,
    detailedParts,
}: Props) => {
    const navigation = useNavigation();
    const [colors,, spacing] = useContext(ThemeContext);

    const sortedFootprintDetails = useMemo(
        () => CFUtils.sortFootprintSections(footprint, category),
        [footprint?.footprintId],
    );
    const totalCarbonMass = useMemo(
        () => footprint?.[category]?.carbonMass,
        [footprint?.[category]?.carbonMass],
    );
    const categoryTitle = locales.t(`global.domains.${category}`);

    const renderDetailedPart = (dpItem: FootprintDetailPartType) => (
        <View
            key={dpItem.key}
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <ThematicDetailIcon
                detailName={dpItem.key}
                thematic={category}
            />
            <Heading
                customStyle={{
                    flex: 1,
                    marginHorizontal: spacing[1],
                }}
                variant='h4'
            >
                {dpItem?.title}
            </Heading>
            <Body variant='paragraph'>
                {`${dpItem.value.toLocaleString('fr-FR')} kg`}
            </Body>
        </View>
    );

    const renderListHeader = useCallback(() => (
        <>
            <BasicCard customStyle={{marginBottom: spacing[2]}}>
                <Heading customStyle={{marginBottom: spacing[1] * 1.5}} variant='h2'>
                    {locales.t('cf.my_impact_category', {category: categoryTitle})}
                </Heading>
                <BasicCard customStyle={{backgroundColor: colors.background.card}}>
                    <FootprintPie
                        centerComponent={(
                            <View
                                style={{alignItems: 'center'}}
                            >
                                <TotalFootprintPieCenter
                                    fontSize={40}
                                    totalCarbon={totalCarbonMass}
                                />
                            </View>
                        )}
                        footprint={sortedFootprintDetails}
                        sectionFocus={category}
                        size={200}
                    />
                </BasicCard>
            </BasicCard>
            <Heading customStyle={{marginBottom: spacing[1]}} variant='h2'>
                {"En détail"}
            </Heading>
        </>
    ), [sortedFootprintDetails, totalCarbonMass, footprint?.footprintId]);

    return (
        <DecoratedLayout
            topLeftOrnament={(<SecondaryBTop />)}
        >
            <LightPageHeader
                backButton
                onPress={navigation.goBack}
                title='Détail de la catégorie'
            />
            <FlatList
                ItemSeparatorComponent={() => <View style={{height: spacing[1]}} />}
                ListHeaderComponent={renderListHeader()}
                data={detailedParts}
                keyExtractor={item => item.key}
                renderItem={({item}) => renderDetailedPart(item)}
                showsVerticalScrollIndicator={Config.showVScrollBar}
            />
        </DecoratedLayout>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    footprint: getStandaloneDetailedFootprint(store),
    category: props.route.params.category,
    detailedParts: getCategoryDetailedParts(store, props.route.params.category.toLowerCase()),
    ...props,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FootprintCategoryDetails);
