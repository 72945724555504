// @flow
import React from 'react';
import {connect} from 'react-redux';
import IcPlasticBottle from '../../../../UI/Icons/CarbonFootprint/Consumption/IcPlasticBottle';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import {getDailyNbPlasticBottles} from "../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
  setDailyNbPlasticBottles: number => void,
  dailyNbPlasticBottles: number,
}

const QWeeklyPlasticBottles = ({
    setDailyNbPlasticBottles,
    dailyNbPlasticBottles,
}: Props) => {
    function handleChange(value: number){
        setDailyNbPlasticBottles(value);
    }
    return (
        <>
            <SliderScaleMinMax
                max='20'
                min='0'
            />
            <ESlider
                defaultValue={dailyNbPlasticBottles}
                hasSliderSubText
                max={20}
                onSlidingComplete={handleChange}
                step={1}
                subText="bouteille(s) en plastique"
                thumbIcon={(<SliderThumbBackground><IcPlasticBottle height={60} width={60} /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    dailyNbPlasticBottles: getDailyNbPlasticBottles(store),
    ...props,
});

const mapDispatchToProps = {setDailyNbPlasticBottles: CFActions.setDailyNbPlasticBottles};

export default connect(mapStateToProps, mapDispatchToProps)(QWeeklyPlasticBottles);
