// @flow
import React, {useContext} from 'react';
import {View} from 'react-native';
import locales from '../../locales/locales';
import appRoutes from "../../Navigation/AppRoutes";
import FootprintTopLeft from "../../Components/UI/Ornaments/FootprintTopLeft";
import FootprintTopRight from "../../Components/UI/Ornaments/FootprintTopRight";
import OnboardingBottom from "../../Components/UI/Ornaments/OnboardingBottom";
import LightPageHeader from "../../Components/UI/PageHeaders/LightPageHeader/LightPageHeader";
import DecoratedLayout from "../../Components/UI/Layouts/DecoratedLayout/DecoratedLayout";
import ActionIcon from "../../Components/UI/Icons/IconContainers/ActionIcon/ActionIcon";
import Close from "../../Components/UI/Icons/UI/Close";
import BasicCard from "../../Components/UI/Cards/BasicCard/BasicCard";
import {ThemeContext} from "../../libs/Hoc/ThemeProvider";
import Heading from "../../Components/UI/Typos/Heading/Heading";
import Body from "../../Components/UI/Typos/Body/Body";
import {NavigationProp} from "@react-navigation/native";
import Button from "../../Components/UI/Buttons/Button/Button";
import type {Store} from "../../Types/Reducers/Store";
import {
    getTCO2Footprint,
} from "../../Reducers/CarbonFootprintReducer";
import {connect} from "react-redux";
import ShareFootprintIcon from "../../Components/Sections/Footprint/ShareFootprintIcon";
import {openLink} from "../../libs/Utils/LinkUtils";

type Props = {
    onPressNext: () => void,
    tCO2Footprint: number,
    navigation: NavigationProp,
};

const CFEndScreen = ({
    onPressNext,
    tCO2Footprint,
    navigation,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    const roundedTCO2Footprint = tCO2Footprint.toFixed(2);

    return (
        <DecoratedLayout
            bottomRightOrnament={(<OnboardingBottom />)}
            topLeftOrnament={(<FootprintTopLeft />)}
            topRightOrnament={(<FootprintTopRight />)}
        >
            <LightPageHeader
                endComponent={(
                    <>
                        <ShareFootprintIcon />
                        <ActionIcon onPress={() => navigation.navigate(appRoutes.cf.start)}>
                            <Close />
                        </ActionIcon>
                    </>
                )}
                title={locales.t('global.pages.cf')}
            />
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <BasicCard
                    customStyle={{backgroundColor: colors.background.card}}
                >
                    <Body centered variant='onboarding_text'>
                        {locales.t('cf.end.text')}
                    </Body>
                    <View
                        style={{marginVertical: spacing[1]}}
                    >
                        <Heading
                            centered
                            variant='onboarding_title'
                        >
                            {roundedTCO2Footprint}
                        </Heading>
                        <Heading centered variant='h3'>
                            {locales.t('cf.end.legend')}
                        </Heading>
                    </View>
                    <Body centered variant='onboarding_text' customStyle={{marginTop: spacing[2]}}>
                        {locales.t('cf.end.details')}
                    </Body>
                </BasicCard>
            </View>

            <View
                style={{marginVertical: spacing[2]}}
            >
                <Button
                    onPress={onPressNext}
                    text={locales.t('cf.end.see_detailed_result')}
                    customStyle={{marginBottom: spacing[1]}}
                />
                <Button
                    variant='outlined'
                    onPress={() => openLink('https://energic.io')}
                    text={locales.t('cf.end.discover_energic')}
                />
            </View>
        </DecoratedLayout>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    ...props,
    onPressNext: () => props.navigation.navigate(appRoutes.cf.detail),
    tCO2Footprint: getTCO2Footprint(store),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CFEndScreen);
