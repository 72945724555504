// @flow
import Const from "../../Config/Const";
import {getCarbonMassFloatFromString} from "../../libs/Utils/StringUtils";
import {DetailedCarbonFootprint} from "../../Types/CarbonFootprint/FootprintHistoryTypes";
import type {DietType} from "../../Types/CarbonFootprint/FootprintQuestionsTypes";
import type {FootprintSectionString} from "../../Types/CarbonFootprint/FootprintTypes";

const gCO2toTCO2Ratio = 1000000;
const gCO2toKgCO2Ratio = 1000;

/**
 * Emission factor per type of mobility medium
 * Speed in km/h
 * Emission in gCO2eq/km
 */
const transportEmissionFactors = {
    walkorbike: {speed: 15, emission: 0},
    motorbike: {speed: 87, emission: 192},
    bus: {speed: 20, emission: 151},
    subway: {speed: 23, emission: 3.3},
    train: {speed: 80, emission: 29.6},
    tgv: {speed: 190, emission: 2.36},
    fuelcar: {speed: 47, emission: 221},
    hybridcar: {speed: 47, emission: 147},
    electriccar: {speed: 47, emission: 103},
    plane: {speed: 540, emission: 199},
};

const dietImpact = {
    vegan: 1100000,
    vegetarian: 1450000,
    flexitarian: 1900000,
    weeklymeateater: 2300000,
    dailymeateater: 2600000,
    everymealmeateater: 2800000,
};

const energyConsumptions = {
    greenelectricity: {conversionFactor: 1/0.19, emissionFactor: 7.33},
    electricity: {conversionFactor: 1/0.17, emissionFactor: 80},
    gas: {conversionFactor: 1/0.9, emissionFactor: 234},
    biogas: {conversionFactor: 1/0.17, emissionFactor: 23.4},
    heatingoil: {conversionFactor: 1/0.15, emissionFactor: 530},
    heatnetwork: {conversionFactor: 1/0.07, emissionFactor: 87},
    wood: {conversionFactor: 1/0.06, emissionFactor: 13},
};

const consumptionImpact = {
    various: 128,
    technology: 3328,
    mails: 3,
    streaming: 5,
};

const financeImpact = {
    greenAssets: 200,
    assets: 903,
};

const emailQuantityTypeToNumber = {
    never: 0,
    verylittle: 5,
    little: 15,
    alot: 35,
    enormously: 75,
    madly: 200,
};

const streamingQuantityTypeToNumber = {
    never: 0,
    little: 1.5,
    alot: 4.5,
    enormously: 10.5,
    madly: 31.5,
};

const redMeatPartArray = [
    {
        white: 100,
        red: 0,
        label: 'Uniquement de la viande blanche et du poisson',
    },
    {
        white: 80,
        red: 20,
        label: 'Majoritairement de la viande blanche ou du poisson',
    },
    {
        white: 65,
        red: 35,
        label: 'Plutôt de la viande blanche ou du poisson',
    },
    {
        white: 50,
        red: 50,
        label: 'Moitié-moitié',
    },
    {
        white: 35,
        red: 65,
        label: 'Plutôt de la viande rouge',
    },
    {
        white: 20,
        red: 80,
        label: 'Majoritairement de la viande rouge',
    },
    {
        white: 0,
        red: 100,
        label: 'Uniquement de la viande rouge',
    },
];

const carTransportTypes = ['fuelcar', 'hybridcar', 'electriccar'];

const meatDietTypes: Array<DietType> = [
    'flexitarian',
    'dailymeateater',
    'weeklymeateater',
    'everymealmeateater',
];

const irreduciblePublicServicesImpact = 1489000;

/**
 * Impact imputed to people living in a house in gCO2eq.
 * Represents the average impact of a house construction (25tCO2eq), divided by an average lifetime (25 years)
 * @type {number}
 */
const houseConstructionImpactPerYear = 961000;

const NB_DAYS = 365.25;
const NB_WEEKS = 52.1425;
const NB_MONTHS = 12;

const sortFootprintSections = (
    footprint: DetailedCarbonFootprint,
    sectionFocus: ?FootprintSectionString
) => Object.entries(footprint)
    // keep only the 6 sections. Object.entries give a second argument as mixed, therefore the fix me below
    .filter(([, v]) => Object.hasOwn(v, "carbonMass"))
    .filter(([k]) => k !== 'finances')
    // add key and return an Object
    .map(([key, value]) => (
        {
            ...value,
            key,
            fill: (
                // if focus on a section, then only this one has a fill color
                sectionFocus
                && sectionFocus === key
                && Const.DOMAIN_COLORS?.[key]
            ) || (
                // no focus on any section, give each one a color
                !sectionFocus
                && Const.DOMAIN_COLORS?.[key]
            )
                ? Const.DOMAIN_COLORS?.[key]
                : '#888',
        })
    )
    // sort by carbon mass increasingly
    .sort((a, b) => getCarbonMassFloatFromString(b.carbonMass) - getCarbonMassFloatFromString(a.carbonMass));

/**
 * Impact of each technology device in kgC02eq.
 */
const technologyDeviceImpact = {
    tv: 84.4,
    laptop: 33.8,
    computer: 58.3,
    screen: 50.1,
    tablet: 14,
    smartphone: 7.6,
    console: 20.4,
    home_cinema: 30.6,
    speaker: 1.6,
    printer: 21.2,
    camera: 5.4,
};

/**
 * Impact of each appliance device in kgC02eq.
 */
const applianceDeviceImpact = {
    oven: 17,
    stove: 45,
    microwave: 15,
    dishwasher: 47,
    washing_machine: 46,
    clothes_dryer: 42,
    fridge: 35,
    freezer: 36,
    wine_cellar: 13,
    vacuum_cleaner: 6.8,
    mixer: 5,
};

export default {
    transportEmissionFactors,
    dietImpact,
    energyConsumptions,
    consumptionImpact,
    financeImpact,
    gCO2toTCO2Ratio,
    gCO2toKgCO2Ratio,
    NB_DAYS,
    NB_WEEKS,
    NB_MONTHS,
    emailQuantityTypeToNumber,
    streamingQuantityTypeToNumber,
    irreduciblePublicServicesImpact,
    houseConstructionImpactPerYear,
    redMeatPartArray,
    carTransportTypes,
    meatDietTypes,
    sortFootprintSections,
    technologyDeviceImpact,
    applianceDeviceImpact,
};
