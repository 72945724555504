/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcVacuumCleaner = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M132.414 4.414h-14.919C52.707 4.414 0 57.121 0 121.956c0 31.383 12.22 60.888 34.413 83.078l24.966-24.966C43.858 164.547 35.31 143.91 35.31 121.909c0-45.319 36.866-82.185 82.185-82.185h14.919c141.16 0 256 114.84 256 256v26.483h35.31v-26.483c0-160.629-130.681-291.31-291.31-291.31z"
            style={{fill: "#464655"}}
        />
        <Path
            d="m23.402 198.932 29.735 51.895 53.898-53.898-51.895-29.735a4.414 4.414 0 0 0-5.315.708L24.11 193.617a4.415 4.415 0 0 0-.708 5.315z"
            style={{fill: "#707487"}}
        />
        <Path
            d="M176.552 498.759H44.138c-14.626 0-26.483-11.857-26.483-26.483V322.207c0-82.881 67.188-150.069 150.069-150.069h44.138c14.626 0 26.483 11.857 26.483 26.483v238.345c0 34.127-27.666 61.793-61.793 61.793z"
            style={{fill: "#ffd764"}}
        />
        <Path
            d="M52.966 472.276v-79.448h-35.31v79.448c0 14.626 11.857 26.483 26.483 26.483h35.31c-14.627 0-26.483-11.857-26.483-26.483z"
            style={{fill: "#ffb95a"}}
        />
        <Path
            d="M141.241 481.103H54.588c3.648 10.262 13.347 17.655 24.861 17.655h61.793v-17.655z"
            style={{fill: "#959cb3"}}
        />
        <Path
            d="M423.724 454.621h-35.31a8.829 8.829 0 0 0-8.828 8.828v17.655h52.966v-17.655a8.828 8.828 0 0 0-8.828-8.828z"
            style={{fill: "#464655"}}
        />
        <Path
            d="M211.862 172.138h-8.828v229.517h35.31V198.621c.001-14.627-11.856-26.483-26.482-26.483z"
            style={{fill: "#ffe678"}}
        />
        <Circle
            cx={167.724}
            cy={428.138}
            r={79.448}
            style={{fill: "#707487"}}
        />
        <Circle
            cx={167.724}
            cy={428.138}
            r={52.966}
            style={{fill: "#ffb95a"}}
        />
        <Path
            d="M388.414 313.379h35.31V454.62h-35.31z"
            style={{fill: "#d7deed"}}
        />
        <Path
            d="M63.591 267.444a106.44 106.44 0 0 1 49.369-49.369c9.438-4.568 19.622-7.827 30.336-9.451 8.852-1.342 15.599-8.562 15.599-17.516v-18.705c-78.77 4.572-141.24 69.888-141.24 149.804v88.276H35.31c9.75 0 17.655-7.905 17.655-17.655V313.38c.001-16.473 3.889-32.017 10.626-45.936z"
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M379.586 472.276h105.931c14.626 0 26.483 11.857 26.483 26.483a8.829 8.829 0 0 1-8.828 8.828H379.586a8.829 8.829 0 0 1-8.828-8.828v-17.655a8.829 8.829 0 0 1 8.828-8.828zM54.588 481.103h-35.31c3.648 10.262 13.347 17.655 24.861 17.655h35.31c-11.515.001-21.213-7.392-24.861-17.655z"
            style={{fill: "#707487"}}
        />
    </Svg>
);

export default IcVacuumCleaner;
