/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcStove = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M450.207 26.483H61.793V8.828A8.829 8.829 0 0 1 70.621 0H441.38a8.829 8.829 0 0 1 8.828 8.828v17.655z"
            style={{fill: "#707487"}}
        />
        <Path
            d="M88.276 512H70.621a8.829 8.829 0 0 1-8.828-8.828v-35.31h35.31v35.31A8.828 8.828 0 0 1 88.276 512zM441.379 512h-17.655a8.829 8.829 0 0 1-8.828-8.828v-35.31h35.31v35.31a8.827 8.827 0 0 1-8.827 8.828z"
            style={{fill: "#959cb3"}}
        />
        <Path
            d="M450.207 476.69H61.793c-9.751 0-17.655-7.904-17.655-17.655V35.31c0-9.751 7.904-17.655 17.655-17.655h388.414c9.751 0 17.655 7.904 17.655 17.655v423.724c0 9.752-7.904 17.656-17.655 17.656z"
            style={{fill: "#ffebbe"}}
        />
        <Path
            d="M432.552 423.724H79.448c-9.751 0-17.655-7.904-17.655-17.655v-256c0-9.751 7.904-17.655 17.655-17.655h353.103c9.751 0 17.655 7.904 17.655 17.655v256c.001 9.751-7.903 17.655-17.654 17.655z"
            style={{fill: "#f0dca0"}}
        />
        <Circle
            cx={211.862}
            cy={79.448}
            r={26.483}
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M211.862 88.276a8.829 8.829 0 0 1-8.828-8.828V52.966a8.829 8.829 0 0 1 17.656 0v26.483a8.829 8.829 0 0 1-8.828 8.827z"
            style={{fill: "#707487"}}
        />
        <Circle
            cx={300.138}
            cy={79.448}
            r={26.483}
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M300.138 88.276a8.829 8.829 0 0 1-8.828-8.828V52.966a8.829 8.829 0 0 1 17.656 0v26.483a8.829 8.829 0 0 1-8.828 8.827z"
            style={{fill: "#707487"}}
        />
        <Circle
            cx={388.414}
            cy={79.448}
            r={26.483}
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M388.414 88.276a8.829 8.829 0 0 1-8.828-8.828V52.966a8.829 8.829 0 0 1 17.656 0v26.483a8.83 8.83 0 0 1-8.828 8.827z"
            style={{fill: "#707487"}}
        />
        <Circle
            cx={123.586}
            cy={79.448}
            r={26.483}
            style={{fill: "#afb9d2"}}
        />
        <Path
            d="M123.586 88.276a8.829 8.829 0 0 1-8.828-8.828V52.966a8.829 8.829 0 0 1 17.656 0v26.483a8.829 8.829 0 0 1-8.828 8.827zM414.897 397.241H97.103a8.829 8.829 0 0 1-8.828-8.828V203.034a8.829 8.829 0 0 1 8.828-8.828h317.793a8.829 8.829 0 0 1 8.828 8.828v185.379a8.827 8.827 0 0 1-8.827 8.828z"
            style={{fill: "#707487"}}
        />
        <Path
            d="M414.897 176.552H97.103a8.829 8.829 0 0 1 0-17.656h317.793a8.828 8.828 0 0 1 .001 17.656z"
            style={{fill: "#fff"}}
        />
        <Path
            d="M379.586 361.931H132.414a8.829 8.829 0 0 1-8.828-8.828V238.345a8.829 8.829 0 0 1 8.828-8.828h247.172a8.829 8.829 0 0 1 8.828 8.828v114.759a8.829 8.829 0 0 1-8.828 8.827z"
            style={{fill: "#ffd764"}}
        />
        <Path
            d="M244.043 329.759a8.796 8.796 0 0 1-6.241-2.586l-49.836-49.87c-3.448-3.448-3.448-9.038 0-12.483a8.825 8.825 0 0 1 12.483 0l49.836 49.87c3.448 3.448 3.448 9.038 0 12.483a8.8 8.8 0 0 1-6.242 2.586zM313.036 347.972a8.796 8.796 0 0 1-6.241-2.586l-29.063-29.076c-3.448-3.448-3.448-9.038 0-12.483a8.825 8.825 0 0 1 12.483 0l29.063 29.076c3.448 3.448 3.448 9.038 0 12.483a8.804 8.804 0 0 1-6.242 2.586z"
            style={{fill: "#ffe678"}}
        />
        <Path
            d="M257.495 292.409a8.79 8.79 0 0 1-6.241-2.586l-10.862-10.871c-3.448-3.448-3.448-9.038 0-12.483 3.448-3.448 9.043-3.448 12.483 0l10.862 10.871c3.448 3.448 3.448 9.038 0 12.483a8.796 8.796 0 0 1-6.242 2.586z"
            style={{fill: "#fff08c"}}
        />
        <Path
            d="M379.586 229.517H132.414a8.829 8.829 0 0 0-8.828 8.828V256a8.829 8.829 0 0 1 8.828-8.828h247.172a8.829 8.829 0 0 1 8.828 8.828v-17.655a8.829 8.829 0 0 0-8.828-8.828z"
            style={{fill: "#ffbe5a"}}
        />
    </Svg>
);

export default IcStove;
