/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Circle, Defs, G, Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcCapsuleCoffee({size = 45}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .519h41.34v22.237H0z" id="daily_meat_prefix__a" />
                <Path d="M0 27.174h41.34V0H0z" id="daily_meat_prefix__c" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M252.083 70.833V200"
                    style={{
                        fill: "none",
                        fillRule: "nonzero",
                        stroke: "#c7cac7",
                        strokeWidth: "8.33px",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M260.417 266.667H31.25v-10.9c0-7.788 6.312-14.1 14.1-14.1h200.967c7.787 0 14.1 6.312 14.1 14.1v10.9Z"
                    style={{
                        fill: "#556080",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M223.246 120.833H68.421a36.988 36.988 0 0 1-20.504-6.179v127.013h191.666V117.012c-4.937 2.425-10.466 3.821-16.337 3.821Z"
                    style={{
                        fill: "#38454f",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M223.246 120.833H68.421c-20.529 0-37.171-16.641-37.171-37.17V42.696C31.25 32.921 39.171 25 48.946 25h193.775c9.775 0 17.696 7.921 17.696 17.696v40.967c0 20.529-16.642 37.17-37.171 37.17Z"
                    style={{
                        fill: "#556080",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M97.917 41.667h91.667v62.5H97.917z"
                    style={{fill: "#a4ca83"}}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M158.246 241.667h-28.992c-10.404 0-18.837-8.434-18.837-18.838V187.5h66.666v35.329c0 10.404-8.433 18.838-18.837 18.838Z"
                    style={{
                        fill: "#e7eced",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M114.583 225H97.917c-9.204 0-16.667-7.462-16.667-16.667 0-9.204 7.463-16.666 16.667-16.666h16.666V225Z"
                    style={{
                        fill: "none",
                        fillRule: "nonzero",
                        stroke: "#e7eced",
                        strokeWidth: "8.33px",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Circle
                    cx={222.917}
                    cy={54.167}
                    r={16.667}
                    style={{fill: "#e7eced"}}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Circle
                    cx={222.917}
                    cy={95.833}
                    r={12.5}
                    style={{fill: "#28384c"}}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M56.25 41.667h25V62.5h-25zM56.25 83.333h25v20.833h-25z"
                    style={{fill: "#28384c"}}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M110.417 66.667a4.156 4.156 0 0 1-2.946-1.221 4.163 4.163 0 0 1 0-5.892l8.333-8.333a4.163 4.163 0 0 1 5.892 0 4.163 4.163 0 0 1 0 5.892l-8.333 8.333a4.16 4.16 0 0 1-2.946 1.221ZM110.417 87.5a4.152 4.152 0 0 1-2.946-1.221 4.162 4.162 0 0 1 0-5.891l29.166-29.167a4.163 4.163 0 0 1 5.892 0 4.163 4.163 0 0 1 0 5.892l-29.166 29.166a4.156 4.156 0 0 1-2.946 1.221Z"
                    style={{
                        fill: "#88c057",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M135.417 166.667V175M152.083 166.667V175"
                    style={{
                        fill: "none",
                        fillRule: "nonzero",
                        stroke: "#e7eced",
                        strokeWidth: "8.33px",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M206.25 154.167h-44.558a5.44 5.44 0 0 1-5.442-5.442v-5.779a5.44 5.44 0 0 1 5.442-5.442h44.558a8.33 8.33 0 0 1 8.333 8.334 8.33 8.33 0 0 1-8.333 8.329Z"
                    style={{
                        fill: "#c7cac7",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
                <Path
                    d="M161.692 137.5h11.225v-16.667h-58.334v33.334c0 6.904 5.596 12.5 12.5 12.5h33.334c6.904 0 12.5-5.596 12.5-12.5h-11.225a5.44 5.44 0 0 1-5.442-5.442v-5.779a5.445 5.445 0 0 1 5.442-5.446Z"
                    style={{
                        fill: "#949493",
                        fillRule: "nonzero",
                    }}
                    transform="matrix(.17648 0 0 .1738 9.223 9.615)"
                />
            </G>
        </Svg>
    );
}

export default IcCapsuleCoffee;
