/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
  size?: number,
};

function IcTrash({size = 35}: Props){
    return (
        <Svg
            height={size}
            style={{enableBackground: "new 0 0 511.981 511.981"}}
            viewBox="0 0 511.981 511.981"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M453.323 95.996a10.677 10.677 0 0 1-9.547-5.89l-18.389-36.78h-57.405c-5.891 0-10.671-4.766-10.671-10.656s4.78-10.671 10.671-10.671h63.997c4.047 0 7.734 2.281 9.547 5.89l21.328 42.67c2.641 5.266.5 11.672-4.766 14.312a10.62 10.62 0 0 1-4.765 1.125zM261.315 0h-85.324C170.1 0 165.32 4.78 165.32 10.671V42.67c0 5.891 4.781 10.656 10.671 10.656h85.325c5.906 0 10.672-4.766 10.672-10.656V10.671C271.987 4.78 267.221 0 261.315 0z"
                style={{fill: "#434a54"}}
            />
            <Path
                d="M378.654 74.668H58.667c-5.891 0-10.671 4.766-10.671 10.656v42.67c0 1.656.391 3.281 1.125 4.766l41.545 83.075v178.821c0 1.172.125 29.046 11.421 57.295 15.718 39.265 44.952 60.029 84.575 60.029h191.992c5.891 0 10.656-4.781 10.656-10.672V85.324a10.65 10.65 0 0 0-10.656-10.656z"
                style={{fill: "#37bc9b"}}
            />
            <Path
                d="M325.312 447.982c0-29.451 23.89-53.326 53.342-53.326 29.453 0 53.325 23.875 53.325 53.326 0 29.452-23.872 53.326-53.325 53.326-29.452 0-53.342-23.874-53.342-53.326z"
                style={{fill: "#656d78"}}
            />
            <Path
                d="M378.654 511.98c-35.295 0-63.998-28.702-63.998-63.998 0-35.295 28.703-63.997 63.998-63.997 35.28 0 63.997 28.702 63.997 63.997 0 35.296-28.717 63.998-63.997 63.998zm0-106.668c-23.53 0-42.67 19.14-42.67 42.67s19.14 42.671 42.67 42.671c23.531 0 42.655-19.141 42.655-42.671s-19.124-42.67-42.655-42.67z"
                style={{fill: "#434a54"}}
            />
            <Path
                d="M378.654 31.998H58.667c-5.891 0-10.671 4.781-10.671 10.671v42.655c0 5.891 4.781 10.671 10.671 10.671h319.987c5.891 0 10.656-4.781 10.656-10.671V42.67c0-5.891-4.765-10.672-10.656-10.672z"
                style={{fill: "#48cfad"}}
            />
            <Path
                d="M123.43 451.951c-11.312-28.249-11.437-56.123-11.437-57.295V215.835l-41.53-83.075a10.546 10.546 0 0 1-1.141-4.766V42.67c0-5.891 4.781-10.671 10.672-10.671H58.667c-5.891 0-10.671 4.781-10.671 10.671v85.325c0 1.656.391 3.281 1.125 4.766l41.545 83.075v178.821c0 1.172.125 29.046 11.421 57.295 15.718 39.265 44.952 60.029 84.575 60.029h21.328c-39.608-.001-68.857-20.765-84.56-60.03z"
                style={{
                    opacity: 0.2,
                    fill: "#fff",
                    enableBackground: "new",
                }}
            />
            <Path
                d="M386.201 440.435c4.156 4.172 4.156 10.922 0 15.094-4.172 4.155-10.922 4.155-15.094 0a10.666 10.666 0 0 1 0-15.094c4.172-4.156 10.922-4.156 15.094 0z"
                style={{fill: "#aab2bc"}}
            />
        </Svg>
    );
}

export default IcTrash;
