/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const Waste = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M10.208 31.166a2.807 2.807 0 0 1-2.059-.856 2.81 2.81 0 0 1-.857-2.06V9.29H5.833V6.374h7.292V4.916h8.75v1.458h7.292v2.917h-1.459v18.958c0 .802-.285 1.49-.856 2.061a2.812 2.812 0 0 1-2.06.856H10.208ZM24.792 9.291H10.208v18.958h14.584V9.291ZM13.125 25.333h2.917V12.208h-2.917v13.125Zm5.833 0h2.917V12.208h-2.917v13.125Z"
            fill={color}
        />
    </Svg>
);

export default Waste;