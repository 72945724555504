// @flow
import React, {useContext} from 'react';
import BasicCard from '../../Cards/BasicCard/BasicCard';
import Body from '../../Typos/Body/Body';
import {ThemeContext} from '../../../../libs/Hoc/ThemeProvider';

type Props = {
    text: string,
}

const ESuccessToast = ({text}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    return (
        <BasicCard
            customStyle={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: spacing[1],
                paddingHorizontal: spacing[2],
                marginHorizontal: spacing[2],
                backgroundColor: colors.feedback.success1,
            }}
        >
            <Body variant='paragraph'>
                {text}
            </Body>
        </BasicCard>
    );
};

export default ESuccessToast;