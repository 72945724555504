/* eslint-disable max-len */
// @flow
import React from "react";
import {
    getAppliancesPurchasesImpact,
    getKgCO2MailsImpact,
    getKgCO2NewProductsImpact,
    getKgCO2RecyclableTrashImpact,
    getKgCO2StreamingImpact,
    getKgCO2TrashImpact,
    getPlasticBottlesImpact,
    getTechnologyPurchasesImpact,
} from "../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer";
import QAppliancesPurchases from "./QAppliancesPurchases";
import QMonthlyNewProductSpendings from "./QMonthlyNewProductSpendings";
import Store from "../../../../../Config/Store";
import CFActions from "../../../../../Actions/CarbonFootprintActions";
import QDailyMailSending from "./QDailyMailSending";
import QDailyStreamingVideoHours from "./QDailyStreamingVideoHours";
import QMonthlyTrashbags from "./QMonthlyTrashbags";
import QMonthlyRecyclableTrashbags from "./QMonthlyRecyclableTrashbags";
import QWeeklyPlasticBottles from "../Diet/QWeeklyPlasticBottles";
import QTechnologyPurchases from "./QTechnologyPurchases";
import type {CFQuestionMapItem} from "../../../../../Types/CarbonFootprint/FootprintTypes";

const ConsumptionQuestionsMap: Map<string, CFQuestionMapItem> = new Map();

ConsumptionQuestionsMap.set('first', {
    question: 'Combien de sacs poubelle de déchets non recyclables (50L) sors-tu environ par mois à la maison ?',
    questionDescription: 'Consommation : 1/8',
    infoModalContent: `Un Français produit en moyenne 1kg de déchets ménagers par jour, c’est 2 fois plus qu’il y a 40 ans !\nUn sac poubelle représente en moyenne 4 kg de [déchets ménagers](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/index.htm?ordures-menageres.htm).`,
    impact: 1,
    form: (<QMonthlyTrashbags />),
    onPressReturn: () => Store.dispatch(CFActions.setMonthlyTrashBags(0)),
    getEstimatedImpact: store => getKgCO2TrashImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => null,
    getNextQuestion: () => 'second',
});

ConsumptionQuestionsMap.set('second', {
    question: 'Combien de sacs de déchets recyclables (50L) sors-tu environ par mois à la maison ?',
    questionDescription: 'Consommation : 2/8',
    infoModalContent: `Le recyclage permet de limiter la consommation de matières premières utilisées dans nos produits de consommation. Le recyclage n’est malheureusement pas infini, un peu de matière est perdue à chaque cycle.\nIl est estimé une économie réalisée pour chaque sac recyclé, plus d’informations sur cette [infographie](https://expertises.ademe.fr/sites/default/files/assets/images/infographie-flux-dechets-2018.jpg).`,
    impact: 1,
    form: (<QMonthlyRecyclableTrashbags />),
    onPressReturn: () => Store.dispatch(CFActions.setMonthlyRecyclableTrashBags(0)),
    getEstimatedImpact: store => getKgCO2RecyclableTrashImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'first',
    getNextQuestion: () => 'third',
});

ConsumptionQuestionsMap.set('third', {
    question: 'Combien de bouteilles en plastique utilises-tu chaque semaine (eau, soda etc) ?',
    questionDescription: 'Consommation : 3/8',
    infoModalContent: `En France, on consomme chaque jour 25 000 000 bouteilles d’eau, dont la moitié seulement sont recyclées ([source](https://bilans-ges.ademe.fr/documentation/UPLOAD_DOC_FR/index.htm?ordures-menageres.htm)). Le calcul se fait sur la base d’une bouteille d’un litre d’eau minérale ou de 50 cl de soda.`,
    impact: 1,
    form: (<QWeeklyPlasticBottles />),
    onPressReturn: () => Store.dispatch(CFActions.setDailyNbPlasticBottles(0)),
    getEstimatedImpact: store => getPlasticBottlesImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'second',
    getNextQuestion: () => 'fourth',
});

ConsumptionQuestionsMap.set('fourth', {
    question: 'Lequel de ces appareils électroniques as-tu acheté neuf dans les 5 dernières années ?',
    questionDescription: 'Consommation : 4/8',
    infoModalContent: `On peut estimer, en fonction de l’appareil électronique, que 80 à 95 % de l’impact est dû à sa fabrication. Ainsi, il vaut toujours mieux conserver son appareil au moins quelques années de plus, surtout s’il fonctionne toujours. Et si tu le peux, achète plutôt d’occasion !\nPar ailleurs, [l’impact carbone](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/ordinateurs_et_equuipements_pe.htm) ne prend pas complètement en compte la pollution des sols et la destruction causée par les mines, lors de l’extraction des différents matériaux nécessaires. Enfin, ces appareils sont difficilement recyclables.`,
    impact: 3,
    form: (<QTechnologyPurchases />),
    getEstimatedImpact: store => getTechnologyPurchasesImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'third',
    getNextQuestion: () => 'fifth',
});

ConsumptionQuestionsMap.set('fifth', {
    question: 'Lequel de ces appareils électroménagers as-tu acheté neuf dans les 10 dernières années ?',
    questionDescription: 'Consommation : 5/8',
    infoModalContent: `L’ADEME estime que la durée de vie de ces appareils est d’au moins 10 ans, mais [leur impact](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/electromenager.htm) n’est pas négligeable. Si tu veux réduire l’impact de ta machine à laver, il vaut mieux la réparer que d’en acheter une nouvelle ! Si tu ne peux pas, essaie d’acheter des appareils avec une catégorie A ou A+, plus économes en énergie et en eau.`,
    impact: 2,
    form: (<QAppliancesPurchases />),
    getEstimatedImpact: store => getAppliancesPurchasesImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'fourth',
    getNextQuestion: () => 'sixth',
});

ConsumptionQuestionsMap.set('sixth', {
    question: 'Combien dépenses-tu par mois en achat d\'autres produits de consommation neufs (vêtements, ameublement, loisirs,...) ?',
    questionDescription: 'Consommation : 6/8',
    infoModalContent: `L’achat de neuf est responsable d’une grande partie de l’empreinte carbone des Français. L’impact des [vêtements](https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/coton_-synthetique_-autre.htm) ou du [mobilier]https://prod-basecarbonesolo.ademe-dri.fr/documentation/UPLOAD_DOC_FR/mobilier.htm) est très différent, cependant, ramené au prix moyen de ces produits, leur impact à l’euro est assez proche pour permettre une estimation globale.`,
    impact: 2,
    form: (<QMonthlyNewProductSpendings />),
    getEstimatedImpact: store => getKgCO2NewProductsImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'fifth',
    getNextQuestion: () => 'seventh',
});

ConsumptionQuestionsMap.set('seventh', {
    question: 'Combien de mails envoies-tu par jour en moyenne au travail ?',
    questionDescription: 'Consommation : 7/8',
    infoModalContent: `Un Français envoie en moyenne 33 mails par jour.\nLe coût du numérique est souvent mis en avant mais il peut parfois permettre d’éviter des déplacements inutiles s’il est utilisé correctement. L’impact est très compliqué à calculer c’est pourquoi nous partons sur une moyenne de 3g par email. [Notre source (EN)](https://carbonliteracy.com/the-carbon-cost-of-an-email/).`,
    impact: 1,
    form: (<QDailyMailSending />),
    onPressReturn: () => Store.dispatch(CFActions.setDailyMailSending('verylittle')),
    getEstimatedImpact: store => getKgCO2MailsImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'sixth',
    getNextQuestion: () => 'eighth',
});

ConsumptionQuestionsMap.set('eighth', {
    question: 'Combien d\'heures de streaming vidéo visionnes-tu en moyenne ?',
    questionDescription: 'Consommation : 8/8',
    infoModalContent: `Selon l’ADEME les vidéos en ligne représentent 60% du flux mondial de données et sont responsables de près de 1% des émissions mondiales de CO2. Ce secteur est en forte croissance.\nLes données sont issues de l’[IEA](https://www.iea.org/commentaries/the-carbon-footprint-of-streaming-video-fact-checking-the-headlines).`,
    impact: 1,
    form: (<QDailyStreamingVideoHours />),
    getEstimatedImpact: store => getKgCO2StreamingImpact(store) ?? 0,
    getDisableSubmit: () => false,
    getPreviousQuestion: () => 'seventh',
    getNextQuestion: () => null,
});

export default ConsumptionQuestionsMap;