// @flow
import type {Immutable as ImmutableType} from 'seamless-immutable';
import Immutable from 'seamless-immutable';
import type {Store} from '../../Types/Reducers/Store';
import type {ActionP} from '../../Types/Reducers/Action';
import ReducerUtils from '../ReducerUtils';
import CarbonFootprintActions from '../../Actions/CarbonFootprintActions';
import CFUtils from './CFUtils';
import type {
    UserCFAnswersType,
    UserConsumptionCFAnswersType,
} from "../../Types/CarbonFootprint/FootprintApiTypes";
import {getLastNbPeopleInAccomodation, getNbPeopleInAccomodation} from "./CFEnergyReducer";
import type {ElementMap} from "../../Types/Reducers/ElementMap";
import type {
    DeviceType,
    DeviceValueCount,
    NewProductQuantity,
    QuantityType,
} from "../../Types/CarbonFootprint/FootprintQuestionsTypes";
import type {FootprintDetailPartType} from "../../Types/CarbonFootprint/FootprintTypes";

export type CFConsumptionReducerState = ImmutableType<{
    monthlyTrashBags: number,
    monthlyRecyclableTrashBags: number,
    dailyNbPlasticBottles: number,

    dailyMailSending: QuantityType,
    dailyStreamingVideoHours: QuantityType,

    technologyPurchases: ElementMap<DeviceType, DeviceValueCount>,
    appliancesPurchases: ElementMap<DeviceType, DeviceValueCount>,
    monthlyNewProductsSpendings: NewProductQuantity[],

    lastAnswers?: UserConsumptionCFAnswersType,
}>;

const INITIAL_STATE: CFConsumptionReducerState = Immutable({
    monthlyTrashBags: 0,
    monthlyRecyclableTrashBags: 0,
    dailyNbPlasticBottles: 0,

    technologyPurchases: ReducerUtils.createElementMap(),
    appliancesPurchases: ReducerUtils.createElementMap(),
    monthlyNewProductsSpendings: [],

    dailyMailSending: 'never',
    dailyStreamingVideoHours: 'never',
    lastAnswers: null,
});


// CONSUMPTION SETTERS
const setMonthlyTrashBags = (state: CFConsumptionReducerState, action: ActionP<number>) => Immutable.merge(
    state,
    {monthlyTrashBags: action?.payload},
);

const setMonthlyRecyclableTrashBags = (state: CFConsumptionReducerState, action: ActionP<number>) => Immutable.merge(
    state,
    {monthlyRecyclableTrashBags: action?.payload},
);

const setDailyNbPlasticBottles = (state: CFConsumptionReducerState, action: ActionP<number>) => Immutable.merge(
    state,
    {dailyNbPlasticBottles: action?.payload},
);

const setMonthlyNewProductsSpendings = (
    state: CFConsumptionReducerState,
    action: ActionP<NewProductQuantity[]>
) => Immutable.merge(
    state,
    {monthlyNewProductsSpendings: action?.payload},
);

const setDailyMailSending = (state: CFConsumptionReducerState, action: ActionP<QuantityType>) => {
    const {payload} = action;

    return Immutable.merge(
        state,
        {dailyMailSending: payload},
    );
};

const setDailyStreamingVideoHours = (state: CFConsumptionReducerState, action: ActionP<QuantityType>) => {
    const {payload} = action;

    return Immutable.merge(
        state,
        {dailyStreamingVideoHours: payload},
    );
};

const addTechnologyPurchase = (state: CFConsumptionReducerState, action: ActionP<DeviceType>) => {
    const currentCount = state?.technologyPurchases?.byId?.[action?.payload]?.count;
    return Immutable.merge(state, {
        technologyPurchases: ReducerUtils.updateMapElement(
            state?.technologyPurchases,
            {
                value: action?.payload,
                count: (currentCount ?? 0) + 1,
            },
            el => el.value,
        ),
    });
};

const removeTechnologyPurchase = (state: CFConsumptionReducerState, action: ActionP<DeviceType>) => {
    return Immutable.merge(state, {
        technologyPurchases: ReducerUtils.removeElementFromMap(
            action?.payload,
            state?.technologyPurchases,
        ),
    });
};

const addAppliancesPurchase = (state: CFConsumptionReducerState, action: ActionP<DeviceType>) => {
    const currentCount = state?.appliancesPurchases?.byId?.[action?.payload]?.count;
    return Immutable.merge(state, {
        appliancesPurchases: ReducerUtils.updateMapElement(
            state?.appliancesPurchases,
            {
                value: action?.payload,
                count: (currentCount ?? 0) + 1,
            },
            el => el.value,
        ),
    });
};

const removeAppliancesPurchase = (state: CFConsumptionReducerState, action: ActionP<DeviceType>) => {
    return Immutable.merge(state, {
        appliancesPurchases: ReducerUtils.removeElementFromMap(
            action?.payload,
            state?.appliancesPurchases,
        ),
    });
};

const resetCFReducer = (state: CFConsumptionReducerState) => Immutable.merge(state, INITIAL_STATE);

const setUserLastCFAnswers = (state: CFConsumptionReducerState, action: ActionP<{answers: UserCFAnswersType}>) => {
    const technologyPurchases = action?.payload?.answers?.consumption?.technologyPurchases?.map(tp => ({
        value: tp?.answerType,
        count: tp?.value,
    }));
    const tpMap = ReducerUtils.setElementsToMap(technologyPurchases, tp => tp?.value);
    const appliancesPurchases = action?.payload?.answers?.consumption?.appliancesPurchases?.map(tp => ({
        value: tp?.answerType,
        count: tp?.value,
    }));
    const apMap = ReducerUtils.setElementsToMap(appliancesPurchases, ap => ap?.value);

    const newProductsSpendings = Array.isArray(action?.payload?.answers?.consumption?.monthlyNewProductsSpendings)
        ? action?.payload?.answers?.consumption?.monthlyNewProductsSpendings
        : []; // handle that new products was just a number before, now an array

    return Immutable.merge(
        state,
        {
            // Purchases
            technologyPurchases: tpMap,
            appliancesPurchases: apMap,
            monthlyNewProductsSpendings: newProductsSpendings,

            // Trash
            monthlyTrashBags: action?.payload?.answers?.consumption?.monthlyTrashBags ?? 0,
            monthlyRecyclableTrashBags: action?.payload?.answers?.consumption?.monthlyRecyclableTrashBags ?? 0,
            dailyNbPlasticBottles: action?.payload?.answers?.consumption?.nbWeeklyPlasticBottles ?? 0,

            // Numeric
            dailyMailSending: action?.payload?.answers?.consumption?.dailyMailSending ?? 'never',
            dailyStreamingVideoHours: action?.payload?.answers?.consumption?.dailyStreamingVideoHours ?? 'never',

            // Last answers
            lastAnswers: action?.payload?.answers?.consumption,
        },
    );
};

export default ReducerUtils.createReducer(
    INITIAL_STATE,
    {
        [CarbonFootprintActions.Types.SET_MONTHLY_TRASHBAGS]: setMonthlyTrashBags,
        [CarbonFootprintActions.Types.SET_MONTHLY_RECYCLABLE_TRASHBAGS]: setMonthlyRecyclableTrashBags,
        [CarbonFootprintActions.Types.SET_DAILY_NB_PLASTIC_BOTTLES]: setDailyNbPlasticBottles,

        [CarbonFootprintActions.Types.ADD_TECHNOLOGY_PURCHASE_ITEM]: addTechnologyPurchase,
        [CarbonFootprintActions.Types.REMOVE_TECHNOLOGY_PURCHASE_ITEM]: removeTechnologyPurchase,
        [CarbonFootprintActions.Types.ADD_APPLIANCES_PURCHASE_ITEM]: addAppliancesPurchase,
        [CarbonFootprintActions.Types.REMOVE_APPLIANCES_PURCHASE_ITEM]: removeAppliancesPurchase,
        [CarbonFootprintActions.Types.SET_MONTHLY_NEW_PRODUCTS_SPENDINGS]: setMonthlyNewProductsSpendings,

        [CarbonFootprintActions.Types.SET_DAILY_MAIL_SENDING]: setDailyMailSending,
        [CarbonFootprintActions.Types.SET_DAILY_STREAMING_VIDEO_HOURS]: setDailyStreamingVideoHours,
        [CarbonFootprintActions.Types.RESET_FOOTPRINT_REDUCERS]: resetCFReducer,
        [CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS_SUCCESS]: setUserLastCFAnswers,
    },
);

export const getState = (store: Store): CFConsumptionReducerState => store.carbonFootprint.consumption;

// TRASH IMPACT
export const getMonthlyRecyclableTrashBags = (store: Store): number => getState(store).monthlyRecyclableTrashBags;
export const getMonthlyTrashBags = (store: Store): number => getState(store).monthlyTrashBags;
export const getDailyNbPlasticBottles = (store: Store): number => getState(store).dailyNbPlasticBottles;

const getCalculatedgCO2TrashImpact = (nbMonthlyRegularTrashBags: number): number => {
    const nbKgOfTrashPerBag = 4;
    const nbgCO2PerKgOfTrash = 386;
    return CFUtils?.NB_MONTHS * nbMonthlyRegularTrashBags * nbKgOfTrashPerBag * nbgCO2PerKgOfTrash;
};

const getgCO2TrashImpact = (store: Store): number => {
    const nbMonthlyRegularTrashBags = getMonthlyTrashBags(store);
    return getCalculatedgCO2TrashImpact(nbMonthlyRegularTrashBags);
};

const getLastgCO2TrashImpact = (store: Store): number => {
    const nbMonthlyRegularTrashBags = getState(store).lastAnswers?.monthlyTrashBags || 0;
    return getCalculatedgCO2TrashImpact(nbMonthlyRegularTrashBags);
};

const getCalculatedgCO2RecyclableTrashImpact = (nbMonthlyRecyclableTrashBags: number): number => {
    const nbKgOfTrashPerBag = 4;
    const nbgCO2PerKgOfRecyclableTrash = 254;
    return CFUtils?.NB_MONTHS * nbMonthlyRecyclableTrashBags * nbKgOfTrashPerBag * nbgCO2PerKgOfRecyclableTrash;
};

const getgCO2RecyclableTrashImpact = (store: Store): number => {
    const nbMonthlyRecyclableTrashBags = getMonthlyRecyclableTrashBags(store);
    return getCalculatedgCO2RecyclableTrashImpact(nbMonthlyRecyclableTrashBags);
};

const getLastgCO2RecyclableTrashImpact = (store: Store): number => {
    const nbMonthlyRecyclableTrashBags = getState(store).lastAnswers?.monthlyRecyclableTrashBags || 0;
    return getCalculatedgCO2RecyclableTrashImpact(nbMonthlyRecyclableTrashBags);
};

const getgCO2WasteConsumptions = (store: Store): number => {
    return getgCO2TrashImpact(store) + getgCO2RecyclableTrashImpact(store);
};

const getLastgCO2WasteConsumptions = (store: Store): number => {
    return getLastgCO2TrashImpact(store) + getLastgCO2RecyclableTrashImpact(store);
};

export const getKgCO2TrashImpact = (store: Store): number =>
    getgCO2TrashImpact(store) / CFUtils?.gCO2toKgCO2Ratio;
export const getKgCO2RecyclableTrashImpact = (store: Store): number =>
    getgCO2RecyclableTrashImpact(store) / CFUtils?.gCO2toKgCO2Ratio;

export const getLastgCO2IndividualTrashImpact = (store: Store): number => {
    const trashImpact = getLastgCO2WasteConsumptions(store);
    const nbPeople = getLastNbPeopleInAccomodation(store);
    return (trashImpact / nbPeople) / CFUtils?.gCO2toKgCO2Ratio;
};
export const getgCO2TotalTrashImpact = (store: Store): number =>
    getgCO2WasteConsumptions(store) / getNbPeopleInAccomodation(store);


export const getCalculatedPlasticBottlesImpact = (nbBottlesAWeek: number): number => {
    const plasticBottleImpact = 0.286;
    return plasticBottleImpact * nbBottlesAWeek * CFUtils?.NB_WEEKS;
};
/**
 * Returns the yearly impact of plastic bottles in kgCO2eq depending on number wasted by week
 * @param store
 * @returns {number}
 */
export const getPlasticBottlesImpact = (store: Store): number => {
    return getCalculatedPlasticBottlesImpact(getDailyNbPlasticBottles(store));
};

export const getLastPlasticBottlesImpact = (store: Store): number => {
    const nbWeeklyPlasticBottles = getState(store).lastAnswers?.nbWeeklyPlasticBottles ?? 0;
    return getCalculatedPlasticBottlesImpact(nbWeeklyPlasticBottles);
};
export const getTechnologyPurchases = (store: Store): ElementMap<DeviceType, DeviceValueCount> =>
    getState(store).technologyPurchases;
export const getTechnologyPurchasesImpact = (store: Store): number =>
    ReducerUtils.mapToArray(getTechnologyPurchases(store))
        ?.reduce((acc, el) => acc + (CFUtils?.technologyDeviceImpact?.[el?.value] * el?.count), 0);

export const getAppliancesPurchases = (store: Store): ElementMap<DeviceType, DeviceValueCount> =>
    getState(store).appliancesPurchases;
export const getAppliancesPurchasesImpact = (store: Store): number =>
    ReducerUtils.mapToArray(getAppliancesPurchases(store))
        ?.reduce((acc, el) => acc + (CFUtils?.applianceDeviceImpact?.[el?.value] * el?.count), 0);

/**
 * Returns total impact of tech + appliance devices, in kgC02eq.
 */
export const getDevicesPurchaseImpact = (store: Store): number => {
    const technology = getTechnologyPurchasesImpact(store);
    const appliances = getAppliancesPurchasesImpact(store);
    return technology + appliances;
};
/**
 * Returns last answers total impact of tech + appliance devices, in kgC02eq.
 */
export const getLastAnswersDevicesPurchaseImpact = (store: Store): number => {
    const lastAnswers = getState(store)?.lastAnswers;
    const technology = lastAnswers?.technologyPurchases
        ?.reduce((acc, el) => acc + (CFUtils?.technologyDeviceImpact?.[el?.answerType] * el?.value), 0);
    const appliances = lastAnswers?.appliancesPurchases
        ?.reduce((acc, el) => acc + (CFUtils?.applianceDeviceImpact?.[el?.answerType] * el?.value), 0);
    return technology + appliances;
};

export const getMonthlyNewProductsSpendings = (store: Store): NewProductQuantity[] => {
    const currentProducts = getState(store).monthlyNewProductsSpendings;
    return Array.isArray(currentProducts) ? currentProducts : [];
};
export const getDailyStreamingVideoHours = (store: Store): QuantityType =>
    getState(store).dailyStreamingVideoHours;
export const getDailyMailSending = (store: Store): QuantityType => getState(store).dailyMailSending;

const getYearlyStreamingVideoHours = (store: Store): number =>
    CFUtils.streamingQuantityTypeToNumber[getDailyStreamingVideoHours(store)] * CFUtils?.NB_WEEKS;
const getLastYearlyStreamingVideoHours = (store: Store): number => {
    const dailyStreamingVideoHours = getState(store).lastAnswers?.dailyStreamingVideoHours;
    if(!dailyStreamingVideoHours) return 0;
    return CFUtils.streamingQuantityTypeToNumber[dailyStreamingVideoHours] * CFUtils?.NB_WEEKS;
};
const getYearlyNewProductsSpendings = (store: Store): number => {
    const spendings = getMonthlyNewProductsSpendings(store);
    return (Array.isArray(spendings) ? spendings : [])?.reduce((acc, elm) => acc + elm?.value, 0) * 12;
};
const getLastYearlyNewProductsSpendings = (store: Store): number => {
    const lastAnswers = getState(store).lastAnswers?.monthlyNewProductsSpendings;
    const monthlyNewProductsSpendings = Array.isArray(lastAnswers) ? lastAnswers : [];
    return (monthlyNewProductsSpendings?.reduce((acc, el) => acc + el?.value, 0)) * 12;
};
const getYearlyMailSending = (store: Store): number =>
    CFUtils.emailQuantityTypeToNumber[getDailyMailSending(store)] * 5 * CFUtils?.NB_WEEKS;
const getLastYearlyMailSending = (store: Store): number => {
    const dailyMailSending = getState(store).lastAnswers?.dailyMailSending;
    if(!dailyMailSending) return 0;
    return CFUtils.emailQuantityTypeToNumber[dailyMailSending] * 5 * CFUtils?.NB_WEEKS;
};

export const getgCO2ConsumptionImpact = (store: Store): number => {
    const {various, mails, streaming} = CFUtils.consumptionImpact;
    const trashImpact = getgCO2TotalTrashImpact(store);
    const bottlesImpact = getPlasticBottlesImpact(store);
    const streamingImpact = getYearlyStreamingVideoHours(store) * streaming;
    const purchasesImpact = getYearlyNewProductsSpendings(store) * various;
    const technologyImpact = getDevicesPurchaseImpact(store) * CFUtils?.gCO2toKgCO2Ratio; // multiply to get grams
    const mailsImpact = getYearlyMailSending(store) * mails;
    return trashImpact + bottlesImpact + streamingImpact + purchasesImpact + technologyImpact + mailsImpact;
};

export const getTCO2ConsumptionImpact = (store: Store): number =>
    getgCO2ConsumptionImpact(store) / CFUtils?.gCO2toTCO2Ratio;

export const getCalculatedKgCO2TechnologyImpact = (spendings: number): number => {
    const {technology} = CFUtils.consumptionImpact;
    const impact = spendings * technology;
    return impact / CFUtils?.gCO2toKgCO2Ratio;
};

export const getLastKgCO2TechnologyImpact = (store: Store): number => getLastAnswersDevicesPurchaseImpact(store);

const getCalculatedKgCO2NewProductsImpact = (spendings: number): number => {
    const {various} = CFUtils.consumptionImpact;
    const impact = spendings * various;
    return impact / CFUtils?.gCO2toKgCO2Ratio;
};

export const getKgCO2NewProductsImpact = (store: Store): number => {
    return getCalculatedKgCO2NewProductsImpact(getYearlyNewProductsSpendings(store));
};

export const getLastKgCO2NewProductsImpact = (store: Store): number => {
    return getCalculatedKgCO2NewProductsImpact(getLastYearlyNewProductsSpendings(store));
};

export const getCalculatedKgCO2MailsImpact = (sending: number): number => {
    const {mails} = CFUtils.consumptionImpact;
    const impact = sending * mails;
    return impact / CFUtils?.gCO2toKgCO2Ratio;
};

export const getKgCO2MailsImpact = (store: Store): number => {
    return getCalculatedKgCO2MailsImpact(getYearlyMailSending(store));
};

export const getLastKgCO2MailsImpact = (store: Store): number => {
    return getCalculatedKgCO2MailsImpact(getLastYearlyMailSending(store));
};

export const getCalculatedKgCO2StreamingImpact = (streamingHours: number): number => {
    const {streaming} = CFUtils.consumptionImpact;
    const impact = streamingHours * streaming;
    return impact / CFUtils?.gCO2toKgCO2Ratio;
};

export const getKgCO2StreamingImpact = (store: Store): number => {
    return getCalculatedKgCO2StreamingImpact(getYearlyStreamingVideoHours(store));
};

export const getLastKgCO2StreamingImpact = (store: Store): number => {
    return getCalculatedKgCO2StreamingImpact(getLastYearlyStreamingVideoHours(store));
};

export const getConsumptionDetailedParts = (store: Store): Array<FootprintDetailPartType> => [
    {
        title: 'Déchets',
        value: (
            (getgCO2TotalTrashImpact(store) / CFUtils?.gCO2toKgCO2Ratio) + getPlasticBottlesImpact(store)
        ).toFixed(1),
        key: 'Trash',
    },
    {
        title: 'Streaming vidéo',
        value: getKgCO2StreamingImpact(store).toFixed(1),
        key: 'VideoStreaming',
    },
    {
        title: 'Produits de consommation neufs',
        value: getKgCO2NewProductsImpact(store).toFixed(1),
        key: 'NewPurchase',
    },
    {
        title: 'Électronique & électroménager',
        value: getDevicesPurchaseImpact(store).toFixed(1),
        key: 'TechPurchase',
    },
    {
        title: 'Envoi de mails',
        value: getKgCO2MailsImpact(store).toFixed(1),
        key: 'MailSending',
    },
].sort((a, b) => b.value - a.value);




