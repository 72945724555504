/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const DietType = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} id="DietTypeIcon" width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M14.547 29.708h5.833v-1.604l1.823-.73c1.58-.632 2.923-1.513 4.03-2.644 1.105-1.13 1.9-2.387 2.387-3.772H6.307c.486 1.385 1.276 2.643 2.37 3.772 1.094 1.131 2.443 2.012 4.047 2.644l1.823.73v1.604Zm-1.459 2.916c-.413 0-.759-.14-1.038-.42a1.407 1.407 0 0 1-.42-1.038v-1.094c-2.333-.923-4.271-2.29-5.814-4.1a12.123 12.123 0 0 1-2.826-6.254 1.446 1.446 0 0 1 .382-1.167c.304-.34.7-.51 1.185-.51h1.24V7.687c0-.365.127-.687.382-.967.256-.279.566-.443.93-.491l23.735-2.625c.34-.05.626.03.857.236.23.207.346.48.346.82 0 .268-.091.505-.273.711a1.153 1.153 0 0 1-.675.384L15.276 7.54v2.48h15.677c.316 0 .578.103.785.31.206.206.309.467.309.783s-.103.577-.31.783c-.206.207-.468.31-.784.31H15.276v5.834H30.37c.486 0 .881.17 1.185.51.304.34.431.73.383 1.167a12.123 12.123 0 0 1-2.827 6.253c-1.543 1.81-3.48 3.178-5.814 4.101v1.094c0 .413-.14.76-.419 1.038-.28.28-.626.42-1.04.42h-8.75ZM11.63 10.02h1.458V7.76l-1.458.146v2.114Zm-3.646 0h1.459V8.197l-1.459.146v1.677Zm3.646 8.021h1.458v-5.833H11.63v5.833Zm-3.646 0h1.459v-5.833H7.984v5.833Z"
            fill={color}
        />
    </Svg>
);

export default DietType;