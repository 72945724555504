/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcFridge = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 483.2 483.2"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M168.8 478.4c0 2.4-2.4 4.8-4.8 4.8h-18.4c-2.4 0-4.8-2.4-4.8-4.8V472c0-2.4 2.4-4.8 4.8-4.8H164c2.4 0 4.8 2.4 4.8 4.8v6.4zM342.4 478.4c0 2.4-2.4 4.8-4.8 4.8h-18.4c-2.4 0-4.8-2.4-4.8-4.8V472c0-2.4 2.4-4.8 4.8-4.8h18.4c2.4 0 4.8 2.4 4.8 4.8v6.4z"
            style={{fill: "#03263a"}}
        />
        <Path
            d="M369.6 441.6c0 32.8-26.4 29.6-59.2 29.6H172.8c-32.8 0-59.2 3.2-59.2-29.6V59.2C113.6 26.4 140 0 172.8 0h137.6c32.8 0 59.2 26.4 59.2 59.2v382.4z"
            style={{fill: "#ff5b00"}}
        />
        <Path
            d="M310.4 0H228v471.2h82.4c32.8 0 59.2 3.2 59.2-29.6V59.2c0-32.8-26.4-59.2-59.2-59.2z"
            style={{fill: "#ff3c00"}}
        />
        <Path
            d="M248.8 174.4 140 66.4l-.8 70.4 38.4 37.6zM369.6 441.6v-6.4L140 205.6l-.8 70.4 195.2 195.2c20.8-.8 35.2-5.6 35.2-29.6z"
            style={{fill: "#ff3c00"}}
        />
        <Path
            d="M133.6 441.6V59.2C133.6 26.4 160 0 192.8 0h-20c-32.8 0-59.2 26.4-59.2 59.2v382.4c0 32.8 26.4 29.6 59.2 29.6-22.4 0-39.2-4-39.2-29.6z"
            style={{fill: "#ff781d"}}
        />
        <Path
            d="M310.4 471.2h20-20z"
            style={{fill: "#ff8b00"}}
        />
        <Path
            d="M113.6 165.6h256v19.2h-256z"
            style={{fill: "#03263a"}}
        />
        <Path
            d="M113.6 174.4h256v9.6h-256zM152 130.4c0 4.8-4 9.6-9.6 9.6-4.8 0-9.6-4-9.6-9.6V77.6c0-4.8 4-9.6 9.6-9.6 4.8 0 9.6 4 9.6 9.6v52.8z"
            style={{fill: "#053c54"}}
        />
        <Path
            d="M138.4 68.8c-3.2 1.6-5.6 4.8-5.6 8.8v52.8c0 4 2.4 7.2 5.6 8.8 3.2-1.6 5.6-4.8 5.6-8.8V77.6c.8-4-1.6-7.2-5.6-8.8z"
            style={{fill: "#055875"}}
        />
        <Path
            d="M152 267.2c0 4.8-4 9.6-9.6 9.6-4.8 0-9.6-4-9.6-9.6v-52.8c0-4.8 4-9.6 9.6-9.6 4.8 0 9.6 4 9.6 9.6v52.8z"
            style={{fill: "#053c54"}}
        />
        <Path
            d="M138.4 205.6c-3.2 1.6-5.6 4.8-5.6 8.8v52.8c0 4 2.4 7.2 5.6 8.8 3.2-1.6 5.6-4.8 5.6-8.8v-52.8c.8-4-1.6-8-5.6-8.8z"
            style={{fill: "#055875"}}
        />
        <Path
            d="M337.6 132.8c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12zM337.6 143.2c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12zM337.6 152.8c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12zM337.6 434.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12zM337.6 444c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12zM337.6 454.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h12c.8 0 1.6.8 1.6 1.6s-.8 1.6-1.6 1.6h-12z"
            style={{fill: "#ff781d"}}
        />
    </Svg>
);

export default IcFridge;
