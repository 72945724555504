// @flow
import React, {Node, useCallback, useEffect, useState} from 'react';
import {View} from 'react-native';
import {Slider} from "@miblanchard/react-native-slider";
import styles from './styles';
import {useContext} from "react";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Body from "../../UI/Typos/Body/Body";
import Heading from "../../UI/Typos/Heading/Heading";

type Props = {
    leftSideTrackColor?: string,
    rightSideTrackColor?: string,
    max: number,
    min?: number,
    onSlidingComplete?: (value: any) => any,
    thumbIcon: Node,
    hasThumbLabel?: boolean,
    unit?: string,
    step: number,
    defaultValue?: number,
    width?: number,
    hasSliderSubText?: boolean,
    subText?: string,
    hasTrackMarks?: boolean,
    trackClickable?: boolean,
    doubleInput?: boolean,
    beyondEndLabel?: boolean,
    renderBelowLabel?: (number) => Node,
};

const defaultWidth = '90%';

const ESlider = ({
    leftSideTrackColor,
    rightSideTrackColor,
    max,
    min = 0,
    onSlidingComplete,
    thumbIcon,
    hasThumbLabel = false,
    unit = '',
    defaultValue = 0,
    step = 1,
    width = defaultWidth,
    hasSliderSubText = false,
    subText = '',
    hasTrackMarks = false,
    trackClickable = true,
    doubleInput = false,
    beyondEndLabel = false,
    renderBelowLabel = () => null,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    const [sliderValue, setSliderValue] = useState<number>(defaultValue);

    useEffect(() => {
        setSliderValue(defaultValue);
    }, [defaultValue]);

    const renderThumbIcon = useCallback(() => (
        <View
            style={styles.thumbIconContainer}
        >
            {thumbIcon}
        </View>
    ), [thumbIcon]);

    const renderAboveThumb = index => {
        if(!hasThumbLabel) return null;
        const text = doubleInput
            ? (sliderValue[index] === max && beyondEndLabel)
                ? '∞'
                : sliderValue[index]
            : (sliderValue >= max) && beyondEndLabel
                ? '∞'
                : sliderValue;
        return (
            <Body
                centered
                customStyle={{
                    paddingBottom: spacing[1],
                    minWidth: 60,
                    textAlign: 'center',
                    marginLeft: -5,
                }}
                numberOfLines={1}
                variant='paragraph'
            >
                {text}{unit}
            </Body>
        );
    };

    return (
        <View
            style={[styles.container, {width}]}
        >
            <Slider
                maximumTrackTintColor={rightSideTrackColor ?? colors.text.disabled}
                maximumValue={max}
                minimumTrackTintColor={leftSideTrackColor ?? colors.action}
                minimumValue={min}
                onSlidingComplete={(value) => {
                    if(!!value && value.length > 0) onSlidingComplete(doubleInput ? value : value[0]);
                }}
                onValueChange={setSliderValue}
                renderAboveThumbComponent={renderAboveThumb}
                renderThumbComponent={renderThumbIcon}
                renderTrackMarkComponent={() => hasTrackMarks ? (
                    <View style={styles.trackMark} />
                ) : null}
                step={step}
                thumbTintColor='#fff'
                thumbTouchSize={{
                    width: 62,
                    height: 62,
                }}
                trackClickable={trackClickable}
                trackMarks={hasTrackMarks ? Array.from({length: max}, (_, i) => i + 1) : null}
                trackStyle={styles.trackStyle}
                value={sliderValue}
            />
            {
                hasSliderSubText ? (
                    <View style={styles.subTextContainer}>
                        <Heading centered variant='h3'>
                            {sliderValue ?? min}
                        </Heading>
                        {
                            subText ? (<Body centered variant='paragraph'>{subText}</Body>) : null
                        }
                    </View>
                ) : null
            }
            {
                renderBelowLabel?.(sliderValue)
            }
        </View>
    );
};

export default ESlider;