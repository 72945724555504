/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcHybridCar({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .07h43.969v21.977H0z" id="hybridcar_prefix__a" />
                <Path d="M.296.07h9.773v9.774H.296z" id="hybridcar_prefix__c" />
                <Path d="M1.375.07h9.768v9.774H1.375z" id="hybridcar_prefix__e" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(13 23.336)">
                    <Mask fill="#fff" id="hybridcar_prefix__b">
                        <Use xlinkHref="#hybridcar_prefix__a" />
                    </Mask>
                    <Path
                        d="M15.875 22.047h24.428s3.666 0 3.666-4.88c0-2.442 0-7.329-1.22-8.548C41.527 7.393 32.974.07 29.308.07h-7.327C18.32.07 9.773 8.62 9.773 8.62l-4.887 1.22S1.22 11.054 1.22 13.5v3.666S0 17.578 0 19.552c0 2.495 2.441 2.495 2.441 2.495h13.434z"
                        fill="#7F2EDD"
                        mask="url(#hybridcar_prefix__b)"
                    />
                </G>
                <Path
                    d="M37.428 25.842h-2.446c-2.441 0-9.769 7.332-9.769 7.332s6.103-.323 12.215-.634v-6.698zm12.214 3.666c-1.222-1.22-6.107-3.666-8.548-3.666h-1.225v6.573c4.9-.248 9.261-.46 9.773-.46 1.22 0 1.22-1.226 0-2.447z"
                    fill="#BBDDF5"
                />
                <G transform="translate(20.031 39.21)">
                    <Mask fill="#fff" id="hybridcar_prefix__d">
                        <Use xlinkHref="#hybridcar_prefix__c" />
                    </Mask>
                    <Path
                        d="M10.07 4.957a4.891 4.891 0 01-4.887 4.888 4.887 4.887 0 114.887-4.888"
                        fill="#292F33"
                        mask="url(#hybridcar_prefix__d)"
                    />
                </G>
                <Path
                    d="M27.655 44.167a2.443 2.443 0 01-4.883.11v-.11a2.437 2.437 0 012.496-2.385 2.435 2.435 0 012.387 2.385"
                    fill="#CCD6DD"
                />
                <G transform="translate(39.719 39.21)">
                    <Mask fill="#fff" id="hybridcar_prefix__f">
                        <Use xlinkHref="#hybridcar_prefix__e" />
                    </Mask>
                    <Path
                        d="M11.143 4.957a4.891 4.891 0 01-4.887 4.888 4.886 4.886 0 01-4.88-4.888A4.882 4.882 0 016.255.07a4.887 4.887 0 014.887 4.887"
                        fill="#292F33"
                        mask="url(#hybridcar_prefix__f)"
                    />
                </G>
                <Path
                    d="M48.42 44.167a2.445 2.445 0 01-4.886.11v-.11a2.435 2.435 0 012.496-2.385 2.436 2.436 0 012.39 2.385"
                    fill="#CCD6DD"
                />
            </G>
        </Svg>
    );
}

export default IcHybridCar;
