/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcDieselCar({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .206h39.677v23.691H0z" id="diesel_prefix__a" />
                <Path d="M.455.068h9.34v9.477H.454z" id="diesel_prefix__c" />
                <Path d="M1.309.068h9.339v9.477h-9.34z" id="diesel_prefix__e" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M55.015 36.762c0 .654-.521 1.183-1.166 1.183h-1.17c-.646 0-1.167-.529-1.167-1.183v-7.105c0-.653.521-1.183 1.166-1.183h1.17c.646 0 1.167.53 1.167 1.183v7.105z"
                    fill="#292F33"
                />
                <G transform="translate(13 21.158)">
                    <Mask fill="#fff" id="diesel_prefix__b">
                        <Use xlinkHref="#diesel_prefix__a" />
                    </Mask>
                    <Path
                        d="M5.83 23.897h30.35c1.166 0 3.497-1.187 3.497-4.738 0-2.372 0-9.476-1.165-10.66-1.166-1.183 0-8.293-4.665-8.293h-16.34c-3.504 0-7.001 8.293-7.001 8.293L5.83 9.683s-4.664 1.182-4.664 4.733v3.56S0 18.374 0 20.288c0 1.92 1.167 2.42 1.167 2.42l4.664 1.188z"
                        fill="#3B88C3"
                        mask="url(#diesel_prefix__b)"
                    />
                </G>
                <G transform="translate(18.375 40.249)">
                    <Mask fill="#fff" id="diesel_prefix__d">
                        <Use xlinkHref="#diesel_prefix__c" />
                    </Mask>
                    <Path
                        d="M9.794 4.807c0 2.612-2.09 4.74-4.664 4.74-2.584 0-4.675-2.128-4.675-4.74C.455 2.19 2.546.067 5.13.067c2.574 0 4.664 2.123 4.664 4.74"
                        fill="#292F33"
                        mask="url(#diesel_prefix__d)"
                    />
                </G>
                <Path
                    d="M25.837 45.055c.028 1.304-.996 2.387-2.285 2.42-1.284.03-2.356-1.01-2.385-2.313v-.107c.029-1.307 1.1-2.348 2.385-2.312 1.251.023 2.257 1.043 2.285 2.312"
                    fill="#CCD6DD"
                />
                <G transform="translate(38.531 40.249)">
                    <Mask fill="#fff" id="diesel_prefix__f">
                        <Use xlinkHref="#diesel_prefix__e" />
                    </Mask>
                    <Path
                        d="M10.648 4.807c0 2.612-2.09 4.74-4.67 4.74-2.578 0-4.67-2.128-4.67-4.74 0-2.617 2.092-4.74 4.67-4.74 2.58 0 4.67 2.123 4.67 4.74"
                        fill="#292F33"
                        mask="url(#diesel_prefix__f)"
                    />
                </G>
                <Path
                    d="M46.847 45.055c.028 1.304-.996 2.387-2.285 2.42-1.29.03-2.357-1.01-2.385-2.313a.353.353 0 010-.107c.028-1.307 1.095-2.348 2.385-2.312 1.247.023 2.251 1.043 2.285 2.312"
                    fill="#CCD6DD"
                />
                <Path
                    d="M41.007 28.474c0 .65.526 1.183 1.166 1.183h4.674a1.18 1.18 0 001.166-1.183v-3.551c0-.659-.527-1.188-1.166-1.188h-4.674c-.64 0-1.166.529-1.166 1.188v3.55zm-12.837 0c0 1.183.525 1.183 1.165 1.183h8.172c.645 0 1.167-.533 1.167-1.183v-3.551c0-.659-.522-1.188-1.167-1.188h-5.834c-1.166 0-3.503 3.55-3.503 4.739z"
                    fill="#BBDDF5"
                />
                <Path
                    d="M50.345 36.17c0 .988-.782 1.776-1.75 1.776H22.918a1.76 1.76 0 01-1.749-1.775c0-.982.786-1.776 1.749-1.776h25.679c.967 0 1.75.794 1.75 1.776"
                    fill="#55ACEE"
                />
            </G>
        </Svg>
    );
}

export default IcDieselCar;
