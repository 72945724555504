/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcFlexitarian({size = 35}: Props){
    return (
        <Svg
            height={size}
            viewBox='0 0 512 512'
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M298.468 288.076h151.356c4.391 12.438 16.234 21.344 30.171 21.344 17.672 0 32-14.328 32-31.999 0-8.195-3.094-15.672-8.156-21.335 5.062-5.656 8.156-13.133 8.156-21.328 0-17.679-14.328-31.999-32-31.999-13.937 0-25.78 8.906-30.171 21.328H298.468v63.989z"
                fill="#E5E8EC"
            />
            <Path
                d="M384.138 255.859c0-102.318-384.133-227.762-384.133.227 0 227.987 384.133 102.083 384.133-.227z"
                fill="#965353"
            />
            <Path
                d="M382.778 266.53c.891-3.578 1.359-7.141 1.359-10.671 0-102.318-384.133-227.762-384.133.227 0 3.648.102 7.203.297 10.664 11.477-205.825 358.166-98.46 382.477-.22z"
                fill="#FFF"
                opacity={0.1}
            />
            <Path
                d="M277.343 320.075c0 5.891-4.781 10.672-10.679 10.672-5.891 0-10.664-4.781-10.664-10.672 0-5.89 4.773-10.655 10.664-10.655 5.898 0 10.679 4.766 10.679 10.655zM298.655 298.748c0 5.891-4.766 10.672-10.656 10.672s-10.656-4.781-10.656-10.672 4.766-10.672 10.656-10.672 10.656 4.781 10.656 10.672zM256 298.748c0 5.891-4.773 10.672-10.664 10.672-5.89 0-10.671-4.781-10.671-10.672s4.781-10.672 10.671-10.672c5.891 0 10.664 4.781 10.664 10.672z"
                fill="#7F4545"
            />
        </Svg>
    );
}

export default IcFlexitarian;
