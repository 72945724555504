/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcVegan({size = 35}: Props){
    return (
        <Svg
            className="icon"
            height={size}
            viewBox="0 0 1024 1024"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M767.253 383.637c50.539-50.538 80.214-40.49 29.675-91.093l-66.624-66.581c-50.539-50.539-61.824-20.843-112.384 29.674L144.576 671.872a129.493 129.493 0 0 0 0 183.083l23.552 24.448a129.493 129.493 0 0 0 183.083 0l416.042-495.766z"
                fill="#8BC34A"
            />
            <Path
                d="M624.619 444.736a77828.139 77828.139 0 0 0-210.24 211.797C310.315 761.835 272.192 831.66 254.74 860.16c-32.256 3.285-61.738 4.373-84.842-7.21-9.259-27.755-2.432-59.542 4.757-91.883 41.728-26.048 105.6-68.8 189.184-152.96 69.91-70.4 143.339-144.384 201.365-202.902-11.712-1.92-23.168-10.261-26.24-22.698a48.384 48.384 0 0 1-1.002-10.091L191.616 706.581c-29.141 29.163-122.71 86.038-34.155 160.79 80.448 96.256 136.32-14.336 165.504-43.456l327.126-353.131c-8.704-7.53-16.64-19.648-16.128-31.36-2.112 2.56-5.44 4.267-9.344 5.312z"
                fill="#689F38"
            />
            <Path
                d="M858.133 208.853c70.379 9.664 35.158 61.27 22.379 64.448 57.536 32.299 35.2 77.398 16 67.755 9.621 12.928 19.2 19.35 19.2 58.048 0 9.664-12.843 25.835-48 9.664 12.8 12.885 28.8 48.363 16 71.019-6.421 9.664-22.421 9.664-28.843 3.178 3.243 19.35 3.243 77.376-25.578 87.104-38.422 19.328-44.843-12.906-57.579-32.277-6.421 54.848-57.579 25.77-54.421-3.243-54.379 35.52-70.422-9.664-51.158-32.234-41.621 38.698-73.621 12.928-60.842-19.35-15.958 19.35-95.979 16.128-60.8-51.584-9.579 9.664-80 9.664-44.8-38.677-35.2 12.843-89.6-22.656-31.979-58.07-38.421 0-70.464-29.013-35.243-51.562-57.6-19.435-9.578-45.184 16-58.09-12.736-6.443-47.957-19.35 6.4-38.7-19.114-19.327 16-29.013 67.158-22.549-15.936-19.392 6.442-67.754 96.064-16.128-16-41.962 16-51.626 60.8-29.056-6.422-19.349 47.957-12.906 60.757 12.907 22.464-29.056 76.843-16.128 70.464 25.792 25.6-16.107 76.757 16.085 48.021 51.605z"
                fill="#558B2F"
            />
            <Path
                d="M720.747 206.315c-2.731-22.016-21.035-41.472-44.459-39.552-12.16 1.002-25.472 25.898-10.71 32.213-8.98-20.459-43.775-21.248-61.674-18.496-12.117 1.835-22.293 9.43-19.904 23.253 2.73 15.787 17.387 17.174 29.888 20.054-20.8-14.422-65.13-3.627-80.619 14.741-5.397 6.357-14.485 20.587-8.682 29.056 5.504 8.021 23.168 9.579 31.36 6.805-21.44 3.584-46.635 25.92-51.35 47.787-1.984 9.259-.96 20.693 9.302 23.445 9.493 2.667 30.378.384 34.282-10.88-8.533 10.795-12.544 34.411-9.258 47.787 6.208 25.088 46.826 34.923 54.485 5.632-6.656 10.667-10.24 34.688-4.352 46.187 8.085 15.872 52.01 16.917 47.424-4.672-9.088 16.298 5.952 36.821 18.688 44.992 20.31 13.141 66.752 23.573 67.69-14.592.171 21.397 111.126-142.998 94.785-137.28 14.592-1.323 33.749-17.387 25.45-34.283-9.002-18.41-44.8-22.613-58.453-9.621 10.325-7.51 38.357-31.872 20.31-45.504-15.233-11.563-44.011-7.574-54.145 8.618 11.051-10.069 26.283-22.058 25.003-39.36-.704-9.706-12.053-17.301-20.8-19.712-6.89-1.877-18.09-1.514-24.384 2.88-4.565 3.2-7.168 11.35-9.92 13.91"
                fill="#7CB342"
            />
            <Path
                d="M697.173 275.968c-16.128 8.533-23.125 22.4-8.917 36.373-16.299-9.898-77.077-.469-62.507 30.699 4.8 10.219 25.302 7.445 33.11 3.392-15.595 6.08-47.808 25.365-47.104 46.677.96 29.099 46.997 37.739 56.128 11.947-6.912 22.315-21.334 50.304-.171 70.272 25.344 23.893 68.01 7.019 78.635-21.653-11.115 24 14.08 42.624 36.245 30.784 26.581-14.294 26.453-43.136 6.037-63.446 16.96 5.675 40.768 5.376 51.712-12.245 21.206-34.176-17.877-41.941-44.16-42.027 14.123-5.248 44.502-33.45 33.707-52.778-12.992-23.339-56.427-10.155-73.75 8 7.873-9.344 20.075-23.382 12.075-32.363-18.218-20.587-31.85 8.128-31.85 28.416 0-.021-3.499-51.776-39.19-42.048z"
                fill="#33691E"
            />
            <Path
                d="M172.203 723.99c-4.672-22.529-5.419-47.723-.918-75.606l-26.709 23.51c-45.717 45.717-49.664 116.885-12.693 167.551-32.022-49.045 9.536-88.618 40.32-115.456zM303.38 847.466c-27.029 34.197-66.496 80.896-117.717 46.293 50.496 35.328 120.448 30.741 165.525-14.336l32.534-38.784a171.925 171.925 0 0 1-80.342 6.827z"
                fill="#DCEDC8"
            />
            <Path
                d="M156.224 868.01c-25.003-24.96-23.552-64.127 0-90.495 18.027-20.203 55.68-41.216 55.68-41.216s-16.107 59.008.448 75.584c17.088 17.088 74.09 1.408 73.536.426s-11.861 30.08-39.19 55.702c-29.46 27.605-65.493 24.981-90.474 0z"
                fill="#DCEDC8"
            />
        </Svg>
    );
}

export default IcVegan;
