/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const Drinks = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M11.667 28.25h10.208V13.665h-2.917c-.68 0-1.24.17-1.677.51-.437.34-.96.839-1.567 1.495a22.147 22.147 0 0 1-1.695 1.658c-.645.572-1.429.991-2.352 1.259v9.661ZM8.75 31.165V18.551a5.92 5.92 0 0 1-3.135-2.041c-.827-1.021-1.24-2.212-1.24-3.573 0-1.288.37-2.43 1.113-3.427a5.758 5.758 0 0 1 2.861-2.078 6.954 6.954 0 0 1 2.498-2.844c1.106-.73 2.35-1.094 3.736-1.094.851 0 1.647.146 2.388.438a7.77 7.77 0 0 1 2.024 1.166c.243-.048.474-.091.692-.128.22-.036.462-.054.73-.054 1.604 0 2.977.571 4.12 1.714 1.142 1.142 1.713 2.515 1.713 4.12a5.77 5.77 0 0 1-.2 1.53 5.401 5.401 0 0 1-.602 1.386h2.26c.802 0 1.49.285 2.061.856.57.572.856 1.259.856 2.06v8.75c0 .803-.285 1.49-.856 2.061a2.812 2.812 0 0 1-2.06.856h-2.917v2.917H8.75Zm-1.458-18.23c0 .803.285 1.49.857 2.06.57.572 1.257.857 2.06.857.777 0 1.44-.255 1.987-.765a32.638 32.638 0 0 0 1.695-1.714 13.464 13.464 0 0 1 2.06-1.805c.765-.546 1.768-.82 3.007-.82h4.375c0-.802-.285-1.488-.856-2.059a2.81 2.81 0 0 0-2.06-.857c-.608 0-1.118.078-1.532.236l-.62.238-1.13-.948a5.342 5.342 0 0 0-1.038-.639c-.426-.206-.93-.31-1.514-.31-.777 0-1.488.207-2.132.62a4.032 4.032 0 0 0-1.477 1.678l-.51 1.093-1.167.402a2.825 2.825 0 0 0-1.458 1.038 2.802 2.802 0 0 0-.547 1.696Zm17.5 12.397h2.916v-8.75h-2.916v8.75Z"
            fill={color}
        />
    </Svg>
);

export default Drinks;