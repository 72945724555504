// @flow
import React, {Node} from 'react';
import {Platform} from 'react-native';
import Immutable from 'seamless-immutable';
import {getMarkdownRendererStyles} from './styles';
import {useContext} from "react";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import markdownRules from "./MarkdownRules";
import Markdown from '../../../../libs/Markdown';

type Props = {
    children: Node,
    style?: Object,
    rules?: Object,
}

const EMarkdown = ({
    children,
    style = {},
    rules = {},
}: Props) => {
    const [colors, fonts] = useContext(ThemeContext);
    const baseMdStyles = getMarkdownRendererStyles(colors, fonts, style);
    const webStyles = {
        ...baseMdStyles,
        ...style,
    };

    const finalRules = Immutable.merge(markdownRules, rules, {deep: true});

    return (
        <Markdown
            mergeStyle={false}
            rules={finalRules}
            style={Platform.OS === 'web' ? webStyles : baseMdStyles}
        >
            {children}
        </Markdown>
    );
};

export default EMarkdown;