/* eslint-disable max-len */
// @flow
import React, {useContext} from "react";
import Svg, {Path} from "react-native-svg";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";

type Props = {
    color?: string,
}

const SecondaryBTop = ({color}: Props) => {
    const [colors] = useContext(ThemeContext);
    return (
        <Svg fill="none" height={312} width={197} xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M-10.174 41.616C2.711 27.926 16.868 15.64 31.523 3.9c9.946-7.96 20.153-15.578 30.94-22.371C79.18-29.014 96.77-37.801 115.467-44.255c10.214-3.529 20.664-5.989 31.472-6.703 6.322-.419 12.628-.217 18.843 1.168 11.329 2.534 19.541 9.068 24.708 19.413 3.562 7.123 5.126 14.793 5.604 22.7.568 9.238-.545 18.33-2.392 27.355-2.55 12.503-6.763 24.452-12.045 36.017-6.335 13.876-12.858 27.661-19.356 41.471-3.363 7.15-6.645 14.332-9.462 21.719-3.209 8.431-5.634 17.057-6.323 26.099-.418 5.427-.216 10.854.19 16.272.609 8.316 1.348 16.631 1.818 24.955.624 11.106-.294 22.09-3.251 32.863-3.365 12.324-9.43 23.155-18.039 32.584-6.871 7.526-14.777 13.788-23.222 19.43-14.238 9.513-29.095 17.866-45.06 24.148-23.7 9.32-48.23 14.87-73.697 16.169-15 .764-29.879-.487-44.658-2.946-6.942-1.148-13.63-3.235-20.048-6.129-16.189-7.298-27.27-19.314-33.194-36.066-3.895-11.031-5.351-22.431-5.069-34.084.381-15.963 3.878-31.306 9.316-46.258 8.059-22.158 18.957-42.849 31.917-62.505 9.77-14.815 20.453-28.945 31.722-42.634 9.883-12.008 19.986-23.828 30.602-35.2 1.273-1.37 2.652-2.652 3.974-3.975l.008.008Z"
                fill={color || colors.illustrations.yellow.light}
            />
        </Svg>
    );
};

export default SecondaryBTop;