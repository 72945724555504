// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getPartOfRedMeat} from '../../../../../Reducers/CarbonFootprintReducers/CFDietReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import CFUtils from "../../../../../Reducers/CarbonFootprintReducers/CFUtils";
import IcMeatType from "../../../../UI/Icons/CarbonFootprint/Diet/IcMeatType";
import Heading from "../../../../UI/Typos/Heading/Heading";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderLabelContainer from "../../../../Shared/ESlider/SliderLabelContainer";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
    setPartOfRedMeatType: number => void,
    partOfRedMeat: number,
};

const QMeatType = ({
    setPartOfRedMeatType,
    partOfRedMeat,
}: Props) => {
    function handleChange(value: number){
        setPartOfRedMeatType(value);
    }
    const {redMeatPartArray} = CFUtils;
    return (
        <>

            <SliderScaleMinMax
                max='Viande rouge'
                min={`Viande blanche\nou poisson`}
            />
            <ESlider
                defaultValue={partOfRedMeat}
                max={6}
                onSlidingComplete={handleChange}
                renderBelowLabel={(value) => (
                    <SliderLabelContainer>
                        <Heading centered variant='h3'>
                            {
                                redMeatPartArray?.[value]?.label ?? ''
                            }
                        </Heading>
                    </SliderLabelContainer>
                )}
                step={1}
                thumbIcon={(<SliderThumbBackground><IcMeatType /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    partOfRedMeat: getPartOfRedMeat(store),
    ...props,
});
const mapDispatchToProps = {setPartOfRedMeatType: CFActions.setPartOfRedMeatType};

export default connect(mapStateToProps, mapDispatchToProps)(QMeatType);
