/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcWeeklyMeatEater({size = 45}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .519h41.34v22.237H0z" id="weekly_meat_prefix__a" />
                <Path d="M0 27.174h41.34V0H0z" id="weekly_meat_prefix__c" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(14 21)">
                    <G transform="translate(0 4.418)">
                        <Mask fill="#fff" id="weekly_meat_prefix__b">
                            <Use xlinkHref="#weekly_meat_prefix__a" />
                        </Mask>
                        <Path
                            d="M20.67.519C9.723.519 0 4.799 0 11.636c0 5.134 4.86 9.41 12.155 9.41 4.202 0 8.647-2.576 11.828-2.576.114 0 .223 0 .336.011 4.75.286 4.86 4.276 10.944 4.276 2.846 0 6.076-2.566 6.076-8.557C41.34 6.505 32.83.52 20.67.52"
                            fill="#FFD983"
                            mask="url(#weekly_meat_prefix__b)"
                        />
                    </G>
                    <Path
                        d="M35.263 21.378c-2.766 0-3.995-.933-5.418-2.01-1.299-.99-2.776-2.099-5.423-2.265a6.441 6.441 0 00-.438-.014c-1.745 0-3.62.601-5.612 1.234-2.079.656-4.232 1.344-6.217 1.344-6.342 0-10.94-3.596-10.94-8.554C1.215 4.45 11.235.853 20.67.853c11.27 0 19.453 5.393 19.453 12.83 0 5.644-2.908 7.695-4.86 7.695"
                        fill="#A0041E"
                    />
                    <Mask fill="#fff" id="weekly_meat_prefix__d">
                        <Use xlinkHref="#weekly_meat_prefix__c" />
                    </Mask>
                    <Path
                        d="M38.909 18.53h2.43V13.58h-2.43v4.948zM0 16.054h2.429v-4.943H0v4.943z"
                        fill="#FFD983"
                        mask="url(#weekly_meat_prefix__d)"
                    />
                    <Path
                        d="M20.67 0C9.723 0 0 4.275 0 11.113c0 5.139 4.86 9.408 12.155 9.408 4.202 0 8.647-2.574 11.828-2.574.114 0 .223.006.336.01 4.75.286 4.86 4.275 10.944 4.275 2.846 0 6.076-2.564 6.076-8.549C41.34 5.981 32.83.001 20.67.001m0 1.71c10.57 0 18.24 5.032 18.24 11.971 0 5.118-2.505 6.838-3.646 6.838-2.232 0-3.146-.688-4.529-1.735-1.378-1.044-3.091-2.344-6.208-2.528-.177-.01-.36-.026-.543-.026-2 0-3.995.637-6.11 1.31-1.96.626-3.995 1.27-5.718 1.27-4.83 0-9.725-2.645-9.725-7.699 0-6.104 9.398-9.402 18.24-9.402"
                        fill="#FFE8B6"
                        mask="url(#weekly_meat_prefix__d)"
                    />
                    <Path
                        d="M18.432 1.781s3.453-.07 5.887 1.636c1.215.857.405 2.779.405 2.779l2.024.643s-.073-1.029 3.646-1.707c2.737-.5 5.527 1.707 5.527 1.707s-3.093-4.276-9.173-5.129c-6.079-.856-8.316.071-8.316.071"
                        fill="#FFE8B6"
                        mask="url(#weekly_meat_prefix__d)"
                    />
                    <Path
                        d="M29.179 8.55c0 1.89-2.179 3.421-4.86 3.421-2.688 0-4.865-1.53-4.865-3.422 0-1.886 2.177-3.416 4.865-3.416 2.681 0 4.86 1.53 4.86 3.416"
                        fill="#E6E7E8"
                        mask="url(#weekly_meat_prefix__d)"
                    />
                    <Path
                        d="M26.749 8.55c0 .942-1.09 1.71-2.43 1.71-1.343 0-2.435-.768-2.435-1.71 0-.944 1.092-1.712 2.436-1.712 1.338 0 2.429.768 2.429 1.711"
                        fill="#A7A9AC"
                        mask="url(#weekly_meat_prefix__d)"
                    />
                </G>
            </G>
        </Svg>
    );
}

export default IcWeeklyMeatEater;
