// @flow
import {LinearGradient} from 'expo-linear-gradient';
import React, {Node, useContext} from 'react';
import {Appearance, Image, StyleSheet, TouchableOpacity, View} from 'react-native';
import app_qr_code from '../assets/images/web/app_qr_code.png';
import energicLogoDark from '../assets/images/web/energic_blue.png';
import energicLogoLight from '../assets/images/web/energic_white.png';
import BasicCard from '../Components/UI/Cards/BasicCard/BasicCard';
import WebAppBottomLeft from '../Components/UI/Ornaments/WebAppBottomLeft';
import WebAppBottomRight from '../Components/UI/Ornaments/WebAppBottomRight';
import WebAppTopLeft from '../Components/UI/Ornaments/WebAppTopLeft';
import WebAppTopRight from '../Components/UI/Ornaments/WebAppTopRight';
import Heading from '../Components/UI/Typos/Heading/Heading';
import Config from '../Config/Config';
import {ThemeContext} from '../libs/Hoc/ThemeProvider';
import {openLink} from '../libs/Utils/LinkUtils';

type Props = {
    children: Node,
};

const styles = StyleSheet.create({
    backgroundStyle: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: '100%',
        justifyContent: 'space-evenly',
    },
    bottomLeftOrnament: {
        bottom: 0,
        left: 0,
        position: 'absolute',
    },
    bottomRightOrnament: {
        bottom: 0,
        position: 'absolute',
        right: 0,
    },
    phoneContainer: {
        backgroundColor: 'white',
        display: 'flex',
        height: Config.DEVICE_HEIGHT,
        overflow: 'hidden',
        width: Config.DEVICE_WIDTH,
        zIndex: 1,
    },
    phoneFrame: {
        borderColor: 'white',
        borderRadius: 32,
        borderStyle: 'solid',
        borderWidth: 2,
    },
    topLeftOrnament: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
    topRightOrnament: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    webEnergicLogo: {
        height: 0.25 * Config.WINDOW_HEIGHT,
        resizeMode: 'contain',
        width: 0.25 * Config.WINDOW_HEIGHT,
        zIndex: 0,
    },
    webEnergicLogoContainer: {
        alignItems: 'center',
        width: '30%',
    },
    webQrCode: {
        height: 0.2 * Config.WINDOW_HEIGHT,
        marginHorizontal: 'auto',
        resizeMode: 'contain',
        width: 0.2 * Config.WINDOW_HEIGHT,
        zIndex: 0,
    },
});

const WebAppDecorator = ({children}: Props) => {
    const [colors,, spacing, colorScheme] = useContext(ThemeContext);
    const isMobileScreen = Config.DEVICE_HEIGHT < Config.mobileScreenLimits.height
        && Config.DEVICE_WIDTH < Config.mobileScreenLimits.width;
    const isLittleScreen = Config.WINDOW_HEIGHT < 600 || Config.WINDOW_WIDTH < 900;

    const renderLeftImage = () => !isMobileScreen && !isLittleScreen ? (
        <View
            style={styles.webEnergicLogoContainer}
        >
            <TouchableOpacity
                onPress={() => openLink('https://energic.io')}
            >
                <BasicCard customStyle={{backgroundColor: colors.background.gradient_start}}>
                    <Image
                        source={{
                            uri: colorScheme === 'light'
                            || (colorScheme === 'default' && Appearance.getColorScheme() === 'light')
                                ? energicLogoDark
                                : energicLogoLight,
                        }}
                        style={styles.webEnergicLogo}
                    />
                </BasicCard>
            </TouchableOpacity>
        </View>
    ) : null;

    const renderRightImage = () => !isMobileScreen && !isLittleScreen ? (
        <View
            style={{
                width: '30%',
                minWidth: 300,
                alignItems: 'center',
                zIndex: 0,
            }}
        >
            <BasicCard customStyle={{backgroundColor: colors.background.gradient_start}}>
                <Heading centered customStyle={{marginBottom: spacing[2]}} variant='h1'>
                    {`Télécharge\nl'app Energic !`}
                </Heading>
                <Image
                    source={{uri: app_qr_code}}
                    style={styles.webQrCode}
                />
            </BasicCard>
        </View>
    ) : null;

    return (
        <LinearGradient
            colors={[
                colors.background.gradient_start,
                colors.background.gradient_end,
            ]}
            end={{x: 0, y: 1}}
            locations={[0, 1]}
            start={{x: 0, y: 0.001}}
            style={styles.backgroundStyle}
        >
            {
                !isMobileScreen && !isLittleScreen ? (
                    <>
                        <View style={styles.topLeftOrnament}>
                            <WebAppTopLeft />
                        </View>
                        <View style={styles.topRightOrnament}>
                            <WebAppTopRight />
                        </View>
                        <View style={styles.bottomLeftOrnament}>
                            <WebAppBottomLeft />
                        </View>
                        <View style={styles.bottomRightOrnament}>
                            <WebAppBottomRight />
                        </View>
                    </>
                ) : null
            }
            {renderLeftImage()}
            <View
                style={[styles.phoneContainer, !isMobileScreen && styles.phoneFrame]}
            >
                {children}
            </View>
            {renderRightImage()}
        </LinearGradient>
    );
};

export default WebAppDecorator;