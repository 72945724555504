// @flow
import type {Immutable as ImmutableType} from 'seamless-immutable';
import Immutable from 'seamless-immutable';
import type {Store} from '../../Types/Reducers/Store';
import type {ActionP} from '../../Types/Reducers/Action';
import ReducerUtils from '../ReducerUtils';
import CarbonFootprintActions from '../../Actions/CarbonFootprintActions';
import CFUtils from './CFUtils';
import type {UserCFAnswersType, UserEnergyCFAnswersType} from "../../Types/CarbonFootprint/FootprintApiTypes";
import type {EnergyType, EnergyTypeSpendings} from "../../Types/CarbonFootprint/FootprintQuestionsTypes";
import type {FootprintDetailPartType} from "../../Types/CarbonFootprint/FootprintTypes";

export type CFEnergyReducerState = ImmutableType<{
    nbPeopleInAccomodation: number,
    energyTypes: Array<EnergyType>,
    monthlyEnergySpendings: Array<EnergyTypeSpendings>,
    lastAnswers: UserEnergyCFAnswersType,
}>;

const INITIAL_STATE: CFEnergyReducerState = Immutable({
    nbPeopleInAccomodation: 1,
    energyTypes: [],
    monthlyEnergySpendings: [],
});

const setNbPeopleInAccomodation = (state: CFEnergyReducerState, action: ActionP<number>) => Immutable.merge(
    state,
    {nbPeopleInAccomodation: action?.payload},
);

const setEnergyTypes = (state: CFEnergyReducerState, action: ActionP<Array<EnergyType>>) => {
    const {payload: energyTypes} = action;
    const {monthlyEnergySpendings} = state;
    const updatedSpending = monthlyEnergySpendings.filter(sp => energyTypes.includes(sp.energy));
    return Immutable.merge(
        state, {
            energyTypes,
            monthlyEnergySpendings: updatedSpending,
        });
};

const setMonthlyEnergySpendings = (
    state: CFEnergyReducerState,
    action: ActionP<Array<EnergyTypeSpendings>>
) => Immutable.merge(
    state,
    {monthlyEnergySpendings: action?.payload},
);

const resetCFReducer = (state: CFEnergyReducerState) => Immutable.merge(state, INITIAL_STATE);

const setUserLastCFAnswers = (state: CFEnergyReducerState, action: ActionP<{answers: UserCFAnswersType}>) => {
    return Immutable.merge(
        state,
        {
            nbPeopleInAccomodation: action?.payload?.answers?.energy?.nbPeopleInAccomodation ?? 1,
            energyTypes: action?.payload?.answers?.energy?.energyTypes?.map(e => e.answerType) ?? [],
            monthlyEnergySpendings: action?.payload?.answers?.energy?.energyTypes?.map(e => ({
                energy: e.answerType,
                value: e.value,
            })) ?? [],
            lastAnswers: action?.payload?.answers?.energy,
        },
    );
};

export default ReducerUtils.createReducer(
    INITIAL_STATE,
    {
        [CarbonFootprintActions.Types.SET_NB_PEOPLE_IN_ACCOMODATION]: setNbPeopleInAccomodation,
        [CarbonFootprintActions.Types.SET_ENERGY_TYPES]: setEnergyTypes,
        [CarbonFootprintActions.Types.SET_MONTHLY_ENERGY_SPENDINGS]: setMonthlyEnergySpendings,
        [CarbonFootprintActions.Types.RESET_FOOTPRINT_REDUCERS]: resetCFReducer,
        [CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS_SUCCESS]: setUserLastCFAnswers,
    },
);

export const getState = (store: Store): CFEnergyReducerState => store.carbonFootprint.energy;
export const getNbPeopleInAccomodation = (store: Store): number => getState(store).nbPeopleInAccomodation;
export const getLastNbPeopleInAccomodation = (store: Store): number =>
    getState(store).lastAnswers?.nbPeopleInAccomodation || 1;
export const getEnergyTypes = (store: Store): Array<EnergyType> => getState(store).energyTypes;
export const getMonthlyEnergySpendings = (store: Store): Array<EnergyTypeSpendings> =>
    getState(store).monthlyEnergySpendings;

const getCalculatedgCO2EnergyConsumptions = (energySpendings: Array<EnergyTypeSpendings>): number => {
    const emissions = energySpendings.map((spending) => {
        const {value, energy} = spending;
        const factor = CFUtils?.energyConsumptions[energy];
        const {conversionFactor, emissionFactor} = factor;
        return CFUtils?.NB_MONTHS * value * conversionFactor * emissionFactor;
    });
    return emissions.reduce((acc, elm) => acc + elm, 0);
};

const getgCO2EnergyConsumptions = (store: Store): number => {
    const energySpendings = getMonthlyEnergySpendings(store);
    return getCalculatedgCO2EnergyConsumptions(energySpendings);
};

const getLastgCO2EnergyConsumptions = (store: Store): number => {
    const lastAnswers = getState(store).lastAnswers;
    if(!lastAnswers) return 0;
    const energySpendings = (lastAnswers.energyTypes || []).map(r => ({...r, energy: r.answerType}));
    return getCalculatedgCO2EnergyConsumptions(energySpendings);
};

export const getgCO2EnergyImpact = (store: Store): number =>
    (getgCO2EnergyConsumptions(store) + CFUtils?.houseConstructionImpactPerYear) / getNbPeopleInAccomodation(store);

export const getTCO2EnergyImpact = (store: Store): number =>
    getgCO2EnergyImpact(store) / CFUtils?.gCO2toTCO2Ratio;

export const getKgCO2EnergiesImpact = (store: Store): number =>
    getgCO2EnergyConsumptions(store) / CFUtils?.gCO2toKgCO2Ratio;

export const getCO2IndividualEnergiesImpact = (store: Store): number => {
    const energyCons = getgCO2EnergyConsumptions(store);
    const nbPeople = getNbPeopleInAccomodation(store);
    return energyCons / nbPeople;
};

export const getgCO2IndividualHouseConstructionImpact = (store: Store): number => {
    const nbPeople = getNbPeopleInAccomodation(store);
    return CFUtils?.houseConstructionImpactPerYear / nbPeople;
};

export const getEnergyDetailedParts = (store: Store): Array<FootprintDetailPartType> => [
    {
        title: 'Consommations d\'énergies',
        value: (getCO2IndividualEnergiesImpact(store) / CFUtils?.gCO2toKgCO2Ratio).toFixed(1), // convert to kg
        key: 'EnergyConsumption',
    },
    {
        title: 'Construction du logement',
        value: (getgCO2IndividualHouseConstructionImpact(store) / CFUtils?.gCO2toKgCO2Ratio).toFixed(1),
        key: 'HouseConstruction',
    },
].sort((a, b) => b.value - a.value);