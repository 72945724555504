// @flow
import type {Store as TypeStore} from "../../../../../Types/Reducers/Store";
import appRoutes from "../../../../../Navigation/AppRoutes";
import {connect} from "react-redux";
import CFQuestions from "../../CFQuestion/CFQuestions";
import ConsumptionQuestionsMap from "./ConsumptionQuestionsMap";

const mapStateToProps = (store: TypeStore, props: Object) => ({
    questions: ConsumptionQuestionsMap,
    store,
    userFirstName: '',
    onPressBefore: () => props.navigation.navigate(appRoutes.cf.consumption.thematic),
    onCompleteThematic: () => props.navigation.navigate(appRoutes.cf.public_services.thematic),
    comesFromGoBack: props?.route?.params?.back ?? false,
    ...props,
});

export default connect(mapStateToProps)(CFQuestions);