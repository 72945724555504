// @flow
import React, {useContext, useMemo} from "react";
import type {Node} from 'react';
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import Touchable from '../../../Buttons/Touchable/Touchable';
import {ButtonVariantType} from "../../../Buttons/Button/Button";

type Props = {
    children: Node,
    customStyle?: Object,
    onPress: any => any,
    size?: number,
    disabled?: boolean,
    inactive?: boolean,


    variant?: ButtonVariantType,
    color?: string,
};

const ActionIcon = ({
    children,
    customStyle = {},
    onPress,
    size = 32,
    disabled = false,
    inactive = false,

    variant = 'contained',
    color,
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);

    const defaultColor = useMemo(() => color ?? colors.action, [color]);

    const finalColor = useMemo(
        () => disabled
            ? colors.background.disabled
            : defaultColor,
        [inactive, disabled, defaultColor]
    );

    return (
        <Touchable
            onPress={disabled ? null : onPress}
            style={({pressed}) => ({
                width: size,
                height: size,
                borderRadius: spacing[1],
                alignItems: 'center',
                justifyContent: 'center',

                backgroundColor: variant === 'contained' ? finalColor : 'transparent',
                borderWidth: 0.5,
                borderColor: finalColor,

                // ...boxStyle,
                ...customStyle,
                opacity: (pressed && !disabled) ? 0.7 : 1,
            })}
        >
            {children}
        </Touchable>
    );
};

export default ActionIcon;