// @flow
/* eslint-disable max-len */
import {combineReducers} from 'redux';
import type {Immutable as ImmutableType} from 'seamless-immutable';
import Immutable from 'seamless-immutable';
import CFTransportReducer, {
    getMobilityDetailedParts,
    getNbPeopleInCar,
    getNbYearlyPlaneHours,
    getTCO2TransportImpact,
    getWeeklyMeansOfTransport,
    getgCO2TransportImpact,
} from './CarbonFootprintReducers/CFTransportReducer';
import CFDietReducer, {
    getDietDetailedParts,
    getDietType,
    getDrinkTypesQuantities,
    getPartOfLocalConsumption,
    getRealPartOfRedMeat,
    getTCO2DietImpact,
    getgCO2DietImpact,
} from './CarbonFootprintReducers/CFDietReducer';
import CFConsumptionReducer, {
    getConsumptionDetailedParts,
    getDailyMailSending,
    getDailyStreamingVideoHours,
    getMonthlyNewProductsSpendings,
    getTCO2ConsumptionImpact,
    getgCO2ConsumptionImpact,
    getDailyNbPlasticBottles,
    getMonthlyTrashBags,
    getMonthlyRecyclableTrashBags,
    getTechnologyPurchases,
    getAppliancesPurchases,
} from './CarbonFootprintReducers/CFConsumptionReducer';
import CFEnergyReducer, {
    getEnergyDetailedParts,
    getMonthlyEnergySpendings,
    getNbPeopleInAccomodation,
    getTCO2EnergyImpact,
    getgCO2EnergyImpact,
} from './CarbonFootprintReducers/CFEnergyReducer';
import ReducerUtils from './ReducerUtils';
import CarbonFootprintActions from '../Actions/CarbonFootprintActions';
import CFUtils from './CarbonFootprintReducers/CFUtils';
import type {CFTransportReducerState} from './CarbonFootprintReducers/CFTransportReducer';
import type {CFDietReducerState} from './CarbonFootprintReducers/CFDietReducer';
import type {CFConsumptionReducerState} from './CarbonFootprintReducers/CFConsumptionReducer';
import type {CFEnergyReducerState} from './CarbonFootprintReducers/CFEnergyReducer';
import type {Store} from '../Types/Reducers/Store';
import type {
    DetailedCarbonFootprint,
    UserCarbonFootprintHistoryType,
} from '../Types/CarbonFootprint/FootprintHistoryTypes';
import type {CarbonFootprintPayloadType, UserCFAnswersType} from '../Types/CarbonFootprint/FootprintApiTypes';
import type {ActionP} from '../Types/Reducers/Action';
import type {FetchStatus} from '../Types/Reducers/FetchStatus';
import type {PostStatus} from '../Types/Reducers/PostStatus';
import type {FootprintId} from '../Types/Types';
import {SortedFootprintSections} from "../Types/CarbonFootprint/FootprintHistoryTypes";
import type {DeleteStatus} from "../Types/Reducers/DeleteStatus";
import moment from "moment";
import type {
    CarbonFootprintSectionType,
    CarbonMassType,
    ClientAverageCarbonMassType,
    FootprintSectionString,
    UserSavedCo2,
} from "../Types/CarbonFootprint/FootprintTypes";

type CFHistoryReducerState = ImmutableType<{
    user: ?UserCarbonFootprintHistoryType,
    userFetchStatus: FetchStatus,
    client: ?ClientAverageCarbonMassType,
    clientFetchStatus: FetchStatus,
    userSavedCo2: ?UserSavedCo2,
    fetchUserSavedCo2Status: FetchStatus,
    postUserCarbonFootprintStatus: PostStatus,
    tribe: ?CarbonMassType,
    tribeFetchStatus: FetchStatus,

    deleteFootprintStatus: DeleteStatus,
    lastCFAnswers: UserCFAnswersType,
    lastCFAnswersFetchStatus: FetchStatus,

    isSimulating: boolean,
    isUpdating: boolean,
}>;

const INITIAL_STATE: CFHistoryReducerState = Immutable({
    user: null,
    userFetchStatus: ReducerUtils.createFetchStatus(),
    client: null,
    clientFetchStatus: ReducerUtils.createFetchStatus(),
    userSavedCo2: null,
    fetchUserSavedCo2Status: ReducerUtils.createFetchStatus(),
    postUserCarbonFootprintStatus: ReducerUtils.createPostStatus(),
    tribe: null,
    tribeFetchStatus: ReducerUtils.createFetchStatus(),

    deleteFootprintStatus: ReducerUtils.createDeleteStatus(),
    lastCFAnswers: null,
    lastCFAnswersFetchStatus: ReducerUtils.createFetchStatus(),

    isSimulating: false,
    isUpdating: false,
});

export type CarbonFootprintReducerState = {
    transport: CFTransportReducerState,
    diet: CFDietReducerState,
    energy: CFEnergyReducerState,
    consumption: CFConsumptionReducerState,
    history: CFHistoryReducerState,
};

const setUserCarbonFootprintHistory = (state: CFHistoryReducerState, action: ActionP<UserCarbonFootprintHistoryType>) => {
    const {payload} = action;

    return Immutable.merge(
        state,
        {user: payload},
    );
};

const setClientAverageCarbonFootprintHistory = (state: CFHistoryReducerState, action: ActionP<CarbonMassType>) => {
    const {payload} = action;

    return Immutable.merge(
        state,
        {client: payload},
    );
};

const setTribeAverageCarbonFootprintHistory = (state: CFHistoryReducerState, action: ActionP<CarbonMassType>) => {
    const {payload} = action;

    return Immutable.merge(
        state,
        {tribe: payload},
    );
};

const deleteFootprint = (state: CFHistoryReducerState, action: ActionP<{footprintId: FootprintId}>) => {
    const {footprintId} = action.payload;
    const formerUserHistory = state?.user?.history ?? [];
    if(!formerUserHistory) return state;
    const newUserHistory = formerUserHistory.filter(footprint => footprint.footprintId !== footprintId);
    return Immutable.merge(
        state,
        {user: {history: newUserHistory}},
    );
};

const addUserCarbonFootprintHistory = (state: CFHistoryReducerState, action: ActionP<{footprint: UserCarbonFootprintHistoryType}>) => {
    const {payload} = action;
    const {footprint} = payload;
    const formerUserHistory = state?.user?.history;
    const newUserHistory = Array.isArray(formerUserHistory) ? [footprint, ...formerUserHistory] : [footprint];
    return Immutable.merge(
        state,
        {user: {history: newUserHistory}},
    );
};

const addUserSavedCo2 = (state: CFHistoryReducerState, action: ActionP<UserSavedCo2>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {userSavedCo2: payload},
    );
};

const setSimulating = (state: CFHistoryReducerState, action: ActionP<boolean>) => {
    const {payload: simulating} = action;
    return Immutable.merge(
        state,
        {isSimulating: simulating},
    );
};

const setUpdating = (state: CFHistoryReducerState, action: ActionP<boolean>) => {
    const {payload: updating} = action;
    return Immutable.merge(
        state,
        {isUpdating: updating},
    );
};

const setUserLastCFAnswers = (state: CFHistoryReducerState, action: ActionP<{answers: UserCFAnswersType}>) => {
    return Immutable.merge(
        state,
        {lastCFAnswers: action?.payload?.answers ?? null},
    );
};


export const CFHistoryReducer = ReducerUtils.createReducer(
    INITIAL_STATE,
    {
        [CarbonFootprintActions.Types.FETCH_USER_CARBON_FOOTPRINT_HISTORY_SUCCESS]: setUserCarbonFootprintHistory,
        [CarbonFootprintActions.Types.FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY_SUCCESS]: setClientAverageCarbonFootprintHistory,
        [CarbonFootprintActions.Types.POST_USER_CARBON_FOOTPRINT_SUCCESS]: addUserCarbonFootprintHistory,
        [CarbonFootprintActions.Types.FETCH_USER_SAVED_CO2_SUCCESS]: addUserSavedCo2,
        [CarbonFootprintActions.Types.FETCH_TRIBE_AVERAGE_FOOTPRINT_SUCCESS]: setTribeAverageCarbonFootprintHistory,
        [CarbonFootprintActions.Types.DELETE_FOOTPRINT_SUCCESS]: deleteFootprint,
        [CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS_SUCCESS]: setUserLastCFAnswers,
        [CarbonFootprintActions.Types.SET_SIMULATING]: setSimulating,
        [CarbonFootprintActions.Types.SET_UPDATING]: setUpdating,
    },
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.FETCH_USER_CARBON_FOOTPRINT_HISTORY, () => ['userFetchStatus']),
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.FETCH_USER_SAVED_CO2, () => ['fetchUserSavedCo2Status']),
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.FETCH_CLIENT_AVERAGE_CARBON_FOOTPRINT_HISTORY, () => ['clientFetchStatus']),
    ReducerUtils.handlePostStatus(CarbonFootprintActions.Types.POST_USER_CARBON_FOOTPRINT, () => ['postUserCarbonFootprintStatus']),
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.FETCH_TRIBE_AVERAGE_FOOTPRINT, () => ['tribeFetchStatus']),
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.DELETE_FOOTPRINT, () => ['deleteFootprintStatus']),
    ReducerUtils.handleFetchStatus(CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS, () => ['lastCFAnswersFetchStatus']),
);

const CarbonFootprintReducer = combineReducers({
    transport: CFTransportReducer,
    diet: CFDietReducer,
    energy: CFEnergyReducer,
    consumption: CFConsumptionReducer,
    history: CFHistoryReducer,
});

export const getTCO2Footprint = (store: Store): number => {
    const irreduciblePublicServicesImpact = CFUtils.irreduciblePublicServicesImpact / 1000000;
    const transportFootprint = getTCO2TransportImpact(store);
    const dietFootprint = getTCO2DietImpact(store);
    const consumptionFootprint = getTCO2ConsumptionImpact(store);
    const energyFootprint = getTCO2EnergyImpact(store);
    return transportFootprint + dietFootprint + consumptionFootprint + energyFootprint + irreduciblePublicServicesImpact;
};

export const getUserCarbonFootprintHistory = (store: Store): ?UserCarbonFootprintHistoryType => store.carbonFootprint.history.user;
export const getUserLastFootprint = (store: Store): DetailedCarbonFootprint => getUserCarbonFootprintHistory(store)?.history?.[0];
export const getUserFootprintById = (store: Store, footprintId: FootprintId): ?DetailedCarbonFootprint => {
    const history = getUserCarbonFootprintHistory(store);
    return history.history.find(cf => cf.footprintId === footprintId);
};

export const getDifferenceWithLastFootprint = (store: Store): ?Object<{difference: number, evol: 'greater' | 'lesser' | 'equals', date: string}> => {
    const currentFootprint = getUserCarbonFootprintHistory(store)?.history?.[0];
    const previousFootprint = getUserCarbonFootprintHistory(store)?.history?.[1];
    if(!currentFootprint || !previousFootprint) return null;
    const currentTons = parseFloat(currentFootprint?.totalCarbon?.split(' ')?.[0]);
    const previousTons = parseFloat(previousFootprint?.totalCarbon?.split(' ')?.[0]);
    const difference = parseFloat((currentTons - previousTons).toFixed(2));
    const evol = (difference > 0)
        ? "greater"
        : difference === 0
            ? "equals"
            : "lesser";
    return {
        difference,
        evol,
        date: moment(previousFootprint?.date).format('DD/MM/YYYY'),
    };
};

export const getSortedCurrentFootprintSections = (store: Store): SortedFootprintSections => {
    const currentFootprint = getUserCarbonFootprintHistory(store)?.history?.[0];
    if (!currentFootprint) return [];
    return CFUtils.sortFootprintSections(currentFootprint);
};

export const getUserCFHistoryFetchStatus = (store: Store): FetchStatus => store.carbonFootprint.history.userFetchStatus;
export const getUserSavedCo2 = (store: Store): ?UserSavedCo2 => store.carbonFootprint.history.userSavedCo2;
export const getClientAverageCarbonFootprintHistory = (store: Store): ?ClientAverageCarbonMassType => store.carbonFootprint.history.client;
export const getTribeAverageFootprint = (store: Store): ?CarbonMassType => store.carbonFootprint.history.tribe;
export const getClientAverageCFHistoryFetchStatus = (store: Store): FetchStatus => store.carbonFootprint.history.clientFetchStatus;
export const getFootprintDeleteStatus = (store: Store): DeleteStatus => store.carbonFootprint.history.deleteFootprintStatus;

export const getDietAnswersPayload = (store: Store): Object => ({
    diet: {
        diet: getDietType(store),
        localDiet: getPartOfLocalConsumption(store),
        looseConsumption: 0,
        redMeatPercentage: getRealPartOfRedMeat(store),
        drinks: getDrinkTypesQuantities(store).map(dt => ({answerType: dt.drinkType, value: dt.quantity})),
    },
});

export const getMobilityAnswersPayload = (store: Store): Object => ({
    mobility: {
        meansOfTransport: getWeeklyMeansOfTransport(store).map(mean => ({answerType: mean.mean, value: mean.value})),
        nbPeopleInCar: getNbPeopleInCar(store),
        nbPlaneHours: getNbYearlyPlaneHours(store),
    },
});

export const getEnergyAnswersPayload = (store: Store): Object => ({
    energy: {
        nbPeopleInAccomodation: getNbPeopleInAccomodation(store),
        energyTypes: getMonthlyEnergySpendings(store).map(et => ({answerType: et.energy, value: et.value})),
    },
});

export const getConsumptionAnswersPayload = (store: Store): Object => ({
    consumption: {
        monthlyTrashBags: getMonthlyTrashBags(store),
        monthlyRecyclableTrashBags: getMonthlyRecyclableTrashBags(store),
        nbWeeklyPlasticBottles: getDailyNbPlasticBottles(store),
        technologyPurchases: ReducerUtils.mapToArray(
            getTechnologyPurchases(store))?.map(tp => ({answerType: tp.value, value: tp.count})
        ),
        appliancesPurchases: ReducerUtils.mapToArray(
            getAppliancesPurchases(store))?.map(ap => ({answerType: ap.value, value: ap.count})
        ),
        monthlyNewProductsSpendings: getMonthlyNewProductsSpendings(store),
        dailyMailSending: getDailyMailSending(store),
        dailyStreamingVideoHours: getDailyStreamingVideoHours(store),
    },
});

export const getPureFootprintPayload = (store: Store): Object => ({
    footprint: {
        diet: getgCO2DietImpact(store) / 1000,
        mobility: getgCO2TransportImpact(store) / 1000,
        energy: getgCO2EnergyImpact(store) / 1000,
        consumptionsAndDigital: getgCO2ConsumptionImpact(store) / 1000,
        publicServices: CFUtils.irreduciblePublicServicesImpact / 1000,
    },
});


export const getUserPostUserCarbonFootprintStatus = (store: Store): PostStatus => store.carbonFootprint.history.postUserCarbonFootprintStatus;

export const getIsSimulatingCF = (store: Store): boolean => store.carbonFootprint.history.isSimulating;
export const getIsUpdatingCF = (store: Store): boolean => store.carbonFootprint.history.isUpdating;

export const getLastCFAnswers = (store: Store): ?UserCFAnswersType => store.carbonFootprint.history.lastCFAnswers;
export const getLastCFAnswersFetchStatus = (store: Store): FetchStatus => store.carbonFootprint.history.lastCFAnswersFetchStatus;

export const getCategoryDetailedParts = (store: Store, category: FootprintSectionString): Array<{title: string, value: string}> => {
    switch (category){
        case "energy":
            return getEnergyDetailedParts(store);
        case "consumptionsAndDigital":
            return getConsumptionDetailedParts(store);
        case "consumptionsanddigital":
            return getConsumptionDetailedParts(store);
        case "diet":
            return getDietDetailedParts(store);
        case "mobility":
            return getMobilityDetailedParts(store);
        default:
            return [];
    }
};

export const getUserCFPayloadWithPartialInput = (store: Store, key): CarbonFootprintPayloadType => {
    let categoryData;
    switch (key.toLowerCase()){
        case "mobility":
            categoryData = getMobilityAnswersPayload(store);
            break;
        case "diet":
            categoryData = getDietAnswersPayload(store);
            break;
        case "consumptionsanddigital":
            categoryData = getConsumptionAnswersPayload(store);
            break;
        case "energy":
            categoryData = getEnergyAnswersPayload(store);
            break;
        default:
            break;
    }
    return {
        ...getPureFootprintPayload(store),
        answers: {
            ...getLastCFAnswers(store),
            ...categoryData,
        },
    };
};

export const getUserCarbonFootprintPayload = (store: Store): CarbonFootprintPayloadType => ({
    ...getPureFootprintPayload(store),
    answers: {
        ...getLastCFAnswers(store),
        ...getDietAnswersPayload(store),
        ...getMobilityAnswersPayload(store),
        ...getEnergyAnswersPayload(store),
        ...getConsumptionAnswersPayload(store),
    },
});



/**
 * Function specific to Standalone Carbon Footprint.
 * Used to create a CarbonFootprintSectionType from footprint payload
 * @param sectionFootprint footprint of the section in kgCO2eq
 * @param totalCarbon total footprint of cumulated sections in kgCO2eq
 * @returns {{}}
 */
export const createCFSectionTypeFromPayloadType = (
    sectionFootprint: number,
    totalCarbon: number,
): CarbonFootprintSectionType => {
    const carbonMass = (sectionFootprint / CFUtils.gCO2toKgCO2Ratio).toFixed(2) + ' t'; //make it tons
    const percentageShare = (sectionFootprint * 100 / totalCarbon).toFixed(2) + ' %';
    return {
        carbonMass,
        percentageShare,
    };
};

/**
 * Function specific to Standalone Carbon Footprint.
 * Used to simulate the footprint POST and get the object type usually returned by backend on success.
 * @param store
 * @returns {{date: Date, footprintId: number}}
 */
export const getStandaloneDetailedFootprint = (store: Store): DetailedCarbonFootprint => {
    const footprintPayload: CarbonFootprintPayloadType = getUserCarbonFootprintPayload(store);
    const totalFootprint = footprintPayload.footprint.diet
        + footprintPayload.footprint.mobility
        + footprintPayload.footprint.energy
        + footprintPayload.footprint.publicServices
        + footprintPayload.footprint.consumptionsAndDigital;
    return {
        footprintId: 1,
        date: Date.parse(new Date().toString()),
        diet: createCFSectionTypeFromPayloadType(footprintPayload.footprint.diet, totalFootprint),
        mobility: createCFSectionTypeFromPayloadType(footprintPayload.footprint.mobility, totalFootprint),
        energy: createCFSectionTypeFromPayloadType(footprintPayload.footprint.energy, totalFootprint),
        consumptionsAndDigital: createCFSectionTypeFromPayloadType(footprintPayload.footprint.consumptionsAndDigital, totalFootprint),
        publicServices: createCFSectionTypeFromPayloadType(footprintPayload.footprint.publicServices, totalFootprint),
        totalCarbon: (totalFootprint / CFUtils.gCO2toKgCO2Ratio).toFixed(2) + ' t',
    };
};



export default CarbonFootprintReducer;
