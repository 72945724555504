/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const ConsumptionAndDigital = ({
    color = theme.colors.light.text.white,
    size = 36,
}: Props) => (
    <Svg fill="none" height={size} viewBox="0 0 36 36" width={size} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M29.167 32.624V10.75c0-.802-.286-1.488-.856-2.059a2.81 2.81 0 0 0-2.061-.857h-1.458v1.896a.989.989 0 0 1-.292.729.989.989 0 0 1-.73.291h-8.166c-.34 0-.613-.182-.82-.546-.207-.365-.225-.717-.055-1.058L17.5 3.166c.17-.365.42-.65.748-.857a2.11 2.11 0 0 1 1.148-.31h3.354c.583 0 1.07.22 1.458.657.39.437.584.948.584 1.53v.73h1.458c1.604 0 2.977.571 4.12 1.714 1.142 1.142 1.713 2.515 1.713 4.12v21.874h-2.916ZM18.52 7.833h3.354V4.916h-2.042l-1.312 2.917ZM7.29 32.624c-1.239 0-2.278-.42-3.117-1.258-.838-.838-1.257-1.877-1.257-3.117v-3.645c0-.802.267-1.55.802-2.243a3.255 3.255 0 0 1 2.114-1.257V18.04c0-.802.286-1.489.858-2.06a2.807 2.807 0 0 1 2.059-.857h11.667c.802 0 1.489.286 2.06.856.571.572.856 1.259.856 2.061v3.063c.875.145 1.58.546 2.115 1.203.535.656.802 1.422.802 2.297v3.645c0 1.24-.419 2.279-1.257 3.117-.84.839-1.878 1.258-3.118 1.258H7.292ZM8.75 18.041v3.646c.438.364.79.796 1.057 1.293.268.5.401 1.04.401 1.624v.729h8.75v-.73c0-.583.134-1.124.401-1.623.268-.497.62-.928 1.058-1.293V18.04H8.75ZM7.292 29.708h14.583c.438 0 .79-.152 1.057-.455.268-.305.401-.639.401-1.004v-3.645a.718.718 0 0 0-.2-.53.719.719 0 0 0-.529-.2.719.719 0 0 0-.53.2.718.718 0 0 0-.199.53v3.645H7.292v-3.645a.719.719 0 0 0-.2-.53.719.719 0 0 0-.53-.2.714.714 0 0 0-.527.2.716.716 0 0 0-.202.53v3.645c0 .365.134.7.401 1.004.268.303.62.455 1.058.455Z"
            fill={color}
        />
    </Svg>
);

export default ConsumptionAndDigital;