// @flow
import React, {useContext} from "react";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import Heading, {HeadingVariant} from '../../Typos/Heading/Heading';
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import Touchable from '../Touchable/Touchable';
import {ActivityIndicator} from "react-native";

export type ButtonVariantType = 'contained' | 'outlined';

type Props = {
    text: string,
    onPress: any => any,
    variant?: ButtonVariantType,
    disabled?: boolean,
    loading?: boolean,
    customStyle?: ViewStyleProp,
    fixedWidth?: boolean,
    headingVariant?: HeadingVariant,
};

const Button = ({
    text,
    onPress,
    variant = 'contained',
    disabled = false,
    loading = false,
    customStyle = {},
    fixedWidth = false,
    headingVariant = 'h2',
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);

    const outlinedStyle = {
        borderColor: disabled ? colors.background.disabled : colors.action,
        borderWidth: 2,
        backgroundColor: 'transparent',
    };

    const containedStyle = {
        backgroundColor: disabled ? colors.background.disabled : colors.action,
        borderColor: disabled ? colors.background.disabled : colors.action,
        borderWidth: 2,
    };

    return (
        <Touchable
            onPress={disabled ? null : onPress}
            style={({pressed}) => [
                variant === 'outlined' ? outlinedStyle : containedStyle, {
                    paddingVertical: 11,
                    paddingHorizontal: spacing[2],
                    borderRadius: spacing[1],
                    alignSelf: fixedWidth ? 'center' : 'stretch',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...customStyle,
                    opacity: disabled
                        ? 1
                        : pressed ? 0.7 : 1,
                    cursor: disabled ? 'default' : 'pointer',
                },
            ]}
        >
            {
                loading
                    ? (
                        <ActivityIndicator
                            color={variant === 'outlined'
                                ? disabled ? colors.text.disabled : colors.action
                                : colors.text.white}
                        />
                    )
                    : (
                        <Heading
                            adjustsFontSizeToFit
                            color={variant === 'outlined'
                                ? disabled ? colors.text.disabled : colors.action
                                : colors.text.white}
                            minimumFontScale={0.6}
                            numberOfLines={1}
                            selectable={false}
                            variant={headingVariant}
                        >{text}
                        </Heading>
                    )
            }
        </Touchable>
    );
};

export default Button;