// @flow
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import type {Store} from '../../../../../Types/Reducers/Store';
import ThematicMainScreen from '../../ThematicMainScreen';
import appRoutes from "../../../../../Navigation/AppRoutes";
import {getIsUpdatingCF} from "../../../../../Reducers/CarbonFootprintReducer";
import ThematicIcon from "../../Icons/ThematicIcon";

const thematicText = `Le transport est à l'origine de 27% de l'empreinte carbone moyenne d'un Français, soit 2,65 tonnes équivalent CO2 par an 🧐.\nAlors, plutôt vélo ou vol long courrier ? Réponds à ces questions pour découvrir ta moyenne personnelle 🤠`;

const mapStateToProps = (store: Store, props: Object) => ({
    thematicTitle: 'Mobilité',
    thematicIcon: <ThematicIcon size={100} thematic='mobility' />,
    thematicIndex: 1,
    thematicsNumber: 5,
    onPressReturn: () => {
        if(getIsUpdatingCF(store)) props.navigation.goBack();
        else props.navigation.navigate(appRoutes.cf.start);
    },
    onPressNext: () => props.navigation.navigate(appRoutes.cf.mobility.questions),
    thematicText,
    ...props,
});

export default connect(
    mapStateToProps,
)(ThematicMainScreen);
