/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcScreen = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 496.8 496.8"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M300.8 440.4H195.2l18.4-96h68.8z"
            style={{fill: "#a5bcc4"}}
        />
        <Path
            d="M216 344.4h66.4l20 98.4"
            style={{fill: "#8daab2"}}
        />
        <Path
            d="M496 337.2c0 16.8-4.8 23.2-23.2 23.2H23.2C4.8 360.4 0 354 0 337.2v-264c0-16.8 12.8-32.8 31.2-32.8h434.4c17.6 0 31.2 16 31.2 32.8v264h-.8z"
            style={{fill: "#afc5ce"}}
        />
        <Path
            d="M496 328.4c0 16.8-4.8 24-23.2 24H23.2c-18.4 0-23.2-8-23.2-24v-264c0-16.8 12.8-32 31.2-32h434.4c17.6 0 30.4 15.2 30.4 32v264z"
            style={{fill: "#e7eef2"}}
        />
        <Path
            d="M0 64.4c0-16.8 13.6-32 31.2-32h434.4c17.6 0 30.4 15.2 30.4 32v264c0 16.8-4.8 24-23.2 24H216"
            style={{fill: "#c9dae2"}}
        />
        <Path
            d="M464.8 32.4H31.2C12.8 32.4 0 47.6 0 64.4v240h496v-240c0-16.8-12.8-32-31.2-32zM248 325.2l9.6 9.6 5.6-5.6L248 314l-15.2 15.2 5.6 5.6z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M480 304.4V75.6c0-13.6-3.2-27.2-16.8-27.2H32.8C19.2 48.4 16 62 16 75.6v228.8h464z"
            style={{fill: "#97ed69"}}
        />
        <Path
            d="M480 304.4V75.6c0-13.6-3.2-27.2-16.8-27.2H32.8c-13.6 0-16 13.6-16 26.4"
            style={{fill: "#87dd50"}}
        />
        <Path
            d="M16 280.4h464v24H16z"
            style={{fill: "#80d350"}}
        />
        <Path
            d="M356 452.4c0 5.6 0 12-8.8 12H149.6c-9.6 0-8.8-6.4-8.8-12s8-12 16.8-12h181.6c8.8 0 16.8 6.4 16.8 12z"
            style={{fill: "#e7eef2"}}
        />
        <Path
            d="M356 453.2c0 5.6 0 11.2-8.8 11.2H149.6c-9.6 0-8.8-5.6-8.8-11.2"
            style={{fill: "#c9dae2"}}
        />
        <Circle
            cx={223.2}
            cy={392.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={240}
            cy={392.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={256}
            cy={392.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={272.8}
            cy={392.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={223.2}
            cy={408.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={240}
            cy={408.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={256}
            cy={408.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={272.8}
            cy={408.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={223.2}
            cy={424.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={240}
            cy={424.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={256}
            cy={424.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
        <Circle
            cx={272.8}
            cy={424.4}
            r={3.2}
            style={{fill: "#e7eef2"}}
        />
    </Svg>
);

export default IcScreen;
