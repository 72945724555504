/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const MailSending = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M17.5 19.5 5.833 12.207V26.79h13.125v2.917H5.833a2.807 2.807 0 0 1-2.059-.856 2.81 2.81 0 0 1-.857-2.061v-17.5c0-.802.286-1.488.857-2.06a2.805 2.805 0 0 1 2.06-.857h23.333a2.81 2.81 0 0 1 2.06.858c.571.57.856 1.257.856 2.059v10.208h-2.916v-7.291L17.5 19.499Zm0-2.917L29.167 9.29H5.833L17.5 16.583Zm10.208 17.5-2.041-2.042 2.297-2.333h-6.089V26.79h6.089l-2.334-2.333 2.078-2.042 5.834 5.833-5.834 5.834ZM5.833 12.208v16.041-8.75.11V9.29v2.917Z"
            fill={color}
        />
    </Svg>
);

export default MailSending;