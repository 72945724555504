/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
  size?: number,
};

function IcLocalConsumption({size = 35}: Props){
    return (
        <Svg height={size} viewBox="0 0 28 28" width={size}>
            <Defs>
                <Path d="M0 27.358h27.359V0H0z" id="localcons_prefix__a" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M27.358 27.358v-1.521a4.558 4.558 0 00-4.56-4.556H10.642a4.562 4.562 0 00-4.563 4.556v1.521h21.28z"
                    fill="#77B255"
                />
                <Path
                    d="M16.718 25.836c.586 0 3.803-2.278 3.803-4.556h-7.603c0 2.278 3.22 4.556 3.8 4.556"
                    fill="#3E721D"
                />
                <Path
                    d="M13.406 21.308c0 2.16 6.627 2.25 6.627 0v-2.852h-6.627v2.852z"
                    fill="#F7DECE"
                />
                <Path
                    d="M13.4 19.737c.923 1.043 2.07 1.327 3.315 1.327 1.247 0 2.392-.284 3.319-1.327v-2.651h-6.635v2.651z"
                    fill="#EEC2AD"
                />
                <Path
                    d="M11.736 12.11c0 1.101-.556 1.99-1.244 1.99s-1.247-.889-1.247-1.99c0-1.1.559-1.994 1.247-1.994.688 0 1.244.895 1.244 1.994m12.455 0c0 1.101-.555 1.99-1.244 1.99s-1.247-.889-1.247-1.99c0-1.1.558-1.994 1.247-1.994.689 0 1.244.895 1.244 1.994"
                    fill="#F7DECE"
                />
                <Path
                    d="M10.242 12.887c0-4.247 2.901-7.692 6.476-7.692 3.578 0 6.477 3.445 6.477 7.692 0 4.248-2.9 7.693-6.475 7.693-3.577 0-6.478-3.445-6.478-7.693"
                    fill="#F7DECE"
                />
                <Path
                    d="M16.718 18.089c-2.093 0-2.735-.538-2.84-.645a.5.5 0 01-.022-.705.5.5 0 01.704-.017c.04.027.55.37 2.158.37 1.676 0 2.158-.37 2.161-.374a.491.491 0 01.694.012.514.514 0 01-.008.714c-.109.107-.75.645-2.847.645"
                    fill="#C1694F"
                />
                <Path
                    d="M16.718 2.642c4.294 0 6.977 3.17 6.977 5.81 0 2.638-.538 3.69-1.075 2.638l-1.07-2.114s-3.22 0-4.295-1.055c0 0 1.611 3.17-1.609 0 0 0 .535 2.114-2.682-.528 0 0-1.611 1.06-2.148 3.697-.148.733-1.074 0-1.074-2.638 0-2.64 2.148-5.81 6.976-5.81"
                    fill="#292F33"
                />
                <Path
                    d="M13.68 12.918a.766.766 0 01-.762-.762V11.4a.76.76 0 011.522 0v.756c0 .42-.342.762-.76.762m6.078 0a.764.764 0 01-.76-.762V11.4a.761.761 0 011.522 0v.756c0 .42-.342.762-.762.762"
                    fill="#662113"
                />
                <Path
                    d="M17.289 15.008h-1.14a.569.569 0 01-.57-.568c0-.315.257-.574.57-.574h1.14c.311 0 .57.259.57.574a.568.568 0 01-.57.568"
                    fill="#C1694F"
                />
                <Mask fill="#fff" id="localcons_prefix__b">
                    <Use xlinkHref="#localcons_prefix__a" />
                </Mask>
                <Path
                    d="M9.88 27.358h2.279v-6.077H9.88v6.077zm11.4 0h2.278v-6.077H21.28v6.077z"
                    fill="#3B88C3"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M9.974 27.358h13.584v-.76H9.974z"
                    fill="#3B88C3"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M22.799 6.686c0 1.003-2.35 1.67-6.08 1.67s-6.08-.667-6.08-1.67C10.64 3.991 13.68 0 14.694 0c1.013 0 1.688 1.337 2.025 1.337.337 0 1.015-1.337 2.028-1.337 1.012 0 4.053 3.991 4.053 6.686"
                    fill="#C1694F"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M27.312 6.078c0 1.259-2.658 4.562-10.64 4.562-7.976 0-10.637-3.303-10.637-4.562 0-1.26 5.182-.76 10.637-.76 5.46 0 10.64-.5 10.64.76"
                    fill="#C1694F"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M22.799 6.686c0 1.003-2.35 1.67-6.08 1.67s-6.08-.667-6.08-1.67c0-.432.078-.892.214-1.367 1.815 1.519 9.918 1.519 11.737.002.132.475.209.94.209 1.365"
                    fill="#292F33"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M5.318 24.127a.948.948 0 00-1.897 0v3.23h1.897v-3.23z"
                    fill="#C1694F"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M7.602 17.286a3.233 3.233 0 01-3.23 3.235h-.004a3.23 3.23 0 01-3.23-3.232v-.003c0-.064-1.138-.064-1.138 0a4.363 4.363 0 004.371 4.367 4.367 4.367 0 004.368-4.367c0-.064-1.137-.064-1.137 0"
                    fill="#66757F"
                    mask="url(#localcons_prefix__b)"
                />
                <Path
                    d="M1.142 17.286a.577.577 0 01-.574.574.572.572 0 01-.568-.574v-6.458a.57.57 0 01.568-.57c.315 0 .574.256.574.57v6.458zm7.597 0a.573.573 0 01-.569.574.575.575 0 01-.57-.574v-6.458a.57.57 0 111.14 0v6.458zm-3.797 6.272a.571.571 0 01-1.142 0v-12.73a.571.571 0 011.142 0v12.73z"
                    fill="#66757F"
                    mask="url(#localcons_prefix__b)"
                />
            </G>
        </Svg>
    );
}

export default IcLocalConsumption;
