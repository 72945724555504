// @flow
import {setStringAsync} from 'expo-clipboard';
import {openURL} from 'expo-linking';
import React, {useCallback, useContext, useMemo} from 'react';
import EToaster from '../../UI/Toasters/EToaster/EToaster';
import {ThemeContext} from '../../../libs/Hoc/ThemeProvider';
import {getFootprintShareText} from '../../../libs/Utils/ShareUtils';
import ActionModal from '../../UI/Modals/ActionModal/ActionModal';
import Body from '../../UI/Typos/Body/Body';

type Props = {
    onClose: () => void,
    footprint: string,
};

const WebShareFootprintModal = ({
    onClose,
    footprint,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    const subject = useMemo(() => 'Bilan carbone Energic', []);
    const body = useMemo(() => getFootprintShareText(footprint), [footprint]);

    const onPressMail = useCallback(() => {
        const mailBody = getFootprintShareText(footprint, true);
        openURL(`mailto:?subject=${subject}&body=${mailBody}`);
    }, [subject, body]);

    const onPressClipboard = useCallback(async() => {
        await setStringAsync(body);
        EToaster.showSuccessToast({text: 'Message copié dans le presse-papiers.'});
        onClose();
    }, []);

    const renderContent = () => (
        <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
            {body}
        </Body>
    );

    return (
        <ActionModal
            content={renderContent()}
            mainButtonOnPress={onPressClipboard}
            mainButtonText="Copier"
            onClose={onClose}
            secondButtonOnPress={onPressMail}
            secondButtonText="Envoyer par mail"
            title={subject}
        />
    );
};

export default WebShareFootprintModal;