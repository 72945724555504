// @flow
import React, {Node} from "react";
import {View} from "react-native";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";

type Props = {
    children: Node,
    customStyle?: ViewStyleProp,
};

const SliderLabelContainer = ({
    children,
    customStyle = {},
}: Props) => (
    <View
        style={{
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            marginTop: 15,
            ...customStyle,
        }}
    >
        {children}
    </View>
);

export default SliderLabelContainer;