// @flow
import React from 'react';
import {View, Image} from 'react-native';
import Const from '../../Config/Const';
import locales from "../../locales/locales";
import ModalManager from "../../Components/UI/Modals/ModalManager/ModalManager";
import FootprintTopLeft from "../../Components/UI/Ornaments/FootprintTopLeft";
import FootprintTopRight from "../../Components/UI/Ornaments/FootprintTopRight";
import OnboardingBottom from "../../Components/UI/Ornaments/OnboardingBottom";
import DecoratedLayout from "../../Components/UI/Layouts/DecoratedLayout/DecoratedLayout";
import ActionIcon from "../../Components/UI/Icons/IconContainers/ActionIcon/ActionIcon";
import Help from "../../Components/UI/Icons/UI/Help";
import LightPageHeader from "../../Components/UI/PageHeaders/LightPageHeader/LightPageHeader";
import Button from "../../Components/UI/Buttons/Button/Button";
import BasicCard from "../../Components/UI/Cards/BasicCard/BasicCard";
import Body from "../../Components/UI/Typos/Body/Body";
import Heading from "../../Components/UI/Typos/Heading/Heading";
import {useContext} from "react";
import {ThemeContext} from "../../libs/Hoc/ThemeProvider";
import type {Store as TypeStore} from "../../Types/Reducers/Store";
import appRoutes from "../../Navigation/AppRoutes";
import {connect} from "react-redux";
import Touchable from "../../Components/UI/Buttons/Touchable/Touchable";
import {openLink} from "../../libs/Utils/LinkUtils";
import Config from "../../Config/Config";
import energicLogoWhite from '../../assets/images/web/energic_white.png';
import energicLogoBlue from '../../assets/images/web/energic_blue.png';

type Props = {
    onPressNext: () => void,
};

const CFStartScreen = ({
    onPressNext,
}: Props) => {
    const [colors,, spacing, colorScheme] = useContext(ThemeContext);

    const showHelpModal = () => ModalManager.showHelpModal(
        Const.CARBON_FOOTPRINT_DISCLAIMER,
        () => null,
    );

    return (
        <DecoratedLayout
            bottomRightOrnament={(<OnboardingBottom />)}
            topLeftOrnament={(<FootprintTopLeft />)}
            topRightOrnament={(<FootprintTopRight />)}
        >
            <LightPageHeader
                endComponent={(
                    <ActionIcon onPress={showHelpModal}>
                        <Help />
                    </ActionIcon>
                )}
                title={locales.t('global.pages.cf')}
            />

            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <BasicCard
                    customStyle={{backgroundColor: colors.background.card}}
                >
                   <View
                       style={{
                           alignSelf: 'center',
                           marginBottom: spacing[2]
                       }}
                   >
                       <Touchable
                           opacity
                           onPress={() => openLink('https://energic.io')}
                       >
                           <Image
                               source={colorScheme === 'dark' ? energicLogoWhite : energicLogoBlue}
                               style={{
                                   width: Config.DEVICE_WIDTH * 0.4,
                                   height: Config.DEVICE_WIDTH * 0.4,
                               }}
                           />
                       </Touchable>
                   </View>

                    <Body centered variant='onboarding_text'>
                        {locales.t('cf.start.text')}
                    </Body>
                    <Heading
                        centered
                        customStyle={{marginTop: spacing[2]}}
                        variant='h3'
                    >
                        {locales.t('cf.start.duration')}
                    </Heading>
                </BasicCard>
            </View>

            <Button
                customStyle={{marginVertical: spacing[2]}}
                onPress={onPressNext}
                text={locales.t('global.actions.start')}
            />
        </DecoratedLayout>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    onPressNext: () => props.navigation.navigate(appRoutes.cf.mobility.thematic),
    //onPressNext: () => props.navigation.navigate(appRoutes.cf.end),
    ...props,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CFStartScreen);
