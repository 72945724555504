// @flow
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {View} from "react-native";
import Heading from "../../../UI/Typos/Heading/Heading";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";
import {useCountUp} from "use-count-up";

type Props = {
    totalCarbon: string,
    fontSize?: number,
    showSubtitle?: boolean,
    noAnimation?: boolean,
}

const TotalFootprintPieCenter = ({
    totalCarbon,
    fontSize = 30,
    showSubtitle = true,
    noAnimation = false,
}: Props) => {
    const [colors, fonts] = useContext(ThemeContext);
    const [animating, setAnimating] = useState(false); // use to delay animation

    const totalFootprint = useMemo(() => parseFloat(totalCarbon?.split(' ')?.[0]), [totalCarbon]);

    const {value} = useCountUp({
        isCounting: animating,
        end: totalFootprint,
        duration: 1.5,
        decimalSeparator: ',',
    });

    useEffect(() => {
        setTimeout(() => {
            setAnimating(true);
        }, 900);
    }, []);

    return (
        <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <Heading
                color={colors.text.dark}
                customStyle={{
                    fontSize: fontSize,
                    lineHeight: fontSize + (0.3 * fontSize),
                    fontFamily: showSubtitle ? fonts.bold : fonts.regular,
                }}
                variant='h2'
            >
                {
                    `${noAnimation ? totalFootprint : value}${showSubtitle ? '' : 't'}`
                    ?? '-'
                }
            </Heading>
            {
                showSubtitle
                    ? (
                        <Heading
                            centered
                            customStyle={{
                                fontSize: fontSize * 0.5,
                                lineHeight: (fontSize * 0.5) + (0.3 * 0.5 * fontSize),
                            }}
                            variant='h2'
                        >
                            {`tonnes de\nCO2eq. / an`}
                        </Heading>
                    )
                    : null
            }
        </View>
    );
};

export default TotalFootprintPieCenter;