// @flow
import React, {Node, useCallback, useMemo} from 'react';
import {FlatList, View} from 'react-native';
import type {ItemInfos} from "../../../Types/Utils/ItemInfos";
import RoundAnswerItem from './RoundAnswerItem';
import locales from '../../../locales/locales';

type Option<T> = { value: T, icon: Node };

export type Props<T> = {
    options: Array<Option<T>>,
    numColumns: number,
    selectedValues: Array<T>,
    handleChange: (Array<T>) => void,
    listHeaderComponent?: Node,
};

function MultiAnswerContainer<T>({
    options,
    numColumns,
    selectedValues,
    handleChange,
    listHeaderComponent = null,
}: Props<T>){
    function handlePress(selected: Option<T>){
        let newValue;
        if (selectedValues.includes(selected.value)){
            newValue = selectedValues.filter(
                current => current !== selected.value
            );
        } else newValue = [...selectedValues, selected.value];
        handleChange(newValue);
    }

    const isAnswerSelected = useCallback(
        (value) => selectedValues?.includes(value),
        [selectedValues]
    );

    function renderAnswer({item}: ItemInfos<T>){
        const selected = isAnswerSelected(item?.value);
        return (
            <RoundAnswerItem
                Icon={item.icon}
                cardLegend={locales.t(`cf.${item.value}`)}
                onPress={() => handlePress(item)}
                selected={selected}
            />
        );
    }

    const additionalMultiColumnStyle = useMemo(
        () => numColumns > 1 ? {columnWrapperStyle: {justifyContent: 'space-evenly'}} : {},
        [numColumns]
    );

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: 20}} />)}
            ListHeaderComponent={listHeaderComponent}
            data={options}
            extraData={selectedValues}
            keyExtractor={(item) => item?.value?.toString()}
            numColumns={numColumns}
            renderItem={renderAnswer}
            {...additionalMultiColumnStyle}
        />
    );
}


export default MultiAnswerContainer;