/* eslint-disable max-len */
// @flow
import React, {useContext} from "react";
import Svg, {Path} from "react-native-svg";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";

const FootprintTopLeft = () => {
    const [colors] = useContext(ThemeContext);
    return (
        <Svg fill="none" height={206} width={304} xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M123.457 205.479c-16.863.725-34.3-.977-51.964-3.627-11.985-1.807-24.067-4.161-36.336-7.788-19.021-5.611-38.123-13.606-57.286-24.725-10.469-6.07-20.724-13.324-30.607-22.489-5.781-5.359-11.302-11.266-16.274-18.165-9.06-12.585-13.768-25.969-14.264-40.048-.344-9.698 1.303-18.078 4.007-25.687 3.152-8.898 7.739-16.138 12.952-22.652 7.213-9.031 15.685-16.049 24.953-21.749 11.12-6.84 22.369-13.428 33.606-20.061 5.818-3.435 11.575-6.973 17-11.119 6.188-4.737 11.757-10.363 15.947-17.944 2.52-4.545 4.488-9.652 6.272-14.938 2.75-8.098 5.385-16.315 8.262-24.295 3.841-10.645 9.004-19.78 15.894-26.871 7.867-8.129 17.54-12.452 28.919-13.192 9.082-.592 18.583.903 28.317 3.45 16.411 4.293 32.914 10.2 49.581 18.995 24.739 13.058 48.725 30.291 71.859 52.232 13.627 12.925 26.347 27.568 38.501 43.217 5.711 7.343 10.826 15.308 15.381 23.762 11.492 21.32 16.578 42.283 15.21 62.862-.907 13.547-4.125 25.214-8.988 35.533-6.656 14.139-15.834 24.887-26.583 33.519-15.928 12.791-33.797 21.674-53.088 27.745-14.541 4.574-29.622 7.698-45.051 9.889-13.53 1.925-27.182 3.48-41.113 4.161-1.673.089-3.405 0-5.103 0l-.004-.015Z"
                fill={colors.illustrations.yellow.main}
            />
        </Svg>
    );
};

export default FootprintTopLeft;