/* eslint-disable max-len */
// @flow

export function getFootprintShareText(footprint: string, webFormatting: boolean = false): string{
    if(!footprint) return ('Erreur lors de la récupération des données du bilan, veuillez réessayer.');
    if(webFormatting){
        return (
            `Je viens de réaliser mon bilan carbone et ce dernier s'élève à... ${footprint} tonnes équivalent CO2 par an !%0D%0A%0D%0A` +
            `Envie de faire (peut-être 😉) mieux que moi et de connaître ton niveau d'engament ?%0D%0A%0D%0A` +
            `Le test est disponible sur le site d'Energic : https://bilancarbone.energic.io/`
        );
    }

    return (
        `Je viens de réaliser mon bilan carbone et ce dernier s'élève à... ${footprint} tonnes équivalent CO2 par an !\n\n` +
        `Envie de faire (peut-être 😉) mieux que moi et de connaître ton niveau d'engament ?\n\n` +
        `Le test est disponible sur le site d'Energic : https://bilancarbone.energic.io/`
    );
}
