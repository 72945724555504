/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcWashingMachine = ({size = 35}: Props) => (
    <Svg
        className="WashingMachine_svg__icon"
        height={size}
        viewBox="0 0 1024 1024"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M960 959.968a64 64 0 0 1-64 64H128a64 64 0 0 1-64-64v-896a64 64 0 0 1 64-64h768a64 64 0 0 1 64 64v896z"
            fill="#EAEAEA"
        />
        <Path d="M800 111.968a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#3AAD73" />
        <Path
            d="M256 639.968a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
            fill="#469FCC"
        />
        <Path d="M512 415.968c135.968 0 246.912 106.112 255.2 240 .352-5.312.8-10.592.8-16a256 256 0 0 0-512 0c0 5.408.48 10.688.8 16 8.288-133.888 119.232-240 255.2-240z" />
        <Path
            d="M512 319.968c-176.704 0-320 143.296-320 320s143.296 320 320 320 320-143.296 320-320-143.296-320-320-320zm0 576a256 256 0 1 1 0-512 256 256 0 0 1 0 512z"
            fill="#434854"
        />
        <Path
            d="m667.776 552.992-67.872-67.872a16 16 0 0 0-22.624 22.624l67.872 67.872a16 16 0 0 0 22.624-22.624zm-134.944-46.048a16 16 0 0 0-22.624 22.624l113.12 113.12a16 16 0 0 0 22.624-22.624l-113.12-113.12z"
            fill="#FFF"
        />
        <Path
            d="M384 111.968a48 48 0 0 1-48 48H176a48 48 0 0 1 0-96h160a48 48 0 0 1 48 48z"
            fill="#434854"
        />
        <Path d="M336 159.936H176a47.872 47.872 0 0 1-45.056-32 47.456 47.456 0 0 0-2.944 16 48 48 0 0 0 48 48h160a48 48 0 0 0 48-48c0-5.632-1.152-10.976-2.944-16-6.624 18.592-24.192 32-45.056 32zM64 223.968h896v32H64zm829.056-96.032c-6.624 18.592-24.192 32-45.056 32s-38.432-13.408-45.056-32a47.456 47.456 0 0 0-2.944 16 48 48 0 0 0 96 0c0-5.632-1.152-10.976-2.944-16z" />
    </Svg>
);

export default IcWashingMachine;
