/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const TransportMeans = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M14.583 28.76v-8.313l2.042-5.833a1.79 1.79 0 0 1 .528-.674c.231-.183.517-.274.857-.274h10.646c.34 0 .632.091.875.274.243.182.414.407.51.674l2.042 5.833v8.313a.913.913 0 0 1-.274.673.913.913 0 0 1-.674.275h-1.02a.913.913 0 0 1-.674-.274.913.913 0 0 1-.274-.674v-1.24H17.5v1.24a.913.913 0 0 1-.274.673.913.913 0 0 1-.674.275h-1.02a.913.913 0 0 1-.675-.274.913.913 0 0 1-.274-.674Zm2.917-9.99h11.667l-1.021-2.916H18.52L17.5 18.77Zm1.458 5.834c.414 0 .76-.14 1.04-.42a1.41 1.41 0 0 0 .419-1.039c0-.413-.14-.76-.419-1.04a1.415 1.415 0 0 0-1.04-.418c-.413 0-.759.14-1.038.418-.28.28-.42.627-.42 1.04 0 .413.14.76.42 1.038.279.28.625.42 1.038.42Zm8.75 0c.414 0 .76-.14 1.039-.42.28-.28.42-.626.42-1.039 0-.413-.14-.76-.42-1.04a1.41 1.41 0 0 0-1.039-.418c-.413 0-.759.14-1.038.418-.28.28-.42.627-.42 1.04 0 .413.14.76.42 1.038.279.28.625.42 1.038.42ZM5.833 29.707v-1.459l1.459-1.458c-1.216 0-2.249-.425-3.1-1.276-.85-.85-1.275-1.884-1.275-3.099V10.749c0-1.604.717-2.734 2.15-3.39 1.435-.657 3.877-.985 7.329-.985 3.597 0 6.076.316 7.437.948 1.362.632 2.042 1.775 2.042 3.427v1.459h-2.917v-1.459H5.833v8.75h7.292v10.209H5.833Zm1.459-5.834c.413 0 .76-.14 1.04-.42a1.41 1.41 0 0 0 .418-1.038c0-.413-.14-.76-.419-1.04a1.415 1.415 0 0 0-1.04-.418c-.413 0-.76.14-1.04.418-.278.28-.418.627-.418 1.04 0 .413.14.76.419 1.038.28.28.626.42 1.04.42Zm9.479 1.459h13.125v-4.375H16.77v4.375Z"
            fill={color}
        />
    </Svg>
);

export default TransportMeans;