/* eslint-disable max-len */
// @flow
import React, {useContext} from 'react';
import {connect} from 'react-redux';
import MultiAnswerContainer from '../../MultiAnswerContainer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import {getDrinkTypes} from "../../../../../Reducers/CarbonFootprintReducers/CFDietReducer";
import IcCapsuleCoffee from "../../../../UI/Icons/CarbonFootprint/Diet/IcCapsuleCoffee";
import IcCoffee from "../../../../UI/Icons/CarbonFootprint/Diet/IcCoffee";
import IcAlcohol from "../../../../UI/Icons/CarbonFootprint/Diet/IcAlcohol";
import IcTea from "../../../../UI/Icons/CarbonFootprint/Diet/IcTea";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import Body from "../../../../UI/Typos/Body/Body";
import type {DrinkType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setDrinkTypes: (Array<DrinkType>) => void,
  drinkTypes: Array<DrinkType>,
};

const options: Array<{value: DrinkType, icon: Node}> = [
    {value: 'coffee', icon: IcCoffee},
    {value: 'capsule_coffee', icon: IcCapsuleCoffee},
    {value: 'tea_or_chocolate', icon: IcTea},
    {value: 'alcool', icon: IcAlcohol},
];

const QDrinkTypes = ({
    setDrinkTypes,
    drinkTypes,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    function handleChange(values: Array<DrinkType>){
        setDrinkTypes(values);
    }
    return (
        <MultiAnswerContainer
            handleChange={handleChange}
            listHeaderComponent={(
                <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                    {"Plusieurs réponses possibles"}
                </Body>
            )}
            numColumns={2}
            options={options}
            selectedValues={drinkTypes}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    drinkTypes: getDrinkTypes(store),
    ...props,
});
const mapDispatchToProps = {setDrinkTypes: CFActions.setDrinkTypes};

export default connect(mapStateToProps, mapDispatchToProps)(QDrinkTypes);
