// @flow
import React, {Node} from "react";
import Config from "../../Config/Config";
import AnimatePositionOnEnter from "./AnimatePositionOnEnter";

type Props = {
    children: Node,
    index?: number,
}

const AnimateListItem = ({
    children,
    index = 0,
}: Props) => (
    <AnimatePositionOnEnter
        delay={200 + index * 40}
        finalX={0}
        initialX={Config.DEVICE_WIDTH}
    >
        {children}
    </AnimatePositionOnEnter>
);

export default AnimateListItem;