// @flow
import React, {Node} from "react";
import {ScrollView} from "react-native";
import Heading from "../../../Typos/Heading/Heading";

type Props = {
    title: string,
    content: Node,
};

const InfoMM = ({
    title,
    content,
}: Props) => {
    return (
        <>
            <Heading
                centered
                variant='h1'
            >
                {title}
            </Heading>
            <ScrollView>
                {content}
            </ScrollView>
        </>
    );
};

export default InfoMM;
