/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const Diet = ({
    color = theme.colors.light.text.white,
    size = 36,
}: Props) => (
    <Svg fill="none" height={size} viewBox="0 0 36 36" width={size} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M26.25 32.624c-.413 0-.76-.14-1.038-.42a1.407 1.407 0 0 1-.42-1.038V20.958h-1.459a2.805 2.805 0 0 1-2.059-.858 2.805 2.805 0 0 1-.857-2.059v-7.292c0-2.017.71-3.736 2.133-5.158 1.422-1.422 3.141-2.133 5.158-2.133v27.708c0 .413-.14.76-.42 1.038-.279.28-.625.42-1.038.42Zm-14.583 0c-.413 0-.76-.14-1.04-.42a1.41 1.41 0 0 1-.419-1.038V19.281c-1.24-.34-2.278-1.021-3.116-2.042-.84-1.02-1.259-2.212-1.259-3.573v-8.75c0-.413.14-.76.419-1.04.28-.279.626-.418 1.04-.418.413 0 .76.14 1.04.418.278.28.418.627.418 1.04v8.75h1.458v-8.75c0-.413.14-.76.419-1.04.28-.279.627-.418 1.04-.418.413 0 .76.14 1.04.418.278.28.418.627.418 1.04v8.75h1.458v-8.75c0-.413.14-.76.42-1.04a1.41 1.41 0 0 1 1.039-.418c.413 0 .76.14 1.04.418.278.28.418.627.418 1.04v8.75c0 1.361-.42 2.552-1.259 3.573-.838 1.02-1.876 1.701-3.116 2.042v11.885c0 .413-.14.76-.419 1.038-.28.28-.626.42-1.04.42Z"
            fill={color}
        />
    </Svg>
);

export default Diet;