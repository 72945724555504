/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcWood({size = 55}: Props){
    return (
        <Svg
            height={size}
            viewBox="0 0 70 70"
            width={size}
        >
            <Defs>
                <Path d="M0 .973h16.483v16.405H0z" id="prefix__a" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M17.377 31.684l34.425-9.683.172.037c1.687.868 3.313 3.31 4.395 6.22.777 2.099 1.088 4.245.872 6.035l.07.108-.26.224-33.433 12.2-6.241-15.14z"
                    fill="#704D34"
                />
                <Path
                    d="M57.082 34.276c.198-1.642-.103-3.72-.93-5.946-1.1-2.972-2.536-5.187-4.152-5.992l-.744.224-33.98 9.654 6.492 14.138L56.702 34.5l.38-.224z"
                    fill="#8D5D3D"
                />
                <G transform="translate(13 29.972)">
                    <Mask fill="#fff" id="prefix__b">
                        <Use xlinkHref="#prefix__a" />
                    </Mask>
                    <Path
                        d="M16.483 9.02c.01 2.296-.82 4.487-2.431 5.892-1.603 1.396-3.733 2.28-5.914 2.447C3.534 17.716 0 13.06 0 8.441 0 3.824 3.41.973 8.027.973c2.31 0 4.51.604 6.017 2.122a8.504 8.504 0 012.44 5.924"
                        fill="#704D34"
                        mask="url(#prefix__b)"
                    />
                </G>
                <Path
                    d="M28.7 38.965a7.523 7.523 0 01-2.215 5.351 7.547 7.547 0 01-5.36 2.22c-4.186 0-7.109-4.458-7.109-8.645 0-4.187 2.804-6.04 6.99-6.04 2.094 0 4.109.374 5.474 1.752a7.606 7.606 0 012.22 5.362"
                    fill="#CC9764"
                />
                <Path
                    d="M21.267 40.476a1.865 1.865 0 01-1.974-1.529 1.86 1.86 0 01.303-1.396c.258-.37.652-.57 1.085-.631-.454.053-.869.253-1.135.636a1.856 1.856 0 00-.307 1.397c.085.47.346.908.771 1.198.38.267.827.37 1.257.325"
                    fill="#C48053"
                />
                <Path
                    d="M25.895 34.31a4.458 4.458 0 00-1.135-.806c.192.14.398.256.567.425a6.68 6.68 0 011.943 4.697c0 1.83-.739 3.484-1.94 4.686a6.618 6.618 0 01-4.691 1.943 5.07 5.07 0 01-2.424-.629c.871.613 1.872 1.009 2.994 1.009 1.83 0 3.49-.742 4.691-1.943a6.586 6.586 0 001.935-4.686 6.659 6.659 0 00-1.94-4.697"
                    fill="#C0894F"
                />
                <Path
                    d="M16.66 39.723c.079.29.211.552.375.8-.064-.137-.14-.264-.18-.414a4.212 4.212 0 01.407-3.19 4.19 4.19 0 015.729-1.568c.485.275.858.66 1.137 1.107-.287-.613-.712-1.144-1.333-1.497a4.208 4.208 0 00-3.192-.402 4.173 4.173 0 00-2.537 1.97 4.236 4.236 0 00-.406 3.194"
                    fill="#C0894F"
                />
                <Path
                    d="M19.69 38.798a.98.98 0 00.14.3c-.025-.05-.054-.1-.07-.152-.105-.396-.06-.83.154-1.21a1.587 1.587 0 012.167-.594c.185.106.325.251.43.418a1.249 1.249 0 00-.501-.565 1.581 1.581 0 00-1.21-.15 1.593 1.593 0 00-1.111 1.953m5.352-3.061a5.084 5.084 0 011.136 3.195 5.038 5.038 0 01-1.482 3.58 5.054 5.054 0 01-3.585 1.486c-1.22 0-2.278-.568-3.083-1.436.816.93 1.906 1.544 3.176 1.544 1.399 0 2.669-.57 3.582-1.486a5.026 5.026 0 001.484-3.58 5.093 5.093 0 00-1.228-3.303"
                    fill="#C0894F"
                />
                <Path
                    d="M20.852 34.222a3.964 3.964 0 012.55.696 3.918 3.918 0 011.629 2.543 3.946 3.946 0 01-.647 2.954c-.541.781-1.378 1.206-2.291 1.333.958-.108 1.837-.533 2.402-1.344a3.947 3.947 0 00-.985-5.496 3.959 3.959 0 00-2.658-.686"
                    fill="#C0894F"
                />
                <Path
                    d="M21.053 36.758a1.865 1.865 0 011.669 2.925c-.257.37-.65.57-1.083.631.454-.052.869-.253 1.133-.636a1.86 1.86 0 00-1.719-2.92"
                    fill="#C0894F"
                />
                <Path
                    d="M22.572 36.983a2.162 2.162 0 01-.971 3.785c-.513.098-1.004-.052-1.41-.35.416.321.923.49 1.454.385a2.161 2.161 0 00.927-3.82m-2.824 3.269c-.37-.31-.636-.74-.736-1.249a2.144 2.144 0 01.327-1.618 2.16 2.16 0 011.38-.919c.513-.098 1.001.053 1.41.351-.42-.322-.926-.49-1.457-.385a2.157 2.157 0 00-1.38.913 2.15 2.15 0 00-.33 1.624c.105.528.396.971.786 1.283"
                    fill="#C0894F"
                />
                <Path
                    d="M23.65 37.61c.221.532.271 1.142.094 1.736a2.56 2.56 0 01-1.246 1.547c-.583.32-1.29.415-1.98.212-.6-.175-1.037-.608-1.31-1.152.27.573.716 1.035 1.342 1.22a2.599 2.599 0 003.099-3.564m-4.819 1.923a2.597 2.597 0 01-.093-1.737c.2-.689.662-1.227 1.246-1.547a2.586 2.586 0 011.98-.211c.599.174 1.037.607 1.31 1.151-.267-.573-.716-1.035-1.34-1.22a2.6 2.6 0 00-3.228 1.762 2.61 2.61 0 00.127 1.802"
                    fill="#C0894F"
                />
                <Path
                    d="M18.802 41.904a3.982 3.982 0 01-2.011-1.716 3.925 3.925 0 01-.391-2.994 3.943 3.943 0 011.843-2.4c.826-.475 1.76-.504 2.645-.229-.916-.309-1.893-.301-2.748.193a3.927 3.927 0 00-1.843 2.397 3.917 3.917 0 00.39 2.996 3.972 3.972 0 002.115 1.753"
                    fill="#C0894F"
                />
                <Path
                    d="M26.704 32.721l14.776-5.325-14.129 6.32-2.537.898zm30.28-1.619l-14.892 6.4 15.056-4.843s-.23-1.46-.164-1.557"
                    fill="#704D34"
                />
            </G>
        </Svg>
    );
}

export default IcWood;
