// @flow
import {openBrowserAsync} from 'expo-web-browser';
import {Platform} from 'react-native';

async function openLink(href: string): Promise<any>{
    const link = href.toLowerCase().includes('http') ? href : 'https://' + href;
    if (Platform.OS === 'web') return window.open(link, '_blank');
    else return openBrowserAsync(link);
}

export {
    openLink,
};