/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const NewPurchase = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M5.833 31.166V7.833c0-.802.286-1.49.858-2.06a2.807 2.807 0 0 1 2.059-.857h17.5c.802 0 1.489.285 2.06.856.571.572.857 1.259.857 2.06v23.334H26.25v-2.917H8.75v2.917H5.833ZM8.75 16.583h7.292v-8.75H8.75v8.75Zm10.208-5.834h7.292V7.833h-7.292v2.916Zm0 5.834h7.292v-2.917h-7.292v2.917Zm-4.375 7.291h5.834v-2.916h-5.834v2.916ZM8.75 19.5v5.834h17.5v-5.834H8.75Z"
            fill={color}
        />
    </Svg>
);

export default NewPurchase;