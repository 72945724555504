// @flow
import type {Immutable as ImmutableType} from 'seamless-immutable';
import Immutable from 'seamless-immutable';
import type {Store} from '../../Types/Reducers/Store';
import CarbonFootprintActions from '../../Actions/CarbonFootprintActions';
import ReducerUtils from '../ReducerUtils';
import type {ActionP} from '../../Types/Reducers/Action';
import CFUtils from './CFUtils';
import type {
    UserCFAnswersType,
    UserMobilityCFAnswersType,
} from "../../Types/CarbonFootprint/FootprintApiTypes";
import type {
    MeansOfTransportOptionsType,
    WeeklyMeansOfTransportType,
} from "../../Types/CarbonFootprint/FootprintQuestionsTypes";
import type {FootprintDetailPartType} from "../../Types/CarbonFootprint/FootprintTypes";

export type CFTransportReducerState = ImmutableType<{
    meansOfTransport: Array<MeansOfTransportOptionsType>,
    weeklyMeansOfTransport: Array<WeeklyMeansOfTransportType>,
    nbPeopleInCar: number,
    nbYearlyPlaneHours: number,
    lastAnswers?: UserMobilityCFAnswersType,
}>;

const INITIAL_STATE: CFTransportReducerState = Immutable({
    meansOfTransport: [],
    weeklyMeansOfTransport: [],
    nbPeopleInCar: 1,
    nbYearlyPlaneHours: 0,
    lastAnswers: null,
});

const setMeansOfTransport = (state: CFTransportReducerState, action: ActionP<Array<MeansOfTransportOptionsType>>) => {
    const {payload: meansOfTransport} = action;
    const {weeklyMeansOfTransport} = state;
    const updatedWeekly = weeklyMeansOfTransport.filter(mean => meansOfTransport.includes(mean.mean));
    return Immutable.merge(state, {
        meansOfTransport,
        weeklyMeansOfTransport: updatedWeekly,
    });
};

const setWeeklyMeansOfTransport = (
    state: CFTransportReducerState,
    action: ActionP<Array<WeeklyMeansOfTransportType>>
) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {weeklyMeansOfTransport: payload},
    );
};


const setNbPeopleInCar = (state: CFTransportReducerState, action: ActionP<number>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {nbPeopleInCar: payload},
    );
};

const setNbYearlyPlaneHours = (state: CFTransportReducerState, action: ActionP<number>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {nbYearlyPlaneHours: payload},
    );
};

const resetCFReducer = (state: CFTransportReducerState) => Immutable.merge(state, INITIAL_STATE);

const setUserLastCFAnswers = (state: CFTransportReducerState, action: ActionP<{answers: UserCFAnswersType}>) => {
    return Immutable.merge(
        state,
        {
            meansOfTransport: action?.payload?.answers?.mobility?.meansOfTransport?.map(m => m.answerType) ?? [],
            weeklyMeansOfTransport: action?.payload?.answers?.mobility?.meansOfTransport?.map(m => ({
                mean: m.answerType,
                value: m.value,
            })) ?? [],
            nbPeopleInCar: action?.payload?.answers?.mobility?.nbPeopleInCar ?? 1,
            nbYearlyPlaneHours: action?.payload?.answers?.mobility?.nbPlaneHours ?? 0,
            lastAnswers: action?.payload?.answers?.mobility,
        },
    );
};

export default ReducerUtils.createReducer(
    INITIAL_STATE,
    {
        [CarbonFootprintActions.Types.SET_MEANS_OF_TRANSPORT]: setMeansOfTransport,
        [CarbonFootprintActions.Types.SET_NB_PEOPLE_IN_CAR]: setNbPeopleInCar,
        [CarbonFootprintActions.Types.SET_WEEKLY_MEANS_OF_TRANSPORT]: setWeeklyMeansOfTransport,
        [CarbonFootprintActions.Types.SET_NB_YEARLY_PLANE_HOURS]: setNbYearlyPlaneHours,
        [CarbonFootprintActions.Types.RESET_FOOTPRINT_REDUCERS]: resetCFReducer,
        [CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS_SUCCESS]: setUserLastCFAnswers,
    },
);

export const getState = (store: Store): CFTransportReducerState => store.carbonFootprint.transport;
export const getMeansOfTransport = (store: Store): Array<MeansOfTransportOptionsType> =>
    store.carbonFootprint.transport.meansOfTransport;
export const getWeeklyMeansOfTransport = (store: Store): Array<WeeklyMeansOfTransportType> =>
    store.carbonFootprint.transport.weeklyMeansOfTransport;
export const getNbPeopleInCar = (store: Store): number => store.carbonFootprint.transport.nbPeopleInCar;
export const getNbYearlyPlaneHours = (store: Store): number => store.carbonFootprint.transport.nbYearlyPlaneHours;

export const getIsUsingCar = (store: Store): boolean => {
    const meansOfTransport = getMeansOfTransport(store);
    const intersection = CFUtils.carTransportTypes.filter(type => meansOfTransport.includes(type));
    return intersection.length !== 0;
};

const getCalculatedCo2TotalTransportationEmissions = (
    meansOfTransport: Array<WeeklyMeansOfTransportType>,
    nbPeopleInCar: number,
) => {
    const {NB_WEEKS} = CFUtils;
    const {transportEmissionFactors, carTransportTypes} = CFUtils;
    const costs = meansOfTransport.map((mean) => {
        const factor = transportEmissionFactors[mean.mean];
        const totalForMean = factor.speed * factor.emission * mean.value;
        if (carTransportTypes.includes(mean.mean)) return totalForMean / nbPeopleInCar;

        return totalForMean;
    });
    return costs.reduce((acc, elm) => acc + elm, 0) * NB_WEEKS;
};

const getgCO2TotalTransportationEmissions = (store: Store): number => {
    const meansOfTransport = getWeeklyMeansOfTransport(store);
    const nbPeopleInCar = getNbPeopleInCar(store);
    return getCalculatedCo2TotalTransportationEmissions(meansOfTransport, nbPeopleInCar);
};

/**
 * Returns the impact of the player based on its mobility answers, but not taking cars into account
 * @param store
 * @returns {number}
 */
const getgCO2SoloTransporsButCar = (store: Store): number => {
    const {NB_WEEKS, transportEmissionFactors, carTransportTypes} = CFUtils;
    const meansOfTransport = getWeeklyMeansOfTransport(store);
    const costs = meansOfTransport
        .filter(m => !carTransportTypes.includes(m.mean)) // remove cars
        .map((mean) => {
            const factor = transportEmissionFactors[mean.mean];
            return factor.speed * factor.emission * mean.value;
        });
    return costs.reduce((acc, elm) => acc + elm, 0) * NB_WEEKS;
};

/**
 * Returns the impact of the player based on its mobility answers, but taking only cars into account
 * @param store
 * @returns {number}
 */
const getgCO2SoloTransportsOnlyCar = (store: Store): number => {
    const meansOfTransport = getWeeklyMeansOfTransport(store);
    const {NB_WEEKS, transportEmissionFactors, carTransportTypes} = CFUtils;
    const costs = meansOfTransport
        .filter(m => carTransportTypes.includes(m.mean)) // keep only cars
        .map((mean) => {
            const factor = transportEmissionFactors[mean.mean];
            return factor.speed * factor.emission * mean.value;
        });
    return costs.reduce((acc, elm) => acc + elm, 0) * NB_WEEKS;
};

/**
 * Returns the saved tons of CO2 based on car mobility impact when divided by nb people in car
 * @param store
 * @returns {number}
 */
const getSavedgCO2Transports = (store: Store): number => {
    const soloCarTotal = getgCO2SoloTransportsOnlyCar(store);
    const nbPeopleInCar = getNbPeopleInCar(store);
    return (- soloCarTotal * (nbPeopleInCar - 1)) / nbPeopleInCar;
};

const getCalculatedPlaneEmissions = (nbYearlyPlaneHours: number): number => {
    const {transportEmissionFactors} = CFUtils;
    const planetransportEmissionFactors = transportEmissionFactors.plane;
    const {emission, speed} = planetransportEmissionFactors;
    return speed * emission * nbYearlyPlaneHours;
};

const getgCO2PlaneEmissions = (store: Store): number => {
    const nbYearlyPlaneHours = getNbYearlyPlaneHours(store);
    return getCalculatedPlaneEmissions(nbYearlyPlaneHours);
};

const getLastgCO2PlaneEmissions = (store: Store): number => {
    const lastAnswers = getState(store).lastAnswers;
    if(!lastAnswers) return 0;
    const nbYearlyPlaneHours = lastAnswers?.nbPlaneHours || 0;
    return getCalculatedPlaneEmissions(nbYearlyPlaneHours);
};

export const getgCO2TransportImpact = (store: Store): number =>
    getgCO2TotalTransportationEmissions(store) + getgCO2PlaneEmissions(store);
export const getTCO2TransportImpact = (store: Store): number =>
    getgCO2TransportImpact(store) / CFUtils?.gCO2toTCO2Ratio;
export const getKgCO2MeansOfTransport = (store: Store): number =>
    getgCO2TotalTransportationEmissions(store) / CFUtils?.gCO2toKgCO2Ratio;

/**
 * Returns the total impact (in kg) of user SOLO mobility
 * to display estimation on question 2: weekly means of transports
 * @param store
 * @returns {number}
 */
export const getKgCO2TransportOnlySolo = (store: Store): number =>
    (getgCO2SoloTransporsButCar(store) + getgCO2SoloTransportsOnlyCar(store)) / CFUtils?.gCO2toKgCO2Ratio;

/**
 * Returns the total carbon (in kg) saved by having multiple passengers in car
 * @param store
 * @returns {number}
 */
export const getKgCO2TransportSavedInCar = (store: Store): number =>
    getSavedgCO2Transports(store) / CFUtils?.gCO2toKgCO2Ratio;
export const getKgCO2Plane = (store: Store): number => getgCO2PlaneEmissions(store) / CFUtils?.gCO2toKgCO2Ratio;

const getgco2TotalTransportationEmissions = (store: Store): number => {
    const meansOfTransport = getWeeklyMeansOfTransport(store);
    const nbPeopleInCar = getNbPeopleInCar(store);
    return getCalculatedCo2TotalTransportationEmissions(meansOfTransport, nbPeopleInCar);
};

export const getMobilityDetailedParts = (store: Store): Array<FootprintDetailPartType> => {
    return [
        {
            title: 'Autres moyens de transport',
            value: (getgco2TotalTransportationEmissions(store) / CFUtils?.gCO2toKgCO2Ratio).toFixed(1),
            key: 'TransportMeans',
        },
        {
            title: 'Déplacements en avion',
            value: (getgCO2PlaneEmissions(store) / CFUtils?.gCO2toKgCO2Ratio).toFixed(1),
            key: 'PlaneTravel',
        },
    ].sort((a, b) => b.value - a.value);
};