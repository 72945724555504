/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcRockingHorse = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 350.74 350.74"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M72.666 129.292s-2.329-25.62-32.607-25.62c-30.277 0-35.867 38.663-31.209 56.829l8.385-4.192S26.551 203.357 0 215.001c0 0 54.5 19.564 61.487-50.308"
            style={{fill: "#804c09"}}
        />
        <Path
            d="m316.63 26.931 11.948-16.847C221.215-15.904 214.617 110.705 214.617 110.705l-2.546.001c-.02-.032-.042-.064-.061-.096-14.879.002-87.904.016-99.29.016-12.688 0-37.555 5.93-53.053 25.914-38 49 34.999 177.632 34.999 177.632h140s44.61-50.299 44.61-143.434c0-35.773 1.534-46.779 6.654-57.002 5.118-10.222 17.555-14.357 27.776-9.239a20.613 20.613 0 0 1 7.029 5.745l30.005-14.979-34.11-68.332z"
            style={{fill: "#ff9811"}}
        />
        <Path
            d="m164.666 110.618-51.946.008c-12.688 0-37.555 5.93-53.053 25.914-38 49 34.999 177.632 34.999 177.632h70V110.618z"
            style={{fill: "#be720d"}}
        />
        <Path
            d="M214.666 314.172v-40c0-27.615-22.386-50-50-50-27.613 0-50 22.385-50 50v40h100z"
            style={{fill: "#fff"}}
        />
        <Circle
            cx={294.666}
            cy={68.206}
            r={7.5}
            style={{fill: "#804c09"}}
        />
        <Path
            d="M296.872 266.369c-38.355 23.948-83.658 37.803-132.206 37.803S70.814 290.317 32.46 266.369L3.533 295.296c46.084 30.865 101.501 48.876 161.133 48.876s115.049-18.011 161.133-48.876l-28.927-28.927z"
            style={{fill: "#be720d"}}
        />
        <Path
            d="M164.666 304.172c-48.548 0-93.852-13.854-132.206-37.803L3.533 295.296c46.084 30.865 101.501 48.876 161.133 48.876v-40zM214.617 140.705h28.698l1.259-28.381c.084-1.246 1.652-22.593 10.87-42.756 4.784-10.466 10.259-17.657 15.917-22.579 4.402-3.828 8.917-6.284 13.305-7.844V8.144c-65.372 12.999-70.049 102.562-70.049 102.562h-.076v30h.076v-.001z"
            style={{fill: "#804c09"}}
        />
        <Path
            d="M164.666 110.616h-50v33.556c0 27.615 22.387 50 50 50s50-22.385 50-50v-33.556h-50z"
            style={{fill: "#ea348b"}}
        />
        <Path
            d="M114.666 110.616v33.556c0 27.615 22.387 50 50 50v-83.556h-50z"
            style={{fill: "#af2768"}}
        />
    </Svg>
);

export default IcRockingHorse;
