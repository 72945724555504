/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../../Config/Theme';

type Props = {
    color?: string,
};

const HouseConstruction = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={36} width={35} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M5.833 31.166V17.494L3.208 19.5l-1.75-2.333 4.375-3.354v-4.52H8.75v2.296l8.75-6.672 16.042 12.25-1.75 2.297-2.625-1.969v13.672H5.833ZM8.75 28.25h7.292v-5.833h2.916v5.833h7.292V15.27L17.5 8.598 8.75 15.27v12.98ZM5.833 7.833c0-1.216.426-2.249 1.276-3.1.851-.85 1.884-1.275 3.1-1.275.412 0 .76-.14 1.04-.42a1.41 1.41 0 0 0 .418-1.039h2.916c0 1.216-.425 2.249-1.276 3.1-.85.85-1.883 1.275-3.099 1.275-.413 0-.76.14-1.04.419-.279.28-.418.627-.418 1.04H5.833Z"
            fill={color}
        />
    </Svg>
);

export default HouseConstruction;