// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {View} from "react-native";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";

type Props = {
    children: Node,
    customStyle?: ViewStyleProp,
};

const BasicCard = ({
    children,
    customStyle = {},
    ...rest
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    return (
        <View
            style={{
                borderRadius: spacing[1] * 1.5,
                padding: spacing[2],
                backgroundColor: colors.background.transparent1,
                ...customStyle,
            }}
            {...rest}
        >
            {children}
        </View>
    );
};

export default BasicCard;