// @flow
import React from 'react';
import {FlatList, Platform, View} from 'react-native';
import {connect} from 'react-redux';
import locales from '../../../../../locales/locales';
import IcGreenElectricity from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcGreenElectricity';
import IcElectricity from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcElectricity';
import IcGas from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcGas';
import IcHeatingOil from '../../../../UI/Icons/CarbonFootprint/Accomodation/IcHeatingOil';
import {
    getEnergyTypes,
    getMonthlyEnergySpendings,
} from '../../../../../Reducers/CarbonFootprintReducers/CFEnergyReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import IcWood from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcWood";
import IcHeatNetwork from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcHeatNetwork";
import ESlider from "../../../../Shared/ESlider/ESlider";
import IcBiogas from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcBiogas";
import type {ItemInfos} from "../../../../../Types/Utils/ItemInfos";
import Heading from "../../../../UI/Typos/Heading/Heading";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import type {EnergyType, EnergyTypeSpendings} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
    setMonthlyEnergySpendings: (Array<EnergyTypeSpendings>) => void,
    energyTypes: Array<EnergyType>,
    monthlyEnergySpendings: Array<EnergyTypeSpendings>,
};

const options = {
    greenelectricity: <IcGreenElectricity />,
    electricity: <IcElectricity />,
    gas: <IcGas />,
    biogas: <IcBiogas />,
    heatingoil: <IcHeatingOil />,
    wood: <IcWood />,
    heatnetwork: <IcHeatNetwork />,
};

const QMonthlyEnergySpendings = ({
    setMonthlyEnergySpendings,
    energyTypes,
    monthlyEnergySpendings,
}: Props) => {
    function handleChange(energy: EnergyType, value: number){
        const filteredMonthlySpendings = monthlyEnergySpendings.filter(element => element.energy !== energy);
        const newMonthlySpendings = [...filteredMonthlySpendings, {energy, value}];
        setMonthlyEnergySpendings(newMonthlySpendings);
    }

    function renderItem({item}: ItemInfos<EnergyType>){
        const energyType = monthlyEnergySpendings.find(type => type.energy === item);
        const nbEuros = energyType ? energyType.value : 0;
        return (
            <>
                <Heading customStyle={{marginTop: 10}} variant='h3'>
                    {locales.t(`cf.${item}`)}
                </Heading>
                <ESlider
                    defaultValue={nbEuros}
                    hasSliderSubText
                    max={250}
                    onSlidingComplete={value => handleChange(item, value)}
                    step={5}
                    subText="€/mois"
                    thumbIcon={(<SliderThumbBackground>{options[item]}</SliderThumbBackground>)}
                />
            </>
        );
    }

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: 10}} />)}
            contentContainerStyle={{paddingBottom: (Platform.OS !== 'web' && energyTypes.length > 2) ? 30 : 4}}
            data={energyTypes}
            extraData={monthlyEnergySpendings}
            keyExtractor={item => item}
            renderItem={renderItem}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    monthlyEnergySpendings: getMonthlyEnergySpendings(store),
    energyTypes: getEnergyTypes(store),
    ...props,
});

const mapDispatchToProps = {setMonthlyEnergySpendings: CFActions.setMonthlyEnergySpendings};

export default connect(mapStateToProps, mapDispatchToProps)(QMonthlyEnergySpendings);
