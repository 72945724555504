/* eslint-disable max-len */
// @flow
import React, {useContext} from "react";
import Svg, {Path} from "react-native-svg";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";

const OnboardingBottom = () => {
    const [colors] = useContext(ThemeContext);
    return (
        <Svg fill="none" height={134} width={156} xmlns="http://www.w3.org/2000/svg">
            <Path
                clipRule="evenodd"
                d="M184.381 241.928c-30.857 12-62.889 22.499-93.802 10.648C50.99 237.4 2.006 213.949.842 171.569c-1.144-41.65 56.279-52.807 85.239-82.763C115.187 58.7 124.534-2.163 166.332.39c41.806 2.552 50.598 59.87 68.94 97.525 14.199 29.148 33.075 59.158 22.272 89.728-10.645 30.124-43.386 42.705-73.163 54.286Z"
                fill={colors.illustrations.yellow.light}
                fillRule="evenodd"
            />
        </Svg>
    );
};

export default OnboardingBottom;