/* eslint-disable max-len */
// @flow
import React, {useContext} from 'react';
import Svg, {Path} from 'react-native-svg';
import Config from '../../../Config/Config';
import {ThemeContext} from '../../../libs/Hoc/ThemeProvider';

const WebAppBottomRight = () => {
    const [colors] = useContext(ThemeContext);
    const dimensionRatio = 812 / 237;
    const height = Config.WINDOW_HEIGHT * 0.1646; // ratio from figma: height of exported component divided by 1440px
    const width = height * dimensionRatio;
    return (
        <Svg fill="none" height={height} viewBox='0 0 812 237' width={width} xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M522.874 76.082c1.849 1.269 2.977 2.023 4.222 2.885.337.12.794.324 1.312.427 5.244 1.17 5.751 1.628 5.75 4.214.009 4.238-2.299 7.938-5.376 11.43-9.51 10.856-21.444 20.711-37.101 29.052-7.848 4.156-17.035 7.481-26.465 10.656-13.275 4.452-27.641 7.909-43.264 10.302-24.777 3.782-51.04 5.394-78.499 4.969-13.353-.199-26.777-.52-39.661.269-15.939.983-31.324 2.7-44.678 6.515-10.515 3.006-21.198 5.942-30.867 9.504-11.694 4.296-21.923 9.465-30.957 15.244-12.291 7.859-23.918 16.023-38.113 22.963-10.152 4.959-21.858 8.762-34.943 11.704-9.86 2.217-19.83 4.282-30.76 5.374-11.475 1.163-23.642.769-35.88.254-12.855-.545-25.766-1.879-38.645-4.279-5.49-1.008-10.911-2.183-16.906-3.381-.43-.379-1.138-.979-1.725-1.494.338-2.177 2.945-2.932 6.567-3.061 6.168-.203 12.412-.327 18.55-.623 16.53-.781 33.132-1.461 48.048-4.454 5.966-1.193 11.942-2.451 17.314-4.115 9.998-3.109 20-6.24 29.067-9.94 7.312-2.99 13.736-6.565 19.656-10.329 9.328-5.956 18.032-12.187 26.739-18.44 9.761-7.026 20.881-13.351 33.98-18.677 6.499-2.626 13.119-5.167 20.106-7.495 26.636-8.89 57.487-13.134 92.077-13.097 19.251.02 38.252-.354 56.567-2.039 19.127-1.76 37.046-4.622 53.728-8.969 17.147-4.475 32.265-10.202 45.241-17.309 7.437-4.086 13.916-8.588 19.187-13.613.572-.535 1.184-1.042 1.796-1.55 3.376-2.843 7.743-4.618 15.67-3.835 6.133.595 12.274.567 18.284.808l-.021.13Z"
                fill={colors.illustrations.blue.light}
            />
            <Path
                clipRule="evenodd"
                d="M618.95 390.499c-89.721-.173-181.007-2.723-248.289-31.772-86.16-37.2-188.347-88.884-151.171-140.698 36.536-50.922 192.923-37.082 294.832-59.529 102.422-22.56 183.792-91.726 287.519-68.743 103.749 22.988 71.798 96.502 82.72 150.778 8.454 42.014 27.972 87.298-28.409 119.131-55.559 31.369-150.617 31.001-237.202 30.833Z"
                fill={colors.illustrations.blue.light}
                fillRule="evenodd"
            />
        </Svg>
    );
};

export default WebAppBottomRight;