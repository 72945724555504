/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcHeatingOil({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .58H25.81v36.563H0z" id="heating_oil_prefix__a" />
                <Path d="M1.11.908H5.41v2.149H1.11z" id="heating_oil_prefix__c" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <Path
                    d="M23.075 28.216H46.73V17.462H23.075v10.754zm0 10.756H46.73v-9.678H23.075v9.678zm0 11.823H46.73v-9.673H23.075v9.673z"
                    fill="#3B88C3"
                />
                <G transform="translate(22 15.806)">
                    <Mask fill="#fff" id="heating_oil_prefix__b">
                        <Use xlinkHref="#heating_oil_prefix__a" />
                    </Mask>
                    <Path
                        d="M25.809 1.847c0 .49-.398.883-.887.883H.882A.879.879 0 010 1.847v-.38A.88.88 0 01.882.581h24.04c.49 0 .887.397.887.886v.38zm0 11.833a.887.887 0 01-.887.883H.882A.883.883 0 010 13.68v-.385c0-.49.393-.887.882-.887h24.04c.49 0 .887.398.887.887v.385zm0 10.748c0 .49-.398.883-.887.883H.882A.879.879 0 010 24.428v-.379a.88.88 0 01.882-.886h24.04c.49 0 .887.392.887.886v.379zm0 11.832a.886.886 0 01-.887.883H.882A.882.882 0 010 36.26v-.385c0-.488.393-.885.882-.885h24.04c.49 0 .887.397.887.882v.388z"
                        fill="#269"
                        mask="url(#heating_oil_prefix__b)"
                    />
                </G>
                <G transform="translate(38.095 13.33)">
                    <Mask fill="#fff" id="heating_oil_prefix__d">
                        <Use xlinkHref="#heating_oil_prefix__c" />
                    </Mask>
                    <Path
                        d="M5.411 2.118a.94.94 0 01-.94.939H2.045a.936.936 0 01-.935-.939v-.27c0-.52.42-.94.935-.94h2.428c.516 0 .94.42.94.94v.27z"
                        fill="#99AAB5"
                        mask="url(#heating_oil_prefix__d)"
                    />
                </G>
            </G>
        </Svg>
    );
}

export default IcHeatingOil;
