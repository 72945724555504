/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
  size?: number,
};


function IcTeeShirt({size = 35}: Props){
    return (
        <Svg height={size} viewBox="0 0 27 27" width={size}>
            <Defs>
                <Path d="M0 .166h8.446v9.628H0z" id="prefix__a" />
                <Path d="M0 .454h2.78V8.19H0z" id="prefix__c" />
                <Path d="M.569.166h8.447v9.628H.569z" id="prefix__e" />
                <Path d="M.129.166h18.356V22.4H.13z" id="prefix__g" />
                <Path d="M.443.166h9.728V3.64H.443z" id="prefix__i" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(1 2.633)">
                    <Mask fill="#fff" id="prefix__b">
                        <Use xlinkHref="#prefix__a" />
                    </Mask>
                    <Path
                        d="M7.643.166C5.559.166 0 1.555 0 2.947c0 1.388.697 4.862 1.391 6.254.695 1.388 5.56 0 6.252-.695.696-.697 1.39-8.34 0-8.34"
                        fill="#3B88C3"
                        mask="url(#prefix__b)"
                    />
                </G>
                <G transform="translate(1 4.233)">
                    <Mask fill="#fff" id="prefix__d">
                        <Use xlinkHref="#prefix__c" />
                    </Mask>
                    <Path
                        d="M.697.454C.247.711 0 1.044 0 1.346c0 1.39.697 4.863 1.392 6.255.194.389.722.538 1.388.59C2.085 6.208 1.392 3.43.697.453"
                        fill="#55ACEE"
                        mask="url(#prefix__d)"
                    />
                </G>
                <G transform="translate(17 2.633)">
                    <Mask fill="#fff" id="prefix__f">
                        <Use xlinkHref="#prefix__e" />
                    </Mask>
                    <Path
                        d="M1.37.166c2.086 0 7.646 1.389 7.646 2.781 0 1.388-.696 4.862-1.392 6.254-.693 1.388-5.559 0-6.253-.695-.695-.697-1.39-8.34 0-8.34"
                        fill="#3B88C3"
                        mask="url(#prefix__f)"
                    />
                </G>
                <Path
                    d="M25.32 4.688c.45.257.696.59.696.892 0 1.389-.697 4.862-1.391 6.254-.195.39-.723.539-1.389.59.694-1.982 1.389-4.76 2.083-7.736"
                    fill="#55ACEE"
                />
                <G transform="translate(4.2 2.633)">
                    <Mask fill="#fff" id="prefix__h">
                        <Use xlinkHref="#prefix__g" />
                    </Mask>
                    <Path
                        d="M14.17.166h-.742c-.288 1.178-2.02 2.086-4.12 2.086S5.476 1.344 5.185.166h-.742C2.91.166.972 1.412.972 2.947c0 0 0 13.896-.698 16.673-.694 2.78 1.245 2.78 2.78 2.78h12.508c1.535 0 3.474 0 2.777-2.78-.691-2.777-.691-16.673-.691-16.673 0-1.535-1.942-2.78-3.477-2.78"
                        fill="#3B88C3"
                        mask="url(#prefix__h)"
                    />
                </G>
                <G transform="translate(8.2 2.633)">
                    <Mask fill="#fff" id="prefix__j">
                        <Use xlinkHref="#prefix__i" />
                    </Mask>
                    <Path
                        d="M5.308 3.64c2.687 0 4.863-1.554 4.863-3.474h-.743c-.287 1.178-2.02 2.086-4.12 2.086S1.475 1.344 1.185.166H.443c0 1.92 2.179 3.474 4.865 3.474"
                        fill="#55ACEE"
                        mask="url(#prefix__j)"
                    />
                </G>
            </G>
        </Svg>
    );
}

export default IcTeeShirt;
