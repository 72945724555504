// @flow
import {Dimensions, Platform} from 'react-native';
import Constants from 'expo-constants';

const url = Constants.experienceUrl || "";
const isExpo = Constants.appOwnership === 'expo'; // always null when using EAS according to doc

// const devApi = 'http://192.168.1.39:9000';
const prodApi = 'https://prod.v2.energic.io';

const IS_DEV = process.env.APP_VARIANT === 'development';
const isLocalOrDev = url.includes('staging') || url.includes('localhost') || isExpo || IS_DEV;
const api = Constants.expoConfig?.extra?.apiUrl ?? prodApi;

// Handle phone frame & other size calculations for screen of height above 876px && width above 444px
// else, we use the actual windows dimensions
const {height: WINDOW_HEIGHT, width: WINDOW_WIDTH} = Dimensions.get('window');
const mobileScreenLimits = {
    width: 444,
    height: 876,
};
const DEVICE_WIDTH = WINDOW_WIDTH > mobileScreenLimits.width
    ? mobileScreenLimits.width + (Platform.OS === 'web' ? 4 : 0) // add border width x2 on web to insure exact width
    : WINDOW_WIDTH;
const DEVICE_HEIGHT = WINDOW_HEIGHT > mobileScreenLimits.height ? mobileScreenLimits.height : WINDOW_HEIGHT;

const MENU_BOTTOM_HEIGHT = 64;

const showVScrollBar = Platform.OS === 'web' && WINDOW_HEIGHT < mobileScreenLimits.height;

const PAGINATION_OFFSET = {
    teamAgora: 20,
    agora: 5,
    ideas: 6,
    notificationCenter: 8,
    podium: 10,
    ecogestureFeedbacks: 5,
    kiosqueFeedbacks: 5,
    filteredEcogestures: 8,
    filteredKiosque: 8,
};

export default {
    api: api || '',
    savedKeys: ['auth', 'carbonFootprint', 'help', 'colors'],
    isLocalOrDev,
    quizzTimer: 25,
    ideaNbMinCharacter: 50,
    DEVICE_HEIGHT,
    DEVICE_WIDTH,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    mobileScreenLimits,
    MENU_BOTTOM_HEIGHT,
    PAGINATION_OFFSET,
    showVScrollBar,
};
