// @flow
import React from 'react';
import {FlatList, Platform, View} from 'react-native';
import {connect} from 'react-redux';
import locales from '../../../../../locales/locales';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import {
    getDrinkTypes,
    getDrinkTypesQuantities,
} from "../../../../../Reducers/CarbonFootprintReducers/CFDietReducer";
import IcAlcohol from "../../../../UI/Icons/CarbonFootprint/Diet/IcAlcohol";
import IcTea from "../../../../UI/Icons/CarbonFootprint/Diet/IcTea";
import IcCapsuleCoffee from "../../../../UI/Icons/CarbonFootprint/Diet/IcCapsuleCoffee";
import IcCoffee from "../../../../UI/Icons/CarbonFootprint/Diet/IcCoffee";
import type {ItemInfos} from "../../../../../Types/Utils/ItemInfos";
import Heading from "../../../../UI/Typos/Heading/Heading";
import Body from "../../../../UI/Typos/Body/Body";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderLabelContainer from "../../../../Shared/ESlider/SliderLabelContainer";
import type {DrinkType, DrinkTypeQuantity} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
    setDrinkTypesQuantities: Array<DrinkTypeQuantity> => void,
    drinkTypesQuantities: Array<DrinkTypeQuantity>,
    drinkTypes: Array<DrinkType>,
};

const options = {
    coffee: <IcCoffee height={60} width={60} />,
    capsule_coffee: <IcCapsuleCoffee height={60} width={60} />,
    tea_or_chocolate: <IcTea height={60} width={60} />,
    alcool: <IcAlcohol height={60} width={60} />,
};

const quantities = ['verylittle', 'little', 'alot', 'enormously', 'madly'];

const QDrinkTypesQuantities = ({
    setDrinkTypesQuantities,
    drinkTypes,
    drinkTypesQuantities,
}: Props) => {
    function handleChange(drinkType: DrinkType, value: number){
        const filteredDrinkTypes = drinkTypesQuantities.filter(element => element.drinkType !== drinkType);
        const newFilteredDrinkTypes = [...filteredDrinkTypes, {drinkType, quantity: quantities[value]}];
        setDrinkTypesQuantities(newFilteredDrinkTypes);
    }

    function renderItem({item}: ItemInfos<DrinkType>){
        const drinkQuantity = drinkTypesQuantities.find(qty => qty.drinkType === item);
        const currentQuantity = drinkQuantity ? drinkQuantity.quantity : 'verylittle';
        return (
            <>
                <Heading customStyle={{marginTop: 10}} variant='h3'>
                    {`${
                        locales.t(`cf.diet.drink_type_label.${item}`)
                    } (${locales.t(`cf.diet.drink_type_unit.${item}`)})`}
                </Heading>
                <ESlider
                    defaultValue={quantities.indexOf(currentQuantity)}
                    hasTrackMarks
                    max={quantities.length - 1}
                    onSlidingComplete={value => handleChange(item, value)}
                    renderBelowLabel={(value) => (
                        <SliderLabelContainer>
                            <Heading centered variant='h3'>
                                {locales.t(`cf.${quantities[value]}`)}
                            </Heading>
                            <Body centered variant='paragraph'>
                                {locales.t(`cf.diet.drink_quantity_label.${quantities[value]}`)}
                            </Body>
                        </SliderLabelContainer>
                    )}
                    step={1}
                    thumbIcon={(<SliderThumbBackground>{options[item]}</SliderThumbBackground>)}
                />
            </>
        );
    }

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: 10}} />)}
            contentContainerStyle={{paddingBottom: (Platform.OS !== 'web' && drinkTypes.length > 2) ? 30 : 4}}
            data={drinkTypes}
            keyExtractor={item => item.toString()}
            renderItem={renderItem}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    drinkTypes: getDrinkTypes(store),
    drinkTypesQuantities: getDrinkTypesQuantities(store),
    ...props,
});
const mapDispatchToProps = {setDrinkTypesQuantities: CFActions.setDrinkTypesQuantities};

export default connect(mapStateToProps, mapDispatchToProps)(QDrinkTypesQuantities);
