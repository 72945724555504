// @flow
import React from 'react';
import {connect} from 'react-redux';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import IcTrash from "../../../../UI/Icons/CarbonFootprint/Consumption/IcTrash";
import {getMonthlyTrashBags} from "../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
  setMonthlyTrashBags: number => void,
  monthlyTrashBags: number,
};

const QMonthlyTrashbags = ({
    setMonthlyTrashBags,
    monthlyTrashBags,
}: Props) => {
    return (
        <>
            <SliderScaleMinMax
                max='10'
                min='0'
            />
            <ESlider
                defaultValue={monthlyTrashBags}
                hasSliderSubText
                max={10}
                onSlidingComplete={setMonthlyTrashBags}
                step={1}
                subText="sac(s) poubelle"
                thumbIcon={(<SliderThumbBackground><IcTrash /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    monthlyTrashBags: getMonthlyTrashBags(store),
    ...props,
});
const mapDispatchToProps = {setMonthlyTrashBags: CFActions.setMonthlyTrashBags};

export default connect(mapStateToProps, mapDispatchToProps)(QMonthlyTrashbags);
