// @flow
import React from 'react';
import {connect} from 'react-redux';
import locales from '../../../../../locales/locales';
import IcMail from '../../../../UI/Icons/CarbonFootprint/Consumption/IcMail';
import {getDailyMailSending} from '../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store as TypeStore} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import Body from "../../../../UI/Typos/Body/Body";
import Heading from "../../../../UI/Typos/Heading/Heading";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderLabelContainer from "../../../../Shared/ESlider/SliderLabelContainer";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";
import type {QuantityType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setDailyMailSending: QuantityType => void,
  dailyMailSending: QuantityType,
};

const frequencies = ['never', 'verylittle', 'little', 'alot', 'enormously', 'madly'];

const QDailyMailSending = ({
    setDailyMailSending,
    dailyMailSending,
}: Props) => {
    function handleChange(value: number){
        const frequency = frequencies[value];
        setDailyMailSending(frequency);
    }

    function getNumberFromQuantityType(value: QuantityType): string{
        switch (value){
            case 'never':
                return '';
            case 'verylittle':
                return 'Moins de 10 mails / jour';
            case 'little':
                return 'Entre 10 et 20 mails / jour';
            case 'alot':
                return 'Entre 20 et 50 mails / jour';
            case 'enormously':
                return 'Entre 50 et 100 mails / jour';
            case 'madly':
                return 'Plus de 100 mails / jour';
            default:
                return '';
        }
    }

    return (
        <>
            <SliderScaleMinMax
                max='À la folie'
                min='Jamais'
            />
            <ESlider
                defaultValue={frequencies.indexOf(dailyMailSending)}
                hasTrackMarks
                max={frequencies.length - 1}
                onSlidingComplete={handleChange}
                renderBelowLabel={(value) => (
                    <SliderLabelContainer>
                        <Heading centered variant='h3'>
                            {locales.t(`cf.${frequencies[value]}`)}
                        </Heading>
                        <Body centered variant='paragraph'>
                            {getNumberFromQuantityType(frequencies[value])}
                        </Body>
                    </SliderLabelContainer>
                )}
                step={1}
                thumbIcon={(<SliderThumbBackground><IcMail /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    dailyMailSending: getDailyMailSending(store),
    ...props,
});

const mapDispatchToProps = {setDailyMailSending: CFActions.setDailyMailSending};

export default connect(mapStateToProps, mapDispatchToProps)(QDailyMailSending);
