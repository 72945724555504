// @flow
import React, {useContext, useMemo} from 'react';
import {View} from "react-native";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Touchable from '../../UI/Buttons/Touchable/Touchable';
import Body from "../../UI/Typos/Body/Body";

export type Props = {
    Icon: Element<any>,
    onPress?: () => any,
    selected: boolean,
    cardLegend: string,
    horizontal?: boolean,
};

const RoundedAnswerItem = ({
    Icon,
    onPress = () => null,
    selected,
    cardLegend = '',
    horizontal = false,
}: Props) => {
    const [colors] = useContext(ThemeContext);
    const flexStyle = useMemo(() => horizontal
        ? {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}
        : {alignItems: 'center'}, [horizontal]);
    const width: string | number = useMemo(() => horizontal ? '90%' : 120, [horizontal]);
    const textAlign = useMemo(() => horizontal ? undefined : 'center', [horizontal]);

    return (
        <Touchable
            onPress={onPress}
            style={({pressed}) => [
                {
                    alignItems: 'center',
                    width,
                    opacity: pressed ? 0.85 : 1,
                },
                flexStyle,
            ]}
        >
            <View
                style={{
                    backgroundColor: colors.text.white,
                    borderRadius: 60,
                    width: 60,
                    height: 60,
                    borderWidth: 2,
                    borderColor: selected ? colors.action : colors.background.disabled,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Icon />
            </View>
            <Body
                customStyle={{
                    marginTop: horizontal ? 0 : 6,
                    width: '85%',
                    textAlign,
                    marginLeft: horizontal ? 8 : 0,
                }}
                variant='paragraph'
            >
                {cardLegend}
            </Body>
        </Touchable>
    );
};

export default RoundedAnswerItem;
