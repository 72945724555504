// @flow
import React, {useContext} from "react";
import type {Node} from 'react';
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import Touchable from '../../Buttons/Touchable/Touchable';
import ActionIcon from "../../Icons/IconContainers/ActionIcon/ActionIcon";
import LeftChevron from "../../Icons/UI/LeftChevron";
import Heading, {HeadingVariant} from "../../Typos/Heading/Heading";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";

type Props = {
    backButton?: boolean,
    title?: string,
    onPress?: any => any,
    endComponent?: Node,
    customStyle?: ViewStyleProp,
    headingVariant?: HeadingVariant,
};

const LightPageHeader = ({
    backButton = false,
    title = null,
    onPress = () => null,
    endComponent = null,
    customStyle = {},
    headingVariant = 'h2',
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <Touchable
            onPress={onPress}
            style={({pressed}) => [
                {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: endComponent ? 'space-between' : 'flex-start',
                    opacity: pressed ? 0.7 : 1,
                    marginBottom: spacing[3],
                },
                customStyle,
            ]}
        >
            {backButton ? (
                <ActionIcon onPress={onPress}>
                    <LeftChevron />
                </ActionIcon>
            ) : null}
            {
                title
                    ? (
                        <Heading
                            customStyle={{
                                marginLeft: backButton ? spacing[1] : 0,
                                marginRight: endComponent ? spacing[1] : 0,
                                flex: 1,
                            }}
                            selectable={false}
                            variant={headingVariant}
                        >
                            {title}
                        </Heading>
                    )
                    : null
            }
            {endComponent}
        </Touchable>
    );
};

export default LightPageHeader;