// @flow
import React, {useContext} from 'react';
import type {Node} from 'react';
import {LinearGradient} from "expo-linear-gradient";
import {ThemeContext} from "../../../../libs/Hoc/ThemeProvider";

type Props = {
    children: Node,
};

const BaseLayout = ({children}: Props) => {
    const [colors] = useContext(ThemeContext);
    return (
        <LinearGradient
            colors={[
                colors.background.gradient_start,
                colors.background.gradient_end,
            ]}
            end={{x: 0, y: 1}}
            locations={[0, 1]}
            start={{x: 0, y: 0.001}}
            style={{flex: 1}}
        >
            {children}
        </LinearGradient>
    );
};

export default BaseLayout;