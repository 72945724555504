// @flow
import type {Store as TypeStore} from "../../../../../Types/Reducers/Store";
import MobilityQuestionsMap from "./MobilityQuestionsMap";
import appRoutes from "../../../../../Navigation/AppRoutes";
import {connect} from "react-redux";
import CFQuestions from "../../CFQuestion/CFQuestions";

const mapStateToProps = (store: TypeStore, props: Object) => ({
    questions: MobilityQuestionsMap,
    store,
    userFirstName: '',
    onPressBefore: () => props.navigation.navigate(appRoutes.cf.mobility.thematic),
    onCompleteThematic: () => props.navigation.navigate(appRoutes.cf.diet.thematic),
    comesFromGoBack: props?.route?.params?.back ?? false,
    ...props,
});

export default connect(mapStateToProps)(CFQuestions);