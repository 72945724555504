/* eslint-disable no-console */
// @flow
import 'expo-dev-client';
import "react-native-reanimated";
import 'react-native-gesture-handler';
import {Asset} from 'expo-asset';
import * as Font from 'expo-font';
import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ActionSheetProvider} from '@expo/react-native-action-sheet';
import {
    LogBox,
    Image, ScrollView,
} from 'react-native';
import {Provider} from 'react-redux';
import EToaster from './src/Components/UI/Toasters/EToaster';
import Store from './src/Config/Store';
import {navigationRef} from './src/Navigation/NavigationService';
import {NavigationContainer} from '@react-navigation/native';
import {requestTrackingPermissionsAsync} from 'expo-tracking-transparency';
import ModalManager from "./src/Components/UI/Modals/ModalManager/ModalManager";
import {GestureHandlerRootView} from "react-native-gesture-handler";
import {ThemeProvider} from "./src/libs/Hoc/ThemeProvider";
import WebAppDecorator from './src/Views/WebAppDecorator';
import * as SplashScreen from 'expo-splash-screen';
import CarbonFootprintNavigator from "./src/Navigation/MainPagesNavigators/CarbonFootprintNavigator";
import {createMatomoInstance} from "./src/libs/Matomo/MatomoTracker";
import {NavigationContainerRef} from "@react-navigation/native";

LogBox.ignoreLogs([
    'Require cycle:',
    'Provided value to SecureStore',
    'VirtualizedLists should never be nested',
]);

const loadLocales = async() => {
    moment.locale('fr-FR', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L',
        },
        relativeTime : {
            future : 'dans %s',
            past : 'il y a %s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans',
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function(number){
            return number + (number === 1 ? 'er' : 'e');
        },
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 4, // Used to determine first week of the year.
        },
    });
    return Promise.resolve();
};

function cacheImages(images){
    return images.map(image => {
        if (typeof image === 'string')
            return Image.prefetch(image);
        else
            return Asset.fromModule(image).downloadAsync();
    });
}

function cacheFonts(fonts){
    return fonts.map(font => Font.loadAsync(font));
}

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const App = () => {
    const [appIsReady, setAppIsReady] = useState(false);
    const routeNameRef = useRef<NavigationContainerRef>();
    const instance = createMatomoInstance();
    instance?.trackAppStart();

    useEffect(() => {
        async function prepare(){
            try {
                const imageAssets = cacheImages([
                    // web
                    require('./src/assets/images/web/energic_white.png'),
                    require('./src/assets/images/web/energic_blue.png'),
                    require('./src/assets/images/web/app_qr_code.png'),
                ]);

                const fontAssets = cacheFonts([
                    {'Montserrat': require('./src/assets/fonts/Montserrat-Regular.ttf')},
                    {'Montserrat-Bold': require('./src/assets/fonts/Montserrat-SemiBold.ttf')},
                    {'Montserrat-Light': require('./src/assets/fonts/Montserrat-Light.ttf')},
                ]);

                await Promise.all([
                    ...imageAssets,
                    ...fontAssets,
                    loadLocales(),
                    requestTrackingPermissionsAsync(),
                ]);
            } catch (e){
                console.warn(e);
            } finally {
                // Tell the application to render
                setAppIsReady(true);
            }
        }

        prepare();
    }, []);

    const onLayoutRootView = useCallback(async() => {
        if (appIsReady){
            // This tells the splash screen to hide immediately! If we call this after
            // `setAppIsReady`, then we may see a blank screen while the app is
            // loading its initial state and rendering its first pixels. So instead,
            // we hide the splash screen once we know the root view has already
            // performed layout.
            await SplashScreen.hideAsync();
        }
    }, [appIsReady]);

    if (!appIsReady)
        return null;

    return (
        <GestureHandlerRootView onLayout={onLayoutRootView} style={{flex: 1}}>
            <Provider store={Store}>
                <ActionSheetProvider>
                    <ThemeProvider>
                        <NavigationContainer
                            onReady={() => {
                                routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name;
                            }}
                            onStateChange={() => {
                                const previousRouteName = routeNameRef.current;
                                const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name;
                                if (previousRouteName !== currentRouteName){
                                    instance?.trackScreenView(currentRouteName);
                                }
                                routeNameRef.current = currentRouteName;
                            }}
                            ref={navigationRef}
                        >
                            <WebAppDecorator>
                                <ScrollView contentContainerStyle={{flex: 1}}>
                                    <CarbonFootprintNavigator />
                                </ScrollView>
                                {EToaster.instance}
                                {ModalManager.instance}
                            </WebAppDecorator>
                        </NavigationContainer>
                    </ThemeProvider>
                </ActionSheetProvider>
            </Provider>
        </GestureHandlerRootView>
    );
};

export default App;
