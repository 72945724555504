// @flow
import React from "react";
import {View} from "react-native";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import Body from "../../UI/Typos/Body/Body";

type Props = {
    min: string,
    max: string,
    customStyle?: ViewStyleProp,
};

const SliderScaleMinMax = ({
    min,
    max,
    customStyle = {},
}: Props) => (
    <View
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
            ...customStyle,
        }}
    >
        <Body variant='paragraph'>{min}</Body>
        <Body variant='paragraph'>{max}</Body>
    </View>
);

export default SliderScaleMinMax;