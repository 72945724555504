/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const Close = ({
    color = theme.colors.light.text.white,
    size = 18,
}: Props) => (
    <Svg
        fill="none"
        height={size}
        viewBox='0 0 18 18'
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M4.807 14.241 3.76 13.193 7.952 9 3.759 4.807 4.807 3.76 9 7.952l4.193-4.193 1.048 1.048L10.048 9l4.193 4.193-1.048 1.048L9 10.048l-4.193 4.193Z"
            fill={color}
        />
    </Svg>
);

export default Close;