// @flow
import React, {useContext} from 'react';
import {connect} from "react-redux";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import type {Store as TypeStore} from "../../../../../Types/Reducers/Store";
import IcClothesDryer from "../../../../UI/Icons/CarbonFootprint/Consumption/IcClothesDryer";
import IcDishwasher from "../../../../UI/Icons/CarbonFootprint/Consumption/IcDishwasher";
import IcFreezer from "../../../../UI/Icons/CarbonFootprint/Consumption/IcFreezer";
import IcFridge from "../../../../UI/Icons/CarbonFootprint/Consumption/IcFridge";
import IcMicrowave from "../../../../UI/Icons/CarbonFootprint/Consumption/IcMicrowave";
import IcMixer from "../../../../UI/Icons/CarbonFootprint/Consumption/IcMixer";
import IcOven from "../../../../UI/Icons/CarbonFootprint/Consumption/IcOven";
import IcStove from "../../../../UI/Icons/CarbonFootprint/Consumption/IcStove";
import IcVacuumCleaner from "../../../../UI/Icons/CarbonFootprint/Consumption/IcVacuumCleaner";
import IcWashingMachine from "../../../../UI/Icons/CarbonFootprint/Consumption/IcWashingMachine";
import IcWineCellar from "../../../../UI/Icons/CarbonFootprint/Consumption/IcWineCellar";
import Body from "../../../../UI/Typos/Body/Body";
import MultiAnswerCountContainer from "../../MultiAnswerCountContainer";
import CFActions from "../../../../../Actions/CarbonFootprintActions";
import {getAppliancesPurchases} from "../../../../../Reducers/CarbonFootprintReducers/CFConsumptionReducer";
import type {ElementMap} from "../../../../../Types/Reducers/ElementMap";
import type {ApplianceDeviceType, DeviceValueCount} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
    addAppliancesPurchase: ApplianceDeviceType => void,
    removeAppliancesPurchase: ApplianceDeviceType => void,
    appliancesPurchases: ElementMap<ApplianceDeviceType, DeviceValueCount>,
};

const options: Array<{value: ApplianceDeviceType, icon: Node}> = [
    {value: 'oven', icon: IcOven},
    {value: 'stove', icon: IcStove},
    {value: 'microwave', icon: IcMicrowave},
    {value: 'dishwasher', icon: IcDishwasher},
    {value: 'washing_machine', icon: IcWashingMachine},
    {value: 'clothes_dryer', icon: IcClothesDryer},
    {value: 'fridge', icon: IcFridge},
    {value: 'freezer', icon: IcFreezer},
    {value: 'wine_cellar', icon: IcWineCellar},
    {value: 'vacuum_cleaner', icon: IcVacuumCleaner},
    {value: 'mixer', icon: IcMixer},
];

const QAppliancesPurchases = ({
    addAppliancesPurchase,
    removeAppliancesPurchase,
    appliancesPurchases,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    return (
        <MultiAnswerCountContainer
            addItem={addAppliancesPurchase}
            listHeaderComponent={(
                <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                    {"Plusieurs réponses possibles"}
                </Body>
            )}
            numColumns={2}
            options={options}
            removeItem={removeAppliancesPurchase}
            selectedValues={appliancesPurchases}
        />
    );
};

const mapStateToProps = (store: TypeStore, props: Object) => ({
    ...props,
    appliancesPurchases: getAppliancesPurchases(store),
});

const mapDispatchToProps = {
    addAppliancesPurchase: CFActions.addAppliancesPurchase,
    removeAppliancesPurchase: CFActions.removeAppliancesPurchase,
};

export default connect(mapStateToProps, mapDispatchToProps)(QAppliancesPurchases);
