// @flow
import {Animated, Easing} from "react-native";
import Config from "../../Config/Config";
import {useState} from "react";

/*
    Use ease-out when object enters the screen (it decelerates)
    Use ease-in when object leaves the screen (it accelerates)
*/

const DURATION = 200;
const MAX_VALUE = Config.DEVICE_WIDTH;
const MIN_VALUE = 0;

function useEaseAnim(delay: number = 0){
    // todo: fix rule below by replacing useState with useMemo/useRef
    // eslint-disable-next-line react/hook-use-state
    const [leftPos] = useState(new Animated.Value(MAX_VALUE));
    const [running, setRunning] = useState<boolean>(false);


    const arriveFromRight = (callback: () => void = () => null) => {
        setRunning(true);
        leftPos.setValue(MAX_VALUE);
        Animated.sequence([
            Animated.delay(delay),
            Animated.timing(leftPos, {
                toValue: MIN_VALUE,
                duration: DURATION,
                easing: Easing.out(Easing.linear),
                useNativeDriver: false,
            }),
        ]).start(() => {
            setRunning(false);
            callback?.();
        });
    };

    const leaveToRight = (callback: () => void = () => null) => {
        setRunning(true);
        leftPos.setValue(MIN_VALUE);
        Animated.sequence([
            Animated.delay(delay),
            Animated.timing(leftPos, {
                toValue: MAX_VALUE,
                duration: DURATION,
                easing: Easing.in(Easing.linear),
                useNativeDriver: false,
            }),
        ]).start(() => {
            setRunning(false);
            callback?.();
        });
    };

    const leaveToLeft = (callback: () => void = () => null) => {
        setRunning(true);
        leftPos.setValue(MIN_VALUE);
        Animated.sequence([
            Animated.delay(delay),
            Animated.timing(leftPos, {
                toValue: -MAX_VALUE,
                duration: DURATION,
                easing: Easing.in(Easing.linear),
                useNativeDriver: false,
            }),
        ]).start(() => {
            setRunning(false);
            callback?.();
        });
    };

    const arriveFromLeft = (callback: () => void = () => null) => {
        setRunning(true);
        leftPos.setValue(-MAX_VALUE);
        Animated.sequence([
            Animated.delay(delay),
            Animated.timing(leftPos, {
                toValue: MIN_VALUE,
                duration: DURATION,
                easing: Easing.out(Easing.linear),
                useNativeDriver: false,
            }),
        ]).start(() => {
            setRunning(false);
            callback?.();
        });
    };

    return [
        leftPos,
        arriveFromRight,
        leaveToRight,
        arriveFromLeft,
        leaveToLeft,
        running,
        setRunning,
    ];
}

export default useEaseAnim;