/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from 'react-native-svg';
type Props = {
    size?: number,
};

function IcWalk({size = 35}: Props){
    return (
        <Svg
            aria-hidden="true"
            height={size}
            viewBox="0 0 36 36"
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="m18.011 34.975-.052.016c.018-.068.042-.19.042-.383 0-.303-.156-.869-.199-.95s-.09-.139-.194-.11c-.079.022-.685.326-1.072.341-.719.027-1.066-.618-1.066-.618s-.399.228-.716.413c-.318.185-.687.462-.959.627-.232.141-.497.208-.771.243s-.497.023-.563.029c-.066.006-.621-.061-.641.488l.003.127-.054-.026s-.048.17-.052.202c-.004.033-.024.052.046.096.07.044.378.176.77.274s1.028.243 1.519.243.909-.098 1.151-.156c.243-.058.763-.169.813-.146.05.023.116.191.173.243.058.052 1.61-.081 1.721-.104a.177.177 0 0 0 .146-.162v-.649c.002-.027-.021-.045-.045-.038zm10.947-3.752-.504-.429a.038.038 0 0 0-.059.012l-.022.051a1.523 1.523 0 0 0-.269-.285c-.235-.2-.777-.454-.868-.473-.091-.02-.167-.022-.213.077-.035.076-.199.746-.444 1.056-.454.575-1.184.418-1.184.418l-.153.828c-.067.368-.096.838-.148 1.158a1.954 1.954 0 0 1-.322.758c-.154.236-.31.4-.35.456-.039.055-.457.441-.045.82l.101.081-.055.025s.1.149.122.174c.022.025.025.053.104.028s.386-.177.721-.416c.336-.24.868-.637 1.192-1.018.325-.381.524-.77.64-.996.116-.226.373-.703.424-.727.051-.024.224.036.303.026.078-.01 1.001-1.302 1.057-1.403s.005-.193-.028-.221z"
                fill="#292F33"
            />
            <Path
                d="M19.3 12.243c.927.96 3.062 2.89 3.109 3.121.251 1.223.614 2.606.52 3.454-.068.615.376 1.298.551 1.583.218.354.781.898 1.141.86.224-.023.567-.43.384-.636-.357-.4-.298-1.009-.522-1.559-.449-1.105-.045-3.194-.661-4.563-.256-.567-.733-1.693-2.824-3.626-.511.462-1.698 1.366-1.698 1.366z"
                fill="#F9CA55"
            />
            <Path
                d="M19.005 1.384c-1.593-.627-4.077.182-4.365 2.043-.287 1.848.239 4.747 1.863 4.572 1.702-.184 3.448-.554 4.138-2.307s-.043-3.681-1.636-4.308z"
                fill="#FFDC5D"
            />
            <Path
                d="M20.189 6.143c2.318-2.723-3.266-2.458-3.266-2.458-1.057.038-.329 1.799-.827 2.761-.341.665 1.095 1.018 1.095 1.018s.659-.01.694.79v.007c.008.204-.013.445-.108.769-.473 1.601 1.677 2.582 2.149.978.187-.635.114-1.193.02-1.708l-.009-.046c-.144-.766-.322-1.437.252-2.111z"
                fill="#FFDC5D"
            />
            <Path
                d="M20.825 2.026c-1.061-1.242-2.58-1.901-5.019-.791-.994.452-1.439.285-1.58.484-.679.953.246 2.01.608 1.799 1.148-.669 2.183-.47 2.447.014s.021 1.354.234 1.359c.579.015.485-.552.714-.878.375-.534.946-.232 1.071.362.099.471 0 1.271-.77 1.412.523 1.151 1.56 1.502 1.56 1.502s.337.132.912-1.001c.576-1.134.877-3.029-.177-4.262z"
                fill="#FFAC33"
            />
            <Path
                d="M16.062 34.203c-1.266.109-1.853-.233-1.721-.416.165-.228.128-.397.13-.536C14.499 30.81 14 27.26 14 27.26c0-.348.003-.813.312-1.562.778-1.883 3.951-7.69 3.951-7.69a2 2 0 0 1 2.729-.744c.959.548 1.122 1.405.744 2.729-.715 2.508-2.965 5.602-3.903 7.477.224 2.121-.174 3.853.035 5.857.03.288-.54.767-1.806.876z"
                fill="#2A6797"
            />
            <Path
                d="M26.797 31.931c-.364.553-.97.942-1.598.838-1.269-1.924-4.955-5.321-4.955-5.321-.241-.25-.562-.587-.86-1.341-.748-1.895-2.498-8.277-2.498-8.277a1.999 1.999 0 0 1 1.446-2.43c1.07-.272 1.783.232 2.43 1.446 1.227 2.301 1.757 6.09 2.384 8.09 1.87 1.568 2.383 3.603 4.275 5.151-.065.857-.26 1.291-.624 1.844z"
                fill="#4289C1"
            />
            <Path
                d="M23 20s0 1-2 1h-4.898c-.415-2-.027-5.004.006-7.765.043-3.623 2.298-5.609 3.71-5.155 1.846.594 2.693 2.641 2.932 5.858S23 20 23 20z"
                fill="#77B255"
            />
            <Path
                d="M17.75 11.792c-.167 1.399-.322 4.433-.479 4.625-.833 1.021-1.722 2.24-2.479 2.729-.549.354-.811 1.174-.927 1.507-.144.414-.213 1.238.057 1.507.169.168.73.177.776-.11.09-.559.626-.917.927-1.467.604-1.104 2.583-2.167 3.292-3.584.294-.588.896-1.729 1.083-4.729-.72-.11-2.25-.478-2.25-.478z"
                fill="#FFDC5D"
            />
        </Svg>
    );
}

export default IcWalk;
