// @flow
import type {ThemeColorsType} from "../../Types/ThemeTypes";
import type {FootprintSectionString} from "../../Types/CarbonFootprint/FootprintTypes";

export const hexToRgb = (hex: string): number[] =>
    // match hex of 3 or 6 numbers i.e. #234 or #234567, keep only numbers
    hex.match(/^#?([a-f\d][a-f\d][a-f\d]|[a-f\d]{2}[a-f\d]{2}[a-f\d]{2})$/i)?.[1]
        // if length of exactly 3 replace by doubling numbers i.e. 234 -> 223344
        .replace(
            /^([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => '' + r + r + g + g + b + b
        )
        // match each group of 2 numbers i.e. 22, 33, 44 or 23, 45, 67
        .match(/.{2}/g)
        // parse to int = make decimal
        .map(x => parseInt(x, 16));

export const rgbToRgba = (rgb: number[], opacity: number = 1): string =>
    `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;

export const rgbaToRgb = (rgba: string): number[] => {
    const rgb = rgba.match(/^.*\((\d{1,3}),(\d{1,3}),(\d{1,3}).*$/i);
    return [rgb?.[1], rgb?.[2], rgb?.[3]]; // r, g, b
};

export const parseColorToRgb = (color: string): number[] => {
    if(color.includes('#')) return hexToRgb(color);
    else return rgbaToRgb(color);
};

/**
 * Returns the theme color associated to the given thematic
 * @param thematic the thematic you need the color of
 * @param colors the theme colors from the context
 * @returns {string} the thematic color
 */
export const getFootprintThematicColor = (thematic: FootprintSectionString, colors: ThemeColorsType): string => {
    switch (thematic.toLowerCase()){
        case "diet":
            return colors.action;
        case "consumptionsanddigital":
            return colors.green.main;
        case "energy":
            return colors.yellow.main;
        case "mobility":
            return colors.blue.light;
        case "publicservices":
            return colors.green.light;
        default:
            return colors.background.card;
    }
};
