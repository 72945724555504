/* eslint-disable max-len */
// @flow
import type {UserTypeEnum} from '../Types/Types';
import type {DomainType} from '../Types/Ecogestures/Domain';

const USER_TYPES: {[string]: UserTypeEnum} = {
    USER: 1,
    TEAM_MANAGER: 4,
    GROUP_AMBASSADOR: 16,
    ADMIN: 128,
};

const DOMAIN_COLORS: { [DomainType]: string } = {
    diet: '#E66161',
    mobility: '#14C293',
    finances: '#DF73FF',
    energy: '#FFC847',
    consumptionsanddigital: '#2A99BE',
    consumptionsAndDigital: '#2A99BE',
    publicServices: '#9A6AA2',
    commitment: '#5bad55',
    natureandclimate: '#83B594',
    alternatives: '#dc5f2f',
    youth: '#FBA473',
};

const DOMAINS: Array<DomainType> = [
    'diet',
    'mobility',
    'finances',
    'energy',
    'consumptionsanddigital',
    'publicServices',
    'commitment',
    'natureandclimate',
    'alternatives',
    'youth',
];

const ECOGESTURES_DOMAINS: Array<DomainType> = [
    'diet',
    'mobility',
    'finances',
    'energy',
    'consumptionsanddigital',
    'commitment',
];

const KIOSQUE_KINDS = ['any', 'video', 'audio', 'text'];

const DAY_NAMES_SHORT = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];
const MONTH_NAMES = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

const CARBON_FOOTPRINT_DISCLAIMER = `
Le Bilan Carbone Energic évalue les émissions de gaz à effet de serre liées aux habitudes de vie d’une personne en suivant les recommandations du guide méthodologique Bilan Carbone® et de la norme ISO 14064-1 sans pour autant être certifié par ces mêmes organismes. Le Bilan Carbone Energic considère exclusivement, en 2019, les estimations carbone en France.\n
Le calcul de l’empreinte carbone Energic est donc une estimation de l’impact carbone d’une personne. Les sources et bases carbone utilisées pour la conception de ce test sont listées ci-dessous:

* [Base Carbone ADEME](http://www.bilans-ges.ademe.fr)
* DATALAB, Commissariat général au développement Durable, Chiffres clés du climat France, Europe et Monde ÉDITION 2018
* Calculs et données par Jean-Marc JANCOVICI, notamment [cet article](https://jancovici.com/changement-climatique/les-ges-et-nous/combien-de-gaz-a-effet-de-serre-dans-notre-assiette/)
* [L'empreinte Carbone des banques Françaises, Utopies et Amis de la Terre](http://www.epargneclimat.com/documents/Classement.pdf)
* [PARIS CHANGE D'ÈRE Vers la neutralité carbone en 2050](http://paris2050.elioth.com), Elioth, Mairie de Paris
* Calculateur MicMac par TaCa et Avenir Climatique, 2016 v1.2

\n\n
Des questions ? N’hésitez pas à nous en faire part à [contact@energic.io](mailto:contact@energic.io) ☺️  
`;

const EXTERNAL_URL = {
    energic: {
        cgu: 'https://energic.io/cgu/',
        privacy: 'https://energic.io/rgpd/',
        legalMentions: 'https://energic.io/mentionslegales/',
        faq: 'https://docs.energic.io/resources/outils/decouvrir_energic/faq_application.pdf',
        challengeRules: 'https://docs.energic.io/resources/reglement_challenge.pdf',
        changelog: 'https://energic.io/blog/changelog',
    },
    forecast: {
        ecowatt: 'https://www.monecowatt.fr/',
        ecogaz: 'https://myecogaz.com/home',
    },
};

export default {
    USER_TYPES,
    DOMAIN_COLORS,
    DOMAINS,
    ECOGESTURES_DOMAINS,
    KIOSQUE_KINDS,
    DAY_NAMES_SHORT,
    MONTH_NAMES,
    CARBON_FOOTPRINT_DISCLAIMER,
    EXTERNAL_URL,
};
