// @flow
import React, {Node, useContext} from "react";
import {View} from "react-native";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";

type Props = {
    children: Node,
    size?: number,
    customStyle?: ViewStyleProp,
};

const SliderThumbBackground = ({
    children,
    size = 60,
    customStyle = {},
}: Props) => {
    const [colors] = useContext(ThemeContext);
    return (
        <View
            style={{
                width: size,
                height: size,
                borderRadius: size,
                alignItems: 'center',
                backgroundColor: colors.text.white,
                borderColor: colors.background.disabled,
                borderWidth: 2,
                flexDirection: 'column',
                justifyContent: 'center',
                ...customStyle,
            }}
        >
            {children}
        </View>
    );
};

export default SliderThumbBackground;