/* eslint-disable react/default-props-match-prop-types */
// @flow
import * as GestureHandler from 'react-native-gesture-handler';
import * as React from 'react';
import {Animated} from 'react-native';
import Config from '../../../../Config/Config';

const {PanGestureHandler, State} = GestureHandler;

type Props = {
    children: React.Node,
    onSwiped: () => any,
};
type CompState = {};

const width = Config.DEVICE_WIDTH;

export default class ESwipeView extends React.PureComponent<Props, CompState>{
    constructor(){
        super();

        this.translateX = new Animated.Value(0);

        this.onGestureEvent = Animated.event(
            [{nativeEvent: {translationX: this.translateX}}],
            {useNativeDriver: true},
        );
    }

    translateX: Animated.Value;

    onGestureEvent: Animated.Event;

    static defaultProps = {
        children: null,
        onSwiped: () => null,
    };

    isSwiped(translationX: number){
        const toValue = translationX > 0 ? width : -width;
        const {onSwiped} = this.props;
        Animated.spring(this.translateX, {
            toValue,
            duration: 10,
            useNativeDriver: true,
        }).start(onSwiped);
    }

    onHandlerStateChange(event: Object){
        if (event.nativeEvent.state === State.END){
            const {translationX, velocityX} = event.nativeEvent;
            if (Math.abs(translationX) > width / 3 || Math.abs(velocityX) > 3500) this.isSwiped(translationX);
            else {
                Animated.spring(this.translateX, {
                    toValue: 0,
                    duration: 200,
                    useNativeDriver: true,
                }).start();
            }
        }
    }

    reset(){
        this.translateX.setValue(0);
    }

    render(){
        const {children} = this.props;

        return (
            <PanGestureHandler
                onGestureEvent={this.onGestureEvent}
                onHandlerStateChange={this.onHandlerStateChange.bind(this)}
            >
                <Animated.View
                    style={{transform: [{translateX: this.translateX}]}}
                >
                    {children}
                </Animated.View>
            </PanGestureHandler>
        );
    }
}
