// @flow
import * as React from 'react';
import ESimpleToast from '../EToast/ESimpleToast';
import EErrorToast from '../EToast/EErrorToast';
import type {AllToastOptions, ToastOptions} from '../EToast/EToast';
import EToast from '../EToast/EToast';
import ESuccessToast from "../EToast/ESuccessToast";

type Props = {};

type State = {
    isLive: boolean,
    isPaused: boolean,
};

class EToaster extends React.PureComponent<Props, State>{
    constructor(){
        super();

        this.queue = [];
        this.state = {
            isLive: false,
            isPaused: false,
        };
    }

    toastRef: ?EToast;

    queue: Array<[AllToastOptions, number, number]>;

    setToastRef(ref: ?EToast){
        this.toastRef = ref;
    }

    onToastHide(){
        this.setState({isLive: false});
        this.showIfPossible();
    }

    showIfPossible(){
        const {isLive, isPaused} = this.state;
        if (!isLive && this.queue.length > 0 && this.toastRef && !isPaused){
            const params: [AllToastOptions, number, number] = this.queue.shift();
            // $FlowFixMe
            this.toastRef.show(...params);
            this.setState({isLive: true});
        }
    }

    pauseShowing(){
        this.setState({isPaused: true});
    }

    resumeShowing(){
        this.setState({isPaused: false});
        this.showIfPossible();
    }

    show(options: AllToastOptions, duration: number = 5000, offset: number = 0){
        this.queue.push([options, duration, offset]);

        this.showIfPossible();
    }

    render(){
        return <EToast onHide={this.onToastHide.bind(this)} ref={this.setToastRef.bind(this)} />;
    }
}

let compRef: ?EToaster;

const instance = (
    <EToaster
        ref={(r) => {
            compRef = r;
        }}
    />
);

const show = (...params: Array<any>) => {
    if (compRef) compRef.show(...params);
};

const pause = () => {
    if(compRef) compRef.pauseShowing();
};

const resume = () => {
    if(compRef) compRef.resumeShowing();
};

const showSimpleToast = (toastData: Object, options: ToastOptions, ...params: Array<any>) => {
    if (compRef){
        compRef.show(
            {
                ...options,
                toastProps: toastData,
                toastComponent: ESimpleToast,
            },
            ...params,
        );
    }
};

const showSuccessToast = (toastData: Object, options: ToastOptions, ...params: Array<any>) => {
    if (compRef){
        compRef.show(
            {
                ...options,
                toastProps: toastData,
                toastComponent: ESuccessToast,
            },
            ...params,
        );
    }
};

const showErrorToast = (toastData: Object, options: ToastOptions, ...params: Array<any>) => {
    if (compRef){
        compRef.show(
            {
                ...options,
                toastProps: toastData,
                toastComponent: EErrorToast,
            },
            ...params,
        );
    }
};

export default {
    instance,
    show,
    pause,
    resume,
    showSimpleToast,
    showErrorToast,
    showSuccessToast,
};
