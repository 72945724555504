/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcSpeakers = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M161.607 511.883h-12.558c-8.634 0-15.698-7.064-15.698-15.698v-16.406c0-8.634 7.064-15.698 15.698-15.698h12.558c8.634 0 15.698 7.064 15.698 15.698v16.406c0 8.634-7.064 15.698-15.698 15.698zM362.953 512h-12.558c-8.634 0-15.698-7.064-15.698-15.698v-16.406c0-8.634 7.064-15.698 15.698-15.698h12.558c8.634 0 15.698 7.064 15.698 15.698v16.406c-.001 8.634-7.065 15.698-15.698 15.698z"
            style={{fill: "#6a563d"}}
        />
        <Path
            d="M161.607 464.081h-12.558c-8.634 0-15.698 7.064-15.698 15.698v16.406h43.954v-16.406c0-8.634-7.064-15.698-15.698-15.698zM362.953 464.198h-12.558c-8.634 0-15.698 7.064-15.698 15.698v16.406h43.954v-16.406c-.001-8.634-7.065-15.698-15.698-15.698z"
            style={{fill: "#554431"}}
        />
        <Path
            d="M389.788 488.069H122.214c-8.634 0-15.698-7.064-15.698-15.698V15.698C106.516 7.064 113.58 0 122.214 0h267.573c8.634 0 15.698 7.064 15.698 15.698v456.673c0 8.634-7.064 15.698-15.697 15.698z"
            style={{fill: "#957856"}}
        />
        <Path
            d="M155.328 472.459V15.786c0-8.634 7.064-15.698 15.698-15.698L122.214 0c-8.634 0-15.698 7.064-15.698 15.698V472.37c0 8.634 7.064 15.698 15.698 15.698h47.168c-7.869-.829-14.054-7.531-14.054-15.609z"
            style={{fill: "#6a563d"}}
        />
        <Circle
            cx={273.185}
            cy={345.028}
            r={95.882}
            style={{fill: "#6a563d"}}
        />
        <Circle
            cx={271.092}
            cy={146.513}
            r={60.595}
            style={{fill: "#6a563d"}}
        />
        <Circle
            cx={256.001}
            cy={328.943}
            r={95.882}
            style={{fill: "#ffeb99"}}
        />
        <Path
            d="M256.001 393.436c-35.559 0-64.489-28.93-64.489-64.489s28.93-64.488 64.489-64.488c35.56 0 64.489 28.93 64.489 64.488 0 35.56-28.93 64.489-64.489 64.489z"
            style={{fill: "#57565c"}}
        />
        <Circle
            cx={256.001}
            cy={132.521}
            r={60.595}
            style={{fill: "#ffeb99"}}
        />
        <Path
            d="M256.001 166.951c-18.986 0-34.432-15.446-34.432-34.431s15.446-34.432 34.432-34.432 34.431 15.447 34.431 34.432-15.446 34.431-34.431 34.431z"
            style={{fill: "#57565c"}}
        />
        <Path
            d="M273.188 281.281a64.111 64.111 0 0 1 35.68 10.803C297.2 275.401 277.859 264.46 256 264.46c-35.559 0-64.489 28.93-64.489 64.488 0 22.374 11.458 42.116 28.809 53.686-7.314-10.457-11.622-23.164-11.622-36.864.002-35.559 28.931-64.489 64.49-64.489zM273.188 114.909a34.21 34.21 0 0 1 13.968 2.972c-5.512-11.685-17.403-19.794-31.156-19.794-18.986 0-34.432 15.447-34.432 34.432 0 14.014 8.42 26.09 20.463 31.458a34.232 34.232 0 0 1-3.275-14.637c0-18.984 15.447-34.431 34.432-34.431z"
            style={{fill: "#2d2d30"}}
        />
        <Circle
            cx={150.899}
            cy={43.337}
            r={17.547}
            style={{fill: "#fff5cc"}}
        />
        <Circle
            cx={361.103}
            cy={40.992}
            r={17.547}
            style={{fill: "#fff5cc"}}
        />
        <Circle
            cx={361.103}
            cy={447.075}
            r={17.547}
            style={{fill: "#fff5cc"}}
        />
        <Circle
            cx={150.899}
            cy={447.075}
            r={17.547}
            style={{fill: "#fff5cc"}}
        />
        <Path
            d="m267.396 359.887-9.25-9.25c-2.035-2.035-2.035-5.366 0-7.4l9.25-9.25c2.035-2.035 5.366-2.035 7.4 0l9.25 9.25c2.035 2.036 2.035 5.366 0 7.4l-9.25 9.25c-2.036 2.034-5.366 2.034-7.4 0zM246.49 333.723l-9.25-9.25c-2.035-2.035-2.035-5.366 0-7.4l9.25-9.25c2.035-2.035 5.366-2.035 7.4 0l9.25 9.25c2.035 2.035 2.035 5.366 0 7.4l-9.25 9.25c-2.034 2.035-5.364 2.035-7.4 0z"
            style={{fill: "#3e3d42"}}
        />
    </Svg>
);

export default IcSpeakers;
