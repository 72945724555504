/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcBiogas({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .651h34.453v36.433H0z" id="gas_prefix__a" />
                <Path
                    d="M19.172.651c2.913 5.574.336 9.035-2.367 9.245-2.996.233-5.746-2.578-4.73-7.138-3.28 2.42-3.375 6.49-2.366 9.121 1.058 2.749-.04 5.03-2.617 5.276-2.878.288-4.474-3.082-3.002-8.442A17.18 17.18 0 000 19.86c0 9.514 7.713 17.224 17.228 17.224 9.51 0 17.225-7.71 17.225-17.224 0-2.09-.375-4.092-1.055-5.944-.469 5.453-3.377 8.261-6.419 6.954-2.854-1.221-.93-5.996-.786-8.271.238-3.855-.014-8.275-7.02-11.948"
                    id="gas_prefix__c"
                />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(18 15.516)">
                    <Mask fill="#fff" id="gas_prefix__b">
                        <Use xlinkHref="#gas_prefix__a" />
                    </Mask>
                    <Path
                        d="M34.453 19.86c0-2.091-.375-4.092-1.054-5.948-.47 5.457-3.378 8.264-6.42 6.959-2.854-1.223-.93-5.996-.786-8.273.238-3.86-.013-8.275-7.022-11.948 2.914 5.573.337 9.035-2.366 9.245-2.996.23-5.746-2.576-4.73-7.137-3.28 2.42-3.374 6.49-2.366 9.12 1.057 2.747-.041 5.026-2.617 5.277-2.878.285-4.474-3.082-3-8.442A17.2 17.2 0 000 19.86c0 9.516 7.713 17.225 17.228 17.225 9.512 0 17.225-7.71 17.225-17.225"
                        fill="#2B900C"
                        mask="url(#gas_prefix__b)"
                    />
                </G>
                <G transform="translate(18 15.516)">
                    <Mask fill="#fff" id="gas_prefix__d">
                        <Use xlinkHref="#gas_prefix__c" />
                    </Mask>
                    <Path
                        d="M29.386 31.004c0-2.213-.592-4.292-1.628-6.078.15 3.123-2.594 4.35-4.072 3.758-2.132-.857-1.56-2.322-2.11-5.36-.552-3.045-2.66-5.152-5.784-6.083 2.281 6.417-1.263 8.783-3.124 9.207-1.892.429-3.802 0-4.02-4.067a12.139 12.139 0 00-3.577 8.623c0 6.713 5.44 12.163 12.157 12.163 6.713 0 12.158-5.45 12.158-12.163"
                        fill="#6BCC4D"
                        mask="url(#gas_prefix__d)"
                    />
                </G>
            </G>
        </Svg>
    );
}

export default IcBiogas;
