/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcClothesDryer = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 511.98 511.98"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M405.313 511.98c-.5 0-1.016-.047-1.516-.109L105.48 468.872a10.68 10.68 0 0 1-9.093-9.438L53.388 55.123a10.67 10.67 0 0 1 3.031-8.641L99.418 3.14A10.704 10.704 0 0 1 106.996 0h340.986c5.891 0 10.672 4.765 10.672 10.655V458.31c0 2.812-1.125 5.515-3.094 7.515l-42.67 42.999a10.676 10.676 0 0 1-7.577 3.156z"
            style={{fill: "#f6bb42"}}
        />
        <Path
            d="M447.982 0H347.564l-45.779 45.779a10.665 10.665 0 0 0 0 15.093c2.078 2.078 106.966 3.125 109.684 3.125a10.65 10.65 0 0 0 7.547-3.125l39.639-39.639V10.655C458.654 4.764 453.872 0 447.982 0z"
            style={{fill: "#434a54"}}
        />
        <Path
            d="m371.813 507.246 31.984 4.625c.5.062 1.016.109 1.516.109 2.828 0 5.562-1.125 7.577-3.156l42.67-42.999c1.969-2 3.094-4.703 3.094-7.515V10.655c0-2.938-1.203-5.609-3.125-7.546l-50.216 50.217-33.5 453.92z"
            style={{fill: "#f6bb42"}}
        />
        <Path
            d="M405.313 42.654H63.997c-5.89 0-10.671 4.781-10.671 10.672v447.982c0 5.891 4.781 10.672 10.671 10.672h341.316c5.891 0 10.671-4.781 10.671-10.672V53.326c-.001-5.891-4.781-10.672-10.671-10.672z"
            style={{fill: "#ffce54"}}
        />
        <Path
            d="M234.661 127.994c-64.701 0-117.339 52.623-117.339 117.324 0 64.7 52.639 117.323 117.339 117.323 64.685 0 117.324-52.623 117.324-117.323.001-64.701-52.639-117.324-117.324-117.324z"
            style={{fill: "#e6e9ed"}}
        />
        <Path
            d="m337.362 241.646-21.125 7.344c-11.093 3.844-29.217 3.844-40.31 0l-21.125-7.344c-11.078-3.844-29.217-3.844-40.295 0l-21.124 7.344c-11.078 3.844-29.217 3.844-40.311 0l-21.109-7.344c-5.25-1.812-10.406-1.359-14.593.828a87.013 87.013 0 0 0-.047 2.844c0 64.7 52.639 117.323 117.339 117.323 64.685 0 117.324-52.623 117.324-117.323 0-1.5-.031-2.984-.094-4.469-4.891-.968-9.999-.781-14.53.797z"
            style={{fill: "#5d9cec"}}
        />
        <Path
            d="M284.863 184.446c4.172 4.156 4.172 10.921 0 15.078-4.172 4.171-10.921 4.171-15.077 0-4.172-4.156-4.172-10.921 0-15.078 4.157-4.172 10.905-4.172 15.077 0zM231.536 163.103c4.156 4.171 4.156 10.921 0 15.093-4.171 4.156-10.921 4.156-15.093 0-4.156-4.172-4.156-10.921 0-15.093 4.173-4.156 10.923-4.156 15.093 0zM199.538 205.773c4.156 4.172 4.156 10.922 0 15.094-4.171 4.156-10.921 4.156-15.093 0-4.156-4.172-4.156-10.922 0-15.094 4.172-4.157 10.922-4.157 15.093 0z"
            style={{fill: "#ccd1d9"}}
        />
        <Path
            d="M309.315 425.764H159.993c-5.891 0-10.672-4.78-10.672-10.671s4.781-10.672 10.672-10.672h149.322c5.89 0 10.671 4.781 10.671 10.672s-4.78 10.671-10.671 10.671z"
            style={{fill: "#434a54"}}
        />
    </Svg>
);

export default IcClothesDryer;
