/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path} from "react-native-svg";

type Props = {
  size?: number,
};

const IcDesktopComputer = ({size = 35}: Props) => (
    <Svg
        aria-hidden="true"
        className="DesktopComputer_svg__iconify DesktopComputer_svg__iconify--twemoji"
        height={size}
        viewBox="0 0 36 36"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M36 22a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z"
            fill="#CCD6DD"
        />
        <Path d="M4 4h28v18H4z" fill="#5DADEC" />
        <Path d="M13 26h10v6H13z" fill="#CCD6DD" />
        <Path d="M13 26h10v2H13z" fill="#9AAAB4" />
        <Path
            d="M36 33a3 3 0 0 0-3-3H3a3 3 0 1 0 0 6h30a3 3 0 0 0 3-3z"
            fill="#E1E8ED"
        />
        <Path
            d="M3 32h2v2H3zm4 0h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2zm4 0h2v2h-2z"
            fill="#F5F8FA"
        />
    </Svg>
);

export default IcDesktopComputer;
