// @flow
import type {Immutable as ImmutableType} from 'seamless-immutable';
import Immutable from 'seamless-immutable';
import type {Store} from '../../Types/Reducers/Store';
import type {ActionP} from '../../Types/Reducers/Action';
import ReducerUtils from '../ReducerUtils';
import CarbonFootprintActions from '../../Actions/CarbonFootprintActions';
import CFUtils from './CFUtils';
import type {UserCFAnswersType, UserDietCFAnswersType} from "../../Types/CarbonFootprint/FootprintApiTypes";
import type {
    DietType,
    DrinkType,
    DrinkTypeQuantity,
    QuantityType,
} from "../../Types/CarbonFootprint/FootprintQuestionsTypes";
import type {FootprintDetailPartType} from "../../Types/CarbonFootprint/FootprintTypes";

export type CFDietReducerState = ImmutableType<{
    dietType: ?DietType,
    drinkTypes: Array<DrinkType>,
    drinkTypesQuantities: Array<DrinkTypeQuantity>,
    partOfLocalConsumption: number,
    partOfRedMeat: number,
    lastAnswers?: UserDietCFAnswersType,
}>;

const INITIAL_STATE: CFDietReducerState = Immutable({
    dietType: null,
    drinkTypes: [],
    drinkTypesQuantities: [],
    partOfLocalConsumption: 0,
    partOfRedMeat: 0,
    lastAnswers: null,
});

const setDietType = (state: CFDietReducerState, action: ActionP<DietType>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {dietType: payload},
    );
};

const setPartOfRedMeat = (state: CFDietReducerState, action: ActionP<number>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {partOfRedMeat: payload},
    );
};

const setDrinkTypes = (state: CFDietReducerState, action: ActionP<Array<DrinkType>>) => {
    const {payload: drinkTypes} = action;
    const {drinkTypesQuantities} = state;
    const newQuantities = drinkTypes?.map(dt => {
        // If drink was previously selected, use old value
        const existing = drinkTypesQuantities?.find(dtq => dtq?.drinkType === dt);
        if(existing) return existing;
        // Otherwise, set minimum quantity for drink
        else return ({drinkType: dt, quantity: 'verylittle'});
    });
    return Immutable.merge(state, {
        drinkTypes,
        drinkTypesQuantities: newQuantities,
    });
};

const setDrinkTypesQuantities = (state: CFDietReducerState, action: ActionP<Array<DrinkTypeQuantity>>) => {
    const {payload: drinkTypesQuantities} = action;
    return Immutable.merge(state, {drinkTypesQuantities});
};

const setPartOfLocalConsumption = (state: CFDietReducerState, action: ActionP<number>) => {
    const {payload} = action;
    return Immutable.merge(
        state,
        {partOfLocalConsumption: payload},
    );
};

const resetCFReducer = (state: CFDietReducerState) => Immutable.merge(state, INITIAL_STATE);

const setUserLastCFAnswers = (state: CFDietReducerState, action: ActionP<{answers: UserCFAnswersType}>) => {
    const redMeatSliderValue = CFUtils?.redMeatPartArray.findIndex(
        value => value.red === action?.payload?.answers?.diet?.redMeatPercentage * 100
    );
    return Immutable.merge(
        state,
        {
            dietType: action?.payload?.answers?.diet?.diet ?? null,
            drinkTypes: action?.payload?.answers?.diet?.drinks?.map(d => d.answerType) ?? [],
            drinkTypesQuantities: action?.payload?.answers?.diet?.drinks?.map(d => ({
                drinkType: d.answerType,
                quantity: d.value,
            })) ?? [],
            partOfLocalConsumption: action?.payload?.answers?.diet?.localDiet ?? 0,
            partOfRedMeat: redMeatSliderValue > 0 ? redMeatSliderValue : 0,
            lastAnswers: action?.payload?.answers?.diet,
        },
    );
};

export default ReducerUtils.createReducer(
    INITIAL_STATE,
    {
        [CarbonFootprintActions.Types.SET_DIET_TYPE]: setDietType,
        [CarbonFootprintActions.Types.SET_PART_OF_RED_MEAT]: setPartOfRedMeat,
        [CarbonFootprintActions.Types.SET_DRINK_TYPES]: setDrinkTypes,
        [CarbonFootprintActions.Types.SET_DRINK_TYPES_QUANTITIES]: setDrinkTypesQuantities,
        [CarbonFootprintActions.Types.SET_PART_OF_LOCAL_CONSUMPTION]: setPartOfLocalConsumption,
        [CarbonFootprintActions.Types.RESET_FOOTPRINT_REDUCERS]: resetCFReducer,
        [CarbonFootprintActions.Types.FETCH_USER_LAST_CF_ANSWERS_SUCCESS]: setUserLastCFAnswers,

    },
);

export const getState = (store: Store): CFDietReducerState => store.carbonFootprint.diet;
export const getDietType = (store: Store): ?DietType => store.carbonFootprint.diet.dietType;
export const getPartOfLocalConsumption = (store: Store): number => store.carbonFootprint.diet.partOfLocalConsumption;

/**
 * Returns a number from 0 to 7 corresponding to the step of the slider
 * @param store
 * @returns {*}
 */
export const getPartOfRedMeat = (store: Store): number => store.carbonFootprint.diet.partOfRedMeat;
/**
 * Returns real part of red meat in percentage (from 0 to 1) based on user input
 * @param store
 * @returns {number|*}
 */
export const getRealPartOfRedMeat = (store: Store): number =>
    (CFUtils?.redMeatPartArray?.[getPartOfRedMeat(store)]?.red ?? 0) / 100;
export const getDrinkTypes = (store: Store): Array<DrinkType> => store.carbonFootprint.diet.drinkTypes;
export const getDrinkTypesQuantities = (store: Store): Array<DrinkTypeQuantity> =>
    store.carbonFootprint.diet.drinkTypesQuantities;

/**
 * Returns true if the diet type submitted by user belongs to meat based diets, false otherwise
 * @param store
 * @returns {boolean}
 */
export const isPlayerEatingMeat = (store: Store): boolean => {
    const playerDietType = getDietType(store);
    return CFUtils?.meatDietTypes.includes(playerDietType);
};


export const wasPlayerLastEatingMeat = (store: Store): boolean => {
    const playerDietType = getState(store).lastAnswers?.diet;
    return CFUtils?.meatDietTypes.includes(playerDietType);
};

/**
 * Returns true if the user drinks impactful drinks (i.e. selected any of the bad drinks), false otherwise
 * @param store
 * @returns {boolean}
 */
export const isPlayerDrinkingShit = (store: Store): boolean => getDrinkTypes(store).length > 0;

/**
 * Returns the frequency factor for drinks depending on quantity
 * @param quantity
 * @returns {number}
 */
export const getDrinkQuantityImpactFactor = (quantity: QuantityType): number => {
    switch (quantity){
        case "verylittle":
            return 1.5 * CFUtils?.NB_MONTHS;
        case "little":
            return 1.5 * CFUtils?.NB_WEEKS;
        case "alot":
            return 4.5 * CFUtils?.NB_WEEKS;
        case "enormously":
            return 1.5 * 7 * CFUtils?.NB_WEEKS;
        case "madly":
            return 4.5 * 7 * CFUtils?.NB_WEEKS;
        default:
            return 0;
    }
};

/**
 * Returns the impact of a drink type in kgCO2eq
 * @param drinkType
 * @returns {number}
 */
const getDrinkTypeImpact = (drinkType: DrinkType): number => {
    switch (drinkType){
        case "coffee":
            return 0.05;
        case "capsule_coffee":
            return 0.1;
        case "tea_or_chocolate":
            return 0.0123;
        case "alcool":
            return 0.51;
        default:
            return 0;
    }
};

export const getCalculatedDrinksImpact = (drinkTypeQuantities: Array<DrinkTypeQuantity>): number => {
    return (drinkTypeQuantities ?? []).map((dq: DrinkTypeQuantity) => ({
        ...dq,
        quantityImpact: getDrinkQuantityImpactFactor(dq.quantity),
        drinkTypeImpact: getDrinkTypeImpact(dq.drinkType),
    })).reduce((acc, elm) => acc + (elm.quantityImpact * elm.drinkTypeImpact), 0);
};

/**
 * Returns the impact of all drinks (in kgCO2eq) selected by user depending on quantity of drinking
 * @param store
 * @returns {number}
 */
export const getDrinksImpact = (store: Store): number => {
    const drinkTypeQuantities: Array<DrinkTypeQuantity> = getDrinkTypesQuantities(store);
    return getCalculatedDrinksImpact(drinkTypeQuantities);
};

export const getLastDrinksImpact = (store: Store): number => {
    const drinkTypes = getState(store).lastAnswers?.drinks || [];
    const drinkTypeQuantities: Array<DrinkTypeQuantity> = drinkTypes.map(
        r => ({drinkType: r.answerType, quantity: r.value})
    );
    return getCalculatedDrinksImpact(drinkTypeQuantities);
};

/**
 * Returns the impact of breakfast in kgCO2eq - no user input required, it's an average value.
 */
export const getBreakfastImpact = (): number => {
    const breakfastCO2 = 0.37;
    return breakfastCO2 * CFUtils?.NB_DAYS;
};

export const getCalculatedNoMeatDietImpactEstimation = (dietType: ?DietType): ?number => {
    const breakfastImpact = getBreakfastImpact();
    switch (dietType){
        case "vegan":
            return (0.39 * 2 * CFUtils?.NB_DAYS) + breakfastImpact;
        case "vegetarian":
            return (0.51 * 2 * CFUtils?.NB_DAYS) + breakfastImpact;
        default:
            return 0;
    }
};

/**
 * Returns an estimation of the impact (in kgCO2eq) of user's diet only if he eats no meat (null otherwise)
 * @param store
 * @returns {null|number}
 */
export const getNoMeatDietImpactEstimation = (store: Store): ?number => {
    const dietType = getDietType(store);
    return getCalculatedNoMeatDietImpactEstimation(dietType);
};

export const getLastNoMeatDietImpactEstimation = (store: Store): ?number => {
    const dietType = getState(store).lastAnswers?.diet;
    return getCalculatedNoMeatDietImpactEstimation(dietType);
};

export const getCalculatedMeatEatingImpactEstimation = (dietType: ?DietType, redMeatPart: number): ?number => {
    const breakfastImpact = getBreakfastImpact();
    const redMeatImpact = 6.29;
    const whiteMeatPart = 1 - redMeatPart; // x
    const whiteMeatImpact = 1.35;
    const avgNoMeatImpact = 0.51;
    const meatImpact = (whiteMeatPart * whiteMeatImpact) + (redMeatPart * redMeatImpact);

    switch (dietType){
        case "flexitarian":
            return breakfastImpact
                + (avgNoMeatImpact * 13.5 * CFUtils?.NB_WEEKS)
                + (meatImpact * 0.5 * CFUtils?.NB_WEEKS);
        case "weeklymeateater":
            return breakfastImpact + (avgNoMeatImpact * 12 * CFUtils?.NB_WEEKS) + (meatImpact * 2 * CFUtils?.NB_WEEKS);
        case "dailymeateater":
            return breakfastImpact + (avgNoMeatImpact * 7 * CFUtils?.NB_WEEKS) + (meatImpact * 7 * CFUtils?.NB_WEEKS);
        case "everymealmeateater":
            return breakfastImpact + (meatImpact * CFUtils?.NB_DAYS);
        default:
            return 0;
    }
};

/**
 * Returns an estimation of the impact (in kgCO2eq) of user's diet only if he eats meat (null otherwise)
 * @param store
 * @returns {null|number}
 */
export const getMeatEatingImpactEstimation = (store: Store): ?number => {
    const dietType = getDietType(store);
    const redMeatPart = getRealPartOfRedMeat(store); // y
    return getCalculatedMeatEatingImpactEstimation(dietType, redMeatPart);
};

export const getLastMeatEatingImpactEstimation = (store: Store): ?number => {
    const dietType = getState(store).lastAnswers?.diet;
    const redMeatPart = getState(store).lastAnswers?.redMeatPercentage;
    return getCalculatedMeatEatingImpactEstimation(dietType, redMeatPart);
};

/**
 * Returns the impact of user's diet (kgCO2eq)
 * @param store
 * @returns {?number}
 */
const getDietTypeImpact = (store: Store): number => isPlayerEatingMeat(store)
    ? getMeatEatingImpactEstimation(store)
    : getNoMeatDietImpactEstimation(store);


const getLastDietTypeImpact = (store: Store): number => wasPlayerLastEatingMeat(store)
    ? getLastMeatEatingImpactEstimation(store)
    : getLastNoMeatDietImpactEstimation(store);

export const getCalculatedLocalConsumptionSavedImpact = (localPart: number, dietImpact: number): number => {
    const foodTransportImpact = 0.2; // Part of transports in food emissions
    const halfTransportImpact = foodTransportImpact / 2; // We consider half of the transport impact to be saved
    return -1 * (localPart / 100) * halfTransportImpact * dietImpact;
};

/**
 * Returns the impact (in kgCO2eq) saved by consuming local stuff
 * @param store
 * @returns {number}
 */
export const getLocalConsumptionSavedImpact = (store: Store): number => {
    const localPart = getPartOfLocalConsumption(store);
    const dietImpact = getDietTypeImpact(store);
    return getCalculatedLocalConsumptionSavedImpact(localPart, dietImpact);
};

export const getLastLocalConsumptionSavedImpact = (store: Store): number => {
    const localPart = getState(store).lastAnswers?.localDiet || 0;
    const dietImpact = getLastDietTypeImpact(store);
    return getCalculatedLocalConsumptionSavedImpact(localPart, dietImpact);
};

export const getgCO2DietImpact = (store: Store): number => {
    const partOfLocal = getLocalConsumptionSavedImpact(store);
    const dietTypeImpact = getDietTypeImpact(store);
    const foodImpact = dietTypeImpact - partOfLocal;
    const drinksImpact = getDrinksImpact(store);

    const totalKgCO2Diet = foodImpact // food impact
        + drinksImpact;

    return totalKgCO2Diet * CFUtils?.gCO2toKgCO2Ratio; // make it grams
};

export const getTCO2DietImpact = (store: Store): number => getgCO2DietImpact(store) / CFUtils?.gCO2toTCO2Ratio;

export const getDietDetailedParts = (store: Store): Array<FootprintDetailPartType> => {
    const partOfLocal = getLocalConsumptionSavedImpact(store);
    const dietTypeImpact = getDietTypeImpact(store);
    const foodImpact = dietTypeImpact - partOfLocal;
    return [
        {
            title: 'Régime alimentaire',
            value: foodImpact.toFixed(1),
            key: 'DietType',
        },
        {
            title: 'Boissons',
            value: getDrinksImpact(store).toFixed(1),
            key: 'Drinks',
        },
    ].sort((a, b) => b.value - a.value);
};