/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
    size?: number,
};

const Mobility = ({
    color = theme.colors.light.text.white,
    size = 36,
}: Props) => (
    <Svg fill="none" height={size} viewBox="0 0 36 36" width={size} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M0 28.25v-2.917h5.98a8.398 8.398 0 0 1 2.06-4.339 8.737 8.737 0 0 1 4.064-2.588L9.99 9.29H4.375V6.374H9.99c.656 0 1.258.213 1.805.64a2.76 2.76 0 0 1 1.038 1.62l2.844 12.324h-1.094c-1.604 0-2.977.57-4.12 1.713-1.142 1.143-1.713 2.516-1.713 4.12v1.458H0Zm14.583 2.916c-1.215 0-2.248-.425-3.099-1.276-.85-.85-1.276-1.884-1.276-3.099 0-1.215.426-2.248 1.276-3.099.851-.85 1.884-1.276 3.1-1.276 1.215 0 2.248.425 3.098 1.276.851.85 1.276 1.884 1.276 3.099 0 1.215-.425 2.248-1.276 3.099-.85.85-1.883 1.276-3.099 1.276Zm0-2.917c.413 0 .76-.14 1.04-.42a1.41 1.41 0 0 0 .419-1.038c0-.413-.14-.76-.419-1.038-.28-.28-.627-.42-1.04-.42-.413 0-.759.14-1.038.42-.28.279-.42.625-.42 1.038 0 .413.14.76.42 1.038.279.28.625.42 1.038.42Zm13.125-1.458c-1.75 0-3.287-.541-4.61-1.623-1.326-1.081-2.17-2.485-2.535-4.21h-3.428l-.656-2.917h4.084a8.712 8.712 0 0 1 .492-1.568c.207-.486.48-.935.82-1.349h-6.052l-.656-2.916h8.24l-1.605-4.375H18.01V4.916h3.792c.632 0 1.197.17 1.696.51.498.34.856.803 1.075 1.386l1.969 5.323h1.166c2.018 0 3.738.71 5.16 2.132C34.289 15.689 35 17.409 35 19.427c0 2.065-.71 3.81-2.132 5.232-1.422 1.421-3.142 2.132-5.16 2.132Zm0-2.917c1.24 0 2.28-.425 3.118-1.276.838-.85 1.257-1.883 1.257-3.099 0-1.24-.419-2.278-1.257-3.116-.839-.84-1.878-1.259-3.118-1.259h-.145l1.458 3.865-2.771 1.02-1.385-3.864c-.486.413-.863.9-1.13 1.459-.268.559-.402 1.19-.402 1.895 0 1.216.42 2.249 1.259 3.1.838.85 1.877 1.275 3.116 1.275Z"
            fill={color}
        />
    </Svg>
);

export default Mobility;