import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../Reducers';
import rootSaga from '../Sagas';

const sagaMiddleware = createSagaMiddleware({});
const persistedReducer = reducers;
/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window === 'object'
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(
    sagaMiddleware,
)));

sagaMiddleware.run(rootSaga);

export default store;
