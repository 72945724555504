/* eslint-disable max-len */
// @flow
import React from "react";
import type {Node} from "react";
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg';
import {View} from "react-native";
import theme from "../../../../../Config/Theme";

type Props = {
    color: string,
    children: Node,
    size?: number,
};

const ThemeIcon = ({
    color = theme.colors.light.action,
    size = 48,
    children,
}: Props) => (
    <View
        style={{
            width: size,
            height: size,
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <View
            style={{position: 'absolute'}}
        >
            <Svg fill="none" height={size} viewBox="0 0 100 100" width={size} xmlns="http://www.w3.org/2000/svg">
                <G clipPath="url(#ThemeIcon_a)">
                    <Rect fill={color} height={100} rx={8} width={100} />
                    <Path
                        d="M109.588 60.195c.351 4.681-.558 9.22-1.917 13.65-1.08 3.513-.684 6.75.819 10.013 1.251 2.728 2.539 5.474 3.43 8.327 3.294 10.574-3.961 23.163-13.205 27.22-3.15 1.382-6.507 2.398-9.874 3.183-4.41 1.034-8.1 2.996-10.98 6.491-4.537 5.528-10.154 9.272-17.4 10.485-5.58.936-11.044.588-16.462-.847-3.016-.794-6.049-1.569-8.965-2.648-6.085-2.247-12.223-2.55-18.551-1.302-3.799.749-7.669 1.177-11.53.285-5.779-1.328-10.324-4.609-13.925-9.129-6.382-8.007-8.785-17.128-6.607-27.122 1.702-7.784 6.652-13.267 13.7-17.003 5.787-3.067 11.98-4.984 18.308-6.544 6.246-1.542 12.142-3.798 17.165-7.962 3.609-2.996 6.237-6.758 8.496-10.752 2.953-5.198 5.671-10.52 8.407-15.826 1.998-3.878 4.375-7.444 7.804-10.253 5.122-4.146 11.035-5.376 17.462-4.779 5.004.464 9.586 2.158 13.798 4.815 5.13 3.236 9.937 6.874 14.087 11.34 4.014 4.325 5.859 9.425 5.94 15.229.009 1.043 0 2.095 0 3.138v-.009Z"
                        fill="#000"
                        fillOpacity={0.2}
                    />
                </G>
                <Defs>
                    <ClipPath id="ThemeIcon_a">
                        <Rect fill="#fff" height={100} rx={8} width={100} />
                    </ClipPath>
                </Defs>
            </Svg>

        </View>
        <View style={{zIndex: 1}}>
            {children}
        </View>
    </View>
);

export default ThemeIcon;