/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcCamera = ({size = 35}: Props) => (
    <Svg
        aria-hidden="true"
        className="Camera_svg__iconify Camera_svg__iconify--twemoji"
        height={size}
        viewBox="0 0 36 36"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path d="M4 5s0-1 1-1h6s1 0 1 1v2H4V5z" fill="#66757F" />
        <Path
            d="M0 10s0-4 4-4h28s4 0 4 4v18s0 4-4 4H4s-4 0-4-4V10z"
            fill="#31373D"
        />
        <Circle cx={21} cy={19} fill="#CCD6DD" r={10} />
        <Circle cx={21} cy={19} fill="#31373D" r={8} />
        <Circle cx={21} cy={19} fill="#3B88C3" r={5} />
        <Circle cx={32.5} cy={9.5} fill="#FFF" r={1.5} />
        <Path
            d="M12 9.5a1.5 1.5 0 0 1-1.5 1.5h-5a1.5 1.5 0 1 1 0-3h5A1.5 1.5 0 0 1 12 9.5z"
            fill="#F5F8FA"
        />
    </Svg>
);

export default IcCamera;
