/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Ellipse, Path} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcPlasticBottle({size = 35}: Props){
    return (
        <Svg
            height={size}
            viewBox="0 0 498.967 498.967"
            width={size}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M322.283 304c4.8-4.8 8.8-12 8.8-19.2 0-8-3.2-15.2-8.8-20 5.6-4.8 9.6-12 9.6-20s-3.2-15.2-8.8-20c5.6-4.8 8.8-12 8.8-20s-4-15.2-9.6-20.8c8-8 12.8-19.2 12.8-37.6 0-52-38.4-85.6-85.6-85.6s-85.6 33.6-85.6 85.6c0 17.6 4.8 29.6 12.8 37.6-5.6 4.8-9.6 12-9.6 20.8 0 8 3.2 15.2 8.8 20-5.6 4.8-8.8 12-8.8 20s3.2 15.2 8.8 20c-5.6 4.8-8.8 12-8.8 20 0 7.2 4.8 14.4 9.6 19.2-5.6 4-7.2 10.4-7.2 20.8v152.8c0 24 15.2 18.4 39.2 18.4h80.8c24 0 39.2 5.6 39.2-18.4V324.8c.8-10.4-.8-16.8-6.4-20.8z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M287.883 496c24 0 41.6 5.6 41.6-18.4V324.8c0-10.4-1.6-16.8-7.2-20.8 4.8-4.8 8.8-12 8.8-19.2 0-8-3.2-15.2-8.8-20 5.6-4.8 9.6-12 9.6-20s-3.2-15.2-8.8-20c5.6-4.8 8.8-12 8.8-20s-4-15.2-9.6-20.8c8-8 12.8-19.2 12.8-37.6 0-52-38.4-85.6-85.6-85.6-23.2 0-44.8 8-60 23.2"
                style={{fill: "#42bec1"}}
            />
            <Path
                d="M217.483 48h64v24h-64z"
                style={{fill: "#00637a"}}
            />
            <Path
                d="M297.483 48.8c0 8-6.4 7.2-14.4 7.2h-66.4c-8 0-14.4.8-14.4-7.2V14.4c0-8 6.4-14.4 14.4-14.4h66.4c8 0 14.4 5.6 14.4 14.4v34.4z"
                style={{fill: "#00669e"}}
            />
            <Path
                d="M217.483 0h65.6c8 0 14.4 5.6 14.4 14.4v34.4c0 8-5.6 7.2-14.4 7.2"
                style={{fill: "#004f77"}}
            />
            <Path
                d="M231.083 74.4c5.6-3.2 12-4.8 18.4-4.8s12.8 1.6 18.4 4.8c4.8-3.2 10.4-4.8 16.8-4.8-11.2-4-22.4-6.4-35.2-6.4s-24 2.4-35.2 7.2c6.4 0 12 1.6 16.8 4z"
                style={{fill: "#2d9eaa"}}
            />
            <Path
                d="M334.283 141.6c-3.2.8-7.2 1.6-11.2 1.6-6.4 0-12.8-1.6-18.4-4.8-5.6 3.2-12 4.8-18.4 4.8s-12.8-1.6-18.4-4.8c-5.6 3.2-12 5.6-18.4 5.6s-12.8-1.6-18.4-4.8c-5.6 2.4-12 4.8-18.4 4.8s-12.8-1.6-18.4-4.8c-5.6 3.2-12 4.8-18.4 4.8-4 0-7.2-.8-11.2-1.6v6.4c0 17.6 4.8 29.6 12.8 37.6-5.6 4.8-9.6 12-9.6 20.8 0 8 3.2 15.2 8.8 20-5.6 4.8-8.8 12-8.8 20s3.2 15.2 8.8 20c-5.6 4.8-8.8 12-8.8 20 0 7.2 4.8 14.4 9.6 19.2-5.6 4-7.2 10.4-7.2 20.8V480c0 24 15.2 18.4 39.2 18.4h80.8c24 0 39.2 5.6 39.2-18.4V324.8c0-10.4-1.6-16.8-7.2-20.8 4.8-4.8 8.8-12 8.8-19.2 0-8-3.2-15.2-8.8-20 5.6-4.8 9.6-12 9.6-20s-3.2-15.2-8.8-20c5.6-4.8 8.8-12 8.8-20s-4-15.2-9.6-20.8c8-8 12.8-19.2 12.8-37.6 0 0 0-2.4-.8-4.8z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M231.083 74.4c5.6-3.2 12-4.8 18.4-4.8s12.8 1.6 18.4 4.8c4.8-3.2 10.4-4.8 16.8-4.8-11.2-4-22.4-6.4-35.2-6.4s-24 2.4-35.2 7.2c6.4 0 12 1.6 16.8 4zM334.283 149.6c-3.2.8-7.2 1.6-11.2 1.6-6.4 0-12.8-1.6-18.4-4.8-5.6 3.2-12 4.8-18.4 4.8s-12.8-1.6-18.4-4.8c-5.6 3.2-12 5.6-18.4 5.6s-12.8-1.6-18.4-4.8c-5.6 2.4-12 4.8-18.4 4.8s-12.8-1.6-18.4-4.8c-5.6 3.2-12 4.8-18.4 4.8-4 0-7.2-.8-11.2-1.6v6.4c0 17.6 4.8 21.6 12.8 29.6-5.6 4.8-9.6 12-9.6 20.8 0 8 3.2 15.2 8.8 20-5.6 4.8-8.8 12-8.8 20s3.2 15.2 8.8 20c-5.6 4.8-8.8 12-8.8 20 0 7.2 4.8 14.4 9.6 19.2-5.6 4-7.2 10.4-7.2 20.8V480c0 24 15.2 18.4 39.2 18.4h80.8c24 0 39.2 5.6 39.2-18.4V324.8c0-10.4-1.6-16.8-7.2-20.8 4.8-4.8 8.8-12 8.8-19.2 0-8-3.2-15.2-8.8-20 5.6-4.8 9.6-12 9.6-20s-3.2-15.2-8.8-20c5.6-4.8 8.8-12 8.8-20s-4-15.2-9.6-20.8c8-8 12.8-11.2 12.8-29.6 0 0 0-2.4-.8-4.8z"
                style={{fill: "#2d9eaa"}}
            />
            <Ellipse
                cx={178.283}
                cy={204.8}
                rx={5.6}
                ry={11.2}
                style={{fill: "#42bec1"}}
            />
            <Ellipse
                cx={178.283}
                cy={245.6}
                rx={5.6}
                ry={11.2}
                style={{fill: "#42bec1"}}
            />
            <Ellipse
                cx={178.283}
                cy={286.4}
                rx={5.6}
                ry={11.2}
                style={{fill: "#42bec1"}}
            />
            <Path
                d="M185.483 474.4c0 4 0 6.4-4 6.4s-4-2.4-4-6.4v-152c0-4 0-8 4-8s4 4 4 8v152z"
                style={{fill: "#42bec1"}}
            />
            <Ellipse
                cx={179.747}
                cy={114.922}
                rx={19.2}
                ry={5.6}
                style={{fill: "#91edea"}}
                transform="rotate(111.358 179.75 114.922)"
            />
            <Path
                d="M201.483 32h96v8h-96z"
                style={{fill: "#168fc4"}}
            />
            <Path
                d="M313.483 190.4c0 .8-.8 1.6-1.6 1.6h-124.8c-.8 0-1.6-.8-1.6-1.6v-4c0-.8.8-1.6 1.6-1.6h124c.8 0 1.6.8 1.6 1.6v4h.8zM313.483 230.4c0 .8-.8 1.6-1.6 1.6h-124.8c-.8 0-1.6-.8-1.6-1.6v-4c0-.8.8-1.6 1.6-1.6h124c.8 0 1.6.8 1.6 1.6v4h.8zM313.483 270.4c0 .8-.8 1.6-1.6 1.6h-124.8c-.8 0-1.6-.8-1.6-1.6v-4c0-.8.8-1.6 1.6-1.6h124c.8 0 1.6.8 1.6 1.6v4h.8zM313.483 310.4c0 .8-.8 1.6-1.6 1.6h-124.8c-.8 0-1.6-.8-1.6-1.6v-4c0-.8.8-1.6 1.6-1.6h124c.8 0 1.6.8 1.6 1.6v4h.8z"
                style={{fill: "#42bec1"}}
            />
            <Path
                d="M191.883 408c-2.4 0-4.8-2.4-4.8-4.8 0-26.4 21.6-48 48-48 2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8c-20.8 0-37.6 16.8-37.6 37.6-.8 3.2-3.2 5.6-5.6 5.6z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M275.083 312.8c2.4 0 4.8 2.4 4.8 4.8 0 26.4-21.6 48-48 48-2.4 0-4.8-2.4-4.8-4.8s2.4-4.8 4.8-4.8c20.8 0 37.6-16.8 37.6-37.6.8-3.2 3.201-5.6 5.6-5.6zM207.883 430.4c-2.4 0-4.8-2.4-4.8-4.8 0-26.4 21.6-48 48-48 2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8c-20.8 0-37.6 16.8-37.6 37.6-.8 4-3.2 5.6-5.6 5.6z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M291.083 335.2c2.4 0 4.8 2.4 4.8 4.8 0 26.4-21.6 48-48 48-2.4 0-4.8-2.4-4.8-4.8s2.4-4.8 4.8-4.8c20.8 0 37.6-16.8 37.6-37.6.8-3.2 3.201-5.6 5.6-5.6zM223.883 453.6c-2.4 0-4.8-2.4-4.8-4.8 0-26.4 21.6-48 48-48 2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8c-20.8 0-37.6 16.8-37.6 37.6-.8 3.2-3.2 5.6-5.6 5.6z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M307.083 358.4c2.4 0 4.8 2.4 4.8 4.8 0 26.4-21.6 48-48 48-2.4 0-4.8-2.4-4.8-4.8s2.4-4.8 4.8-4.8c20.8 0 37.6-16.8 37.6-37.6.8-4 3.201-5.6 5.6-5.6z"
                style={{fill: "#5fd6d6"}}
            />
            <Path
                d="M289.483 11.2c0 2.4-2.4 4.8-4.8 4.8h-69.6c-3.2 0-4-2.4-4-4.8s1.6-3.2 4-3.2h69.6c2.4 0 4.8.8 4.8 3.2z"
                style={{fill: "#0579ad"}}
            />
        </Svg>
    );
}

export default IcPlasticBottle;
