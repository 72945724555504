import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    aboveThumbText: {
        fontFamily: 'Montserrat',
        fontSize: 16,
        minWidth: 60,
        paddingBottom: 10,
        textAlign: 'center',
    },
    container: {
        alignItems: 'stretch',
        alignSelf: 'center',
        flexDirection: 'column',
        paddingTop: 15,
    },
    subText: {
        fontFamily: 'Montserrat',
        fontSize: 15,
        lineHeight: 20,
        textAlign: 'center',
    },
    subTextContainer: {marginTop: 15},
    subTextValue: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 15,
        lineHeight: 20,
        textAlign: 'center',
    },
    thumbIconContainer: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    trackMark: {
        borderColor: 'grey',
        borderWidth: 1,
        height: 10,
        opacity: 0.08,
        width: 1,
    },
    trackStyle: {
        borderRadius: 8,
        height: 10,
    },

});
