// @flow
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {FlatList, View} from "react-native";
import type {DetailedCarbonFootprint} from '../../../Types/CarbonFootprint/FootprintHistoryTypes';
import type {DomainType} from '../../../Types/Ecogestures/Domain';
import FootprintSectionCard from "./FootprintSectionCard";
import appRoutes from "../../../Navigation/AppRoutes";
import FootprintPie from "./FootprintPie/FootprintPie";
import CFUtils from "../../../Reducers/CarbonFootprintReducers/CFUtils";
import TotalFootprintPieCenter from "./FootprintPie/TotalFootprintPieCenter";
import {useNavigation} from "@react-navigation/native";
import ModalManager from "../../UI/Modals/ModalManager/ModalManager";
import {publicServicesThematicText} from "./CFQuestionsContainers/Thematics/PublicServicesThematicContainer";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Body from "../../UI/Typos/Body/Body";
import Heading from "../../UI/Typos/Heading/Heading";
import BasicCard from "../../UI/Cards/BasicCard/BasicCard";
import ChipButton from "../../UI/Buttons/ChipButton/ChipButton";
import locales from "../../../locales/locales";
import Config from "../../../Config/Config";
import AnimateListItem from "../../../libs/Animations/AnimateListItem";
import {useSelector} from "react-redux";
import {
    getClientAverageCarbonFootprintHistory,
    getDifferenceWithLastFootprint, getStandaloneDetailedFootprint, getUserFootprintById, getUserLastFootprint,
} from "../../../Reducers/CarbonFootprintReducer";
import type {FootprintId} from "../../../Types/Types";

type Props = {
    footprintId?: FootprintId,
    allowAccessDetails?: boolean,
    allowAccessHistory?: boolean,
    title: string,
}

const FootprintDetailSheet = ({
    footprintId = null,
    allowAccessDetails = true,
    allowAccessHistory = true,
    title,
}: Props) => {
    const navigation = useNavigation();
    const [colors,, spacing] = useContext(ThemeContext);
    const [sortedFootprint, setSortedFootprint] = useState([]);
    const clientAverageCFHistory = useSelector(getClientAverageCarbonFootprintHistory);
    const differenceWithPreviousFootprint = useSelector(getDifferenceWithLastFootprint);

    const footprint: DetailedCarbonFootprint = useSelector(
        store => getStandaloneDetailedFootprint(store)
    );

    useEffect(() => {
        setSortedFootprint(CFUtils.sortFootprintSections(footprint));
    }, [footprint?.footprintId]);

    const goToCategoryDetail = (key: DomainType) => navigation.navigate(appRoutes.cf.category_detail,
        {category: key, footprintId: footprintId}
    );

    const renderDifferenceWithLastFootprint = useCallback(() => differenceWithPreviousFootprint
        ? (
            <Body centered customStyle={{marginTop: spacing[1]}} variant='caption'>
                {`${
                    differenceWithPreviousFootprint?.evol === 'lesser' ? '' : '+'
                }${
                    differenceWithPreviousFootprint?.difference
                        .toString()
                        .replace('.', ',')
                }t par rapport au\nbilan du ${differenceWithPreviousFootprint?.date}`}
            </Body>
        )
        : null, [differenceWithPreviousFootprint?.date]);

    const showPublicServicesInfo = () => ModalManager.showConfirmModal({
        content: publicServicesThematicText,
        validText: 'Ok',
        onValidate: () => null,
    });

    const renderListHeader = useCallback(() => (
        <>
            <BasicCard customStyle={{marginBottom: spacing[2]}}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: spacing[1] * 1.5,
                    }}
                >
                    <Heading variant='h2'>
                        {title}
                    </Heading>
                    {
                        allowAccessHistory
                            ? (
                                <ChipButton
                                    onPress={() => navigation.navigate(appRoutes.cf.footprint_history)}
                                    text={locales.t('cf.history.short')}
                                />
                            ) : null
                    }
                </View>
                <BasicCard customStyle={{backgroundColor: colors.background.card}}>
                    <FootprintPie
                        centerComponent={(
                            <View
                                style={{alignItems: 'center'}}
                            >
                                <TotalFootprintPieCenter
                                    fontSize={40}
                                    totalCarbon={footprint?.totalCarbon}
                                />
                                {renderDifferenceWithLastFootprint()}
                            </View>
                        )}
                        footprint={sortedFootprint}
                        size={220}
                    />
                </BasicCard>
            </BasicCard>
            <Heading customStyle={{marginBottom: spacing[1]}} variant='h2'>
                {"Détail par catégorie"}
            </Heading>
        </>
    ), [sortedFootprint, footprintId]);

    const renderListItem = useCallback(({item: section, index}) => (
        <AnimateListItem index={index} key={`footprintDetail_${index}`}>
            <FootprintSectionCard
                clientAverage={clientAverageCFHistory?.[section?.key]}
                onPress={allowAccessDetails
                    ? section.key !== 'publicServices'
                        ? () => goToCategoryDetail(section.key)
                        : () => showPublicServicesInfo()
                    : null}
                section={section}
                sectionName={section.key}
            />
        </AnimateListItem>
    ), [clientAverageCFHistory, footprintId]);

    return (
        <FlatList
            ItemSeparatorComponent={() => (<View style={{height: spacing[2]}} />)}
            ListHeaderComponent={renderListHeader()}
            contentContainerStyle={{paddingBottom: spacing[1]}}
            data={sortedFootprint}
            keyExtractor={item => item.key.toString()}
            renderItem={renderListItem}
            showsVerticalScrollIndicator={Config.showVScrollBar}
        />
    );
};

export default FootprintDetailSheet;
