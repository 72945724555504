// @flow
import React, {useContext} from 'react';
import {connect} from 'react-redux';
import SoloAnswerContainer from '../../SoloAnswerContainer';
import IcDailyMeatEater from '../../../../UI/Icons/CarbonFootprint/Diet/IcDailyMeatEater';
import IcVegan from '../../../../UI/Icons/CarbonFootprint/Diet/IcVegan';
import IcEveryMealMeatEater from '../../../../UI/Icons/CarbonFootprint/Diet/IcEveryMealMeatEater';
import IcVegetarian from '../../../../UI/Icons/CarbonFootprint/Diet/IcVegetarian';
import IcFlexitarian from '../../../../UI/Icons/CarbonFootprint/Diet/IcFlexitarian';
import IcWeeklyMeatEater from '../../../../UI/Icons/CarbonFootprint/Diet/IcWeeklyMeatEater';
import {getDietType} from '../../../../../Reducers/CarbonFootprintReducers/CFDietReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import {Platform, View} from "react-native";
import Body from "../../../../UI/Typos/Body/Body";
import {ThemeContext} from "../../../../../libs/Hoc/ThemeProvider";
import type {DietType} from "../../../../../Types/CarbonFootprint/FootprintQuestionsTypes";

type Props = {
  setDietType: (DietType) => void,
  dietType: ?DietType,
};

const options = [
    {value: 'vegan', icon: IcVegan},
    {value: 'vegetarian', icon: IcVegetarian},
    {value: 'flexitarian', icon: IcFlexitarian},
    {value: 'weeklymeateater', icon: IcWeeklyMeatEater},
    {value: 'dailymeateater', icon: IcDailyMeatEater},
    {value: 'everymealmeateater', icon: IcEveryMealMeatEater},
];

const QDietType = ({
    setDietType,
    dietType,
}: Props) => {
    const [,, spacing] = useContext(ThemeContext);
    function handleChange(value: DietType){
        setDietType(value);
    }
    return (
        <View style={Platform.OS !== 'web' ? {paddingBottom: 30} : null}>
            <Body customStyle={{marginBottom: spacing[2]}} variant='paragraph'>
                {"Une seule réponse possible"}
            </Body>
            <SoloAnswerContainer
                handleChange={handleChange}
                numColumns={1}
                options={options}
                selectedValue={dietType}
            />
        </View>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    dietType: getDietType(store),
    ...props,
});

const mapDispatchToProps = {setDietType: CFActions.setDietType};

export default connect(mapStateToProps, mapDispatchToProps)(QDietType);
