// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getNbPeopleInCar} from '../../../../../Reducers/CarbonFootprintReducers/CFTransportReducer';
import CFActions from '../../../../../Actions/CarbonFootprintActions';
import type {Store} from '../../../../../Types/Reducers/Store';
import ESlider from "../../../../Shared/ESlider/ESlider";
import IcPerson from "../../../../UI/Icons/CarbonFootprint/Accomodation/IcPerson";
import SliderThumbBackground from "../../../../Shared/ESlider/SliderThumbBackground";
import SliderScaleMinMax from "../../../../Shared/ESlider/SliderScaleMinMax";

type Props = {
  setNbPeopleInCar: number => void,
  nbPeopleInCar: number,
};

const NbPeopleInCarQuestion = ({
    setNbPeopleInCar,
    nbPeopleInCar,
}: Props) => {
    return (
        <>
            <SliderScaleMinMax
                max='10'
                min='0'
            />
            <ESlider
                defaultValue={nbPeopleInCar}
                hasSliderSubText
                max={10}
                min={1}
                onSlidingComplete={setNbPeopleInCar}
                step={1}
                subText="personne(s) au total"
                thumbIcon={(<SliderThumbBackground><IcPerson /></SliderThumbBackground>)}
            />
        </>
    );
};

const mapStateToProps = (store: Store, props: Object) => ({
    nbPeopleInCar: getNbPeopleInCar(store),
    ...props,
});

const mapDispatchToProps = {setNbPeopleInCar: CFActions.setNbPeopleInCar};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NbPeopleInCarQuestion);