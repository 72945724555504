// @flow
import React, {createContext, useState} from 'react';
import {Appearance, ViewProps} from 'react-native';
import Theme from "../../Config/Theme";
import type {ThemeColorsType, ThemeFontsType} from "../../Types/ThemeTypes";
import {ColorSchemeName} from "react-native";

type ThemeContextType = {
    colors: ThemeColorsType,
    fonts: ThemeFontsType,
    spacing: number[],
    colorScheme: ColorSchemeName,
};

export const ThemeContext = createContext<ThemeContextType>();

export const ThemeProvider = ({children}: ViewProps) => {
    const {font: fonts, colors: themeColors, spacing} = Theme;

    const colorScheme = Appearance.getColorScheme();

    const colors = themeColors[colorScheme];

    return (
        <ThemeContext.Provider
            value={[
                colors,
                fonts,
                spacing,
                colorScheme,
            ]}
        >
            {children}
        </ThemeContext.Provider>
    );
};