/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcEveryMealMeatEater({size = 45}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 .519h41.34v22.237H0z" id="every_meat_prefix__a" />
                <Path d="M0 27.174h41.34V0H0z" id="every_meat_prefix__c" />
                <Path d="M.346.63h20.173v20.198H.346z" id="every_meat_prefix__e" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(11 16)">
                    <G transform="translate(0 4.418)">
                        <Mask fill="#fff" id="every_meat_prefix__b">
                            <Use xlinkHref="#every_meat_prefix__a" />
                        </Mask>
                        <Path
                            d="M20.67.519C9.723.519 0 4.799 0 11.636c0 5.134 4.86 9.41 12.155 9.41 4.202 0 8.647-2.576 11.828-2.576.114 0 .223 0 .336.011 4.75.286 4.86 4.276 10.944 4.276 2.846 0 6.076-2.566 6.076-8.557C41.34 6.505 32.83.52 20.67.52"
                            fill="#FFD983"
                            mask="url(#every_meat_prefix__b)"
                        />
                    </G>
                    <Path
                        d="M35.263 21.378c-2.766 0-3.995-.933-5.418-2.01-1.299-.99-2.776-2.099-5.423-2.265a6.441 6.441 0 00-.438-.014c-1.745 0-3.62.601-5.612 1.234-2.079.656-4.232 1.344-6.217 1.344-6.342 0-10.94-3.596-10.94-8.554C1.215 4.45 11.235.853 20.67.853c11.27 0 19.453 5.393 19.453 12.83 0 5.644-2.908 7.695-4.86 7.695"
                        fill="#A0041E"
                    />
                    <Mask fill="#fff" id="every_meat_prefix__d">
                        <Use xlinkHref="#every_meat_prefix__c" />
                    </Mask>
                    <Path
                        d="M38.909 18.53h2.43V13.58h-2.43v4.948zM0 16.054h2.429v-4.943H0v4.943z"
                        fill="#FFD983"
                        mask="url(#every_meat_prefix__d)"
                    />
                    <Path
                        d="M20.67 0C9.723 0 0 4.275 0 11.113c0 5.139 4.86 9.408 12.155 9.408 4.202 0 8.647-2.574 11.828-2.574.114 0 .223.006.336.01 4.75.286 4.86 4.275 10.944 4.275 2.846 0 6.076-2.564 6.076-8.549C41.34 5.981 32.83.001 20.67.001m0 1.71c10.57 0 18.24 5.032 18.24 11.971 0 5.118-2.505 6.838-3.646 6.838-2.232 0-3.146-.688-4.529-1.735-1.378-1.044-3.091-2.344-6.208-2.528-.177-.01-.36-.026-.543-.026-2 0-3.995.637-6.11 1.31-1.96.626-3.995 1.27-5.718 1.27-4.83 0-9.725-2.645-9.725-7.699 0-6.104 9.398-9.402 18.24-9.402"
                        fill="#FFE8B6"
                        mask="url(#every_meat_prefix__d)"
                    />
                    <Path
                        d="M18.432 1.781s3.453-.07 5.887 1.636c1.215.857.405 2.779.405 2.779l2.024.643s-.073-1.029 3.646-1.707c2.737-.5 5.527 1.707 5.527 1.707s-3.093-4.276-9.173-5.129c-6.079-.856-8.316.071-8.316.071"
                        fill="#FFE8B6"
                        mask="url(#every_meat_prefix__d)"
                    />
                    <Path
                        d="M29.179 8.55c0 1.89-2.179 3.421-4.86 3.421-2.688 0-4.865-1.53-4.865-3.422 0-1.886 2.177-3.416 4.865-3.416 2.681 0 4.86 1.53 4.86 3.416"
                        fill="#E6E7E8"
                        mask="url(#every_meat_prefix__d)"
                    />
                    <Path
                        d="M26.749 8.55c0 .942-1.09 1.71-2.43 1.71-1.343 0-2.435-.768-2.435-1.71 0-.944 1.092-1.712 2.436-1.712 1.338 0 2.429.768 2.429 1.711"
                        fill="#A7A9AC"
                        mask="url(#every_meat_prefix__d)"
                    />
                </G>
                <Path
                    d="M52.513 37.981l4.632-6.612a2.527 2.527 0 003.458-.887 2.526 2.526 0 00-.754-3.374 2.527 2.527 0 00-2.319-.3c0-.826-.398-1.598-1.08-2.074a2.525 2.525 0 00-3.52.62 2.525 2.525 0 00.446 3.375l-4.63 6.612 3.767 2.64z"
                    fill="#FFD983"
                />
                <Path
                    d="M34.296 59.981c1.888 1.324 9.478 4.16 14.098-2.438 3.212-4.592 2.434-7.699 2.856-13.814.376-2.544 2.709-6.184 3.369-7.126-.943-.66-3.82.138-3.82.138s-.894-3.442-1.84-4.102c-.66.943-3.525 5.285-5.06 6.224-5.25 2.732-8.106 3.03-11.326 7.623-5.276 7.54-.158 12.178 1.723 13.495"
                    fill="#C1694F"
                />
                <G>
                    <G transform="translate(13 30)">
                        <Mask fill="#fff" id="every_meat_prefix__f">
                            <Use xlinkHref="#every_meat_prefix__e" />
                        </Mask>
                        <Path
                            d="M7.99 6.391l-3.33-3.33a1.473 1.473 0 00-2.174-1.984c-.34.342-.493.826-.407 1.3A1.474 1.474 0 00.778 4.873c.54.537 1.4.574 1.984.085l3.329 3.33 1.898-1.897zm6.794 6.793l3.332 3.334a1.467 1.467 0 011.982.083 1.474 1.474 0 01-1.301 2.495 1.473 1.473 0 01-2.494 1.3 1.466 1.466 0 01-.086-1.982l-3.331-3.332 1.896-1.898h.002z"
                            fill="#FFD983"
                            mask="url(#every_meat_prefix__f)"
                        />
                    </G>
                    <Path
                        d="M29.964 39.773c-.946-.946-2.383-.894-3.612-2.125-1.228-1.228-1.177-2.666-2.123-3.613-2.037-2.035-4.066-.849-6.555 1.64-2.488 2.49-3.674 4.52-1.638 6.555.946.946 2.384.893 3.612 2.122 1.228 1.229 1.176 2.666 2.122 3.612 2.038 2.036 4.067.85 6.556-1.64 2.488-2.487 3.674-4.515 1.638-6.551"
                        fill="#C1694F"
                    />
                    <Path
                        d="M20.6 44a.597.597 0 01-.6-.597c0-.063.01-.132.029-.193.668-1.996 3.186-4.511 5.182-5.18a.602.602 0 01.378 1.141c-1.609.537-3.882 2.809-4.42 4.422a.6.6 0 01-.57.407"
                        fill="#662113"
                    />
                </G>
            </G>
        </Svg>
    );
}

export default IcEveryMealMeatEater;
