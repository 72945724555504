/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcTablet = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 504 504"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M412 482.4c0 12-9.6 21.6-21.6 21.6H113.6c-12 0-21.6-9.6-21.6-21.6V33.6c0-12 9.6-21.6 21.6-21.6h276.8c12 0 21.6 9.6 21.6 21.6v448.8z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M412 470.4c0 12-9.6 21.6-21.6 21.6H113.6c-12 0-21.6-9.6-21.6-21.6V21.6C92 9.6 101.6 0 113.6 0h276.8c12 0 21.6 9.6 21.6 21.6v448.8z"
            style={{fill: "#0c466d"}}
        />
        <Path
            d="M92 21.6C92 9.6 101.6 0 113.6 0h276.8c12 0 21.6 9.6 21.6 21.6v448.8c0 12-9.6 21.6-21.6 21.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M390.4 424c0 10.4-8 18.4-18.4 18.4H132c-10.4 0-18.4-8-18.4-18.4V52.8c0-10.4 8-18.4 18.4-18.4h240c10.4 0 18.4 8 18.4 18.4V424z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M390.4 411.2c0 10.4-8 18.4-18.4 18.4H132c-10.4 0-18.4-8-18.4-18.4v-372c0-10.4 8-18.4 18.4-18.4h240c10.4 0 18.4 8 18.4 18.4v372z"
            style={{fill: "#35dbdb"}}
        />
        <Path
            d="M113.6 39.2c0-10.4 8-18.4 18.4-18.4h240c10.4 0 18.4 8 18.4 18.4v372c0 10.4-8 18.4-18.4 18.4"
            style={{fill: "#1fc5c9"}}
        />
        <Path
            d="M372 20.8c10.4 0 18.4 8 18.4 18.4v372c0 10.4-8 18.4-18.4 18.4"
            style={{fill: "#12aab2"}}
        />
        <Path
            d="M132 429.6c-10.4 0-18.4-8-18.4-18.4v-372c0-10.4 8-18.4 18.4-18.4"
            style={{fill: "#57f2ee"}}
        />
        <Path
            d="M300.8 472c0 5.6-4 9.6-9.6 9.6h-78.4c-5.6 0-9.6-4-9.6-9.6v-12c0-5.6 4-9.6 9.6-9.6h78.4c5.6 0 9.6 4 9.6 9.6v12z"
            style={{fill: "#03355b"}}
        />
        <Path
            d="M291.2 482.4h-78.4c-5.6 0-10.4-4.8-10.4-10.4v-12c0-5.6 4.8-10.4 10.4-10.4h78.4c5.6 0 10.4 4.8 10.4 10.4v12c0 5.6-4.8 10.4-10.4 10.4zm-78.4-31.2c-4.8 0-8.8 4-8.8 8.8v12c0 4.8 4 8.8 8.8 8.8h78.4c4.8 0 8.8-4 8.8-8.8v-12c0-4.8-4-8.8-8.8-8.8h-78.4zM171.2 456.8c0 .8-.8 1.6-1.6 1.6h-26.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h26.4l1.6 1.6zM171.2 465.6c0 .8-.8 1.6-1.6 1.6h-26.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h26.4c.8 0 1.6.8 1.6 1.6zM171.2 474.4c0 .8-.8 1.6-1.6 1.6h-26.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h26.4c.8 0 1.6.8 1.6 1.6zM352.8 456.8c0 .8-.8 1.6-1.6 1.6h-26.4c-.8 0-1.6-.8-1.6-1.6s.8-1.6 1.6-1.6h26.4l1.6 1.6z"
            style={{fill: "#71a7bf"}}
        />
        <Path
            d="M351.2 480c-.8 0-1.6-.8-1.6-.8v-21.6c0-.8.8-.8 1.6-.8s1.6.8 1.6.8v21.6c0 .8-.8.8-1.6.8z"
            style={{fill: "#71a7bf"}}
        />
        <Path
            d="M153.6 10.4c0 .8-.8 2.4-2.4 2.4h-13.6c-.8 0-2.4-.8-2.4-2.4 0-.8.8-2.4 2.4-2.4h13.6c1.6.8 2.4 1.6 2.4 2.4z"
            style={{fill: "#1fc5c9"}}
        />
        <Circle
            cx={364.8}
            cy={10.4}
            r={5.6}
            style={{fill: "#1fc5c9"}}
        />
    </Svg>
);

export default IcTablet;
