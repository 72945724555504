// @flow
import React, {Node} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import BasicCard from "../Cards/BasicCard/BasicCard";
import {useContext} from "react";
import {ThemeContext} from "../../../libs/Hoc/ThemeProvider";
import Close from "../Icons/UI/Close";
import ActionIcon from "../Icons/IconContainers/ActionIcon/ActionIcon";
import Config from "../../../Config/Config";

type Props = {
    children: Node,
    onClose: () => void,
    contentStyle?: Object,
    containerStyle?: Object,
}

const {DEVICE_HEIGHT, DEVICE_WIDTH} = Config;

const variableBgStyling = Platform.OS === 'web'
    ? {
        width: DEVICE_WIDTH,
        height: DEVICE_HEIGHT,
        maxHeight: DEVICE_HEIGHT,
        overflow: 'hidden',
        borderRadius: 28,
        alignSelf: 'center',
        marginVertical: 'auto',
    } : {...StyleSheet.absoluteFill};

const EModal = ({
    children,
    onClose,
    containerStyle = {},
    contentStyle = {},
}: Props) => {
    const [colors,, spacing] = useContext(ThemeContext);
    return (
        <View
            style={{
                ...variableBgStyling,
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                justifyContent: 'center',
            }}
        >
            <View
                style={{
                    flexGrow: 1,
                    justifyContent: 'center',
                    marginHorizontal: spacing[2],
                }}
            >
                <BasicCard
                    customStyle={{
                        backgroundColor: colors.background.gradient_start,
                        ...containerStyle,
                    }}
                >
                    <ActionIcon
                        customStyle={{alignSelf: 'flex-end'}}
                        onPress={onClose}
                    >
                        <Close color={colors.text.white} />
                    </ActionIcon>
                    <View style={contentStyle}>
                        {children}
                    </View>
                </BasicCard>
            </View>
        </View>
    );
};

export default EModal;