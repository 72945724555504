/* eslint-disable max-len */
// @flow
import React from "react";
import Svg, {Path} from "react-native-svg";
import theme from '../../../../Config/Theme';

type Props = {
    color?: string,
};

const Help = ({color = theme.colors.light.text.white}: Props) => (
    <Svg fill="none" height={18} width={18} xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M7.952 11.995c0-1.01.09-1.738.272-2.181.18-.443.564-.926 1.15-1.45.512-.45.902-.84 1.17-1.17.268-.331.403-.709.403-1.133 0-.511-.172-.936-.515-1.273-.343-.337-.82-.505-1.432-.505-.636 0-1.12.193-1.451.58-.33.387-.564.78-.702 1.18L4.92 5.218c.262-.799.743-1.491 1.442-2.078.699-.586 1.578-.88 2.639-.88 1.31 0 2.318.365 3.023 1.096.705.73 1.058 1.606 1.058 2.63 0 .623-.134 1.157-.402 1.6-.269.443-.69.945-1.264 1.507-.612.586-.983 1.032-1.114 1.338-.13.306-.197.827-.197 1.563H7.952ZM9 16.487c-.412 0-.764-.146-1.057-.44a1.443 1.443 0 0 1-.44-1.057c0-.412.146-.765.44-1.058.293-.293.645-.44 1.057-.44.412 0 .764.147 1.058.44.293.293.44.646.44 1.058 0 .412-.147.764-.44 1.058-.293.293-.646.44-1.058.44Z"
            fill={color}
        />
    </Svg>
);

export default Help;