/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Defs, G, Mask, Path, Use} from 'react-native-svg';

type Props = {
    size?: number,
};

function IcElectricCar({size = 55}: Props){
    return (
        <Svg height={size} viewBox="0 0 70 70" width={size}>
            <Defs>
                <Path d="M0 1.066h43.969v21.879H0z" id="electric_car_prefix__a" />
                <Path d="M.296.07h9.773V9.8H.296z" id="electric_car_prefix__c" />
                <Path d="M1.375.07h9.768V9.8H1.375z" id="electric_car_prefix__e" />
            </Defs>
            <G fill="none" fillRule="evenodd">
                <G transform="translate(11 22.334)">
                    <Mask fill="#fff" id="electric_car_prefix__b">
                        <Use xlinkHref="#electric_car_prefix__a" />
                    </Mask>
                    <Path
                        d="M15.875 22.945h24.428s3.666 0 3.666-4.86c0-2.43 0-7.295-1.22-8.509-1.221-1.22-9.774-8.51-13.44-8.51h-7.327c-3.662 0-12.209 8.51-12.209 8.51L4.886 10.79S1.22 12 1.22 14.436v3.65S0 18.495 0 20.46c0 2.484 2.441 2.484 2.441 2.484h13.434z"
                        fill="#2EDD6C"
                        mask="url(#electric_car_prefix__b)"
                    />
                </G>
                <Path
                    d="M35.428 25.825h-2.446c-2.441 0-9.769 7.3-9.769 7.3s6.103-.322 12.215-.632v-6.668zm12.214 3.65c-1.222-1.215-6.107-3.65-8.548-3.65h-1.225v6.544c4.9-.247 9.261-.458 9.773-.458 1.22 0 1.22-1.22 0-2.436z"
                    fill="#BBDDF5"
                />
                <G transform="translate(18.031 39.134)">
                    <Mask fill="#fff" id="electric_car_prefix__d">
                        <Use xlinkHref="#electric_car_prefix__c" />
                    </Mask>
                    <Path
                        d="M10.07 4.935C10.07 7.617 7.88 9.8 5.182 9.8 2.484 9.801.296 7.617.296 4.935.296 2.248 2.484.07 5.183.07c2.698 0 4.886 2.178 4.886 4.865"
                        fill="#292F33"
                        mask="url(#electric_car_prefix__d)"
                    />
                </G>
                <Path
                    d="M25.655 44.069a2.439 2.439 0 01-2.387 2.483 2.438 2.438 0 01-2.496-2.374v-.11a2.432 2.432 0 012.496-2.374 2.43 2.43 0 012.387 2.375"
                    fill="#CCD6DD"
                />
                <G transform="translate(37.719 39.134)">
                    <Mask fill="#fff" id="electric_car_prefix__f">
                        <Use xlinkHref="#electric_car_prefix__e" />
                    </Mask>
                    <Path
                        d="M11.143 4.935c0 2.682-2.188 4.866-4.887 4.866a4.875 4.875 0 01-4.88-4.866A4.871 4.871 0 016.255.07c2.699 0 4.887 2.178 4.887 4.865"
                        fill="#292F33"
                        mask="url(#electric_car_prefix__f)"
                    />
                </G>
                <Path
                    d="M46.42 44.069a2.439 2.439 0 01-2.386 2.483 2.438 2.438 0 01-2.5-2.374v-.11a2.43 2.43 0 012.496-2.374 2.43 2.43 0 012.39 2.375"
                    fill="#CCD6DD"
                />
            </G>
        </Svg>
    );
}

export default IcElectricCar;
