/* eslint-disable max-len */
// @flow
import React from 'react';
import Svg, {Path, Ellipse, Circle} from "react-native-svg";

type Props = {
  size?: number,
};

const IcHomeCinema = ({size = 35}: Props) => (
    <Svg
        height={size}
        viewBox="0 0 496.8 496.8"
        width={size}
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <Path
            d="M56 451.6c-27.2 0-50.4 12-56 28.8h111.2c-4.8-16-27.2-28.8-55.2-28.8z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M0 480.4h111.2c-5.6-16-28-28.8-56-28.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M90.4 434.8c0 14.4-11.2 25.6-25.6 25.6H47.2c-14.4 0-25.6-11.2-25.6-25.6V42c.8-13.6 12-25.6 25.6-25.6h17.6c14.4 0 25.6 11.2 25.6 25.6v392.8z"
            style={{fill: "#ffa515"}}
        />
        <Path
            d="M47.2 16.4h17.6c14.4 0 25.6 11.2 25.6 25.6v392.8c0 14.4-11.2 25.6-25.6 25.6H47.2"
            style={{fill: "#ed900a"}}
        />
        <Path
            d="M64.8 16.4c14.4 0 25.6 11.2 25.6 25.6v392.8c0 14.4-11.2 25.6-25.6 25.6"
            style={{fill: "#ea7711"}}
        />
        <Ellipse
            cx={35.292}
            cy={28.458}
            rx={4}
            ry={12.799}
            style={{fill: "#ffd217"}}
            transform="scale(-1) rotate(50.6 60.206 -74.663)"
        />
        <Path
            d="M30.4 90.8C30.4 76.4 41.6 62 56 62s25.6 15.2 25.6 28.8"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={90.8}
            r={25.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M81.6 90.8c0 14.4-11.2 28.8-25.6 28.8s-25.6-15.2-25.6-28.8c0-14.4 11.2-21.6 25.6-21.6s25.6 7.2 25.6 21.6z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M81.6 90.8c0 14.4-11.2 25.6-25.6 25.6s-25.6-12-25.6-25.6c0-14.4 11.2-21.6 25.6-21.6s25.6 7.2 25.6 21.6z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 65.2c14.4 0 25.6 11.2 25.6 25.6S70.4 116.4 56 116.4"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M64.8 81.2c4.8 4.8 4.8 16 0 20.8s-12.8 4.8-18.4 0c-4.8-4.8-4.8-16 0-20.8s13.6-4.8 18.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={90.8}
            r={12.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M47.2 81.2c4.8-4.8 12.8-4.8 18.4 0s4.8 12.8 0 18.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M63.2 90.8c0 4-3.2 8-7.2 8s-7.2-4.8-7.2-8c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={90.8}
            r={7.2}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 83.6c4 0 7.2 3.2 7.2 7.2S60 98 56 98"
            style={{fill: "#707070"}}
        />
        <Path
            d="M38.4 146.8c0-9.6 8-20 17.6-20s17.6 10.4 17.6 20"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={146.8}
            r={17.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M73.6 146.8c0 9.6-8 20-17.6 20s-17.6-10.4-17.6-20 8-15.2 17.6-15.2 17.6 4.8 17.6 15.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M73.6 146.8c0 9.6-8 17.6-17.6 17.6s-17.6-8-17.6-17.6 8-15.2 17.6-15.2 17.6 4.8 17.6 15.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 128.4c9.6 0 17.6 8 17.6 17.6s-8 17.6-17.6 17.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M62.4 140.4c3.2 3.2 3.2 11.2 0 14.4-3.2 3.2-8.8 3.2-12.8 0-3.2-3.2-3.2-11.2 0-14.4 4-4 9.6-4 12.8 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={146.8}
            r={8.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M49.6 140.4c3.2-3.2 8.8-3.2 12.8 0 3.2 3.2 3.2 8.8 0 12.8"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M60.8 146.8c0 2.4-2.4 5.6-4.8 5.6s-4.8-3.2-4.8-5.6c0-2.4 2.4-4.8 4.8-4.8 3.2 0 4.8 1.6 4.8 4.8z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={146.8}
            r={4.8}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 142c2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M38.4 322c0-9.6 8-20 17.6-20s17.6 10.4 17.6 20"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={322}
            r={17.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M73.6 322c0 9.6-8 20-17.6 20s-17.6-10.4-17.6-20 8-15.2 17.6-15.2 17.6 5.6 17.6 15.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M73.6 322c0 9.6-8 17.6-17.6 17.6s-17.6-8-17.6-17.6 8-15.2 17.6-15.2 17.6 5.6 17.6 15.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 304.4c9.6 0 17.6 8 17.6 17.6s-8 17.6-17.6 17.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M62.4 315.6c3.2 3.2 3.2 11.2 0 14.4-3.2 3.2-8.8 3.2-12.8 0-3.2-3.2-3.2-11.2 0-14.4 4-3.2 9.6-3.2 12.8 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={322}
            r={8.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M49.6 315.6c3.2-3.2 8.8-3.2 12.8 0 3.2 3.2 3.2 8.8 0 12.8"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M60.8 322c0 2.4-2.4 5.6-4.8 5.6s-4.8-3.2-4.8-5.6 2.4-4.8 4.8-4.8c3.2 0 4.8 2.4 4.8 4.8z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={322}
            r={4.8}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 317.2c2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M71.2 266.8c0 8-6.4 15.2-15.2 15.2-8 0-15.2-6.4-15.2-15.2v-59.2c0-8 6.4-15.2 15.2-15.2 8 0 15.2 6.4 15.2 15.2v59.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M56 193.2c8 0 15.2 6.4 15.2 15.2v59.2c0 8-6.4 15.2-15.2 15.2"
            style={{fill: "#a84a0b"}}
        />
        <Path
            d="M47.2 211.6c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={211.6}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M64.8 211.6c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M64.8 211.6c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 202.8c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M59.2 208.4c1.6 1.6 1.6 5.6 0 7.2-1.6 1.6-4 1.6-6.4 0-1.6-1.6-1.6-5.6 0-7.2 1.6-1.6 4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={211.6}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M52.8 208.4c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M58.4 211.6c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4 1.6 0 2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={211.6}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 209.2c1.6 0 2.4.8 2.4 2.4 0 1.6-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M47.2 237.2c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={237.2}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M64.8 237.2c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M64.8 237.2c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 228.4c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M59.2 234c1.6 1.6 1.6 5.6 0 7.2-1.6 1.6-4 1.6-6.4 0-1.6-1.6-1.6-5.6 0-7.2 1.6-1.6 4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={237.2}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M52.8 234c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M58.4 237.2c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4 1.6 0 2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={237.2}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 234.8c1.6 0 2.4.8 2.4 2.4 0 1.6-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M47.2 262.8c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={262.8}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M64.8 262.8c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M64.8 262.8c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 254c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M59.2 259.6c1.6 1.6 1.6 5.6 0 7.2s-4 1.6-6.4 0c-1.6-1.6-1.6-5.6 0-7.2s4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={262.8}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M52.8 259.6c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M58.4 262.8c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4 1.6 0 2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={262.8}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 260.4c1.6 0 2.4.8 2.4 2.4s-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M30.4 386c0-14.4 11.2-28.8 25.6-28.8s25.6 15.2 25.6 28.8"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={56}
            cy={386}
            r={25.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M81.6 386c0 14.4-11.2 28.8-25.6 28.8S30.4 400.4 30.4 386s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M81.6 386c0 14.4-11.2 25.6-25.6 25.6S30.4 400.4 30.4 386s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M56 360.4c14.4 0 25.6 11.2 25.6 25.6S70.4 411.6 56 411.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M64.8 376.4c4.8 4.8 4.8 16 0 20.8s-12.8 4.8-18.4 0c-4.8-4.8-4.8-16 0-20.8 5.6-4.8 13.6-4.8 18.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={56}
            cy={386}
            r={12.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M47.2 376.4c4.8-4.8 12.8-4.8 18.4 0 4.8 4.8 4.8 12.8 0 18.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M63.2 386c0 4-3.2 8-7.2 8s-7.2-4.8-7.2-8c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={56}
            cy={386}
            r={7.2}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M56 378.8c4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2"
            style={{fill: "#707070"}}
        />
        <Path
            d="M441.6 451.6c-27.2 0-50.4 12-56 28.8h111.2c-4.8-16-28-28.8-55.2-28.8z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M385.6 480.4h111.2c-5.6-16-28-28.8-56-28.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M475.2 434.8c0 14.4-11.2 25.6-25.6 25.6h-16.8c-14.4 0-25.6-11.2-25.6-25.6V42c0-13.6 11.2-25.6 25.6-25.6h16.8c14.4 0 25.6 11.2 25.6 25.6v392.8z"
            style={{fill: "#ffa515"}}
        />
        <Path
            d="M432.8 16.4h16.8c14.4 0 25.6 11.2 25.6 25.6v392.8c0 14.4-11.2 25.6-25.6 25.6h-16.8"
            style={{fill: "#ed900a"}}
        />
        <Path
            d="M450.4 16.4c14.4 0 25.6 11.2 25.6 25.6v392.8c0 14.4-11.2 25.6-25.6 25.6"
            style={{fill: "#ea7711"}}
        />
        <Ellipse
            cx={420.813}
            cy={28.294}
            rx={4}
            ry={12.799}
            style={{fill: "#ffd217"}}
            transform="scale(-1) rotate(50.6 59.858 -890.274)"
        />
        <Path
            d="M416 90.8c0-14.4 11.2-28.8 25.6-28.8s25.6 15.2 25.6 28.8"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={90.8}
            r={25.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M467.2 90.8c0 14.4-11.2 28.8-25.6 28.8S416 105.2 416 90.8s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M467.2 90.8c0 14.4-11.2 25.6-25.6 25.6S416 105.2 416 90.8s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 65.2c14.4 0 25.6 11.2 25.6 25.6s-11.2 25.6-25.6 25.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M450.4 81.2c4.8 4.8 4.8 16 0 20.8s-12.8 4.8-18.4 0c-4.8-4.8-4.8-16 0-20.8s13.6-4.8 18.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={90.8}
            r={12.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M432.8 81.2c4.8-4.8 12.8-4.8 18.4 0s4.8 12.8 0 18.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M448.8 90.8c0 4-3.2 8-7.2 8s-7.2-4.8-7.2-8c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={90.8}
            r={7.2}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 83.6c4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2"
            style={{fill: "#707070"}}
        />
        <Path
            d="M424 146.8c0-9.6 8-20 17.6-20s17.6 10.4 17.6 20"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={146.8}
            r={17.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M459.2 146.8c0 9.6-8 20-17.6 20s-17.6-10.4-17.6-20 8-15.2 17.6-15.2 17.6 4.8 17.6 15.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M459.2 146.8c0 9.6-8 17.6-17.6 17.6s-17.6-8-17.6-17.6 8-15.2 17.6-15.2 17.6 4.8 17.6 15.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 128.4c9.6 0 17.6 8 17.6 17.6s-8 17.6-17.6 17.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M448 140.4c3.2 3.2 3.2 11.2 0 14.4s-8.8 3.2-12.8 0c-3.2-3.2-3.2-11.2 0-14.4 3.2-4 8.8-4 12.8 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={146.8}
            r={8.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M435.2 140.4c3.2-3.2 8.8-3.2 12.8 0 3.2 3.2 3.2 8.8 0 12.8"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M446.4 146.8c0 2.4-2.4 5.6-4.8 5.6s-4.8-3.2-4.8-5.6c0-2.4 2.4-4.8 4.8-4.8s4.8 1.6 4.8 4.8z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={146.8}
            r={4.8}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 142c2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M424 322c0-9.6 8-20 17.6-20s17.6 10.4 17.6 20"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={322}
            r={17.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M459.2 322c0 9.6-8 20-17.6 20S424 331.6 424 322s8-15.2 17.6-15.2 17.6 5.6 17.6 15.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M459.2 322c0 9.6-8 17.6-17.6 17.6s-17.6-8-17.6-17.6 8-15.2 17.6-15.2 17.6 5.6 17.6 15.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 304.4c9.6 0 17.6 8 17.6 17.6s-8 17.6-17.6 17.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M448 315.6c3.2 3.2 3.2 11.2 0 14.4-3.2 3.2-8.8 3.2-12.8 0-3.2-3.2-3.2-11.2 0-14.4 3.2-3.2 8.8-3.2 12.8 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={322}
            r={8.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M435.2 315.6c3.2-3.2 8.8-3.2 12.8 0 3.2 3.2 3.2 8.8 0 12.8"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M446.4 322c0 2.4-2.4 5.6-4.8 5.6s-4.8-3.2-4.8-5.6 2.4-4.8 4.8-4.8 4.8 2.4 4.8 4.8z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={322}
            r={4.8}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 317.2c2.4 0 4.8 2.4 4.8 4.8s-2.4 4.8-4.8 4.8"
            style={{fill: "#707070"}}
        />
        <Path
            d="M456 266.8c0 8-6.4 15.2-15.2 15.2-8 0-15.2-6.4-15.2-15.2v-59.2c0-8 6.4-15.2 15.2-15.2 8 0 15.2 6.4 15.2 15.2v59.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M441.6 193.2c8 0 15.2 6.4 15.2 15.2v59.2c0 8-6.4 15.2-15.2 15.2"
            style={{fill: "#a84a0b"}}
        />
        <Path
            d="M432.8 211.6c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={211.6}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M450.4 211.6c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M450.4 211.6c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 202.8c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M444.8 208.4c1.6 1.6 1.6 5.6 0 7.2-1.6 1.6-4 1.6-6.4 0-1.6-1.6-1.6-5.6 0-7.2 1.6-1.6 4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={211.6}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M438.4 208.4c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M444 211.6c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4s2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={211.6}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 209.2c1.6 0 2.4.8 2.4 2.4 0 1.6-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M432.8 237.2c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={237.2}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M450.4 237.2c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M450.4 237.2c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 228.4c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M444.8 234c1.6 1.6 1.6 5.6 0 7.2-1.6 1.6-4 1.6-6.4 0-1.6-1.6-1.6-5.6 0-7.2 1.6-1.6 4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={237.2}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M438.4 234c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M444 237.2c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4s2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={237.2}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 234.8c1.6 0 2.4.8 2.4 2.4 0 1.6-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M432.8 262.8c0-4.8 4-9.6 8.8-9.6s8.8 4.8 8.8 9.6"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={262.8}
            r={8.8}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M450.4 262.8c0 4.8-4 9.6-8.8 9.6s-8.8-4.8-8.8-9.6 4-7.2 8.8-7.2 8.8 2.4 8.8 7.2z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M450.4 262.8c0 4.8-4 8.8-8.8 8.8s-8.8-4-8.8-8.8c0-4.8 4-7.2 8.8-7.2s8.8 2.4 8.8 7.2z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 254c4.8 0 8.8 4 8.8 8.8s-4 8.8-8.8 8.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M444.8 259.6c1.6 1.6 1.6 5.6 0 7.2s-4 1.6-6.4 0c-1.6-1.6-1.6-5.6 0-7.2s4.8-1.6 6.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={262.8}
            r={4}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M438.4 259.6c1.6-1.6 4-1.6 6.4 0 1.6 1.6 1.6 4 0 6.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M444 262.8c0 1.6-.8 2.4-2.4 2.4s-2.4-1.6-2.4-2.4c0-1.6.8-2.4 2.4-2.4s2.4.8 2.4 2.4z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={262.8}
            r={2.4}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 260.4c1.6 0 2.4.8 2.4 2.4s-.8 2.4-2.4 2.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M416 386c0-14.4 11.2-28.8 25.6-28.8s25.6 15.2 25.6 28.8"
            style={{fill: "#ffb717"}}
        />
        <Circle
            cx={441.6}
            cy={386}
            r={25.6}
            style={{fill: "#00233f"}}
        />
        <Path
            d="M467.2 386c0 14.4-11.2 28.8-25.6 28.8S416 400.4 416 386s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M467.2 386c0 14.4-11.2 25.6-25.6 25.6S416 400.4 416 386s11.2-21.6 25.6-21.6 25.6 7.2 25.6 21.6z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M441.6 360.4c14.4 0 25.6 11.2 25.6 25.6s-11.2 25.6-25.6 25.6"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M450.4 376.4c4.8 4.8 4.8 16 0 20.8s-12.8 4.8-18.4 0c-4.8-4.8-4.8-16 0-20.8 5.6-4.8 13.6-4.8 18.4 0z"
            style={{fill: "#00233f"}}
        />
        <Circle
            cx={441.6}
            cy={386}
            r={12.8}
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M432.8 376.4c4.8-4.8 12.8-4.8 18.4 0 4.8 4.8 4.8 12.8 0 18.4"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M448.8 386c0 4-3.2 8-7.2 8s-7.2-4.8-7.2-8c0-4 3.2-7.2 7.2-7.2s7.2 3.2 7.2 7.2z"
            style={{fill: "#a09f9f"}}
        />
        <Circle
            cx={441.6}
            cy={386}
            r={7.2}
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M441.6 378.8c4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2"
            style={{fill: "#707070"}}
        />
        <Path
            d="M253.6 456.4c0 2.4-1.6 4.8-4.8 4.8-2.4 0-4.8-2.4-4.8-4.8v-88c0-2.4 1.6-4.8 4.8-4.8 2.4 0 4.8 1.6 4.8 4.8v88z"
            style={{fill: "#4f4f4f"}}
        />
        <Path
            d="M392 332.4c0 17.6-3.2 31.2-20.8 31.2H126.4c-17.6 0-20.8-13.6-20.8-31.2V207.6c0-17.6 13.6-31.2 31.2-31.2h224c17.6 0 31.2 13.6 31.2 31.2v124.8z"
            style={{fill: "#ed900a"}}
        />
        <Path
            d="M392 207.6v124.8c0 17.6-3.2 31.2-20.8 31.2H126.4c-17.6 0-20.8-13.6-20.8-31.2V207.6"
            style={{fill: "#ea7711"}}
        />
        <Path
            d="M392 332.4c0 17.6-3.2 31.2-20.8 31.2H126.4c-17.6 0-20.8-13.6-20.8-31.2"
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={187.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={197.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={206.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={217.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={226.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={236.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={246}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={255.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={266}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={275.6}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={285.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={294.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={305.2}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={314.8}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={161.6}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={175.2}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={188.8}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={201.6}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={215.2}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={228.8}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={242.4}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={255.2}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={268.8}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={282.4}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={296}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={308.8}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={322.4}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Circle
            cx={336}
            cy={324.4}
            r={1.6}
            style={{fill: "#d16311"}}
        />
        <Path
            d="M132 270.8c0-28.8 24-60 52.8-60s52.8 31.2 52.8 60"
            style={{fill: "#ffb717"}}
        />
        <Path
            d="M237.6 270.8c0 28.8-23.2 52.8-52.8 52.8S132 300.4 132 270.8c0-28.8 24-52.8 52.8-52.8s52.8 23.2 52.8 52.8z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M237.6 270.8c0 28.8-23.2 59.2-52.8 59.2S132 299.6 132 270.8s24-44.8 52.8-44.8 52.8 15.2 52.8 44.8z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M237.6 270.8c0 28.8-23.2 52.8-52.8 52.8S132 300.4 132 270.8c0-28.8 24-44.8 52.8-44.8s52.8 15.2 52.8 44.8z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M184.8 218c28.8 0 52.8 23.2 52.8 52.8 0 28.8-23.2 52.8-52.8 52.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M203.2 251.6c10.4 10.4 10.4 32.8 0 43.2-10.4 10.4-27.2 10.4-37.6 0-10.4-10.4-10.4-32.8 0-43.2 10.4-9.6 27.2-9.6 37.6 0z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M203.2 251.6c10.4 10.4 10.4 27.2 0 37.6s-27.2 10.4-37.6 0c-10.4-10.4-10.4-27.2 0-37.6 10.4-9.6 27.2-9.6 37.6 0z"
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M165.6 251.6c10.4-10.4 27.2-10.4 37.6 0s10.4 27.2 0 37.6"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M199.2 270.8c0 8-6.4 16.8-14.4 16.8s-14.4-8.8-14.4-16.8 6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4z"
            style={{fill: "#a09f9f"}}
        />
        <Path
            d="M199.2 270.8c0 8-6.4 14.4-14.4 14.4s-14.4-6.4-14.4-14.4 6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M184.8 256.4c8 0 14.4 6.4 14.4 14.4s-6.4 14.4-14.4 14.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M260 270.8c0-28.8 24-60 52.8-60s52.8 31.2 52.8 60"
            style={{fill: "#ffb717"}}
        />
        <Path
            d="M365.6 270.8c0 28.8-23.2 52.8-52.8 52.8-28.8 0-52.8-23.2-52.8-52.8 0-28.8 24-52.8 52.8-52.8 29.6 0 52.8 23.2 52.8 52.8z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M365.6 270.8c0 28.8-23.2 59.2-52.8 59.2-28.8 0-52.8-30.4-52.8-59.2s24-44.8 52.8-44.8c29.6-.8 52.8 15.2 52.8 44.8z"
            style={{fill: "#d16311"}}
        />
        <Path
            d="M365.6 270.8c0 28.8-23.2 52.8-52.8 52.8-28.8 0-52.8-23.2-52.8-52.8 0-28.8 24-44.8 52.8-44.8 29.6-.8 52.8 15.2 52.8 44.8z"
            style={{fill: "#064570"}}
        />
        <Path
            d="M312.8 218c28.8 0 52.8 23.2 52.8 52.8 0 28.8-23.2 52.8-52.8 52.8"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M332 251.6c10.4 10.4 10.4 32.8 0 43.2-10.4 10.4-27.2 10.4-37.6 0s-10.4-32.8 0-43.2c10.4-9.6 27.2-9.6 37.6 0z"
            style={{fill: "#00233f"}}
        />
        <Path
            d="M332 251.6c10.4 10.4 10.4 27.2 0 37.6s-27.2 10.4-37.6 0-10.4-27.2 0-37.6c10.4-9.6 27.2-9.6 37.6 0z"
            style={{fill: "#b2b2b2"}}
        />
        <Path
            d="M294.4 251.6c10.4-10.4 27.2-10.4 37.6 0s10.4 27.2 0 37.6"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M327.2 270.8c0 8-6.4 16.8-14.4 16.8s-14.4-8.8-14.4-16.8 6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4z"
            style={{fill: "#a09f9f"}}
        />
        <Path
            d="M327.2 270.8c0 8-6.4 14.4-14.4 14.4s-14.4-6.4-14.4-14.4 6.4-14.4 14.4-14.4 14.4 6.4 14.4 14.4z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M312.8 256.4c8 0 14.4 6.4 14.4 14.4s-6.4 14.4-14.4 14.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M358.4 480.4c-11.2-15.2-56-27.2-109.6-27.2s-98.4 11.2-109.6 27.2h219.2z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M139.2 480.4h220c-11.2-15.2-56-27.2-109.6-27.2"
            style={{fill: "#707070"}}
        />
        <Path
            d="M392.8 361.2c0 3.2-2.4 6.4-6.4 6.4H111.2c-3.2 0-6.4-2.4-6.4-6.4 0-3.2 2.4-6.4 6.4-6.4h275.2c3.2 0 6.4 2.4 6.4 6.4z"
            style={{fill: "#8e8e8e"}}
        />
        <Path
            d="M392.8 361.2c0 3.2-2.4 6.4-6.4 6.4H111.2c-3.2 0-6.4-2.4-6.4-6.4"
            style={{fill: "#707070"}}
        />
        <Path
            d="M254.4 393.2c0 .8 0 .8 0 0l-10.4.8s-.8 0-.8-.8c0 0 0-.8.8-.8l10.4.8zM254.4 398.8s0 .8 0 0l-10.4.8s-.8 0-.8-.8c0 0 0-.8.8-.8l10.4.8c0-.8 0-.8 0 0zM254.4 403.6c0 .8 0 .8 0 0l-10.4.8s-.8 0-.8-.8c0 0 0-.8.8-.8l10.4.8c0-.8 0 0 0 0z"
            style={{fill: "#8e8e8e"}}
        />
    </Svg>
);

export default IcHomeCinema;
