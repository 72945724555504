// @flow
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import type {Store} from '../../../../../Types/Reducers/Store';
import ThematicMainScreen from '../../ThematicMainScreen';
import appRoutes from "../../../../../Navigation/AppRoutes";
import {getIsUpdatingCF} from "../../../../../Reducers/CarbonFootprintReducer";
import ThematicIcon from "../../Icons/ThematicIcon";

const thematicText = `L'alimentation est à l’origine de 24% de l'empreinte carbone moyenne d'un Français, soit 2,35 tonnes équivalent CO2 par an 🧐.\nAlors, plutôt locale ou exotique ? Donne-moi quelques précisions sur ton alimentation pour connaitre ta moyenne personnelle 👩‍🍳`;

const mapStateToProps = (store: Store, props: Object) => ({
    thematicTitle: 'Alimentation',
    thematicIcon: <ThematicIcon size={100} thematic='diet' />,
    thematicIndex: 2,
    thematicsNumber: 5,
    onPressReturn: () => props.navigation.navigate(appRoutes.cf.mobility.questions, {back: true}),
    onPressNext: () => props.navigation.navigate(appRoutes.cf.diet.questions),
    thematicText,
    ...props,
});

export default connect(
    mapStateToProps,
)(ThematicMainScreen);
