/* eslint-disable max-len, quotes */
import fr_global from "./fr_global";
import fr_ecogestures from "./fr_ecogestures";
import fr_cf from "./fr_cf";

/**

global => mot généraux non-spécifiques
 .actions => verbes d'action
 .pages => noms des pages/sections principales de l'app
 .common => mots du vocabulaire global Energic
 .drawerMenu => menu de la home
 .domains => thématiques/catégories de contenus
 .tiers => niveaux d'engagement
 .xp_grade => niveaux d'XP
 .errors => messages d'erreur

auth => rapport au login/register/onboarding

home => page accueil et reliées
 .home_page
 .impact
 .podium
 .totems
 .missions
 .ecosystem
 .notification_center
 .community
 .initiation

ideas => pages idées

agora => pages agora

ecogestures => pages écogestes

kiosk => pages kiosque

profile => page profile et sous-jacentes

quiz => quiz normal et quiz survie

cf => bilan carbone

settings => paramètres

 */

export default {
    global: fr_global,
    ecogestures: fr_ecogestures,
    cf: fr_cf,
};
