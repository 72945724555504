// @flow
import React, {useContext, useMemo} from 'react';
import {ImageBackground, Platform, View} from 'react-native';
import ActionIcon from '../../../Icons/IconContainers/ActionIcon/ActionIcon';
import Close from '../../../Icons/UI/Close';
import Touchable from '../../../Buttons/Touchable/Touchable';
import Utils from '../../../../../Config/Utils';
import {ThemeContext} from '../../../../../libs/Hoc/ThemeProvider';
import styles from '../HelpMM/styles';
import Config from "../../../../../Config/Config";

type Props = {
    onClose: () => void,
    imageURI: string,
};

const ImageZoomMM = ({
    onClose = () => null,
    imageURI = '',
}: Props) => {
    const [colors] = useContext(ThemeContext);
    const lighterURI = useMemo(() => Utils.getLighterImageCloudinary(imageURI), [imageURI]);
    return (
        <Touchable
            onPress={onClose}
            style={styles.modalBackground}
        >
            <View style={styles.modalContainer}>
                <ImageBackground
                    resizeMode="contain"
                    source={{uri: lighterURI, cache: 'force-cache'}}
                    style={{
                        width: '100%',
                        height: Platform.OS === 'web' ? Config.DEVICE_HEIGHT : '100%',
                        borderRadius: 6,
                        backgroundColor: 'rgba(0,0,0, 0.90)',
                    }}
                />
                <ActionIcon
                    customStyle={{
                        zIndex: 10,
                        position: 'absolute',
                        top: 40,
                        right: 30,
                    }}
                    onPress={onClose}
                >
                    <Close color={colors.text.white} />
                </ActionIcon>
            </View>
        </Touchable>
    );
};

export default ImageZoomMM;