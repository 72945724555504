// @flow

const theme = {
    spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120],
    font: {
        regular: 'Montserrat',
        bold: 'Montserrat-Bold',
        light: 'Montserrat-Light',
    },
    colors: {
        light: {
            feedback: {
                success1: '#74C69D',
                success2: '#00A594',
                failure1: '#D55252',
                failure2: '#FF8845',
            },
            background: {
                transparent1: 'rgba(255, 255, 255, 0.6)',
                transparent2: 'rgba(255, 255, 255, 0.8)',
                gradient_start: '#F8FBFF',
                gradient_end: '#FAF0E5',
                card: '#FFFFFF',
                disabled: '#DFDFDF',
                bottomBar: '#03136A',
            },
            blue: {
                light: '#CAD2FF',
                main: '#233495',
                dark: '#03136A',
            },
            yellow: {
                light: '#FFE99D',
                main: '#FFD542',
                dark: '#E6BA21',
            },
            green: {
                light: '#AEEDE6',
                main: '#00BDAA',
                dark: '#009384',
            },
            action: '#FE346E',
            text: {
                dark: '#2C2C2C',
                light: '#545454',
                disabled: '#DFDFDF',
                white: '#FFFFFF',
                highlight: '#233495',
            },
            illustrations: {
                blue: {
                    light: '#CAD2FF',
                    main: '#233495',
                    dark: '#03136A',
                },
                yellow: {
                    light: '#FFE99D',
                    main: '#FFD542',
                    dark: '#E6BA21',
                },
                green: {
                    light: '#AEEDE6',
                    main: '#00BDAA',
                    dark: '#009384',
                },
                action: '#FE346E',
            },
        },

        dark: {
            feedback: {
                success1: '#74C69D',
                success2: '#00A594',
                failure1: '#D55252',
                failure2: '#FF8845',
            },
            background: {
                transparent1: 'rgba(120, 133, 207, 0.6)',
                transparent2: 'rgba(22, 39, 136, 0.6)',
                gradient_start: '#0E1D72',
                gradient_end: '#09165C',
                card: '#1D2A75',
                disabled: 'rgba(52, 52, 52, 0.25)',
                bottomBar: '#00094E',
            },
            blue: {
                light: '#7885CF',
                main: '#162788',
                dark: '#00094E',
            },
            yellow: {
                light: '#E6D28B',
                main: '#E6BA21',
                dark: '#BB9510',
            },
            green: {
                light: '#7BDBD0',
                main: '#00A594',
                dark: '#006D62',
            },
            action: '#FE346E',
            text: {
                dark: '#FFFFFF',
                light: '#FFFFFF',
                disabled: '#DFDFDF',
                white: '#FFFFFF',
                highlight: '#FFFFFF',
            },
            illustrations: {
                blue: {
                    light: '#03136A',
                    main: '#00094E',
                    dark: '#CAD2FF',
                },
                yellow: {
                    light: '#233495',
                    main: '#162788',
                    dark: '#BB9510',
                },
                green: {
                    light: '#CAD2FF',
                    main: '#233495',
                    dark: '#006D62',
                },
                action: '#00094E',
            },
        },
    },
};

export default theme;