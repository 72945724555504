// @flow
import React, {useContext, useState} from 'react';
import {PressableProps} from 'react-native';
import {ThemeContext} from '../../../libs/Hoc/ThemeProvider';
import {Pressable} from "react-native";
import {DropMenuActionType} from "../EDropMenu/EDropMenu";
import {useActionSheet} from '@expo/react-native-action-sheet';
import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import locales from "../../../locales/locales";

type Props = PressableProps & {
    children: Node,
    disabled?: boolean,
    actions: Array<DropMenuActionType>,
    customStyles?: ViewStyleProp | Array<ViewStyleProp>,
    menuTitle?: string,
};

const LongTouchMenu = ({
    children,
    disabled = false,
    actions = [],
    customStyles = {},
    menuTitle = 'Que souhaites-tu faire ?',
    ...rest
}: Props) => {
    const [colors] = useContext(ThemeContext);
    const {showActionSheetWithOptions} = useActionSheet();

    const [pressing, setPressing] = useState<boolean>(false);

    const getActionsWithCancel = () => [
        ...actions,
        {
            name: locales.t('global.actions.cancel'),
            action: () => null,
        },
    ];

    const actionSheetOptions = {
        options: getActionsWithCancel()?.map(a => a?.name ?? 'Erreur') ?? [],
        cancelButtonIndex: actions.length,
        destructiveButtonIndex: 0,
        title: menuTitle,
        destructiveColor: colors.feedback.failure1,
    };

    const onLongPress = () => {
        if(actions?.length > 0){
            showActionSheetWithOptions(
                actionSheetOptions,
                (buttonIndex) => actions?.[buttonIndex]?.action?.(),
            );
        }
    };

    return (
        <Pressable
            disabled={disabled}
            onLongPress={onLongPress}
            onPressIn={() => setPressing(true)}
            onPressOut={() => setPressing(false)}
            style={[{opacity: pressing ? 0.7 : 1}].concat(customStyles)}
            {...rest}
        >
            {children}
        </Pressable>
    );
};

export default LongTouchMenu;